import React, { useContext } from 'react'
import { Navigate } from '../../../../components/Router/Router'
import { SessionContext } from '../../../../context/SessionContext'

const IndexRedirect = () => {
  const sessionCtx = useContext(SessionContext)
  if (sessionCtx.isLoaded && !sessionCtx.currentUser) {
    return <Navigate to={`/login`} />
  }
  return <Navigate to={'/dashboard-redirect'} />
}

export default IndexRedirect
