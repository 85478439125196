import * as React from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import ColoredText from '../../../components/CustomText/ColoredText'
import { View } from 'react-native'
/* SVGR has dropped some elements not supported by react-native-svg: title */
const TutorialNew = () => {
  const { t } = useTranslation()
  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        padding: 40,
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <ColoredText style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 20, marginTop: 10 }}>
        {t('text.newUserTutorial.updated.text_1')}
      </ColoredText>
      <ColoredText style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}>
        {t('text.newUserTutorial.updated.text_2')}
      </ColoredText>
      <ColoredText style={{ fontSize: 20, fontWeight: 'bold' }}>{t('text.newUserTutorial.updated.text_3')}</ColoredText>
      <ColoredText style={{ fontSize: 20, fontWeight: 'bold' }}>{t('text.newUserTutorial.updated.text_4')}</ColoredText>
      <ColoredText style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 30 }}>
        {t('text.newUserTutorial.updated.text_5')}
      </ColoredText>
      {/* <TSpan x={46.188} y={152}>
          {t('text.newUserTutorial.updated.text_2')}
        </TSpan>
        <TSpan x={42.638} y={220}>
          {t('text.newUserTutorial.updated.text_3')}
        </TSpan>
        <TSpan x={89.662} y={254}>
          {t('text.newUserTutorial.updated.text_4')}
        </TSpan>
        <TSpan x={64.688} y={288}>
          {t('text.newUserTutorial.updated.text_5')}
        </TSpan> */}

      <View style={{ borderRadius: 7, paddingVertical: 10, paddingHorizontal: 25, backgroundColor: '#F5A623' }}>
        <ColoredText style={{ fontSize: 14, fontWeight: 'bold' }}>
          {t('text.newUserTutorial.updated.button')}
        </ColoredText>
      </View>
    </View>
  )
}
export default TutorialNew
