import { clone } from 'lodash'

const removeEmpty = (obj: Record<string, unknown>) => {
  const clonedObj = clone(obj)

  Object.entries(clonedObj).forEach(([key, val]) => {
    if (val && typeof val === 'object') {
      clonedObj[key] = removeEmpty(val as Record<string, unknown>)
    } else if (val == null) {
      delete clonedObj[key]
    }
  })

  return clonedObj
}

export default removeEmpty
