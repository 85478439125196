import { useFirestore } from 'react-redux-firebase'
import { useCallback, useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'

const useStoreNotificationSettings = () => {
  const { currentUser } = useContext(SessionContext)
  const firestore = useFirestore()

  return useCallback(
    async (settings: Record<string, any>) => {
      await firestore.doc(`user/${currentUser?.uid}`).set({ preferences: { notifications: settings } }, { merge: true })
    },
    [firestore]
  )
}

export default useStoreNotificationSettings
