import { useCallback, useState } from 'react'
import { AuthProvider, OAuthCredential } from '@firebase/auth-types'
import getApp from '../../../../firebase'
import AuthError from '../../../../utils/AuthError'

export const useLoginProvider = () => {
  const [loginState, setLoginState] = useState<{
    error?: AuthError
    loading: boolean
  }>({
    error: undefined,
    loading: false,
  })

  const handleProviderLogin = useCallback(async (provider: AuthProvider) => {
    try {
      setLoginState({ loading: true })
      await getApp().auth().signInWithPopup(provider)
      setLoginState({ loading: false, error: undefined })
    } catch (e) {
      setLoginState({ loading: false, error: e })
    }
  }, [])

  const handleCredentialLogin = useCallback(async (credential: OAuthCredential) => {
    try {
      setLoginState({ loading: true })
      await getApp().auth().signInWithCredential(credential)
      setLoginState({ loading: false, error: undefined })
      //Timeout required because it takes a few seconds until claims are set in onCreate-Function
      await new Promise((r) => setTimeout(r, 1000))
      await getApp().auth().currentUser?.getIdTokenResult(true)
      await new Promise((r) => setTimeout(r, 2000))
      await getApp().auth().currentUser?.getIdTokenResult(true)
      await new Promise((r) => setTimeout(r, 3000))
      await getApp().auth().currentUser?.getIdTokenResult(true)
    } catch (e) {
      setLoginState({ loading: false, error: e })
    }
  }, [])

  const handleLogin = useCallback(async ({ email, password }: { email: string; password: string }) => {
    try {
      setLoginState({ loading: true })
      await getApp().auth().signInWithEmailAndPassword(email, password)
      setLoginState({ loading: false, error: undefined })
      await new Promise((r) => setTimeout(r, 1000))
      await getApp().auth().currentUser?.getIdTokenResult(true)
      await new Promise((r) => setTimeout(r, 2000))
      await getApp().auth().currentUser?.getIdTokenResult(true)
      await new Promise((r) => setTimeout(r, 3000))
      await getApp().auth().currentUser?.getIdTokenResult(true)
    } catch (e) {
      if (e.code) {
        setLoginState({ loading: false, error: new AuthError(e.code) })
      } else {
        setLoginState({ loading: false, error: e })
      }
    }
  }, [])

  return {
    handleProviderLogin,
    handleCredentialLogin,
    handleLogin,
    loginState,
  }
}
