import { ReactNode } from 'react'
import { RouteProps } from 'react-router'
import BaseModule from '../module/base'
import DashboardModule from '../module/dashboard'
import FavoritesModule from '../module/favorites'
import SettingsModule from '../module/settings'
import ShopModule from '../module/shop'
import NotificationModule from '../module/notifications'
import NotFound from '../components/NotFound'

export default [
  ...BaseModule.routes(),
  ...DashboardModule.routes(),
  ...FavoritesModule.routes(),
  ...SettingsModule.routes(),
  ...ShopModule.routes(),
  ...NotificationModule.routes(),
  ...NotFound.routes(), // must be last entry to work
] as Array<{
  route: RouteProps
  name: string
  title?: ReactNode
  requireAuth?: boolean
}>
