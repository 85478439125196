import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { View, StyleSheet } from 'react-native'
import { pick } from 'lodash'
import { useTextColor } from '../../../hooks/useColors'
import { ColoredFontAwesomeIconProps } from '../types'

/** @description
 * Migrated from @webstollen/shared-react-components
 */
const ColoredFontAwesomeIcon = ({ icon, color, ...props }: ColoredFontAwesomeIconProps) => {
  const colorFromTheme = useTextColor()

  if (icon === 'spacer') {
    return (
      <View
        style={pick(StyleSheet.flatten(props.style), ['width', 'margin', 'padding', 'paddingRight', 'paddingLeft'])}
      />
    )
  }

  return <FontAwesomeIcon {...{ ...props, icon, color: color || colorFromTheme }} />
}

export { ColoredFontAwesomeIcon as FontAwesomeIcon }
