import { Toast } from 'native-base'
import React, { useState } from 'react'
import { View } from 'react-native'
import { functions } from '../../../firebase'
import { useTranslation } from '../../../hooks/useTranslation'
import Button from '../../Button'
import ColoredText from '../../CustomText/ColoredText'
import LoadingScreen from '../../LoadingScreen'
import useStyles from '../styles'
import { IUser } from '../../../types/user'

export const SetOwnerDialogContent = ({
  user,
  modalRef,
  setSetOwner,
}: {
  user: IUser
  modalRef: React.MutableRefObject<any>
  setSetOwner: (arg0: boolean) => void
}) => {
  const owner = functions().httpsCallable('calls-company-setOwner')
  const [settingOwner, setSettingOwner] = useState<boolean>(false)
  const styles = useStyles()
  const { t } = useTranslation()

  if (settingOwner) {
    return <LoadingScreen />
  }
  return (
    <>
      <ColoredText style={styles.modalContentText}>
        <>
          {t('accountOverview.user.submit_promote_owner_text', {
            user: `${user?.firstName} ${user?.lastName}`,
          })}
        </>
      </ColoredText>
      <ColoredText style={styles.modalContentText}>
        <>{t('accountOverview.user.promote_owner_text')}</>
      </ColoredText>
      <View style={styles.buttonWrapper}>
        <Button
          style={styles.cancelButton}
          onPress={() => {
            if (modalRef && modalRef.current) modalRef.current.setVisible(false)
            setSetOwner(false)
          }}
        >
          {t('accountOverview.user.cancel')}
        </Button>
        <Button
          onPress={() => {
            setSettingOwner(true)
            owner({ uid: user.id })
              .then(() => {
                Toast.show({
                  title: t('accountOverview.user.successfully_promoted'),
                })
              })
              .catch((err) => {
                Toast.show({
                  title: t('accountOverview.user.error_promoting'),
                  description: err.message,
                })
              })
              .finally(() => {
                if (modalRef && modalRef.current) modalRef.current.setVisible(false)
                setSettingOwner(false)
                setSetOwner(false)
              })
          }}
          textStyle={{ color: 'white' }}
          style={styles.promoteButton}
        >
          {t('accountOverview.user.promote_owner')}
        </Button>
      </View>
    </>
  )
}
