import { TextInput } from 'react-native'
import React, { useEffect } from 'react'
import useStyles from '../styles'
const NumberInput = ({
  defaultValue = 3,
  disabled = true,
  updateValue,
}: {
  defaultValue: number
  disabled: boolean
  updateValue: (value: number) => void
}) => {
  const styles = useStyles()
  const regex = new RegExp('^[0-9]*$')
  const [value, setValue] = React.useState(0)
  useEffect(() => {
    setValue(+defaultValue)
  }, [])

  const textChangeHandler = (text: string) => {
    if (disabled) return
    if (!regex.test(text)) return
    setValue(+text)
    updateValue(+text)
  }

  return (
    <TextInput
      style={[styles.numberInput, disabled && styles.disabled]}
      keyboardType="numeric"
      maxLength={3}
      value={value.toString()}
      onChangeText={textChangeHandler}
      focusable={!disabled}
      editable={!disabled}
    />
  )
}

export default NumberInput
