import React, { useContext, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'
import WidgetTypes from './WidgetSettings/Type'
import WidgetShop from './WidgetSettings/Shop'
import useAddWidget from '../../../../hooks/widget/useAddWidget'
import { SessionContext } from '../../../../context/SessionContext'
import Pagewrapper from '../../../../components/PageWrapper'
import { get } from 'lodash'
import shopWidgets from '../../../../widgets'
import WidgetConfig from './WidgetSettings/Config'
import { useNavigate, useParams } from '../../../../components/Router/Router'
import LoadingScreen from '../../../../components/LoadingScreen'
import BoldText from '../../../../components/CustomText/BoldText'
import PlatformCard from './WidgetSettings/Shop/PlatformCard'
import ColoredText from '../../../../components/CustomText/ColoredText'
import { ImageSource, Image } from 'expo-image'

const CreateWidget = () => {
  const styles = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [selectedType, selectType] = useState<string | null>()
  const [selectedShop, selectShop] = useState<string | null>()
  const [selectedIsExternal, selectShopIsExternal] = useState<boolean>(false)
  const [selectedConfig, selectConfig] = useState<Record<string, any> | null>()
  const [selectedPluginVersion, selectPluginVersion] = useState<string | null>()
  const [icon, setIcon] = useState<string | ImageSource | ImageSource[] | null>()
  const [typeIcon, setTypeIcon] = useState<any | null>()
  const [title, setTitle] = useState<string | null>()
  const [selectedShopSystem, selectShopSystem] = useState<string | null>()

  const { shops, company } = useContext(SessionContext)

  const { config = undefined } = selectedType ? get(shopWidgets, selectedType || '') : { config: undefined }

  const { dashboardId } = useParams()

  const addWidget = useAddWidget(dashboardId)

  const authorizations = company?.authorizations

  if (!shops) {
    return <LoadingScreen text={`${t('loading.shops')}`} />
  }

  const disabledButton = !selectedShop || !selectedType

  return (
    <Pagewrapper isScrollable={true} fullScreen>
      <View style={{ height: '100%', flexDirection: 'column' }}>
        <>
          {selectedShop && selectedShop !== null ? (
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center', marginVertical: 20 }}>
              {selectedType && selectedType !== null ? null : (
                <PlatformCard
                  isExternal={selectedIsExternal}
                  domain={selectedShop}
                  platform={{ name: '' }}
                  image={icon || ''}
                  select={() => selectShop(null)}
                  buttonText="ändern"
                />
              )}
            </View>
          ) : (
            <WidgetShop
              selectedShop={selectedShop}
              selectShop={selectShop}
              selectShopSystem={selectShopSystem}
              selectPluginVersion={selectPluginVersion}
              selectShopIsExternal={selectShopIsExternal}
              setIcon={setIcon}
              shops={shops}
            />
          )}
          {selectedShop && !selectedType && (
            <WidgetTypes
              selectedType={selectedType}
              selectedShopSystem={selectedShopSystem}
              selectedPluginVersion={selectedPluginVersion}
              selectType={selectType}
              setTitle={setTitle}
              setTypeIcon={setTypeIcon}
              auths={authorizations}
            />
          )}
          {selectedShop && selectedType && config && !selectedConfig && (
            <WidgetConfig config={config} selectConfig={selectConfig} icon={icon} />
          )}
          {selectedShop && selectedType && (!config || (config && selectedConfig)) && (
            <>
              <View
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',

                  padding: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    selectType(null), selectConfig(null)
                  }}
                  style={styles.widgetView}
                >
                  <View
                    style={{
                      width: '100%',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    <View
                      style={{
                        width: 60,
                        height: 60,
                        backgroundColor: 'white',
                        borderRadius: selectedIsExternal ? 10 : 40,
                        overflow: 'hidden',
                        margin: 5,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        marginRight: 20,
                      }}
                    >
                      <Image source={icon} style={{ width: 50, height: 50 }} contentFit={'contain'} />
                    </View>
                    <BoldText numberOfLines={1}>{selectedIsExternal}</BoldText>
                    <BoldText numberOfLines={1}>{selectedShop}</BoldText>
                  </View>
                  <View style={styles.divider} />
                  {typeIcon && typeIcon !== null && (
                    <Image source={typeIcon} style={styles.typeIcon} contentFit={'contain'} />
                  )}
                  <ColoredText style={{ marginVertical: 3, width: '80%', textAlign: 'center' }}>{title}</ColoredText>
                  <ColoredText
                    style={{ fontSize: 10, marginTop: 15, marginBottom: 5, width: '80%', textAlign: 'center' }}
                  >{`click to edit`}</ColoredText>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <TouchableOpacity
                  style={[styles.button, disabledButton && styles.disabledButton]}
                  disabled={disabledButton}
                  onPress={() => {
                    //check if allowed
                    addWidget({
                      type: `${selectedType.substring(0, selectedType.length - 3)}`, //Do not add Widget-Version anymore
                      settings: {
                        shop: selectedShop,
                        interval: 'D30',
                        ...(config && selectedConfig && selectedConfig),
                      },
                    }).then(() => {
                      navigate(-1)
                    })
                  }}
                >
                  <BoldText style={styles.buttonText}>
                    <>{t('dashboard.addWidget')}</>
                  </BoldText>
                </TouchableOpacity>
              </View>
            </>
          )}
        </>
      </View>
    </Pagewrapper>
  )
}

export default CreateWidget
