import React, { PropsWithChildren, useState } from 'react'
import UpsellingDialog from '../components/UpsellingDialog'

export const UpsellingContext = React.createContext<UpsellingContextValueType>({
  open: (_) => null,
  close: () => null,
})

export type UpsellingContextValueType = {
  open: (preferredKey?: string) => void
  close: () => void
}

const UpsellingProvider = ({ children }: PropsWithChildren<unknown>) => {
  // const { user } = useContext(SessionContext)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  return (
    <UpsellingContext.Provider
      value={{
        open: open,
        close: close,
      }}
    >
      {children}
      <UpsellingDialog visible={isOpen} setIsVisible={setIsOpen} />
    </UpsellingContext.Provider>
  )
}

export default UpsellingProvider
