import i18n from '../../../../../../../locales/i18n'

export const getErrorInfosByMessage = (
  message: string | null,
  name?: string
): { headline: string; text: string | null; notes: Array<string> | null } => {
  switch (message) {
    case 'err_booking_exists':
      return {
        headline:
          name !== undefined ? i18n.t('checkout.errors.title_name', { name: name }) : i18n.t('checkout.errors.title'),
        text: i18n.t('checkout.errors.err_booking_exists.text'),
        notes: [
          i18n.t('checkout.errors.err_booking_exists.note_1'),
          i18n.t('checkout.errors.err_booking_exists.note_2'),
          i18n.t('checkout.errors.err_booking_exists.note_3'),
        ],
      }
    case 'err_booking_data_missing':
      return {
        headline:
          name !== undefined ? i18n.t('checkout.errors.title_name', { name: name }) : i18n.t('checkout.errors.title'),
        text: i18n.t('checkout.errors.err_booking_data_missing.text'),
        notes: [
          i18n.t('checkout.errors.err_booking_data_missing.note_1'),
          i18n.t('checkout.errors.err_booking_data_missing.note_2'),
        ],
      }
    case 'err_booking_email_not_verified':
      return {
        headline:
          name !== undefined ? i18n.t('checkout.errors.title_name', { name: name }) : i18n.t('checkout.errors.title'),
        text: i18n.t('checkout.errors.err_booking_email_not_verified.text'),
        notes: null,
      }
    case 'err_booking_missing_billing_data':
      return {
        headline:
          name !== undefined ? i18n.t('checkout.errors.title_name', { name: name }) : i18n.t('checkout.errors.title'),
        text: i18n.t('checkout.errors.err_booking_missing_billing_data.text'),
        notes: null,
      }
    case 'err_booking_no_admin':
      return {
        headline:
          name !== undefined ? i18n.t('checkout.errors.title_name', { name: name }) : i18n.t('checkout.errors.title'),
        text: i18n.t('checkout.errors.err_booking_no_admin.text'),
        notes: [i18n.t('checkout.errors.err_booking_no_admin.note_1')],
      }
    default:
      return {
        headline:
          name !== undefined ? i18n.t('checkout.errors.title_name', { name: name }) : i18n.t('checkout.errors.title'),
        text: i18n.t('checkout.errors.default.text'),
        notes: [i18n.t('checkout.errors.default.note_1'), i18n.t('checkout.errors.default.note_2')],
      }
  }
}
