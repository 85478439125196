import React, { useContext, useRef, useState } from 'react'
import { Button, Center, Modal, Text } from 'native-base'
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form'
import TextInputWithIcon from '../../../../components/TextInputWithIcon'
import { TextInput, View } from 'react-native'
import { IModalProps } from 'native-base/lib/typescript/components/composites/Modal/types'
import { useTranslation } from 'react-i18next'
import WidgetShop from '../CreateWidget/WidgetSettings/Shop'
import { SessionContext } from '../../../../context/SessionContext'
import useStyles from './styles'
import icons from '../../../../constants/icons'
import LoadingScreen from '../../../../components/LoadingScreen'
import ColoredText from '../../../../components/CustomText/ColoredText'

export type CreateDashboardFormType = {
  dashboardName: string
  shopDomain: string
  defaultWidgets: 'empty' | 'standard'
}

const CreateDashboardModal = ({
  createDashboard,
  loading,
  isOpen,
  onClose,
}: IModalProps & { createDashboard: SubmitHandler<CreateDashboardFormType>; loading: boolean }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const [selectedShopDomain, _selectShopDomain] = useState<string>()
  const [defaultWidget, setDefaultWidget] = useState<'empty' | 'standard'>('standard')
  const [_shop, selectShopSystem] = useState<string | null>()
  const [, selectPluginVersion] = useState<string | null>()
  const [, setIcon] = useState<string | null>()
  const selectShopDomain = (shopDomain: string | null) => {
    setValue('shopDomain', shopDomain || 'n/a')
    _selectShopDomain(shopDomain || 'n/a')
  }
  const { shops } = useContext(SessionContext)
  const { domain } = Object.values(shops || {})[0]

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    control,
    setValue,
  } = useForm<CreateDashboardFormType>({ defaultValues: { shopDomain: domain, defaultWidgets: defaultWidget } })
  const textInputRef = useRef<TextInput>(null)
  if (!shops) {
    return <LoadingScreen text={t('loading.shops')} />
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxWidth="800px">
        <Modal.CloseButton />
        <Modal.Header>{t('dashboard.create.title')}</Modal.Header>
        <Modal.Body>
          <Center>
            <View style={styles.containerInModal}>
              <Text marginY={3}>{t('dashboard.create.message')}</Text>
              <Controller
                name={'dashboardName'}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInputWithIcon
                    style={styles.textInput}
                    refForward={textInputRef}
                    icon={[icons.faIconStyle, 'clipboard']}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={value ?? ''}
                    placeholder={`${t('dashboard.create.placeholder')}`}
                    autoCorrect={false}
                    editable={!loading}
                  />
                )}
              />
              <View>
                {errors?.dashboardName ? (
                  <ColoredText>{(errors.dashboardName as FieldError).message}</ColoredText>
                ) : null}
              </View>
            </View>
            <View style={styles.containerInModal}>
              <Text mt={3}>{t('dashboard.create.widgets')}</Text>
              <Controller
                name={'defaultWidgets'}
                control={control}
                rules={{
                  required: true,
                }}
                render={() => (
                  <View style={{ flexDirection: 'row' }}>
                    <Button
                      onPress={() => {
                        setDefaultWidget('standard')
                        setValue('defaultWidgets', 'standard')
                      }}
                      isDisabled={loading || !dirtyFields.dashboardName}
                      variant={defaultWidget === 'standard' ? 'solid' : 'unstyled'}
                    >
                      {t('dashboard.create.radio.standard')}
                    </Button>
                    <Button
                      variant={defaultWidget === 'empty' ? 'solid' : 'unstyled'}
                      onPress={() => {
                        setDefaultWidget('empty')
                        setValue('defaultWidgets', 'empty')
                      }}
                      isDisabled={loading || !dirtyFields.dashboardName}
                    >
                      {t('dashboard.create.radio.empty')}
                    </Button>
                  </View>
                )}
              />

              <View>
                {errors?.defaultWidgets ? (
                  <ColoredText>{(errors.defaultWidgets as FieldError).message}</ColoredText>
                ) : null}
              </View>
            </View>
            <WidgetShop
              selectedShop={selectedShopDomain}
              selectPluginVersion={selectPluginVersion}
              setIcon={setIcon}
              selectShopSystem={selectShopSystem}
              selectShopIsExternal={() => {}}
              selectShop={selectShopDomain}
              shops={shops}
            />
          </Center>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button variant="ghost" colorScheme="blueGray" onPress={() => onClose()}>
              {t('button.cancel')}
            </Button>
            <Button onPress={handleSubmit(createDashboard)} isDisabled={loading || !dirtyFields.dashboardName}>
              {t('button.confirm')}
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

export default CreateDashboardModal
