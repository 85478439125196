import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import useStyles from './styles'
import useFavorites from '../../../../hooks/favorites/useFavorites'
import FavoriteButton from '../../../../layout/Header/FavoriteButton'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import NewUserTutorial from '../../../dashboard/components/NewCostumerTutorial/NewUserTutorial'
import PageWrapper from '../../../../components/PageWrapper'
import { useNavigate } from '../../../../components/Router/Router'
import LoadingScreen from '../../../../components/LoadingScreen'
import ColoredText from '../../../../components/CustomText/ColoredText'

const Favorites = () => {
  const styles = useStyles()
  const navigate = useNavigate()

  const favorites = useFavorites()

  if (!isLoaded(favorites)) {
    return <LoadingScreen text={'Loading Favorites'} />
  }

  if (isEmpty(favorites)) {
    return <NewUserTutorial />
  }

  return (
    <PageWrapper isScrollable={true}>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          {Object.keys(favorites).map(
            (id) =>
              favorites[id] && (
                <TouchableOpacity
                  key={favorites[id].url}
                  style={styles.button}
                  onPress={() => navigate(favorites[id].url)}
                >
                  <FavoriteButton
                    url={favorites[id].url}
                    title={favorites[id].title}
                    subtitle={`${favorites[id].subtitle}`}
                  />
                  <View style={styles.textWrapper}>
                    <ColoredText style={styles.buttonText} numberOfLines={1}>
                      {favorites[id].title}
                    </ColoredText>
                    <ColoredText style={styles.subtitle}>{favorites[id].subtitle}</ColoredText>
                  </View>
                </TouchableOpacity>
              )
          )}
        </View>
      </View>
    </PageWrapper>
  )
}

export default Favorites
