import React from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useForm, FieldError, Controller } from 'react-hook-form'
import Button from '../../Button'
import useStyles from '../login-styles'
import { yupResolver } from '@hookform/resolvers/yup'
import ResetPasswordValidator from '../../../validators/ResetPasswordValidator'
import icons from '../../../constants/icons'
import ColoredText from '../../CustomText/ColoredText'
import InputWithIcon from '../../InputWithIcon'

interface IProps {
  onSubmit: (params: { email: string }) => void
  loading: boolean
}

const ResetPasswordForm = ({ onSubmit, loading }: IProps) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<{ email: string }>({
    resolver: yupResolver(ResetPasswordValidator),
  })
  const { t } = useTranslation()

  const styles = useStyles()
  return (
    <View>
      <Controller
        control={control}
        name={'email'}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputWithIcon
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            icon={[icons.faIconStyle, 'user']}
            style={styles.textInput}
            placeholder={`${t('placeholder.baseLogin.email')}`}
            editable={!loading}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
      />

      <View style={styles.errorWrapper}>
        {errors.email ? (
          <ColoredText style={{ color: 'black', padding: 5 }}>{(errors.email as FieldError).message}</ColoredText>
        ) : null}
      </View>

      <Button style={styles.button} textStyle={styles.buttonText} onPress={handleSubmit(onSubmit)} disabled={loading}>
        {t('button.baseResetPassword.reset')}
      </Button>
    </View>
  )
}

export default ResetPasswordForm
