import React from 'react'
import { useNavigate } from '../../../../../../components/Router/Router'

const Details = () => {
  const navigate = useNavigate()
  navigate('/settings/subscription', { replace: true })
  return <></>
}

export default Details
