import { StyleSheet } from 'react-native'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      marginVertical: 15,
    },
    title: {
      fontSize: 30,
      fontWeight: '600',
      marginVertical: 15,
    },
    titleNative: {
      fontSize: 24,
      fontWeight: '500',
      marginVertical: 15,
    },
  })
}

export default useStyles
