import { StyleSheet } from 'react-native'
import { usePrimaryBackground, useSecondaryBackground } from '../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: usePrimaryBackground(),
    },
    container: {
      flex: 1,
      width: '100%',
      justifyContent: 'flex-end',
      marginBottom: 5,
    },
    button: {
      marginHorizontal: 10,
      marginVertical: 1,
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: 50,
      backgroundColor: useSecondaryBackground(),
    },
    disabledButton: {
      opacity: 0.5,
    },
    buttonText: {},
  })
}

export default useStyles
