import { Platform, StyleSheet } from 'react-native'
import { useHighlightColor } from '../../../../../hooks/useColors'
import { Themes } from '../../../../../types/dashbar/theme'
import { useTheme } from '../../../../../utils/types/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    iconWrapper: {
      borderRadius: 12.5,
      backgroundColor: theme === Themes.Dark ? '#131526' : '#E7E8EB',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    colorPaid: {
      backgroundColor: '#24CB70',
    },
    colorPending: {
      backgroundColor: '#FDAB49',
    },
    orderValue: {
      color: useHighlightColor(),
    },
    actionSubtitle: {
      marginTop: Platform.select({ default: undefined, web: 2 }),
    },
    noData: {
      flex: 1,
      alignItems: 'center',
      textAlign: 'center',
    },
  })
}

export default useStyles
