import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import Widget from '../../../../components/WidgetNoAPI'
import { useTranslation } from 'react-i18next'
import { Image, Platform } from 'react-native'
import { functions } from '../../../../../firebase'
import colors from '../../../../../constants/colors'
import { calculateDatesOfInterval } from './funcs'
import { httpsCallable } from 'firebase/functions'
import { AmazonBuyerTypeSalesObject, AmazonSalesObject, AmazonPieChartData } from './types'
import IntervalSelection from '../../../../components/IntervalSelection'
import useIntervals from '../../../../../hooks/widget/useIntervals'
import { useUpdateWidgetContext } from '../../../../../types/Widget'
import BuyerTypeSkeleton from './BuyerTypeSkeleton'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import AmazonPieChart from './AmazonPieChart'
import { useFirestore } from 'react-redux-firebase'
import { SessionContext } from '../../../../../context/SessionContext'

const AmazonBuyerType = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const updateWidget = useUpdateWidgetContext()
  const getOrderMetricsFunc = httpsCallable(functions(), 'widgets-amazon-ordermetrics')
  const [b2b, setB2B] = useState<Array<AmazonSalesObject>>([])
  const [b2c, setB2C] = useState<Array<AmazonSalesObject>>([])
  const firestore = useFirestore()
  const { company } = useContext(SessionContext)
  const [buyerTypeData, setBuyerTypeData] = useState<{ b2b: number; b2c: number }>({ b2b: 0, b2c: 0 })
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<{ text: string; link: string | null } | null>(null)
  const [needsConfig, setNeedsConfig] = useState<boolean>(false)

  const onIntervalChange = useCallback(
    (newInterval: string) => {
      const newWidget = {
        ...widget,
        settings: {
          ...widget.settings,
          interval: newInterval,
        },
      }
      updateWidget(newWidget)
    },
    [widget, updateWidget]
  )
  const intervals = useIntervals()

  useEffect(() => {
    firestore
      .collection(`company/${company?.id}/extension_config`)
      .doc('amazon')
      .get()
      .then((doc) => {
        if (!doc.exists || !doc.data()) {
          setNeedsConfig(true)
          return
        }
        if (!doc.data()?.oauth || !doc.data()?.oauth.token) {
          setNeedsConfig(true)
          return
        }
        setNeedsConfig(false)
      })
  }, [firestore, company])

  useEffect(() => {
    getOrderMetricsFunc({})
      .then((data: Record<string, any>) => {
        if (data.data.length === 0) {
          setError({ text: `Es konnten keine Daten bei Amazon gefunden werden.`, link: null })
          setLoading(false)
        } else {
          const tmpB2B: Array<AmazonSalesObject> = []
          const tmpB2C: Array<AmazonSalesObject> = []
          if (data.data === null) return
          data.data.forEach((element: AmazonBuyerTypeSalesObject) => {
            tmpB2B.push({
              interval: element.interval,
              sales: element.b2b,
            })
            tmpB2C.push({
              interval: element.interval,
              sales: element.b2c,
            })
          })

          setB2B(tmpB2B)
          setB2C(tmpB2C)
        }
      })
      .catch(() => {
        setError({ text: `Es konnten keine Daten von Amazon abgerufen werden.`, link: null })
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    mapDataToInterval()
    if (b2b.length && b2c.length) setLoading(false)
  }, [widget.settings.interval, b2b, b2c])

  // wird ausgeführt, wenn sich das Interval ändert. Berechnet die korrekten Daten für das ausgewählte Interval
  const mapDataToInterval = () => {
    if (widget.settings.interval === undefined) return
    const dates = calculateDatesOfInterval(widget.settings.interval)
    if (b2b === null || b2c === null) return
    let tmpB2B = 0,
      tmpB2C = 0

    b2b.forEach((value) => {
      const dateDB1 = new Date(value.interval.split('--')[0])

      if (dates !== undefined && dateDB1 < dates?.dateEnd && dateDB1 >= dates?.dateStart) {
        tmpB2B += value.sales
      }
    })

    b2c.forEach((value) => {
      const dateDB1 = new Date(value.interval.split('--')[0])

      if (dates !== undefined && dateDB1 < dates?.dateEnd && dateDB1 >= dates?.dateStart) {
        tmpB2C += value.sales
      }
    })

    setBuyerTypeData({ b2b: tmpB2B, b2c: tmpB2C })
  }

  return (
    <Widget
      widget={widget}
      loading={loading}
      isError={error !== null}
      needsConfig={needsConfig}
      error={error}
      loadingSkeleton={<BuyerTypeSkeleton />}
      customOptions={
        <IntervalSelection
          intervals={intervals}
          key="interval-selection"
          settings={widget && widget.settings}
          onIntervalChange={onIntervalChange}
        />
      }
      image={{
        source:
          Platform.OS === 'web'
            ? require('../../../../../assets/imgs/widgets/amazon/amazon_logo.svg')
            : Image.resolveAssetSource(require('../../../../../assets/imgs/widgets/amazon/amazon_logo.png')).uri,
        alt: '',
      }}
      {...props}
      title={t('shop.widget.amazon.amazonBuyerType.title')}
      hasInterval
      onClickWidget={() => {}}
      footerContent={
        <ColoredText>{(intervals.find(({ value }) => widget.settings.interval === value) || {}).label}</ColoredText>
      }
    >
      {([size]) => {
        const pieData: Array<AmazonPieChartData> = [
          {
            name: t('shop.widget.amazon.amazonBuyerType.b2b'),
            color: colors.color1,
            value: buyerTypeData.b2b,
            key: 1,
            svg: {
              fill: colors.color1,
            },
          },
          {
            name: t('shop.widget.amazon.amazonBuyerType.b2c'),
            color: colors.color3,
            value: buyerTypeData.b2c,
            key: 2,
            svg: {
              fill: colors.color3,
            },
          },
        ]
        if (error !== null) {
          return <></>
        }
        // if (Platform.OS === 'web') {
        //   return <AmazonPieChart size={size} pieData={pieData} />
        // } else {
        //   return <AmazonPieChartMobile size={size} pieData={pieData} />
        // }
        return <AmazonPieChart size={size} pieData={pieData} />
      }}
    </Widget>
  )
}

export default AmazonBuyerType
