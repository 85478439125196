import React, { useRef } from 'react'
import { Button } from 'native-base'
import { useTranslation } from 'react-i18next'
import { useAlertDialog } from '../../../../components/AlertDialog'
import { TouchableOpacity } from 'react-native'
import useStyles from './styles'
import icons from '../../../../constants/icons'
import { useNavigate } from '../../../../components/Router/Router'
import { useHighlightColor } from '../../../../hooks/useColors'
import { FontAwesomeIcon } from '../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import BoldText from '../../../../components/CustomText/BoldText'

const DeleteDashboardButtonWithModal = ({
  removeDashboard,
  mayManageDashboard,
}: {
  removeDashboard: () => Promise<void>
  mayManageDashboard: boolean
}) => {
  const styles = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const highlightColor = useHighlightColor()

  const cancelRef = useRef(null)
  const { AlertDialog, open, close, alertDialogProps } = useAlertDialog({ leastDestructiveRef: cancelRef })

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          if (mayManageDashboard) {
            open()
          } else {
            history.push('/upselling')
          }
        }}
        style={styles.manageDashboardButton}
      >
        <FontAwesomeIcon icon={[icons.faIconStyle, 'trash']} color={highlightColor} />
        <BoldText style={[styles.widgetText, { color: highlightColor }]}>{t('dashboard.deleteDashboard')}</BoldText>
      </TouchableOpacity>
      <AlertDialog {...alertDialogProps}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>{t('dashboard.delete.title')}</AlertDialog.Header>
          <AlertDialog.Body>{t('dashboard.delete.body')}</AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button variant="unstyled" colorScheme="coolGray" onPress={close} ref={cancelRef}>
                {t('dashboard.widgets.delete.cancel')}
              </Button>
              <Button
                colorScheme="danger"
                onPress={() => {
                  navigate(`/settings/dashboard`, { replace: true })
                  removeDashboard()
                }}
              >
                {t('dashboard.delete.title')}
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  )
}

export default DeleteDashboardButtonWithModal
