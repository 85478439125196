import React from 'react'
import Button from '../../components/Button'
import ColoredText from '../../components/CustomText/ColoredText'
import { FontAwesomeIcon } from '../../components/FontAwesome/ColoredFontAwesomeIcon'
import { useTranslation } from '../../hooks/useTranslation'
import useStyles from './styles'

const EmailLogin = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  return (
    <Button style={styles.button}>
      <>
        <FontAwesomeIcon style={{ color: 'white' }} icon={['fas', 'envelope']} />
        <ColoredText style={{ color: 'white' }}>
          <> {t('button.baseLogin.disconnect', { provider: 'Email' })}</>
        </ColoredText>
      </>
    </Button>
  )
}

export default EmailLogin
