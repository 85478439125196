import React from 'react'
import moment from 'moment'
import numbro from 'numbro'
import ListView from '../../../../../components/ListView'
import htmlDecode from '../../../../../utils/htmlDecode'
import { IWidgetProps } from '../../../../../types/props/Widget'
import useStyles from './styles'
import getOrderStatusIcon from '../../../../../utils/get-order-status-icon'
import { OrdersT } from '../../../../../types/widgetDataTypes/V3/types'
import { Platform } from 'react-native'
import Widget from '../../../../components/Widget'
import { useNavigate } from '../../../../../components/Router/Router'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { FontAwesomeIcon } from '../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import OrdersSkeleton from './OrdersSkeleton'

const Orders = ({ widget, ...props }: IWidgetProps) => {
  const styles = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Widget<OrdersT>
      title={t('shop.widget.orders.title')}
      widget={widget}
      loadingSkeleton={<OrdersSkeleton />}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, rowsPerPage = 4, interval = 'D30' }, { page = 1 } = {}) => ({
          body: {
            module: 'Analytics',
            endpoint: ['orders'],
            params: {
              options: {
                details: false,
                refresh: autoRefresh ? refreshInterval : undefined,
                interval,
                pagination: { descending: true, page, rowsPerPage },
              },
            },
          },
          widget: 'orders',
        }),
      ]}
      onClickWidget={() => {
        navigate(`/analytics/orders/${widget.settings.shop}`)
      }}
      {...props}
    >
      {([, { order_arr }, , , size]) => {
        return (
          <ListView
            size={size}
            styles={{
              thumbnailWrapper: styles.iconWrapper,
              actionTitleText: styles.orderValue,
              actionSubtitle: styles.actionSubtitle,
            }}
            data={order_arr?.map(
              ({ current_order_id, customer, status, order_number, total_net_price, currency, order_date }) => ({
                id: current_order_id,
                icon: (
                  <FontAwesomeIcon
                    color={'white'}
                    icon={getOrderStatusIcon(status)}
                    size={Platform.select({ default: undefined, web: 'sm' })}
                  />
                ),
                mainTitle:
                  customer.first_name === null && customer.last_name === null
                    ? t('shop.widget.orders.customer')
                    : customer.first_name + ' ' + customer.last_name,
                mainSubtitle: order_number,
                actionTitle: numbro(total_net_price).formatCurrency({
                  ...numbro.languageData().currencyFormat,
                  currencySymbol: htmlDecode(currency),
                  spaceSeparated: true,
                }),
                actionSubtitle: moment(new Date(order_date /* *1000*/)).format('L LT'),

                onPress: (event) => {
                  event.preventDefault()
                  navigate(`/analytics/orders/${widget.settings.shop}/${current_order_id}`)
                },
              })
            )}
          />
        )
      }}
    </Widget>
  )
}

export default Orders
