export const intervalListFree = [
  {
    name: `2 Stunden`,
    id: '120',
  },
  {
    name: `4 Stunden`,
    id: '240',
  },
  {
    name: `8 Stunden`,
    id: '480',
  },
  {
    name: `1 Tag`,
    id: '1440',
  },
]

export const intervalListBusiness = [
  {
    name: `10 Minuten`,
    id: '10',
  },
  {
    name: `15 Minuten`,
    id: '15',
  },
  {
    name: `20 Minuten`,
    id: '20',
  },
  {
    name: `30 Minuten`,
    id: '30',
  },
  {
    name: `1 Stunden`,
    id: '60',
  },
  {
    name: `2 Stunden`,
    id: '120',
  },
  {
    name: `4 Stunden`,
    id: '240',
  },
  {
    name: `8 Stunden`,
    id: '480',
  },
  {
    name: `1 Tag`,
    id: '1440',
  },
]
export const intervalListPro = [
  {
    name: `1 Minute`,
    id: '1',
  },
  {
    name: `2 Minuten`,
    id: '2',
  },
  {
    name: `3 Minuten`,
    id: '3',
  },
  {
    name: `4 Minuten`,
    id: '4',
  },
  {
    name: `5 Minuten`,
    id: '5',
  },
  {
    name: `6 Minuten`,
    id: '6',
  },
  {
    name: `7 Minuten`,
    id: '7',
  },
  {
    name: `8 Minuten`,
    id: '8',
  },
  {
    name: `9 Minuten`,
    id: '9',
  },
  {
    name: `10 Minuten`,
    id: '10',
  },
  {
    name: `15 Minuten`,
    id: '15',
  },
  {
    name: `20 Minuten`,
    id: '20',
  },
  {
    name: `30 Minuten`,
    id: '30',
  },
  {
    name: `45 Minuten`,
    id: '45',
  },
  {
    name: `1 Stunden`,
    id: '60',
  },
  {
    name: `1,5 Stunden`,
    id: '90',
  },
  {
    name: `2 Stunden`,
    id: '120',
  },
  {
    name: `4 Stunden`,
    id: '240',
  },
  {
    name: `8 Stunden`,
    id: '480',
  },
  {
    name: `1 Tag`,
    id: '1440',
  },
]
