import { StyleSheet } from 'react-native'
import { useInvertedTextColor, useTextColor } from '../../../../../../../hooks/useColors'
import { useFontFamily } from '../../../../../../../hooks/useFontFamily'

const useStyles = () => {
  const textColor = useTextColor()
  const invertedTextColor = useInvertedTextColor()
  const { extrabold } = useFontFamily()
  return StyleSheet.create({
    paymentMethod: {
      marginTop: 25,
      width: '95%',
      minWidth: '95%',
      maxWidth: '95%',
      flexDirection: 'row',
      justifyContent: 'center',
      borderRadius: 20,
      borderWidth: 1,
      padding: 15,
      borderStyle: 'solid',
      borderColor: textColor,
      backgroundColor: textColor,
    },
    paymentMethodTitleWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    paymentMethodTitle: {
      fontSize: 22,
      fontFamily: extrabold,
      marginLeft: 10,
      color: invertedTextColor,
    },
  })
}

export default useStyles
