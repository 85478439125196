import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IWidgetProps } from '../../../../../../types/props/Widget'
import { View } from 'react-native'
import Widget from '../../../../../components/WidgetNoAPI'
import DefaultDashbarIcon from '../../../../../components/DefaultDashbarIcon.tsx'
import SalesForecastLinegraph from './linegraph'
import { SessionContext } from '../../../../../../context/SessionContext'
import ForecastSkeleton from './SalesForecastSkeleton'
import { useFirestore } from 'react-redux-firebase'
import moment from 'moment'
export interface ForecastBuffer {
  forecast: Array<ForecastItem>
  pastSales: Array<PastSales>
}
export interface ForecastItem {
  ds: string
  yhat: number
  yhat_lower: number
  yhat_upper: number
}
export interface PastSales {
  startDate: string
  dateStr: string
  value: number
}
export interface SalesPoint {
  startDate: string
  endDate: string
  value: number
}
export interface Interval {
  label: string
  value: string
  active: boolean
}
function formatDate(date: string): string {
  const [day, month] = date.split('.')
  return `${day}.${month}`
}

const SalesForecast = ({ widget, dashboardId, shops }: IWidgetProps) => {
  const { t } = useTranslation()
  const shopId = widget.settings.shop
  const { company } = useContext(SessionContext)
  const { currentUser } = useContext(SessionContext)
  const [loadingNewInterval, setLoadingNewInterval] = useState(true)
  // const endpoint = 'http://127.0.0.1:8080/'
  const endpoint = 'https://salesforecast-58926.lm.r.appspot.com'
  const db = useFirestore()
  const forecastBufferQuery = db
    .collection('company')
    .doc(company?.id)
    .collection('shop')
    .doc(shopId)
    .collection('forecast')
    .doc('sale')

  const displayedSalesList: Array<{ y: number; ds: number; date: string }> = []
  const displayedForecastList: Array<{ y: number; ds: number; date: string }> = []
  const displayedForecastUpperList: Array<{ y: number; ds: number; date: string }> = []
  const displayedForecastLowerList: Array<{ y: number; ds: number; date: string }> = []

  const [data, setData] = useState<ForecastBuffer>()
  const shopIntervallist: Array<Interval> = []
  Object.entries(shops).map((shop) => shopIntervallist.push({ label: shop[0], value: shop[0], active: false }))
  useEffect(() => {
    forecastBufferQuery
      .get()
      .then((querySnapshot) => {
        const buffer = querySnapshot.data()

        return buffer
      })
      .then((data) => {
        const lastUpdated = new Date(data?.timestamp.seconds * 1000)
        const updatedWithinLast24Hour = moment(lastUpdated).isAfter(moment().subtract(24, 'hours'))

        if (updatedWithinLast24Hour && data !== undefined) {
          //use pased forecast
          setData(data.forecast)
          setLoadingNewInterval(false)
        } else {
          //fetch new one
          currentUser?.getIdToken().then((userJWT) => {
            fetch(endpoint, {
              method: 'POST',
              mode: 'cors',
              headers: {
                'User-Agent':
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_2) AppleWebKit/537.17 (KHTML, like Gecko) Chrome/24.0.1309.0 Safari/537.17',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userJWT}`,
              },
              body: JSON.stringify({
                interval: 'monthly',
                shopId: shopId,
                forecastType: 'sale',
              }),
            }).then((response) =>
              response.json().then((obj) => {
                setData(obj)
                setLoadingNewInterval(false)
              })
            )
          })
        }
      })
  }, [])

  if (data !== undefined) {
    const salesList: Array<PastSales> = data?.pastSales
    const forecastList: Array<ForecastItem> = data?.forecast

    let x = 0
    let y = salesList.length - 1
    salesList.map((item: PastSales) => {
      displayedSalesList.push({
        y: Number(item.value),
        ds: x++,
        date: formatDate(item.dateStr),
      })
    })
    displayedForecastList.unshift({
      y: Number(salesList[salesList.length - 1].value),
      ds: y,
      date: salesList[salesList.length - 1].startDate,
    })
    displayedForecastUpperList.unshift({
      y: Number(salesList[salesList.length - 1].value),
      ds: y,
      date: salesList[salesList.length - 1].startDate,
    })
    displayedForecastLowerList.unshift({
      y: Number(salesList[salesList.length - 1].value),
      ds: y,
      date: salesList[salesList.length - 1].startDate,
    })
    y++
    forecastList.map((item: ForecastItem) => {
      displayedForecastList.push({ y: Number(item.yhat), ds: y + 1, date: item.ds })
      displayedForecastUpperList.push({ y: Number(item.yhat_upper), ds: y + 1, date: item.ds })
      displayedForecastLowerList.push({ y: Number(item.yhat_lower), ds: y + 1, date: item.ds })
      y++
    })
  }

  return (
    <Widget
      dashboardId={dashboardId}
      shops={shops}
      title={t('shop.widget.salesForecasting.title')}
      widget={widget}
      icon={<DefaultDashbarIcon />}
      loading={data === undefined || loadingNewInterval}
      loadingSkeleton={<ForecastSkeleton />}
      // customOptions={
      //   <IntervalSelection
      //     intervals={intervals}
      //     key="interval-selection"
      //     settings={widget && widget.settings}
      //     onIntervalChange={onIntervalChange}
      //   />
      // }
    >
      {([size]) => {
        return (
          <View style={{ width: '100%', height: '100%' }}>
            <SalesForecastLinegraph
              size={size}
              displayedSalesList={displayedSalesList}
              displayedForecastList={displayedForecastList}
              displayedForecastUpperList={displayedForecastUpperList}
              displayedForecastLowerList={displayedForecastLowerList}
              // TODO: include prognose zu max
              maxValue={Math.max(...displayedSalesList.concat(displayedForecastUpperList).map((o) => o.y)) + 1000}
              minValue={0}
              loading={data === undefined || loadingNewInterval}
              tickunit={'€'}
              nextInterval={t(`shop.widget.salesForecasting.nextInterval.mobile`)}
            />
          </View>
        )
      }}
    </Widget>
  )
}

export default SalesForecast
