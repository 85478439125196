import { processFontFamily } from 'expo-font'
import { StyleSheet } from 'react-native'
import sizes from '../../constants/sizes'
import { useHighlightColor } from '../../hooks/useColors'

const useStyles = () => {
  // const textColor = useTextColor()
  const textColor = 'black'
  const invertedTextColor = 'white'

  return StyleSheet.create({
    textInput: {
      color: textColor,
      borderColor: textColor,
    },
    errorWrapper: {
      marginBottom: 15,
    },
    button: {
      width: '100%',
      // backgroundColor: useHighlightColor(),
      backgroundColor: textColor,
      borderRadius: 10,
      height: sizes.buttonHeight + 10,
    },
    buttonOutlined: {
      width: '100%',
      // backgroundColor: useHighlightColor(),
      borderColor: textColor,
      borderWidth: 1,
      borderRadius: 10,
      height: sizes.buttonHeight + 10,
    },
    buttonDisabled: {
      backgroundColor: useHighlightColor(),
      opacity: 0.4,
      borderRadius: 15,
      height: sizes.buttonHeight,
    },
    buttonOutlinedText: {
      // color: getTextColor(Themes.Dark),
      color: textColor,
      fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
    },
    buttonText: {
      // color: getTextColor(Themes.Dark),
      color: invertedTextColor,
      fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
    },
    cancelButton: {
      borderRadius: 15,
      borderColor: useHighlightColor(),
      borderWidth: 1,
      height: sizes.buttonHeight,
      width: '45%',
    },
  })
}

export default useStyles
