import { StyleSheet } from 'react-native'
import { usePrimaryBackground } from '../../hooks/useColors'

const useStyles = () => {
  const backgroundColor = usePrimaryBackground()
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      zIndex: 30,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.6)',
    },
    content: {
      minHeight: 200,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
      backgroundColor: backgroundColor,
      padding: 10,
      borderRadius: 25,
    },
  })
}

export default useStyles
