import { Themes } from '../../../types/dashbar/theme'
import { Languages } from '../../../types/language'

export const SET_THEME = 'theme/changeTheme'

export interface SetThemeAction {
  type: typeof SET_THEME
  payload: Themes
}

export const SET_LANGUAGE = 'language/changeLanguage'

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE
  payload: Languages
}

export type BaseActionTypes = SetThemeAction | SetLanguageAction

// export interface SiteConfig {
//   language: Languages
//   theme: Themes
//   fluid: boolean
// }

// export interface BaseState {
//   language: Languages
//   theme: Themes
//   config: SiteConfig
// }

// these 2 should be removed asap, but for now they just redirect
// export type ThemeType = Themes
// export type LanguageType = Languages
