import { processFontFamily } from 'expo-font'
import * as React from 'react'
import Svg, { SvgProps, G, Text, TSpan, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */

const GoogleLogin = (props: SvgProps) => (
  <Svg width={195} height={25} {...props}>
    <G fill="none" fillRule="evenodd">
      {/* <Text fontFamily="Roboto-Medium,Roboto" fontSize={16} fontWeight="normal" fill="#000"> */}
      <Text
        fillRule="nonzero"
        fontFamily={processFontFamily('Comfortaa_700Bold') || undefined}
        fontSize={16}
        fontWeight="bold"
        fill="#000"
      >
        <TSpan x={49} y={18}>
          {'weiter mit Google'}
        </TSpan>
      </Text>
      <G fillRule="nonzero">
        <Path
          d="M23.52 13.26c0-.76-.067-1.48-.182-2.18H12.485v4.33h6.214c-.279 1.42-1.095 2.62-2.305 3.436v2.88H20.1c2.17-2.006 3.419-4.963 3.419-8.467Z"
          fill="#4285F4"
        />
        <Path
          d="M12.485 24.52c3.112 0 5.714-1.037 7.616-2.794l-3.707-2.88c-1.037.692-2.353 1.114-3.91 1.114-3.005 0-5.55-2.026-6.463-4.762H2.2v2.967C4.09 21.928 7.98 24.52 12.485 24.52Z"
          fill="#34A853"
        />
        <Path
          d="M6.021 15.198A6.682 6.682 0 0 1 5.656 13c0-.768.135-1.507.365-2.198V7.835H2.2a11.382 11.382 0 0 0 0 10.33l3.822-2.967Z"
          fill="#FBBC05"
        />
        <Path
          d="M12.485 6.04c1.7 0 3.217.586 4.418 1.728l3.284-3.283C18.2 2.622 15.597 1.48 12.485 1.48 7.98 1.48 4.09 4.072 2.199 7.835l3.822 2.967C6.934 8.066 9.48 6.04 12.485 6.04Z"
          fill="#EA4335"
        />
      </G>
    </G>
  </Svg>
)

export default GoogleLogin
