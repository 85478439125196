import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Favorite } from '../../types/Favorites'
import useAuth from '../useAuth'

const useFavorites = (): Record<string, Favorite> => {
  const auth = useAuth()

  useFirestoreConnect(() => [
    {
      collection: `user/${auth.uid}/favorites`,
      storeAs: 'user_favorites',
    },
  ])

  const favorites =
    useSelector(
      (state: { firestore: { data: { user_favorites?: Record<string, Favorite> } } }) =>
        state.firestore.data?.user_favorites
    ) || ({} as Record<string, Favorite>)
  return favorites
}

export default useFavorites
