import React from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Logo from '../Logo'
import colors from '../../constants/colors'
import useStyles from '../Login/styles'
import Button from '../Button'
import icons from '../../constants/icons'
import UpdatePasswordForm from '../Form/UpdatePasswordForm'
import { FontAwesomeIcon } from '../FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../CustomText/ColoredText'
import { getTextColor, useInvertedTextColor } from '../../hooks/useColors'
import { Themes } from '../../types/dashbar/theme'
import { Link } from '../Router/Router'

interface IConfirmPasswordProps {
  alert?: {
    content?: string
    color?: 'success' | 'error'
  }
  success?: boolean
  loading: boolean
  handleUpdatePassword: (params: { email: string; password: string }) => void
}

const UpdatePassword = ({ alert, success, loading, handleUpdatePassword }: IConfirmPasswordProps) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const invertedTextColor = useInvertedTextColor()
  if (success) {
    return (
      <View style={styles.container}>
        <View style={styles.wrapper}>
          <Logo color="black" />

          <View style={[styles.hairLine]} />
          <View style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ margin: 5 }}>
              <FontAwesomeIcon color={invertedTextColor} icon={[icons.faIconStyle, 'check-circle']} size={'lg'} />
            </View>
            <View style={[styles.container, {}]}>
              <ColoredText style={{ textAlign: 'center', fontSize: 25, color: invertedTextColor }}>
                {`${t('text.baseUpdatePassword.successfulUpdatedTitle')}`}
              </ColoredText>
              <ColoredText style={{ textAlign: 'center', color: invertedTextColor }}>
                {t('text.baseUpdatePassword.successfulUpdated')}
              </ColoredText>
            </View>
          </View>
          <View style={[styles.hairLine]} />
          <View style={[styles.container]}>
            <Button to={'/'} style={styles.homeButton}>
              <FontAwesomeIcon color={getTextColor(Themes.Dark)} icon={[icons.faIconStyle, 'home']} />
            </Button>
          </View>
        </View>
      </View>
    )
  }
  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <Logo color={invertedTextColor} />

        <ColoredText style={{ color: invertedTextColor }}>{t('text.baseUpdatePassword.welcome')}</ColoredText>

        {alert && (
          <View style={styles.alert}>
            <ColoredText style={{ color: colors[alert?.color || 'error'] }}>{alert.content}</ColoredText>
          </View>
        )}

        <View
          style={{
            paddingTop: 20,
          }}
        >
          <UpdatePasswordForm loading={loading} onSubmit={handleUpdatePassword} />
          <View style={styles.hairLine} />
          <View style={{ flexDirection: 'row', display: 'flex' }}>
            <ColoredText style={{ color: invertedTextColor }}>{t('text.baseLogin.noAccount')} </ColoredText>
            <Link style={{ color: invertedTextColor }} to={'/signup'}>
              {t('link.baseLogin.signup')}
            </Link>
          </View>

          <View style={{ flexDirection: 'row', display: 'flex' }}>
            <ColoredText style={{ color: invertedTextColor }}>{t('text.baseSignup.hasAccount')} </ColoredText>
            <Link style={{ color: invertedTextColor }} to={'/login'}>
              {t('link.baseSignup.login')}
            </Link>
          </View>
        </View>
      </View>
    </View>
  )
}

export default UpdatePassword
