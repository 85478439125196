import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { Interfaces } from '../../../store'
import { useSetTheme } from '../../../module/base/store/actions'
import icons from '../../../constants/icons'
import useStyles from './styles'
import useStoreTheme from '../../../hooks/preferences/useStoreTheme'
import { useTheme } from '../../../utils/types/theme'
import { Themes } from '../../../types/dashbar/theme'
import { FontAwesomeIcon } from '../../../components/FontAwesome/ColoredFontAwesomeIcon'

const ThemeButton = () => {
  // const config = useSelector((state: Interfaces.Store) => state.base.config)
  const themeConfig = useSelector((state: Interfaces.Store) => state.theme.theme)
  const theme = useTheme()
  const storeTheme = useStoreTheme()
  const setTheme = useSetTheme()
  const styles = useStyles()

  const setThemeDark = () => {
    storeTheme(Themes.Dark).then(() => {
      setTheme(Themes.Dark)
    })
  }
  const setThemeLight = () => {
    storeTheme(Themes.Light).then(() => {
      setTheme(Themes.Light)
    })
  }

  return (
    <View style={styles.menuButton}>
      <TouchableOpacity
        style={{ width: 40, height: 40, alignItems: 'center', justifyContent: 'center' }}
        onPress={themeConfig === 'light' ? setThemeDark : setThemeLight}
      >
        <FontAwesomeIcon icon={theme === Themes.Dark ? [icons.faIconStyle, 'sun'] : [icons.faIconStyle, 'moon']} />
      </TouchableOpacity>
    </View>
  )
}

export default ThemeButton
