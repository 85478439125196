import React, { useContext, useEffect, useState } from 'react'
import AuthError from '../../../../utils/AuthError'
import LoginView from '../../../../components/Login'
import { SessionContext } from '../../../../context/SessionContext'
import { useQuery } from '../../../../utils/string'
import getApp, { functions } from '../../../../firebase'
import { useLoginProvider } from './helper'
import { Navigate, useLocation } from '../../../../components/Router/Router'

const Login = () => {
  const { isLoaded, currentUser } = useContext(SessionContext)
  const query = useQuery()
  const { handleLogin, handleProviderLogin, handleCredentialLogin, loginState } = useLoginProvider()

  const [oauthState, setOauthState] = useState<{
    error?: AuthError
    loading: boolean
    client_id?: string
    state?: string
    redirect_url?: string
    client?: Record<string, any>
  }>({
    error: undefined,
    loading: false,
    client_id: query.get('client_id') || undefined,
    state: query.get('state') || undefined,
  })

  useEffect(() => {
    if (oauthState.state && oauthState.client_id) {
      setOauthState((prevState) => ({ ...prevState, loading: true }))
      getApp()
        .firestore()
        .collection('oauth')
        .doc(oauthState.client_id)
        .get()
        .then((data) => {
          setOauthState((prevState) => ({
            ...prevState,
            client: data.data(),
            loading: false,
          }))
        })
        .catch((e) => {
          setOauthState({ loading: false, error: e })
        })
    }
  }, [oauthState.state, oauthState.client_id])

  const currentLocation = useLocation<{ referrer: Location }>()

  useEffect(() => {
    if (isLoaded && currentUser && oauthState.state && oauthState.client_id) {
      functions()
        .httpsCallable('oauth-createOAuthToken')({
          clientId: oauthState.client_id,
          state: oauthState.state,
          userId: currentUser.uid,
        })
        .then((res: Record<string, any>) => {
          if (res.data?.code) {
            setOauthState((prevState) => ({ ...prevState, redirect_url: res.data.redirect_url }))
          }
        })
        .catch((e) => {
          setOauthState({ loading: false, error: e })
        })
    }
  }, [isLoaded, currentUser, oauthState.state, oauthState.client_id])

  if (isLoaded && currentUser) {
    if (oauthState.state && oauthState.client_id) {
      if (oauthState.redirect_url) {
        window.location.href = oauthState.redirect_url
      }
      return (
        <div style={{ margin: '50px auto', backgroundColor: 'white', padding: '30px', borderRadius: '15px' }}>
          Du wirst gleich zu deiner App weitergeleitet...{' '}
          {oauthState.redirect_url ? <a href={oauthState.redirect_url}>weiter</a> : null}
        </div>
      )
    }
    return <Navigate to={currentLocation.state?.referrer.pathname || '/'} />
  }

  const alert =
    loginState.error && !loginState.loading
      ? {
          content: loginState.error.message,
          color: 'error' as 'error' | 'success',
        }
      : undefined

  return (
    <>
      {oauthState.state && oauthState.client ? (
        <div style={{ margin: '50px auto', backgroundColor: 'white', padding: '30px', borderRadius: '15px' }}>
          Verbinde deinen Account mit einem "{oauthState.client.description}"-Plugin.
        </div>
      ) : null}
      <LoginView
        loading={loginState.loading || oauthState.loading}
        alert={alert}
        handleLogin={handleLogin}
        handleCredentialLogin={handleCredentialLogin}
        handleProviderLogin={handleProviderLogin}
      />
    </>
  )
}

export default Login
