import { useCallback, useContext } from 'react'
import { useFirestore } from 'react-redux-firebase'
import { SessionContext } from '../../context/SessionContext'
import { IDashboard } from '../../types/dashboard'

const useAddDashboard = () => {
  const firestore = useFirestore()

  const { user } = useContext(SessionContext)
  return useCallback(
    async ({ icon = 'database', title }: Partial<IDashboard>) => {
      const dashboardRef = await firestore
        .collection(`company/${user?.company ?? '-'}/dashboard`)
        .add({ icon, title, widgets: [], creator: user?.id })

      return (await dashboardRef.get()).id
    },
    [user, firestore]
  )
}

export default useAddDashboard
