import * as React from 'react'
import Svg, { G, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={67} height={67} {...props}>
    <G fill="none" fillRule="nonzero">
      <Path
        fill="#4285F4"
        d="M65.66 34.224c0-2.118-.188-4.128-.51-6.084H34.855v12.087H52.2c-.778 3.966-3.057 7.316-6.435 9.594v8.04h10.35c6.059-5.601 9.544-13.855 9.544-23.637Z"
      />
      <Path
        fill="#34A853"
        d="M34.854 65.66c8.687 0 15.952-2.894 21.261-7.799l-10.35-8.04c-2.895 1.93-6.568 3.109-10.911 3.109-8.392 0-15.497-5.655-18.044-13.293H6.139v8.281C11.42 58.424 22.279 65.66 34.854 65.66Z"
      />
      <Path
        fill="#FBBC05"
        d="M16.81 39.637a18.655 18.655 0 0 1-1.02-6.137c0-2.144.376-4.208 1.02-6.137v-8.281H6.139a31.776 31.776 0 0 0 0 28.836l10.67-8.28Z"
      />
      <Path
        fill="#EA4335"
        d="M34.854 14.07c4.745 0 8.982 1.635 12.333 4.824l9.17-9.166C50.806 4.53 43.54 1.34 34.853 1.34c-12.575 0-23.433 7.236-28.715 17.742l10.67 8.28c2.548-7.637 9.653-13.292 18.045-13.292Z"
      />
    </G>
  </Svg>
)
export default SvgComponent
