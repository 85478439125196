import i18n from '../../../locales/i18n'
const gendersDark = require('../../../assets/imgs/widgets/new_design/wg_forecast_geschlecht.png')
const gendersLight = require('../../../assets/imgs/widgets/new_design/wg_forecast_geschlecht.png')

const forecastVisitorLight = require('../../../assets/imgs/widgets/new_design/wg_forecast_besucher.png')
const forecastVisitorDark = require('../../../assets/imgs/widgets/new_design/wg_forecast_besucher.png')

const forecastSalesLight = require('../../../assets/imgs/widgets/new_design/wg_forecast_umsatz.png')
const forecastSalesDark = require('../../../assets/imgs/widgets/new_design/wg_forecast_umsatz.png')

const performanceIconDeskLight = require('../../../assets/imgs/widgets/new_design/wg_gps_performance_desktop.png')
const performanceIconDeskDark = require('../../../assets/imgs/widgets/new_design/wg_gps_performance_desktop.png')

const performanceIconSmartLight = require('../../../assets/imgs/widgets/new_design/wg_gps_performance_mobile.png')
const performanceIconSmartDark = require('../../../assets/imgs/widgets/new_design/wg_gps_performance_mobile.png')

const webVitalsIconDeskLight = require('../../../assets/imgs/widgets/new_design/wg_gps_vitals_desktop.png')
const webVitalsIconDeskDark = require('../../../assets/imgs/widgets/new_design/wg_gps_vitals_desktop.png')

const webVitalsIconSmartLight = require('../../../assets/imgs/widgets/new_design/wg_gps_vitals_mobile.png')
const webVitalsIconSmartDark = require('../../../assets/imgs/widgets/new_design/wg_gps_vitals_mobile.png')

const performanceHistoryIconDeskLight = require('../../../assets/imgs/widgets/new_design/wg_gps_history_desktop.png')
const performanceHistoryDeskDark = require('../../../assets/imgs/widgets/new_design/wg_gps_history_desktop.png')

const performanceHistoryIconSmartLight = require('../../../assets/imgs/widgets/new_design/wg_gps_history_mobile.png')
const performanceHistoryIconSmartDark = require('../../../assets/imgs/widgets/new_design/wg_gps_history_mobile.png')

const gscDevicesLight = require('../../../assets/imgs/widgets/new_design/wg_gsc_geraete.png')
const gscDevicesDark = require('../../../assets/imgs/widgets/new_design/wg_gsc_geraete.png')

const gscPagesLight = require('../../../assets/imgs/widgets/new_design/wg_gsc_seiten.png')
const gscPagesDark = require('../../../assets/imgs/widgets/new_design/wg_gsc_seiten.png')

const gscSearchLight = require('../../../assets/imgs/widgets/new_design/wg_gsc_begriffe.png')
const gscSearchDark = require('../../../assets/imgs/widgets/new_design/wg_gsc_begriffe.png')

const gscOverviewLight = require('../../../assets/imgs/widgets/new_design/wg_gsc_uebersicht.png')
const gscOverviewDark = require('../../../assets/imgs/widgets/new_design/wg_gsc_uebersicht.png')

const statusDark = require('../../../assets/imgs/widgets/new_design/wg_status.png')
const statusLight = require('../../../assets/imgs/widgets/new_design/wg_status.png')

const orderDark = require('../../../assets/imgs/widgets/new_design/wg_bestellungen.png')
const orderLight = require('../../../assets/imgs/widgets/new_design/wg_bestellungen.png')

const liveTrackerDark = require('../../../assets/imgs/widgets/new_design/wg_livetracker.png')
const liveTrackerLight = require('../../../assets/imgs/widgets/new_design/wg_livetracker.png')

const runnersDark = require('../../../assets/imgs/widgets/new_design/wg_renner.png')
const runnersLight = require('../../../assets/imgs/widgets/new_design/wg_renner.png')

const salesDark = require('../../../assets/imgs/widgets/new_design/wg_umsatz.png')
const salesLight = require('../../../assets/imgs/widgets/new_design/wg_umsatz.png')

const orderValueDark = require('../../../assets/imgs/widgets/new_design/wg_bestellwert.png')
const orderValueLight = require('../../../assets/imgs/widgets/new_design/wg_bestellwert.png')

import AddNewWidget from './default/AddNewWidget'
import Conversion from './default/Conversion'
import LiveTracker from './default/LiveTracker'
import Orders from './default/Orders'
import BasicOrders from './basic/Orders'
import OrderValue from './default/OrderValue'
import Runners from './default/Runners'
import Sales from './default/Sales'
import BasicSales from './basic/Sales'
import Status from './default/Status'
import Gender from './default/Gender'
import WebVitals from './default/Pagespeed/WebVitals'
import HistoryPerformance from './default/Pagespeed/HistoryPerformance'
import HistoryPerformanceMobile from './default/Pagespeed/HistoryPerformanceMobile'
import WebVitalsMobile from './default/Pagespeed/WebVitalsMobile'
import PerformanceMobile from './default/Pagespeed/PerformanceMobile'
import Performance from './default/Pagespeed/Performance'
import { Themes } from '../../../types/dashbar/theme'
import Devices from './default/GoogleSearchConsole/Devices'
import Overview from './default/GoogleSearchConsole/Overview'
import { IWidgetDefinition } from '../../../types/Widget'
import VisitorForecast from './default/Forecast/VisitorsForecasting'
import SalesForecast from './default/Forecast/SalesForecasting'
import Top5Pages from './default/GoogleSearchConsole/Top5Pages'
import Top5SearchRequests from './default/GoogleSearchConsole/Top5Searchrequests'
import BasicConversion from './basic/Conversion'
import BasicOrderValue from './basic/OrderValue'
import BasicLiveTracker from './basic/Livetracker'
import BasicPerformance from './basic/Pagespeed/Performance'
import BasicPerformanceMobile from './basic/Pagespeed/PerformanceMobile'
import BasicHistoryPerformance from './basic/Pagespeed/HistoryPerformance'
import BasicHistoryPerformanceMobile from './basic/Pagespeed/HistoryPerformanceMobile'
import ForecastDemoWidget from './demo/Forecast'
import GoogleSearchConsoleDemoWidget from './demo/GoogleSearchConsole'
import PagespeedDemoWidget from './demo/Pagespeed'
const widgetDefinitions: Record<string, IWidgetDefinition> = {
  //
  //##########################
  //Demo Widgets
  //##########################
  //
  demo_forecast_v2: {
    component: ForecastDemoWidget,
    title: `${i18n.t('shop.widget.forecastStatus.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: forecastSalesLight,
      [Themes.Dark]: forecastSalesDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.basic'],
  },
  demo_gsc_v2: {
    component: GoogleSearchConsoleDemoWidget,
    title: `${i18n.t('shop.widget.search_console.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: gscSearchLight,
      [Themes.Dark]: gscSearchDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.basic'],
  },
  demo_pagespeed_v2: {
    component: PagespeedDemoWidget,
    title: `${i18n.t('shop.widget.pagespeed.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: performanceIconDeskLight,
      [Themes.Dark]: performanceIconDeskDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.basic'],
  },

  //
  //##########################
  //Basic Widgets
  //##########################
  //
  basic_conversion_v2: {
    component: BasicConversion,
    title: `Basic ${i18n.t('shop.widget.conversion.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: salesLight,
      [Themes.Dark]: salesDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.basic'], // TODO: enable / refactor if needed
  },
  basic_live_tracker_v2: {
    component: BasicLiveTracker,
    title: `Basic ${i18n.t('shop.widget.live-tracker.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: true, refreshInterval: 10, rowsPerPage: 4 },
    icon: {
      [Themes.Light]: liveTrackerLight,
      [Themes.Dark]: liveTrackerDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopify'],
    authorizations: ['widget.basic'], // TODO: enable / refactor if needed
  },
  basic_orders_v2: {
    component: BasicOrders,
    title: `Basic ${i18n.t('shop.widget.orders.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: true, refreshInterval: 10, rowsPerPage: 4 },
    icon: {
      [Themes.Light]: orderLight,
      [Themes.Dark]: orderDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.basic'], // TODO: enable / refactor if needed
  },
  basic_sales_v2: {
    component: BasicSales,
    title: `Basic Sales`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: salesLight,
      [Themes.Dark]: salesDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.basic'], // TODO: enable / refactor if needed
  },
  basic_order_value_v2: {
    component: BasicOrderValue,
    title: `Basic ${i18n.t('shop.widget.order-value.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: orderValueLight,
      [Themes.Dark]: orderValueDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.basic'], // TODO: enable / refactor if needed
  },
  basic_pagespeed_performance_v2: {
    component: BasicPerformance,
    title: 'Basic Pagespeed Performance Desktop',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: performanceIconDeskLight,
      [Themes.Dark]: performanceIconDeskDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.basic'],
  },
  basic_pagespeed_performance_mobile_v2: {
    component: BasicPerformanceMobile,
    title: 'Basic Pagespeed Performance Mobile',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: performanceIconSmartLight,
      [Themes.Dark]: performanceIconSmartDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.basic'],
  },
  basic_pagespeed_histperformance_v2: {
    component: BasicHistoryPerformance,
    title: 'Pagespeed Performance History Desktop',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: performanceHistoryIconDeskLight,
      [Themes.Dark]: performanceHistoryDeskDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.basic'],
  },
  basic_pagespeed_histperformance_mobile_v2: {
    component: BasicHistoryPerformanceMobile,
    title: 'Basic Pagespeed Performance History Mobile',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: performanceHistoryIconSmartLight,
      [Themes.Dark]: performanceHistoryIconSmartDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.basic'],
  },

  //
  //##########################
  //Default Widgets
  //##########################
  //
  conversion_v2: {
    component: Conversion,
    title: `${i18n.t('shop.widget.conversion.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: salesLight,
      [Themes.Dark]: salesDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'], // TODO: enable / refactor if needed
  },

  live_tracker_v2: {
    component: LiveTracker,
    title: `${i18n.t('shop.widget.live-tracker.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: true, refreshInterval: 10, rowsPerPage: 4 },
    icon: {
      [Themes.Light]: liveTrackerLight,
      [Themes.Dark]: liveTrackerDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'], // TODO: enable / refactor if needed
  },
  orders_v2: {
    component: Orders,
    title: `${i18n.t('shop.widget.orders.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: true, refreshInterval: 10, rowsPerPage: 4 },
    icon: {
      [Themes.Light]: orderLight,
      [Themes.Dark]: orderDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'], // TODO: enable / refactor if needed
  },
  runners_v2: {
    component: Runners,
    title: `${i18n.t('shop.widget.runners.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: true, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: runnersLight,
      [Themes.Dark]: runnersDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'], // TODO: enable / refactor if needed
  },
  sales_v2: {
    component: Sales,
    title: `${i18n.t('shop.widget.sales.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: salesLight,
      [Themes.Dark]: salesDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'], // TODO: enable / refactor if needed
  },
  order_value_v2: {
    component: OrderValue,
    title: `${i18n.t('shop.widget.order-value.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: orderValueLight,
      [Themes.Dark]: orderValueDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'], // TODO: enable / refactor if needed
  },
  status_v2: {
    component: Status,
    title: `${i18n.t('shop.widget.status.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10 },
    icon: {
      [Themes.Light]: statusLight,
      [Themes.Dark]: statusDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'], // TODO: enable / refactor if needed
  },
  add_new_widget_v2: {
    component: AddNewWidget,
    minVersion: '1.0.0',
  },
  gender_v2: {
    component: Gender,
    title: `${i18n.t('shop.widget.gender.title')}`,
    minVersion: '2.0.2',
    settings: { autoRefresh: false, refreshInterval: 10 },
    icon: {
      [Themes.Light]: gendersLight,
      [Themes.Dark]: gendersDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5'],
    authorizations: ['widget.business', 'widget.pro'], // TODO: enable / refactor if needed
  },
  pagespeed_performance_v2: {
    component: Performance,
    title: 'Pagespeed Performance Desktop',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: performanceIconDeskLight,
      [Themes.Dark]: performanceIconDeskDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'],
  },
  pagespeed_performance_mobile_v2: {
    component: PerformanceMobile,
    title: 'Pagespeed Performance Mobile',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: performanceIconSmartLight,
      [Themes.Dark]: performanceIconSmartDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'],
  },
  pagespeed_histperformance_v2: {
    component: HistoryPerformance,
    title: 'Pagespeed Performance History Desktop',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: performanceHistoryIconDeskLight,
      [Themes.Dark]: performanceHistoryDeskDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'],
  },
  pagespeed_histperformance_mobile_v2: {
    component: HistoryPerformanceMobile,
    title: 'Pagespeed Performance History Mobile',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: performanceHistoryIconSmartLight,
      [Themes.Dark]: performanceHistoryIconSmartDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'],
  },
  pagespeed_webvitals_v2: {
    component: WebVitals,
    title: 'Pagespeed WebVitals Desktop',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: webVitalsIconDeskLight,
      [Themes.Dark]: webVitalsIconDeskDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'],
  },
  pagespeed_webvitals_mobile_v2: {
    component: WebVitalsMobile,
    title: 'Pagespeed WebVitals Mobile',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: webVitalsIconSmartLight,
      [Themes.Dark]: webVitalsIconSmartDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'],
  },
  sale_forecast_v2: {
    component: SalesForecast,
    title: `Sales Forecast`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false },
    icon: {
      [Themes.Light]: forecastSalesLight,
      [Themes.Dark]: forecastSalesDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5'],
    authorizations: ['widget.business', 'widget.pro'], // TODO: enable / refactor if needed
  },
  visitor_forecast_v2: {
    component: VisitorForecast,
    title: `Visitors Forecast`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false },
    icon: {
      [Themes.Light]: forecastVisitorLight,
      [Themes.Dark]: forecastVisitorDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5'],
    authorizations: ['widget.business', 'widget.pro'], // TODO: enable / refactor if needed
  },
  gsc_top5_search_v2: {
    component: Top5SearchRequests,
    title: 'Google Searchrequests',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: gscSearchLight,
      [Themes.Dark]: gscSearchDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    // TODO: Hier durch search engine authorization ersetzen
    authorizations: ['widget.business', 'widget.pro'],
  },
  gsc_top5_pages_v2: {
    component: Top5Pages,
    title: 'Google Pages',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: gscPagesLight,
      [Themes.Dark]: gscPagesDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'],
  },
  gsc_devices_v2: {
    component: Devices,
    title: 'Google Devices',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: gscDevicesLight,
      [Themes.Dark]: gscDevicesDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'],
  },
  gsc_overview_v2: {
    component: Overview,
    title: 'Google Overview',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: gscOverviewLight,
      [Themes.Dark]: gscOverviewDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: ['widget.business', 'widget.pro'],
  },
}

export default widgetDefinitions
