import * as yup from 'yup'
import BillingAddressValidator from './BillingAddressValidator'
import { functions } from '../firebase'

// const CompanyValidator = yup.object().shape({
//   billingAddress: BillingAddressValidator,
//   ustId: yup
//     .string()
//     .required()
//     .when('billingAddress.country', {
//       is: (country: string) => !['CH', 'AT'].includes(country),
//       then: yup.string().test('vat-id-check', 'VAT invalid', async (value) => {
//         try {
//           const vatCheck = functions().httpsCallable('calls-vatCheck')
//           const result = await vatCheck(value)
//           return !!(result && result.data)
//         } catch {
//           return false
//         }
//       }),
//     }),
//   name: yup.string().required(),
//   supportEmail: yup.string().email(),
// })

//Below structure has been updated because new yup package doent support old one.
//ToDo: Check if this works

const CompanyValidator = yup.object().shape({
  billingAddress: BillingAddressValidator,
  ustId: yup
    .string()
    .required()
    .when('billingAddress.country', {
      is: (country: string) => !['CH', 'AT'].includes(country),
      then: (schema) =>
        schema.test('vat-id-check', 'VAT invalid', async (value) => {
          try {
            const vatCheck = functions().httpsCallable('calls-vatCheck')
            const result = await vatCheck(value)
            return !!(result && result.data)
          } catch {
            return false
          }
        }),
    }),
  name: yup.string().required(),
  supportEmail: yup.string().email(),
})

export default CompanyValidator
