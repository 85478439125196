import * as yup from 'yup'

const MIN_PASSWORD_LENGTH = 8

const SignupValidator = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(MIN_PASSWORD_LENGTH).required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')])
    .required(),
})

export default SignupValidator
