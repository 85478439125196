import * as WebBrowser from 'expo-web-browser'
import { Linking, Platform } from 'react-native'

export const openUrl = async (url: string, target?: string): Promise<boolean> => {
  if (Platform.OS === 'web') {
    window.open(url, target)
    return true
  } else if (Platform.OS === 'android') {
    return WebBrowser.openBrowserAsync(url)
      .then(() => {
        return true
      })
      .catch(async () => {
        if (await Linking.canOpenURL(url)) {
          await Linking.openURL(url)
          return true
        }
        return false
      })
  } else {
    return WebBrowser.openBrowserAsync(url)
      .then(() => {
        return true
      })
      .catch(async () => {
        if (await Linking.canOpenURL(url)) {
          await Linking.openURL(url)
          return true
        }
        return false
      })
  }
}
