import React from 'react'
import * as WebBrowser from 'expo-web-browser'
import { Platform, TouchableOpacity } from 'react-native'
import ColoredText from '../CustomText/ColoredText'

const Link = ({ url, children, fontSize }: { url: string; children: React.ReactNode; fontSize?: number }) => {
  const openLink = () => {
    if (Platform.OS === 'web') {
      window.location.href = url
    } else {
      WebBrowser.openBrowserAsync(url)
    }
  }
  return (
    <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center' }} onPress={() => openLink()}>
      <ColoredText
        style={{
          marginHorizontal: 4,
          textDecorationLine: 'underline',
          alignSelf: 'center',
          ...(fontSize !== undefined && { fontSize: fontSize }),
        }}
      >
        {children}
      </ColoredText>
    </TouchableOpacity>
  )
}

export default Link
