import * as React from 'react'
import Svg, { SvgProps, G, Path, Circle } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */

const BlumeLight = (props: SvgProps) => (
  <Svg width={116} height={91} {...props}>
    <G fill="none" fillRule="evenodd">
      <G fillRule="nonzero">
        <G transform="translate(34 26)">
          <Path
            d="M11.462 32.962h3.6a7.2 7.2 0 0 1 7.2 7.2h-3.6a7.2 7.2 0 0 1-7.2-7.2ZM33.062 36.2h-3.6a7.2 7.2 0 0 0-7.2 7.2h3.6a7.2 7.2 0 0 0 7.2-7.2Z"
            fill="#76C881"
          />
          <Path fill="#64AF6E" d="M21.6 26.6v23.487h1.2V26.6z" />
          <Path
            d="m19.512 30.474-1.084.834a2.4 2.4 0 0 1-3.725-2.706l.459-1.288-1.367.038a2.4 2.4 0 0 1-1.423-4.38L13.5 22.2l-1.128-.773a2.4 2.4 0 0 1 1.423-4.379l1.367.038-.459-1.288a2.4 2.4 0 0 1 3.725-2.706l1.084.834.386-1.311a2.4 2.4 0 0 1 4.604 0l.386 1.31 1.084-.833a2.4 2.4 0 0 1 3.725 2.706l-.459 1.288 1.367-.038a2.4 2.4 0 0 1 1.423 4.38L30.9 22.2l1.128.773a2.4 2.4 0 0 1-1.423 4.379l-1.367-.038.459 1.288a2.4 2.4 0 0 1-3.725 2.706l-1.084-.834-.386 1.311a2.4 2.4 0 0 1-4.604 0l-.386-1.31Z"
            fill="#FFBF29"
          />
          <Circle fill="#D6A021" cx={22.2} cy={22.2} r={5.4} />
        </G>
        <Path
          d="M45.12 70.1h22.4l-2.546 18.433a1.12 1.12 0 0 1-1.11.967H48.776a1.12 1.12 0 0 1-1.11-.967L45.12 70.1Z"
          fill="#FF975D"
        />
        <Path
          d="M45.12 70.1h22.4c.619 0 1.12.501 1.12 1.12v3.48a1.12 1.12 0 0 1-1.12 1.12h-22.4A1.12 1.12 0 0 1 44 74.7v-3.48c0-.619.501-1.12 1.12-1.12Z"
          fill="#F76B1C"
        />
      </G>
      <Path
        fill="#000"
        d="M96 51h20v2H96zM57 0v20h-2V0zM96.36 36.132l18.126-8.452-.846-1.812-18.126 8.452zM2.36 27.868l18.126 8.452-.846 1.812L1.514 29.68zM0 53h20v2H0zM25.866 5.84l10 17.32-1.732 1-10-17.32zM77.866 24.16l10-17.32-1.732-1-10 17.32z"
      />
    </G>
  </Svg>
)

export default BlumeLight
