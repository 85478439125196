import i18n from '../../locales/i18n'

export const getErrorByStatusCode = (statusCode: number): { text: string; link: string | null } => {
  switch (statusCode) {
    case 401:
      return { text: `${i18n.t('widget_errors.status_codes.401')}`, link: 'https://www.google.de' }
    case 503:
      return { text: `${i18n.t('widget_errors.status_codes.503')}`, link: null }
    default:
      return { text: `${i18n.t('widget_errors.status_codes.default')}`, link: null }
  }
}
