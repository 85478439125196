import { StyleSheet } from 'react-native'
import { useTextColor } from '../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    numberInput: {
      width: 50,
      textAlign: 'center',
      fontSize: 15,
      borderColor: useTextColor(),
      borderWidth: 2,
      borderStyle: 'solid',
      paddingHorizontal: 10,
      paddingVertical: 5,
      borderRadius: 15,
      color: useTextColor(),
    },
    disabled: {
      backgroundColor: '#404040',
      color: '#bfbfbf',
      borderColor: '#bfbfbf',
    },
  })
}

export default useStyles
