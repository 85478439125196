import { StyleSheet } from 'react-native'
import { useHighlightColor, usePrimaryBackground } from '../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    couponRowWrapper: {
      width: '100%',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignContent: 'center',
      alignItems: 'center',
      opacity: 0.85,
      backgroundColor: usePrimaryBackground(),
      position: 'absolute',
      minHeight: 40,
      paddingHorizontal: 10,
      paddingVertical: 4,
      bottom: 0,
      flexWrap: 'wrap',
    },
    button: {
      backgroundColor: useHighlightColor(),
      borderRadius: 10,
      padding: 3,
      marginVertical: 6,
    },
  })
}

export default useStyles
