import React from 'react'
import { Modal, Pressable, View } from 'react-native'
import useStyles from './styles'
import UpsellingView from './components/UpsellingView'

const UpsellingDialog = ({
  visible,
  setIsVisible,
}: {
  visible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const styles = useStyles()

  return (
    <Modal animationType="fade" visible={visible} transparent={true} onRequestClose={() => setIsVisible(false)}>
      <Pressable style={styles.wrapper} onPress={() => setIsVisible(false)}>
        <View style={styles.content}>
          <UpsellingView />
        </View>
      </Pressable>
    </Modal>
  )
}

export default UpsellingDialog
