import React from 'react'
import IndexRedirect from '../../module/base/page/IndexRedirect'
import NotFound from './NotFound'

export default () => [
  {
    title: 'NotFound',
    name: 'page_not_found',
    route: {
      exact: true,
      path: '/',
      children: <IndexRedirect />,
    },
  },
  {
    title: 'NotFound',
    name: 'page_not_found',
    route: {
      exact: true,
      path: '/*',
      children: <NotFound />,
    },
    requireAuth: true,
  },
]
