import { Dimensions, StyleSheet } from 'react-native'
import { useHighlightColor, useSecondaryBackground } from '../../../../hooks/useColors'

const useStyles = () => {
  const isSmallDevice = Dimensions.get('window').width < 400
  const highlight = useHighlightColor()
  return StyleSheet.create({
    wrapper: {
      backgroundColor: 'transparent',
    },
    container: {
      marginBottom: 5,
    },
    button: {
      width: isSmallDevice ? '100%' : '30%',
      marginHorizontal: 10,
      marginVertical: 1,
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: 50,
      backgroundColor: useSecondaryBackground(),
      borderColor: 'white',
      borderWidth: 1,
      borderRadius: 16,
    },
    disabledButton: {
      opacity: 0.5,
      borderWidth: 0,
    },
    typeIcon: {
      width: 200,
      height: 200,
      marginBottom: 10,
    },
    buttonText: {},
    widgetView: {
      width: 280,
      height: 390,
      //backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.darkBgTransparent7,
      backgroundColor: useSecondaryBackground(),
      borderRadius: 15,
      //flex: 1,
      alignItems: 'center',
    },
    divider: {
      height: 2,
      minHeight: 2,
      maxHeight: 2,
      width: '100%',
      marginTop: 0,
      marginBottom: 20,
      border: 1,
      borderStyle: 'solid',
      borderColor: highlight,
      backgroundColor: highlight,
    },
  })
}

export default useStyles
