export const config = {
  apiKey: 'AIzaSyBsLbEOZAzXi7eSAEZ_c0UwNkOu7p5DStU',
  authDomain: 'dashbar-221110.firebaseapp.com',
  projectId: 'dashbar-221110',
  storageBucket: 'dashbar-221110.appspot.com',
  messagingSenderId: '111455707961',
  appId: '1:111455707961:web:a5815bc15359b6a49a3ee8',
  measurementId: 'G-CY78M55WN3',
}

export const ReCaptchaV3SiteKey = '6LfBCcIfAAAAAG0tm6bKF3_q0H16aCXhH9x7WvBd'
