import { StyleSheet } from 'react-native'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: 10,
    },
    spacer: {
      margin: 5,
    },
    spinner: {},
    text: {},
  })
}

export default useStyles
