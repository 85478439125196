import { StyleSheet } from 'react-native'
import { usePrimaryBackground } from '../../../../../hooks/useColors'

const useStyles = () => {
  const primaryBackground = usePrimaryBackground()
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      borderRadius: 10,
      overflow: 'hidden',
      marginBottom: 5,
      backgroundColor: primaryBackground,
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    rightWrapper: {
      position: 'absolute',
      right: 0,
      marginEnd: 5,
      width: '15%',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  })
}

export default useStyles
