import React, { useState } from 'react'

import { Image, ImageProps, ImageRequireSource } from 'react-native'
import useStyles from './styles'

const LazyLoadingImage = ({
  imageUrl,
  defaultImage,
  ...props
}: Partial<ImageProps> & { imageUrl: string; defaultImage?: ImageRequireSource }) => {
  const styles = useStyles()

  const [loaded, setLoaded] = useState(false)

  const DefaultImage = (
    <Image source={defaultImage} {...props} style={[props.style, !defaultImage ? styles.placeholder : {}]} />
  )

  return !imageUrl || typeof imageUrl !== 'string' || imageUrl?.endsWith('.svg') ? (
    DefaultImage
  ) : loaded ? (
    <Image
      source={{
        uri: imageUrl,
        headers: {
          referrerpolicy: 'no-referrer',
        },
      }}
      defaultSource={defaultImage}
      {...props}
    />
  ) : (
    <>
      <Image
        source={{
          uri: imageUrl,
          headers: {
            referrerpolicy: 'no-referrer',
          },
        }}
        onLoadEnd={() => {
          setLoaded(true)
        }}
        onError={() => {
          setLoaded(false)
        }}
        style={styles.loadingImage}
      />
      {DefaultImage}
    </>
  )
}

export default LazyLoadingImage
