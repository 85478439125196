import { StyleSheet } from 'react-native'
import { useTextColor } from '../../../../../../../hooks/useColors'
import useIsMobileWebRender from '../../../../../../../hooks/useIsMobileWebRender'

const useStyles = () => {
  return StyleSheet.create({
    shopWrapper: {
      width: '100%',
      padding: 30,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'flex-start',
    },
    shopViewWrapper: {
      width: '20%',
      maxWidth: '20%',
      minWidth: useIsMobileWebRender() ? 100 : '20%',
      marginVertical: 10,
      flex: 1,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    avatar: {
      height: 70,
      width: 70,
      borderRadius: 50,
      borderWidth: 1,
      borderColor: useTextColor(),
    },
    name: {
      padding: 10,
      width: '100%',
      textAlign: 'center',
    },
  })
}

export default useStyles
