import { StyleSheet } from 'react-native'

const useStyles = () => {
  return StyleSheet.create({
    menuButton: {
      marginHorizontal: 5,
    },
  })
}

export default useStyles
