import { useCallback } from 'react'
import { useFirestore } from 'react-redux-firebase'
import useAuth from '../useAuth'

const useRemoveFavorite = () => {
  const firestore = useFirestore()
  const auth = useAuth()

  return useCallback(
    async (...ids: Array<string>) => {
      for (const id of ids) {
        await firestore.collection(`user/${auth.uid}/favorites`).doc(id).delete()
      }
    },
    [auth, firestore]
  )
}

export default useRemoveFavorite
