import React from 'react'
import { View } from 'react-native'
import useStyles from './styles'
import * as useDefaultStyles from '../../utils/styles'
import ColoredText from '../../../../components/CustomText/ColoredText'

const MenuTitle = ({ title, subtitle }: { title?: string; subtitle?: string }) => {
  const styles = useStyles()
  const defaultStyles = useDefaultStyles.default()
  return (
    <View style={styles.wrapper}>
      {title && <ColoredText style={defaultStyles.settingTitle}>{title}</ColoredText>}
      {subtitle && <ColoredText style={defaultStyles.settingSubTitle}>{subtitle}</ColoredText>}
    </View>
  )
}

export default MenuTitle
