import React from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import { SalesT } from '../../../../../types/widgetDataTypes/V3/types'
import BarChart from '../../../../../components/VictoryCharts/Bar'
import Widget from '../../../../components/Widget'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { useNavigate } from '../../../../../components/Router/Router'
import SalesSkeleton from './SalesSkeleton'
import { ISettings } from '../../../../../types/dashboard'

const Sales = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Widget<SalesT>
      title={t('shop.widget.sales.title')}
      widget={widget}
      hasInterval
      loadingSkeleton={<SalesSkeleton />}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, interval = 'D30' }: ISettings) => ({
          body: {
            module: 'Analytics',
            endpoint: ['sales'],
            params: {
              options: {
                details: true,
                interval,
                refresh: autoRefresh ? refreshInterval : undefined,
              },
            },
          },
          widget: 'sales',
        }),
      ]}
      onClickWidget={() => {
        navigate(`/analytics/analytics/${widget.settings.shop}`)
      }}
      {...props}
    >
      {([, data, , , size]) => {
        return <BarChart data={[data.current, ...data.history.map((val) => val)].reverse()} size={size} />
      }}
    </Widget>
  )
}

export default Sales
