import React from 'react'
import { useContext } from 'react'
import { SessionContext } from '../context/SessionContext'
import useSetDocument from './useSetDocument'
import { IUser } from '../types/user'

const NotificationCounterContext = React.createContext(0)
const NotificationCounterUpdateContext = React.createContext(() => alert())
export function useNotificationCounter() {
  return useContext(NotificationCounterContext)
}

export function useNotificationCounterUpdate() {
  return useContext(NotificationCounterUpdateContext)
}
export function NotificationsCounterProvider({ children }: { children: any }) {
  const { currentUser, user } = useContext(SessionContext)
  const counter = user?.notificationCounter
  const updateUser = useSetDocument<IUser>('user')

  function setCounterToNull() {
    updateUser({ ...user, notificationCounter: 0 }, currentUser?.uid)
  }
  return (
    <NotificationCounterContext.Provider value={counter || 0}>
      <NotificationCounterUpdateContext.Provider value={setCounterToNull}>
        {children}
      </NotificationCounterUpdateContext.Provider>
    </NotificationCounterContext.Provider>
  )
}
