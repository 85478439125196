import { StyleSheet } from 'react-native'
import colors from '../../../../../../../../constants/colors'
import sizes from '../../../../../../../../constants/sizes'
import { useHighlightColor, useSecondaryBackground, useTextColor } from '../../../../../../../../hooks/useColors'
import { Themes } from '../../../../../../../../types/dashbar/theme'
import { useTheme } from '../../../../../../../../utils/types/theme'

const useStyles = () => {
  const theme = useTheme()
  const color = useTextColor()
  return StyleSheet.create({
    methodCard: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      borderRadius: 20,
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      minHeight: 50,
      padding: 15,
      margin: 5,
      overflow: 'hidden',
    },
    methodCardWrapper: {
      width: '100%',
      maxWidth: '100%',
      justifyContent: 'center',
      flexDirection: 'column',
      flexWrap: 'wrap',
    },
    mandateChangeWrapper: {
      width: '100%',
      justifyContent: 'center',
      flexDirection: 'column',
      alignContent: 'center',
      alignItems: 'center',
    },
    textInput: {
      borderColor: useSecondaryBackground(),
      borderStyle: 'solid',
      borderWidth: 1,
      paddingHorizontal: 12,
      paddingVertical: 9,
      fontSize: 15,
      margin: 5,
      borderRadius: 15,
      color: color,
    },
    text: {
      width: '100%',
      textAlign: 'center',
      fontSize: 18,
    },
    textSelected: {
      color: useHighlightColor(),
    },
    selected: {
      borderColor: useHighlightColor(),
      borderWidth: 3,
      borderStyle: 'solid',
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent2 : colors.lightBgTransparent2,
    },
    button: {
      width: '100%',
      borderRadius: 15,
      marginHorizontal: 5,
      marginTop: 20,
      height: sizes.buttonHeight,
      backgroundColor: useHighlightColor(),
    },
  })
}

export default useStyles
