import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React from 'react'
import useStyles from './styles'
import * as useDefaultStyles from '../../utils/styles'
import { useTheme } from '../../../../utils/types/theme'
import { Themes } from '../../../../types/dashbar/theme'
import ColoredText from '../../../../components/CustomText/ColoredText'

const Dropdown = ({
  items,
  value,
  setValue,
}: {
  items: Array<{ value: string; name: string }>
  value: string
  setValue: (value: string) => void
}) => {
  const styles = useStyles()
  const defaultStyles = useDefaultStyles.default()
  const theme = useTheme()
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value)
  }

  return (
    <Select
      value={value}
      onChange={handleChange}
      style={styles.dropdown}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme === Themes.Dark ? '#EEE' : '#111',
        },
      }}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {items.map((item, index) => {
        return (
          <MenuItem value={item.value} key={`${item.value}_${index}`}>
            <ColoredText style={defaultStyles.settingText}>{item.name}</ColoredText>
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default Dropdown
