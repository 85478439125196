import { StyleSheet } from 'react-native'
import { useFontFamily } from '../../../hooks/useFontFamily'

const useStyles = () => {
  const { bold } = useFontFamily()
  return StyleSheet.create({
    text: {
      fontFamily: bold,
    },
  })
}

export default useStyles
