import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Logo from '../Logo'
import colors from '../../constants/colors'
import ResetPasswordForm from '../Form/ResetPasswordForm'
import useStyles from '../Login/styles'
import ColoredText from '../CustomText/ColoredText'
import { Link } from '../Router/Router'
import { Dimensions, View, Image, TouchableOpacity, Platform } from 'react-native'
import { processFontFamily } from 'expo-font'
import AppStore from '../../assets/imgs/login/AppStore'
import { openUrl } from '../Browser'
import PlayStore from '../../assets/imgs/login/PlayStore'

interface IResetPasswordProps {
  alert?: {
    content?: string
    color?: 'success' | 'error'
  }
  success?: boolean
  loading: boolean
  handleResetPassword: (params: { email: string }) => void
}

const ResetPassword = ({ alert, success, loading, handleResetPassword }: IResetPasswordProps) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const [screenWidth, setScreenWidth] = useState<number>(Dimensions.get('window').width)

  const showImage = screenWidth > 1200
  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setScreenWidth(window.width)
    })
    return () => subscription?.remove()
  })

  return (
    <View style={styles.container}>
      <View style={styles.layoutWrapper}>
        <View style={styles.formWrapper}>
          <View style={styles.wrapper}>
            <View style={styles.center}>
              <View style={{ maxHeight: 75, marginLeft: 80 }}>
                <Logo color="black" />
              </View>
            </View>
            {!success ? (
              <ColoredText style={{ marginBottom: 10, color: 'black' }}>
                {t('text.baseResetPassword.welcome')}
              </ColoredText>
            ) : null}
            {alert && (
              <View style={styles.alert}>
                <ColoredText style={{ color: colors[alert?.color || 'black'] }}>{alert.content}</ColoredText>
              </View>
            )}
            <View>
              {success ? (
                <View style={{ paddingVertical: 5 }}>
                  <ColoredText style={{ color: 'black' }}>{t('text.baseResetPassword.success')} </ColoredText>
                </View>
              ) : (
                <ResetPasswordForm loading={loading} onSubmit={handleResetPassword} />
              )}
              {/* <View style={styles.hairLine} /> */}
              {/* <View style={{ flexDirection: 'row', display: 'flex' }}>
              <ColoredText>{t('text.baseLogin.noAccount')} </ColoredText>
              <Link to={'/signup'}>{t('link.baseLogin.signup')}</Link>
            </View>

            <View style={{ flexDirection: 'row', display: 'flex' }}>
              <ColoredText>{t('text.baseSignup.hasAccount')} </ColoredText>
              <Link to={'/login'}>{t('link.baseSignup.login')}</Link>
            </View> */}
            </View>
            <View
              style={{
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <ColoredText
                style={{
                  fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
                  fontSize: 12,
                  color: 'black',
                }}
              >{`${t('text.baseLogin.noAccount')}`}</ColoredText>
              <Link to={'/signup'} style={{ marginBottom: 5, textDecorationLine: 'none' }}>
                <ColoredText
                  style={{
                    fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
                    fontSize: 12,
                    color: colors.linkColor,
                  }}
                >
                  {t('link.baseLogin.signup_dashbar')}
                </ColoredText>
              </Link>
            </View>
            <View
              style={{
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              <ColoredText
                style={{
                  fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
                  fontSize: 12,
                  color: 'black',
                }}
              >{`${t('text.baseSignup.hasAccount')}`}</ColoredText>
              <Link to={'/login'} style={{ marginBottom: 5, textDecorationLine: 'none' }}>
                <ColoredText
                  style={{
                    fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
                    fontSize: 12,
                    color: colors.linkColor,
                  }}
                >
                  {t('link.baseSignup.login')}
                </ColoredText>
              </Link>
            </View>
          </View>
          {Platform.OS === 'web' && (
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignContent: 'center',
                alignItems: 'center',
                marginTop: 15,
              }}
            >
              <ColoredText style={{ marginRight: 10, fontSize: 14 }}>{`${t(
                'text.baseSignup.install_app'
              )}`}</ColoredText>
              <TouchableOpacity onPress={() => openUrl(`https://apps.apple.com/us/app/dash-bar/id1439795569`)}>
                <AppStore />
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginLeft: 10 }}
                onPress={() => openUrl(`https://play.google.com/store/apps/details?id=bar.dash.app&hl=de`)}
              >
                <PlayStore />
              </TouchableOpacity>
            </View>
          )}
        </View>
        {showImage === true && (
          <>
            <View style={styles.spacer} />
            <View>
              <Image
                {...{
                  height: 550,
                  width: 300,
                  resizeMode: 'contain',
                  source: { uri: 'https://ws-cdn.de/dashbar/landingpage.png' },
                  alt: 'Not found',
                  style: {
                    height: 550,
                    width: 300,
                  },
                }}
              />
            </View>
          </>
        )}
      </View>
    </View>
  )
}

export default ResetPassword
