import React from 'react'
import { VictoryPie } from 'victory'
import numbro from 'numbro'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { View } from 'react-native'
import { AmazonPieChartData } from '../types'
import { WidgetSize } from '../../../../../components/Widget'

interface PropsPieChart {
  size: WidgetSize
  pieData: Array<AmazonPieChartData>
}

const AmazonPieChart = ({ size, pieData }: PropsPieChart) => {
  return (
    <View style={{ height: size.height, flexDirection: 'column' }}>
      <VictoryPie
        width={size.width + 50}
        height={size.height}
        radius={size.height * 0.5}
        innerRadius={size.height * 0.44}
        labels={[...pieData.map((data: AmazonPieChartData) => data.value.toLocaleString())]}
        colorScale={pieData.map((data: AmazonPieChartData) => data.color)}
        data={pieData.map((data: AmazonPieChartData) => data.value)}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexBasis: 16 }}>
        {pieData.map((obj, index) => (
          <View key={index} style={{ flexDirection: 'row' }}>
            <View style={{ backgroundColor: obj.color, height: 12, width: 12, borderRadius: 2, marginRight: 5 }} />
            <ColoredText key={index} style={{ fontSize: 12 }}>
              {`${obj.name} (${numbro(obj.value).formatCurrency({
                ...numbro.languageData().currencyFormat,
                currencySymbol: '€',
                spaceSeparated: true,
                average: true,
              })})`}
            </ColoredText>
          </View>
        ))}
      </View>
    </View>
  )
}

export default AmazonPieChart
