import React from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import StatusView from './View'
import { KeyFiguresT } from '../../../../../types/widgetDataTypes/types'
import Widget from '../../../../components/Widget'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { useNavigate } from '../../../../../components/Router/Router'
import StatusSkeleton from './StatusSkeleton'

const Status = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Widget<KeyFiguresT>
      title={t('shop.widget.status.title')}
      widget={widget}
      loadingSkeleton={<StatusSkeleton />}
      getApiConfig={[
        // () => ({
        //   body: {
        //     request: 'Data',
        //     widget: {
        //       status: {
        //         start_time: Date.now(),
        //       },
        //     },
        //   },
        //   widget: 'status',
        // }),
        ({ autoRefresh, refreshInterval, interval = 'D30' }) => ({
          body: {
            request: 'Analytics',
            widget: {
              status: {
                info: ['sales', 'visitors', 'orders', 'conversions'],
                options: {
                  active: true,
                  details: false,
                  interval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                },
              },
            },
          },
          widget: 'status',
        }),
      ]}
      showMissingData
      hasInterval
      onClickWidget={() => {
        navigate(`/analytics/live-tracker/${widget.settings.shop}`)
      }}
      {...props}
    >
      {([, data, , shop, size]) => {
        return data && <StatusView status={data} keyFigures={data} shop={shop} size={size} />
      }}
    </Widget>
  )
}

export default Status
