import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */
const SvgComponent = (props: SvgProps) => (
  <Svg width={23} height={17} {...props}>
    <Path
      fill={props.color || '#000'}
      fillRule="nonzero"
      d="M18 .5a2.251 2.251 0 0 1 2.25 2.25v2.271c-.534.07-1.048.31-1.455.72l-6.374 6.371c-.289.288-.492.65-.59 1.048l-.212.84H2.25A2.251 2.251 0 0 1 0 11.75v-9A2.25 2.25 0 0 1 2.25.5H18ZM3.937 8.375a.564.564 0 0 0-.562.563c0 .309.253.562.563.562h7.874c.31 0 .563-.253.563-.563a.564.564 0 0 0-.563-.562H3.939Zm0-2.25h11.25c.31 0 .563-.253.563-.563A.564.564 0 0 0 15.187 5H3.938a.564.564 0 0 0-.562.563c0 .309.253.562.563.562Zm18.145.918a1.403 1.403 0 0 1 0 1.99l-1.034 1.033-2.493-2.496 1.034-1.034a1.409 1.409 0 0 1 1.99 0l.503.507Zm-8.867 5.836 4.542-4.511 2.497 2.493-4.543 4.542a1.13 1.13 0 0 1-.524.295l-2.112.53a.574.574 0 0 1-.535-.147.596.596 0 0 1-.147-.566l.527-2.085c.05-.196.151-.376.295-.551Z"
    />
  </Svg>
)
export default SvgComponent
