import React, { useContext } from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import Mixed from '../../../../../components/VictoryCharts/Mixed'
import { ConversionsT } from '../../../../../types/widgetDataTypes/types'
import Widget from '../../../../components/Widget'
import { useTranslation } from '../../../../../hooks/useTranslation'
import ConversionSkeleton from './ConversionSkeleton'
import { UpsellingContext } from '../../../../../context/UpsellingContext'

const BasicConversion = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const { open } = useContext(UpsellingContext)

  return (
    <Widget<ConversionsT>
      title={'Basic ' + t('shop.widget.conversion.title')}
      widget={widget}
      hasInterval
      isBasic
      loadingSkeleton={<ConversionSkeleton />}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, interval }) => ({
          body: {
            request: 'Analytics',
            widget: {
              conversion: {
                options: {
                  active: true,
                  details: false,
                  interval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                },
              },
            },
          },
          widget: 'conversion',
        }),
      ]}
      onClickWidget={() => open()}
      showOptions
      {...props}
    >
      {([, data, , , size]) => {
        return <Mixed data={data} size={size} displayLegend={false} />
      }}
    </Widget>
  )
}

export default BasicConversion
