import firebase from 'firebase/compat/app'

export const updatePluginVersion = async (
  cid: string,
  domain: string,
  version: string,
  platform: Record<string, any>
) => {
  await firebase
    .firestore()
    .collection(`company/${cid}/shop`)
    .doc(`${domain}`)
    .set(
      {
        platform: {
          ...platform,
          pluginVersion: version,
          updated: new Date(),
        },
      },
      { merge: true }
    )
}
