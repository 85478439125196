import { Toast } from 'native-base'
import React, { useContext } from 'react'
import Button from '../../components/Button'
import ColoredText from '../../components/CustomText/ColoredText'
import { FontAwesomeIcon } from '../../components/FontAwesome/ColoredFontAwesomeIcon'
import { SessionContext } from '../../context/SessionContext'
import { useTranslation } from '../../hooks/useTranslation'
import useStyles from './styles'

interface IProps {
  updateConnectedState?: (connected: boolean) => void
}

const AppleDisconnect = ({ updateConnectedState }: IProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { currentUser } = useContext(SessionContext)

  const handleAppleDisconnect = () => {
    if (currentUser?.providerData?.length > 1) {
      currentUser
        ?.unlink('apple.com')
        .then(() => {
          updateConnectedState(false)
        })
        .catch((err) => {
          alert(JSON.stringify(err))
        })
    } else {
      Toast.show({
        title: t('accountOverview.profile.toasts.cannot_disconnect'),
        description: t('accountOverview.profile.toasts.required_number_too_low'),
      })
    }
  }

  return (
    <Button style={styles.button} onPress={() => handleAppleDisconnect()} disabled={false}>
      <FontAwesomeIcon style={{ color: 'white' }} icon={['fab', 'apple']} />
      <ColoredText style={{ color: 'white' }}>
        <> {t('button.baseLogin.disconnect', { provider: 'Apple' })}</>
      </ColoredText>
    </Button>
  )
}

export default AppleDisconnect
