import { useTranslation } from '../../../../../../../hooks/useTranslation'
import ColoredText from '../../../../../../../components/CustomText/ColoredText'
import React from 'react'
import { ApiError } from '../../../../../../../../functions/src/widgets/wawi/fetchRegStatus'

/**
 * used to get the right errormessage for the user depending on the status of the first request
 * @param wawiAuthError the possible error from the authentication request
 * @param styles either the web or the native style
 * @returns a React Element that contains the message
 */
export const WawiErrorText = (wawiAuthError: ApiError | null, styles: any) => {
  const { t } = useTranslation()
  switch (wawiAuthError?.errorCode) {
    case 'NoActiveRegistration':
      return (
        <ColoredText style={styles.errorMessage}>{t('settings.web.connections.wawi.noActiveRegistration')}</ColoredText>
      )
    case 'AppAlreadyRegistered':
      return (
        <ColoredText style={styles.errorMessage}>{t('settings.web.connections.wawi.appAlreadyRegistered')}</ColoredText>
      )
    case 'RequestSyntaxError':
      return (
        <ColoredText style={styles.errorMessage}>{t('settings.web.connections.wawi.requestSyntaxError')}</ColoredText>
      )
    default:
      return <ColoredText style={styles.errorMessage}>{t('settings.web.connections.wawi.basicError')}</ColoredText>
  }
}

export default WawiErrorText
