import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const Paypal = (props: SvgProps) => (
  <Svg fill="none" height={24} width={32} {...props}>
    <Path
      d="M27.904 0H4.096C1.834 0 0 1.849 0 4.129v15.742C0 22.151 1.834 24 4.096 24h23.808C30.166 24 32 22.151 32 19.871V4.129C32 1.849 30.166 0 27.904 0z"
      fill="#0071c0"
    />
    <Path
      d="M20.242 8.87c-.01.072-.023.145-.036.22-.475 2.538-2.1 3.414-4.176 3.414h-1.057a.52.52 0 0 0-.507.453l-.541 3.57-.153 1.012a.278.278 0 0 0 .267.326h1.874c.222 0 .41-.168.445-.396l.019-.099.353-2.33.022-.128a.457.457 0 0 1 .446-.397h.28c1.816 0 3.237-.767 3.653-2.987.174-.927.084-1.702-.376-2.246a1.803 1.803 0 0 0-.513-.412z"
      fill="#99c6e5"
    />
    <Path
      d="M19.746 8.664a3.63 3.63 0 0 0-.462-.107 5.645 5.645 0 0 0-.932-.07h-2.823a.434.434 0 0 0-.195.045.467.467 0 0 0-.25.35l-.6 3.96-.018.115a.52.52 0 0 1 .507-.453h1.057c2.075 0 3.7-.877 4.176-3.414.014-.075.026-.148.036-.22a2.48 2.48 0 0 0-.496-.206z"
      fill="#cce2f2"
    />
    <Path
      d="M15.084 8.883a.465.465 0 0 1 .25-.35.435.435 0 0 1 .195-.046h2.823c.334 0 .647.023.932.07a4.128 4.128 0 0 1 .462.107l.106.035c.14.048.27.105.39.171.142-.938 0-1.576-.488-2.154-.537-.637-1.506-.91-2.747-.91h-3.602a.521.521 0 0 0-.509.453l-1.5 9.896a.318.318 0 0 0 .305.372h2.224l.558-3.686z"
      fill="#fff"
    />
  </Svg>
)

export default Paypal
