import React, { useCallback, useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import AuthError from '../../../../utils/AuthError'
import UpdatePassword from '../../../../components/UpdatePassword'
import { EmailVerified } from '../../../../components/EmailVerified'
import { RecoverEmail } from '../../../../components/RecoverEmail'
import { Navigate, useLocation } from '../../../../components/Router/Router'

const AccountManagement = () => {
  const firebase = useFirebase()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const mode = searchParams.get('mode')
  const oobCode = searchParams.get('oobCode') || '-'
  //const apiKey = searchParams.get('apiKey')

  const [updatePasswordState, setUpdatePasswordState] = useState<{
    error?: AuthError
    loading: boolean
    success?: boolean
  }>({
    error: undefined,
    loading: false,
    success: false,
  })

  const handleUpdatePassword = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      setUpdatePasswordState({ loading: true })
      //console.debug('(AccountManagement) reset password')
      firebase
        .auth()
        .verifyPasswordResetCode(oobCode)
        .then((accountEmail) => {
          if (accountEmail === email) {
            firebase
              .auth()
              .confirmPasswordReset(oobCode, password)
              .then(() => {
                //console.debug('(ResetPassword) Successful reseted password')
                setUpdatePasswordState({ loading: false, success: true })
              })
              .catch(({ code }: { code: string }) => {
                //console.debug('(ResetPassword) Error reseting password:' + code)
                setUpdatePasswordState({ loading: false, error: new AuthError(code) })
              })
          } else {
            setUpdatePasswordState({ loading: false, error: new AuthError('wrongEmail') })
          }
        })
        .catch(({ code }: { code: string }) => {
          setUpdatePasswordState({ loading: false, error: new AuthError(code) })
          //console.debug('(AccountManagemnt) ' + code)
        })
    },
    [setUpdatePasswordState]
  )

  const alert =
    updatePasswordState.error && !updatePasswordState.loading
      ? {
          content: updatePasswordState.error.message,
          color: 'error' as 'error' | 'success',
        }
      : undefined
  switch (mode) {
    case 'resetPassword':
      return (
        <UpdatePassword
          success={updatePasswordState.success}
          alert={alert}
          loading={updatePasswordState.loading}
          handleUpdatePassword={handleUpdatePassword}
        />
      )
    case 'recoverEmail':
      return <RecoverEmail oobCode={oobCode} />
    case 'verifyEmail':
      return <EmailVerified oobCode={oobCode} />
    default:
      return <Navigate to={'/login'} />
  }
}

export default AccountManagement
