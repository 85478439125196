import getApp from '../firebase'
import { useCallback } from 'react'

const useSetDocument = <T>(collection: string) =>
  useCallback(
    (data: Partial<T>, id?: string) => {
      return getApp().firestore().collection(collection).doc(id).set(data, { merge: true })
    },
    [collection]
  )

export default useSetDocument
