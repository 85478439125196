import React, { ReactNode } from 'react'
import { TouchableOpacity, GestureResponderEvent, StyleProp, ViewStyle, TextStyle, View } from 'react-native'
import ColoredText from '../CustomText/ColoredText'
import Link from '../Router/Link'
import useStyles from './styles'

interface IProps {
  disabled?: boolean
  children: ReactNode
  onPress?: (e: GestureResponderEvent) => void
  to?: string
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  primary?: boolean
  oneLine?: boolean
}

const Button = ({ style, textStyle, onPress, to, disabled, children, primary = false, oneLine = true }: IProps) => {
  const styles = useStyles()

  if (to && !onPress) {
    return (
      <Link to={to}>
        <View style={[styles.button, primary && styles.primary, style]}>
          <ColoredText style={[primary && styles.primaryText, textStyle]}>{children}</ColoredText>
        </View>
      </Link>
    )
  }

  return (
    <TouchableOpacity style={[styles.button, primary && styles.primary, style]} onPress={onPress} disabled={disabled}>
      <ColoredText
        numberOfLines={oneLine ? 1 : undefined}
        allowFontScaling={false}
        style={[primary && styles.primaryText, textStyle]}
      >
        {children}
      </ColoredText>
    </TouchableOpacity>
  )
}

export default Button
