import { functions } from '../../../../../firebase'
const setWidgetRefreshInterval = functions().httpsCallable('calls-user-setWidgetRefreshInterval')

export const storeSettings = async (widget: Record<string, any>, bundle: string | undefined) => {
  const interval = +widget.interval
  let allowedInterval = 120
  switch (bundle) {
    case 'pro':
      allowedInterval = 1
      break
    case 'business':
      allowedInterval = 10
      break
    default:
      allowedInterval = 120
  }
  if (interval < allowedInterval) {
    return
  }
  if (interval === 0) {
    return
  }

  setWidgetRefreshInterval({ widget: widget })
    .then(() => {})
    .catch(() => {})
}
