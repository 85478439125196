import React from 'react'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { View } from 'react-native'

const NoDataView = () => {
  const { t } = useTranslation()
  return (
    <View style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <ColoredText>{t('shop.widget.googleSearchConsole.error.cantGetData')}</ColoredText>
    </View>
  )
}

export default NoDataView
