import numbro from 'numbro'
import React from 'react'
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory'
import colors from '../../../constants/colors'
import { View } from 'react-native'
import { useTextColor } from '../../../hooks/useColors'

interface BarChartProps {
  data: Array<number>
  size: {
    width: number
    height: number
  }
  showAxis?: boolean
  displayValues?: boolean
}

const BarChart = ({ data, showAxis = false, size, displayValues = true }: BarChartProps) => {
  const textColor = useTextColor()
  let counter = 0
  const preparedData = data?.map((v) => {
    counter++
    return {
      x: `${numbro(v).format({
        mantissa: 1,
        spaceSeparated: true,
        average: true,
      })}`,
      y: v,
      key: counter,
    }
  })

  return (
    <View
      style={{
        width: size.width,
        height: size.height,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <VictoryChart domainPadding={{ x: 0, y: 0 }} width={size.width + 270} height={size.height + 200}>
        <VictoryBar
          x={'key'}
          y={'y'}
          data={preparedData}
          categories={{ x: ['1', '2', '3', '4'] }}
          alignment={'middle'}
          barRatio={1.1}
          barWidth={90}
          style={{ data: { fill: colors.color2, fillOpacity: 1 } }}
        />

        <VictoryAxis
          tickValues={[0, 1, 2, 3]}
          tickFormat={(index) => {
            console.log(index)
            if (preparedData === undefined) return ''
            return preparedData[index - 1]?.x ?? ''
          }}
          style={{
            ...(!showAxis && { axis: { stroke: 'transparent' } }),
            tickLabels: {
              fill: textColor,
              fontSize: 24,
              fontFamily: 'Comfortaa',
              ...(displayValues === false && { display: 'none' }),
            },
          }}
        />
      </VictoryChart>
    </View>
  )
}

export default BarChart
