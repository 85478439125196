import { useSelector } from 'react-redux'
import { IAuth } from '../types/dashbar/auth'

/** @description
 * Migrated from @webstollen/shared-react-components
 */

const useAuth = () => {
  const auth = useSelector((state: { firebase: { auth: IAuth } }) => state.firebase.auth)

  return {
    ...auth,
    isAuthed: auth.isLoaded === true && auth.isEmpty === false,
  }
}

export default useAuth
