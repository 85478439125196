import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'
import { useTextColor } from '../../../hooks/useColors'
/* SVGR has dropped some elements not supported by react-native-svg: title */
const SvgComponent = (props: SvgProps) => {
  const textColor = useTextColor()
  return (
    <Svg width={74} height={64} {...props}>
      <Path
        fill={textColor}
        fillRule="nonzero"
        d="M7.515 53.33a3.398 3.398 0 0 0 3.402 3.402h58.97a3.394 3.394 0 0 1 3.403 3.402 3.394 3.394 0 0 1-3.402 3.402H10.917C5.28 63.536.71 58.972.71 53.33V3.43a3.403 3.403 0 0 1 6.805 0V53.33Zm41.96-39.692H65.35a3.394 3.394 0 0 1 3.402 3.402v15.877a3.394 3.394 0 0 1-3.402 3.402 3.394 3.394 0 0 1-3.402-3.402v-7.67L46.087 40.87c-1.205 1.446-3.36 1.446-4.693 0L30.068 29.784l-8.93 8.817c-1.205 1.446-3.36 1.446-4.693 0-1.318-1.205-1.318-3.36 0-4.692l11.34-11.34c1.333-1.319 3.488-1.319 4.693 0L43.804 33.78l13.34-13.339h-7.67a3.394 3.394 0 0 1-3.402-3.402 3.394 3.394 0 0 1 3.403-3.402Z"
      />
    </Svg>
  )
}
export default SvgComponent
