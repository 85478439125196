import React from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import Mixed from '../../../../../components/VictoryCharts/Mixed'
import { ConversionsT } from '../../../../../types/widgetDataTypes/V3/types'
import Widget from '../../../../components/Widget'
import { useNavigate } from '../../../../../components/Router/Router'
import { useTranslation } from '../../../../../hooks/useTranslation'
import ConversionSkeleton from './ConversionSkeleton'

const Conversion = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Widget<ConversionsT>
      title={t('shop.widget.conversion.title')}
      widget={widget}
      hasInterval
      loadingSkeleton={<ConversionSkeleton />}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, interval }) => ({
          body: {
            module: 'Analytics',
            endpoint: ['conversion'],
            params: {
              options: {
                // check details
                details: true,
                interval,
                refresh: autoRefresh ? refreshInterval : undefined,
              },
            },
          },
          widget: 'conversion',
        }),
      ]}
      showOptions
      onClickWidget={() => {
        navigate(`/analytics/analytics/${widget.settings.shop}`)
      }}
      {...props}
    >
      {/*  data --> conversionT  */}
      {([, data, , , size]) => {
        //Map Funktion auf Mixed
        // console.log(data)
        const mapedData = data.map((v) => {
          return { rate: v.conversion_rate, sale: v.orders_count, visitors: v.visitors_count }
        })
        return <Mixed data={mapedData} size={size} />
      }}
    </Widget>
  )
}

export default Conversion
