import moment from 'moment'
export type GoogleSearchConsoleResponse = {
  clicks: number
  ctr: number
  impressions: number
  keys: Array<string>
  position: number
}

function getQuarterRange(quarter) {
  const start = moment().quarter(quarter).startOf('quarter')
  const end = moment().quarter(quarter).endOf('quarter')
  return { start, end }
}
export function getApiInterval(interval: string | undefined, start: string, secondInterval: boolean) {
  const today = moment()
  let startDate = moment(start, 'YYYY-MM-DD')
  let endDate = moment(start, 'YYYY-MM-DD')
  let quaterRange
  if (interval === undefined) throw new Error('Ungültiges Intervall: ' + interval)
  const firstChar = interval[0]
  const number = secondInterval ? Number(interval?.slice(1)) - 1 : Number(interval?.slice(1))
  switch (firstChar) {
    case 'D':
      startDate = startDate.subtract(number, 'days')
      if (number === 1) endDate = endDate.subtract(1, 'days')
      break
    case 'M':
      startDate = moment()
        .month(number - 1)
        .startOf('month')
      endDate = moment()
        .month(number - 1)
        .endOf('month')
      break
    case 'Q':
      quaterRange = getQuarterRange(number)
      startDate = quaterRange.start
      endDate = quaterRange.end
      break
    case 'Y':
      startDate = moment(`${number}-01-01`, 'YYYY-MM-DD')
      endDate = moment(`${number}-12-31`, 'YYYY-MM-DD')
      if (endDate.diff(today) < 0 && number === today.year()) endDate = today
      break
    default:
      throw new Error('Ungültiges Intervall: ' + interval)
  }
  // console.log(`startDate: ${startDate.format('YYYY-MM-DD')}, endDate: ${endDate.format('YYYY-MM-DD')} ${secondInterval}`)
  return { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') }
}
export function formatURL(url: string): string {
  // remove "http://" or "https://"
  url = url.replace(/(^\w+:|^)\/\//, '')
  // remove subpages
  const subpages = url.split('/')
  const mainDomain = subpages[0]

  return mainDomain
}
export function filterIntervals(intervals) {
  intervals.map((i) => {
    const objIndex = intervals.findIndex((obj) => obj.label === i.label)
    if (i.value === 'D1') intervals[objIndex].active = false
    if (i.value[0] === 'Q' && Number(i.value.slice(1)) > moment().quarter()) intervals[objIndex].active = false
  })
}
export function getSearchConsoleAnalyticsURI(webpage: string) {
  return `https://www.googleapis.com/webmasters/v3/sites/https%3A%2F%2F${webpage}%2F/searchAnalytics/query/`
}
