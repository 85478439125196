/** @description
 * Migrated from @webstollen/shared-react-components
 */

export type FontFamilyKeys = 'bold' | 'regular' | 'light' | 'medium' | 'semibold' | 'extrabold'

export type FontFamilyMapT = Record<FontFamilyKeys, string> & { monospace?: string }

let _fontFamilyMap: FontFamilyMapT

export const initFontFamily = (fontFamilies: FontFamilyMapT) => {
  _fontFamilyMap = Object.freeze(fontFamilies)
}

export const useFontFamily = () => {
  if (!_fontFamilyMap) {
    throw new Error('please execute `initFontFamily` to initialize font families first')
  }

  return _fontFamilyMap
}
