import { Platform, StyleSheet } from 'react-native'

const useStyles = () => {
  return StyleSheet.create({
    icon: {
      height: 25,
      width: 25,
      borderRadius: 12.5,
    },

    linkWrapper: {
      marginTop: Platform.select({ default: undefined, web: 8 }),
    },

    link: {
      marginTop: 7.5,
      marginRight: 7.5,
    },

    mainTitle: {
      marginTop: Platform.select({ default: undefined, web: 3 }),
      width: '85%',
    },

    mainSubtitle: {
      marginTop: Platform.select({ default: -5, web: -10 }),
    },
  })
}

export default useStyles
