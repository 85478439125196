import React from 'react'
import { View } from 'react-native'
import Mastercard from '../../../../../../assets/imgs/paymentMethods/mastercard'
import Visa from '../../../../../../assets/imgs/paymentMethods/visa'
import Amex from '../../../../../../assets/imgs/paymentMethods/amex'
import Cartasi from '../../../../../../assets/imgs/paymentMethods/cartasi'
import Maestro from '../../../../../../assets/imgs/paymentMethods/maestro'
import Creditcard from '../../../../../../assets/imgs/paymentMethods/creditcard'
import Paypal from '../../../../../../assets/imgs/paymentMethods/paypal'
import Directdebit from '../../../../../../assets/imgs/paymentMethods/directdebit'
import { LinearGradient } from 'expo-linear-gradient'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import useStyles from './styles'

const MandateSkeleton = ({ data }: { data: Record<string, any> }) => {
  const styles = useStyles()
  let methodImage: JSX.Element
  let methodName: string
  let customerName = ''
  let methodInfo = ''
  let validText = ''
  if (data?.mandate?.method === 'creditcard') {
    methodName = `Kreditkarte`
    customerName = `${data?.mandate?.details?.cardHolder}`
    methodInfo = `${data?.mandate?.details?.cardNumber}`
    validText = `gültig bis: ${data?.mandate?.details?.cardExpiryDate}`
    switch (data?.mandate?.details?.cardLabel?.toLowerCase() || null) {
      case 'mastercard':
        methodImage = <Mastercard />
        break
      case 'visa':
        methodImage = <Visa />
        break
      case 'american express':
        methodImage = <Amex />
        break
      case 'carta si':
        methodImage = <Cartasi />
        break
      case 'maestro':
        methodImage = <Maestro />
        break
      default:
        methodImage = <Creditcard />
    }
  } else if (data?.mandate?.method === 'paypal') {
    methodImage = <Paypal />
    methodName = `PayPal`
    customerName = `${data?.mandate?.details?.consumerName}`
    methodInfo = `${data?.mandate?.details?.consumerAccount}`
    validText = `gültig`
  } else {
    methodImage = <Directdebit />
    methodName = `Lastschrift`
    customerName = `${data?.mandate?.details?.consumerName}`
    methodInfo = `${data?.mandate?.details?.consumerAccount}`
    validText = `gültig`
  }
  return (
    <LinearGradient
      colors={['#346eeb', '#2459c9', '#164ec7']}
      style={styles.cardWrapper}
      start={{ x: 1.0, y: 0.5 }}
      end={{ x: 0.0, y: 0.5 }}
    >
      <View style={styles.cardHeader}>
        <ColoredText style={styles.cardType}>{`${methodName}`}</ColoredText>
        {methodImage}
      </View>
      <View style={styles.cardBody}>
        <ColoredText style={styles.cardHolder}>{customerName}</ColoredText>
        <ColoredText style={styles.cardNumber}>{methodInfo}</ColoredText>
      </View>
      <View style={styles.cardFooter}>
        <ColoredText style={styles.validUntil}>{validText}</ColoredText>
      </View>
    </LinearGradient>
  )
}

export default MandateSkeleton
