import { StyleSheet } from 'react-native'
import useIsMobileWebRender from '../../../../../../hooks/useIsMobileWebRender'
import colors from '../../../../../../constants/colors'
import sizes from '../../../../../../constants/sizes'
import { useTheme } from '../../../../../../utils/types/theme'
import { useHighlightColor, useSecondaryBackground } from '../../../../../../hooks/useColors'
import { Themes } from '../../../../../../types/dashbar/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      backgroundColor: useSecondaryBackground(),
      borderRadius: 15,
      marginVertical: 15,
    },
    titleWrapper: {
      height: 200,
      maxHeight: 200,
      minHeight: 200,
      width: '100%',
      backgroundColor: 'orange',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 30,
    },
    titleContentWrapper: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    backgroundFree: {
      backgroundColor: colors.bundleFree,
    },
    backgroundBusiness: {
      backgroundColor: colors.bundleBusiness,
    },
    backgroundPro: {
      backgroundColor: colors.bundlePro,
    },
    borderFree: {
      borderColor: colors.bundleFree,
      borderWidth: 4,
    },
    borderBusiness: {
      borderColor: colors.bundleBusiness,
      borderWidth: 4,
    },
    borderPro: {
      borderColor: colors.bundlePro,
      borderWidth: 4,
    },
    contentWrapper: {
      height: 220,
      flex: 1,
      // maxHeight: 220,
      width: '100%',
    },
    bundleOptionsWrapper: {
      minHeight: 70,
      height: 70,
      maxHeight: 70,
      width: '100%',
      paddingHorizontal: 30,
      paddingVertical: 10,
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'space-around',
    },
    amountWrapper: {
      minHeight: 150,
      // maxHeight: 150,
      width: '100%',
      paddingHorizontal: 30,
      paddingVertical: 10,
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      alignContent: 'space-around',
    },
    intervalCard: {
      borderRadius: 15,
      borderWidth: 1,
      borderColor: colors.color2,
      padding: 15,
      width: '40%',
      minWidth: useIsMobileWebRender() ? 300 : '40%',
      maxWidth: '40%',
      marginTop: useIsMobileWebRender() ? 10 : 0,
      flexWrap: 'wrap',
    },
    itemWrapper: {
      width: '50%',
      maxWidth: '50%',
      minWidth: '50%',
      flex: 1,
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'flex-start',
    },
    itemText: {
      marginHorizontal: 15,
    },
    title: {
      fontSize: 30,
      fontWeight: '500',
      marginVertical: 15,
      color: 'white',
    },
    subtitle: {
      fontSize: 12,
      fontWeight: '300',
      marginVertical: 2,
      color: 'white',
    },
    divider: {
      width: '100%',
      backgroundColor: theme === Themes.Dark ? colors.lightBg1 : colors.darkBg1,
      height: 1,
      maxHeight: 1,
    },
    intervalWrapper: {
      width: '100%',
      height: '100%',
      minHeight: '100%',
      flex: 1,
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    amountInterval: {
      width: '30%',
    },
    amountValue: {
      width: '40%',
      fontWeight: '600',
    },
    selected: {
      borderWidth: 2,
    },
    deleteWrapper: {
      width: '95%',
      maxWidth: '95%',
      minWidth: '95%',
      minHeight: 70,
      flex: 1,
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    deleteText: {
      width: '60%',
      maxWidth: '60%',
      marginVertical: 20,
      marginHorizontal: 20,
      fontSize: 12,
    },
    button: {
      borderColor: colors.error,
      borderWidth: 2,
      width: '30%',
      borderRadius: 10,
      height: sizes.buttonHeight,
      color: theme === Themes.Dark ? colors.lightBg1 : colors.error,
      backgroundColor: useSecondaryBackground(),
    },
    //Mobile Settings
    mobileCard: {
      width: '100%',
      //height: 120,
      minHeight: 120,
      //maxHeight: 120,
      borderRadius: 15,
      padding: 10,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      //backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      backgroundColor: useSecondaryBackground(),
    },
    bundleWrapper: {
      height: 100,
      minHeight: 100,
      maxHeight: 100,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      borderRadius: 15,
      padding: 5,
      overflow: 'hidden',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'center',
    },
    bundleTextWrapper: {
      width: '50%',
      minWidth: '50%',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 10,
      paddingHorizontal: 20,
    },
    iconWrapper: {
      alignSelf: 'flex-end',
      alignItems: 'flex-end',
      flex: 1,
      height: '100%',
      maxHeight: '100%',
      minHeight: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },

    cancelButton: {
      borderRadius: 15,
      borderColor: useHighlightColor(),
      borderWidth: 1,
      height: sizes.buttonHeight,
      width: '100%',
    },
    deleteButton: {
      borderColor: colors.error,
      borderWidth: 2,
      width: '100%',
      height: sizes.buttonHeight,
      borderRadius: 15,
      color: theme === Themes.Dark ? colors.lightBg1 : colors.error,
    },
    deleteModalWrapper: {
      width: '70%',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
}

export default useStyles
