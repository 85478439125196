export const optionsFree = {
  user: 1,
  shops: 1,
  dashboards: 1,
  notifications: 10,
}

export const optionsBusiness = {
  user: 5,
  shops: 1,
  dashboards: 3,
  notifications: 375,
}

export const optionsPro = {
  user: 10,
  shops: 5,
  dashboards: 15,
  notifications: 'unbegrenzt',
}

export const optionsEnterprise = {
  user: 10,
  shops: 10,
  dashboards: 15,
  notifications: 'unbegrenzt',
}

/**
 * Vat amount for 'de'
 */
export const VAT_AMOUNT_IN_PERCENT_DEFAULT = 19

/**
 * Vat amount for EU
 */
export const VAT_AMOUNT_IN_PERCENT_EU = 0

/**
 * Vat amount for non EU
 */
export const VAT_AMOUNT_IN_PERCENT_NOT_EU = 0
