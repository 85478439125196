import { Themes } from '../types/dashbar/theme'
import { isThemeDark, useTheme } from '../utils/types/theme'

/** @description
 * Migrated from @webstollen/shared-react-components
 */

export type ColorMapT = Record<
  'darkBg1' | 'darkBg2' | 'lightBg1' | 'lightBg2' | 'darkText1' | 'lightText1' | 'highlightDark' | 'highlightLight',
  string
>

let _colorMapping: ColorMapT

export const initColorMap = (colorMap: ColorMapT) => {
  _colorMapping = Object.freeze(colorMap)
}

const requiresColorMap = (cb: () => string) => {
  if (!_colorMapping) {
    throw new Error('please initialise colors with `initColorMap` before using it')
  }

  return cb()
}

export const getTextColor = (theme: Themes) =>
  requiresColorMap(() => (isThemeDark(theme) ? _colorMapping.darkText1 : _colorMapping.lightText1))

export const getInvertedTextColor = (theme: Themes) =>
  requiresColorMap(() => (isThemeDark(theme) ? _colorMapping.lightText1 : _colorMapping.darkText1))

export const getPrimaryBackground = (theme: Themes) =>
  requiresColorMap(() => (isThemeDark(theme) ? _colorMapping.darkBg1 : _colorMapping.lightBg1))

export const getSecondaryBackground = (theme: Themes) =>
  requiresColorMap(() => (isThemeDark(theme) ? _colorMapping.darkBg2 : _colorMapping.lightBg2))

export const getHighlightColor = (theme: Themes) =>
  requiresColorMap(() => (isThemeDark(theme) ? _colorMapping.highlightDark : _colorMapping.highlightLight))

export const useTextColor = () => getTextColor(useTheme())
export const useInvertedTextColor = () => getInvertedTextColor(useTheme())
export const usePrimaryBackground = () => getPrimaryBackground(useTheme())
export const useSecondaryBackground = () => getSecondaryBackground(useTheme())
export const useHighlightColor = () => getHighlightColor(useTheme())
