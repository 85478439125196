import { StyleSheet, Platform } from 'react-native'
import colors from '../../../../../constants/colors'
import { useHighlightColor } from '../../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    iconWrapper: {
      borderRadius: 12.5,
      backgroundColor: useHighlightColor(),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    itemCurrentValue: {
      fontSize: Platform.OS === 'web' ? 15 : 13,
    },
    negative: {
      color: colors.error,
    },
    positive: {
      color: colors.success,
    },
    average: {
      color: 'orange',
    },
    status: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    statusItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: 5,
    },
    itemText: {
      fontSize: 12,
      paddingLeft: 10,
      lineHeight: Platform.select({ default: 15, ios: 18 }),
    },
    histIcon: {
      marginRight: 0,
      flexDirection: 'row',
    },
    noData: {
      flex: 1,
      alignItems: 'center',
      textAlign: 'center',
    },
  })
}

export default useStyles
