import axios, { AxiosError } from 'axios'
import { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { SessionContext } from '../../../context/SessionContext'
import { updatePluginVersion } from './helper'
import { mappedVersion } from '../../../utils/versioning'
import { SemVer } from 'semver'
import { ISettings, IShop } from '../../../types/dashboard'
export class ApiError extends Error {
  status: number | undefined
  dbError: Record<string, any> | undefined | null
  dbCode: string | undefined | null
}

// export const useShopApi = (endpoint: string, secret: string, { body, widget }: { body: unknown; widget: string }) => {
export const useShopApi = <DataTypes>(
  id: string,
  settings: ISettings,
  apiConfig: ApiConfigFunction,
  shop: IShop,
  refresh = true
) => {
  const { user } = useContext(SessionContext)
  const autoRefresh = user?.preferences?.widget?.autoRefresh || false
  const refreshInterval = user?.preferences?.widget?.interval || 120
  const endpoint = shop?.endpoint || ''
  const secret = shop?.secretToken || ''
  const config = apiConfig(settings)
  const body = config.body
  const widget = config.widget
  const [hasError, setHasError] = useState<boolean>(false)
  return useQuery<DataTypes, ApiError>(
    [settings, shop, id],
    () =>
      fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${secret}`,
          'User-Agent':
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36',
        },
        cache: 'no-cache',
        redirect: 'error',
        body: typeof body === 'string' ? body : JSON.stringify(body),
      })
        .then(async (response) => {
          if (response.status !== 200) {
            setHasError(true)
            const errorResponse = await response.json()
            setHasError(true)
            const e = new ApiError(`Error`)
            e.dbCode = errorResponse?.error?.dbCode || null
            e.dbError = errorResponse?.error?.dbError || null
            e.status = response.status
            throw e
          }
          setHasError(false)
          return response
        })
        .then((response) => response.json())
        .then((response) => {
          return response
        })
        .then(({ status, widget: result, error_message, data, version }) => {
          try {
            if (user?.company && version && version !== shop?.platform?.pluginVersion) {
              //Updates Plugin-Version with every pai call if outdated
              updatePluginVersion(user?.company, shop.domain, version, shop.platform)
            }
          } catch {
            //Catch error
          }
          if (status === 'ok') {
            //Required for backward compatibility can be delated after realising version 4
            if (mappedVersion(new SemVer(shop?.platform?.pluginVersion)) > 2) {
              if (widget.split(',').length > 1) {
                return data
              } else {
                return data[widget]
              }
            } else {
              return result[widget]
            }
          }

          if (status === 'error') {
            throw new Error(error_message)
          }
        })
        .catch((err) => {
          console.log(err)
          const parsedError = err as ApiError
          setHasError(true)
          if (!parsedError.status) {
            return axios.get(`${shop?.endpoint}`).catch((err: AxiosError) => {
              setHasError(true)
              if (!err.request.status || err.request.status === 0) {
                //In this case it's very likely that the status is either 429 or 503, so it's supposed to be 503
                //This might lead to wrong error messages but the probability therefor is very low
                const e = new ApiError(`Error`)
                e.status = 503
                throw e
              } else {
                const e = new ApiError(`Error`)
                e.status = err.request.status
                throw e
              }
            })
          } else {
            throw parsedError
          }
        })
        .finally(() => {
          // console.log(`Will reload: ${autoRefresh === true && !hasError} in ${refreshInterval * 60000}`)
        }),
    {
      refetchInterval: refresh === true && autoRefresh === true && !hasError ? refreshInterval * 60000 : false,
      staleTime: refreshInterval < 4 ? 30000 : (refreshInterval - 3) * 60000,
      retry() {
        return false
      },
    }
  )
}

export type ApiConfigFunction = (settings: ISettings, additionalOptions?: any) => { body: unknown; widget: string }
