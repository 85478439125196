import { StyleSheet } from 'react-native'
import { usePrimaryBackground, useSecondaryBackground } from '../../../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      backgroundColor: useSecondaryBackground(),
      borderRadius: 15,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'flex-start',
    },
    contentWrapper: {
      width: '100%',
      padding: 30,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    billWrapper: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      backgroundColor: usePrimaryBackground(),
      borderRadius: 15,
      paddingHorizontal: 30,
      paddingVertical: 15,
      marginVertical: 5,
    },
  })
}

export default useStyles
