import i18n from '../../../locales/i18n'
import { Themes } from '../../../types/dashbar/theme'
import DeprecatedVersion from '../../components/DeprecatedVersion'

export default {
  mollie_payment_v1: {
    component: DeprecatedVersion,
    title: 'Mollie',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: null,
      [Themes.Dark]: null,
    },
    shopSystem: [],
    authorizations: ['widgets.mollie'],
    config: {
      extension: 'mollie',
    },
  },
  relevanz_dashboard_v1: {
    component: DeprecatedVersion,
    title: 'Relevanz Dashboard',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: null,
      [Themes.Dark]: null,
    },
    shopSystem: [],
    authorizations: ['widgets.relevanz'],
    config: {
      extension: 'relevanz',
    },
  },
  relevanz_history_v1: {
    component: DeprecatedVersion,
    title: 'Relevanz Historie',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: null,
      [Themes.Dark]: null,
    },
    shopSystem: [],
    authorizations: ['widgets.relevanz'],
    config: {
      extension: 'relevanz',
    },
  },
  amazon_sales_v1: {
    component: DeprecatedVersion,
    title: 'Amazon Umsatz',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: null,
      [Themes.Dark]: null,
    },
    shopSystem: [],
    authorizations: ['widgets.amazon'],
  },
  amazon_buyer_type_v1: {
    component: DeprecatedVersion,
    title: 'Amazon Kundengruppen Umsatz',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: null,
      [Themes.Dark]: null,
    },
    shopSystem: [],
    authorizations: ['widgets.amazon'],
  },
  advertisement_v1: {
    component: DeprecatedVersion,
    title: i18n.t('shop.widget.advertisement.title'),
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: null,
      [Themes.Dark]: null,
    },
    shopSystem: [],
    authorizations: [],
  },
}
