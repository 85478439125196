import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import useIntervals from '../../../../../hooks/widget/useIntervals'
import { IWidgetProps } from '../../../../../types/props/Widget'
import IntervalSelection from '../../../../components/IntervalSelection'
import Widget from '../../../../components/WidgetNoAPI'
import { useUpdateWidgetContext } from '../../../../../types/Widget'
import Top5Rows from './components/Top5Rows'
import {
  filterIntervals,
  getApiInterval,
  getSearchConsoleAnalyticsURI,
  GoogleSearchConsoleResponse,
} from './components/helper'
import { round } from 'lodash'
import moment from 'moment'
import InvalidInterval from './components/InvalidInterval'
import ShopSelection from './components/ShopSelection'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { SessionContext } from '../../../../../context/SessionContext'
import { useFirestore } from 'react-redux-firebase'
import { functions } from '../../../../../firebase'
import GSCSkeleton from './Skeleton'

const Top5SearchRequests = ({ widget, ...props }: IWidgetProps) => {
  const [firstIntervallData, setFirstIntervallData] = useState<Array<GoogleSearchConsoleResponse>>([])
  const [secondIntervalData, setSecondIntervalData] = useState<Array<GoogleSearchConsoleResponse>>([])
  const [access_token, setAccessToken] = useState(undefined)
  const [fistIntervalLoading, setFirstIntervallLoading] = useState(true)
  const [secondIntervalLoading, setSecondIntervallLoading] = useState(true)
  const { company } = useContext(SessionContext)
  const db = useFirestore()
  const accessTokenQuery = db
    .collection('company')
    .doc(company?.id)
    .collection('extension_config')
    .doc('google_search_console')
  const handleRefreshGoogleToken = functions().httpsCallable('widgets-googleSearchConsole-handleRefreshGoogleToken')
  const [tokenRefreshed, setTokenRefreshed] = useState(false)
  async function updateRefresGoogleToken() {
    const newToken = await handleRefreshGoogleToken({ companyId: company?.id })
    setAccessToken(newToken.data.access_token)
    setTokenRefreshed(true)
  }
  const [selectedShop, setSelectedShop] = useState<string | null>(widget?.settings?.googleSearchShop || null)
  const [error, setError] = useState<{ text: string; link: string | null }>({ text: '', link: null })
  const [hasIntervalError, setHasIntervalError] = useState(false)
  const [hasAPIError, setHasAPIError] = useState(false)
  const [shopError, setShopError] = useState(false)
  const intervals = useIntervals()
  const rowLimit = 6
  const { t } = useTranslation()
  const updateWidget = useUpdateWidgetContext()
  const selectGoogleSearchShop = (shop: string) => {
    setSelectedShop(shop)
    const newWidget = {
      ...widget,
      settings: {
        ...widget.settings,
        googleSearchShop: shop,
      },
    }
    updateWidget(newWidget)
  }
  const onIntervalChange = useCallback(
    (newInterval) => {
      const newWidget = {
        ...widget,
        settings: {
          ...widget?.settings,
          interval: newInterval,
        },
      }
      updateWidget(newWidget)
    },
    [widget, updateWidget]
  )
  filterIntervals(intervals)

  function updateShop(newShop: string) {
    setSelectedShop(newShop)
    selectGoogleSearchShop(newShop)
  }
  //fetch Token
  useEffect(() => {
    accessTokenQuery.get().then((querySnapshot) => {
      const oauth = querySnapshot.data()?.oauth
      const lastUpdated = new Date(oauth?.token.updated.seconds * 1000)
      const updatedWithinLastHour = moment(lastUpdated).isAfter(moment().subtract(1, 'hours'))

      if (updatedWithinLastHour && oauth?.token.access_token) {
        setAccessToken(oauth?.token.access_token)
      } else {
        updateRefresGoogleToken()
      }
    })
  }, [tokenRefreshed])
  useEffect(() => {
    if (access_token === undefined) return
    if (selectedShop === null) {
      setShopError(true)
      return
    }
    const firstApiInterval: { startDate: string; endDate: string } = getApiInterval(
      widget.settings.interval,
      new Date().toISOString().split('T')[0],
      false
    )
    const startPoint = new Date(firstApiInterval.startDate)
    const secondApiInterval: { startDate: string; endDate: string } = getApiInterval(
      widget.settings.interval,
      startPoint.toISOString().split('T')[0],
      true
    )

    //Fetching first Intervall of the top 5 search terms
    fetch(getSearchConsoleAnalyticsURI(selectedShop), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${access_token}`,
        'User-Agent':
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_2) AppleWebKit/537.17 (KHTML, like Gecko) Chrome/24.0.1309.0 Safari/537.17',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        startDate: firstApiInterval.startDate,
        endDate: firstApiInterval.endDate,
        dimensions: ['query'],
        type: 'web',
        rowLimit: rowLimit,
      }),
    })
      .then((response) => {
        if (response.ok) return response.json()
        throw Error(`${response.status}`)
      })
      .then((data) => {
        if (data.rows === undefined) throw Error('400')
        setFirstIntervallData(data.rows)
        setFirstIntervallLoading(false)
        setHasIntervalError(false)
      })
      .catch((error) => {
        switch (error.message) {
          case '403':
            setShopError(true)
            break
          case '400':
            setHasIntervalError(true)
            break
          default:
            setHasAPIError(true)
            break
        }
        setError({ text: t('shop.widget.googleSearchConsole.error.text'), link: '../extensions/google_search_console' })
      })
    //Fetching second Intervall of the top 5 search terms
    fetch(getSearchConsoleAnalyticsURI(selectedShop), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${access_token}`,
        'User-Agent':
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_2) AppleWebKit/537.17 (KHTML, like Gecko) Chrome/24.0.1309.0 Safari/537.17',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        startDate: secondApiInterval.startDate,
        endDate: secondApiInterval.endDate,
        dimensions: ['query'],
        type: 'web',
        rowLimit: rowLimit,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw Error(`${response.status}`)
        }
      })
      .then((data) => {
        setSecondIntervalData(data.rows)
        setSecondIntervallLoading(false)
      })
      .catch((error) => {
        switch (error.message) {
          case '403':
            setShopError(true)
            break
          case '400':
            setHasIntervalError(true)
            break
          default:
            setHasAPIError(true)
            break
        }
        console.error(error.message)
        setError({ text: t('shop.widget.googleSearchConsole.error.text'), link: '../extensions/google_search_console' })
      })
  }, [widget.settings.interval, selectedShop, access_token, tokenRefreshed])

  return (
    <Widget
      title={t(`shop.widget.googleSearchConsole.title.Top5SearchRequests`)}
      subtitle={widget.settings?.googleSearchShop}
      widget={widget}
      loading={fistIntervalLoading && secondIntervalLoading && !hasIntervalError && !shopError}
      loadingSkeleton={<GSCSkeleton />}
      isError={hasAPIError}
      error={error}
      customOptions={
        <IntervalSelection
          intervals={intervals}
          key="interval-selection"
          settings={widget && widget.settings}
          onIntervalChange={onIntervalChange}
        />
      }
      {...props}
    >
      {([size]) => (
        <View style={{ width: size.width, height: size.height }}>
          {!hasIntervalError && !shopError && (
            <>
              {/* <Top5Rows description title={t(`shop.widget.googleSearchConsole.title.Top5SearchRequests`)} value={0} icon={'eye'} secondIcon={'chart-line-up'} /> */}
              {firstIntervallData &&
                firstIntervallData?.map((value, index) => (
                  <Top5Rows
                    key={index}
                    title={value.keys[0]}
                    value={round(value.clicks)}
                    secondValue={secondIntervalData[index]?.clicks}
                    percentage={round(
                      (value.clicks / Math.max(...(firstIntervallData?.map((o) => o.clicks) ?? 0))) * 100
                    )}
                  />
                ))}
            </>
          )}
          {hasIntervalError && <InvalidInterval />}
          {shopError ? (
            <ShopSelection
              access_token={access_token}
              setSelectedShop={updateShop}
              setShopError={setShopError}
              setError={setError}
              selectedShop={selectedShop}
            />
          ) : null}
        </View>
      )}
    </Widget>
  )
}

export default Top5SearchRequests
