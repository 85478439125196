import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import useIntervals from '../../../../../hooks/widget/useIntervals'
import { IWidgetProps } from '../../../../../types/props/Widget'
import IntervalSelection from '../../../../components/IntervalSelection'
import Widget from '../../../../components/WidgetNoAPI'
import { useUpdateWidgetContext } from '../../../../../types/Widget'
import moment from 'moment'
import {
  filterIntervals,
  getApiInterval,
  getSearchConsoleAnalyticsURI,
  GoogleSearchConsoleResponse,
} from './components/helper'
import InvalidInterval from './components/InvalidInterval'
import Status from './components/Status'
import ShopSelection from './components/ShopSelection'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { SessionContext } from '../../../../../context/SessionContext'
import { useFirestore } from 'react-redux-firebase'
import { functions } from '../../../../../firebase'
import i18n from '../../../../../locales/i18n'
import GSCSkeleton from './Skeleton'

const fetchSearchConsoleOverview = (
  endpoint: string,
  access_token: string | undefined,
  body: BodyInit | null | undefined,
  setIntervallData: React.Dispatch<React.SetStateAction<GoogleSearchConsoleResponse | undefined>>,
  setHasIntervalError: React.Dispatch<React.SetStateAction<boolean>>,
  setShopError: React.Dispatch<React.SetStateAction<boolean>>,
  setHasAPIError: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<
    React.SetStateAction<{
      text: string
      link: string | null
    }>
  >
) => {
  fetch(endpoint, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'User-Agent':
        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_2) AppleWebKit/537.17 (KHTML, like Gecko) Chrome/24.0.1309.0 Safari/537.17',
      'Content-Type': 'application/json',
    },
    body: body,
  })
    .then((response) => {
      if (response.ok) return response.json()
      throw Error(`${response.status}`)
    })
    .then((data) => {
      if (data.rows === undefined) throw Error('400')
      setIntervallData(data?.rows[0])
      setHasIntervalError(false)
    })
    .catch((error) => {
      switch (error.message) {
        case '403':
          setShopError(true)
          break
        case '400':
          setHasIntervalError(true)
          break
        default:
          setHasAPIError(true)
          break
      }
      console.error(error.message)
      setError({
        text: i18n.t('shop.widget.googleSearchConsole.error.text'),
        link: '../extensions/google_search_console',
      })
    })
}
type Intervall = { startDate: string; endDate: string }

const Overview = ({ widget, ...props }: IWidgetProps) => {
  const [firstIntervallData, setFirstIntervallData] = useState<GoogleSearchConsoleResponse | undefined>(undefined)
  const [secondIntervalData, setSecondIntervalData] = useState<GoogleSearchConsoleResponse | undefined>(undefined)
  const [access_token, setAccessToken] = useState(undefined)
  const [urlInspectData, setUrlInspectData] = useState<Record<string, any>>()
  const { company } = useContext(SessionContext)
  const db = useFirestore()
  const accessTokenQuery = db
    .collection('company')
    .doc(company?.id)
    .collection('extension_config')
    .doc('google_search_console')

  const lastCrawlTime = urlInspectData?.indexStatusResult?.lastCrawlTime
  const timestamp = moment(lastCrawlTime).fromNow()
  const { t } = useTranslation()

  const [selectedShop, setSelectedShop] = useState<string | null>(widget?.settings?.googleSearchShop || null)

  const [hasIntervalError, setHasIntervalError] = useState(false)
  const [hasAPIError, setHasAPIError] = useState(false)
  const [shopError, setShopError] = useState(false)

  const [error, setError] = useState<{ text: string; link: string | null }>({ text: '', link: null })
  const intervals = useIntervals()
  const handleRefreshGoogleToken = functions().httpsCallable('widgets-googleSearchConsole-handleRefreshGoogleToken')
  const [tokenRefreshed, setTokenRefreshed] = useState(false)

  async function updateRefresGoogleToken() {
    const newToken = await handleRefreshGoogleToken({ companyId: company?.id })
    setAccessToken(newToken.data.access_token)
    setTokenRefreshed(true)
  }
  const updateWidget = useUpdateWidgetContext()
  const selectGoogleSearchShop = (shop: string) => {
    setSelectedShop(shop)
    const newWidget = {
      ...widget,
      settings: {
        ...widget.settings,
        googleSearchShop: shop,
      },
    }
    updateWidget(newWidget)
  }
  const onIntervalChange = useCallback(
    (newInterval) => {
      const newWidget = {
        ...widget,
        settings: {
          ...widget.settings,
          interval: newInterval,
        },
      }
      updateWidget(newWidget)
    },
    [widget, updateWidget]
  )
  function updateShop(newShop: string) {
    setSelectedShop(newShop)
    selectGoogleSearchShop(newShop)
  }
  filterIntervals(intervals)

  //fetch Accesstoken
  useEffect(() => {
    accessTokenQuery.get().then((querySnapshot) => {
      const oauth = querySnapshot.data()?.oauth
      const lastUpdated = new Date(oauth?.token.updated.seconds * 1000)
      const updatedWithinLastHour = moment(lastUpdated).isAfter(moment().subtract(1, 'hours'))

      if (updatedWithinLastHour && oauth?.token.access_token) {
        setAccessToken(oauth?.token.access_token)
      } else {
        updateRefresGoogleToken()
      }
    })
  }, [])
  //fetch Displayed Data
  useEffect(() => {
    if (access_token === undefined) return
    if (selectedShop === null) {
      setShopError(true)
      return
    }

    const firstApiInterval: Intervall = getApiInterval(
      widget.settings.interval,
      new Date().toISOString().split('T')[0],
      false
    )
    if (!intervals.find((x) => x.value === widget.settings.interval)?.active) {
      setHasIntervalError(true)
      return
    }
    const startPoint = new Date(firstApiInterval.startDate)

    const secondApiInterval: Intervall = getApiInterval(
      widget.settings.interval,
      startPoint.toISOString().split('T')[0],
      true
    )
    //Fetching first API intervall
    fetchSearchConsoleOverview(
      getSearchConsoleAnalyticsURI(selectedShop),
      access_token,
      JSON.stringify({
        startDate: firstApiInterval.startDate,
        endDate: firstApiInterval.endDate,
        dimensions: [],
        type: 'web',
      }),
      setFirstIntervallData,
      setHasIntervalError,
      setShopError,
      setHasAPIError,
      setError
    )

    //Fetching second API intervall
    fetchSearchConsoleOverview(
      getSearchConsoleAnalyticsURI(selectedShop),
      access_token,
      JSON.stringify({
        startDate: secondApiInterval.startDate,
        endDate: secondApiInterval.endDate,
        type: 'web',
      }),
      setSecondIntervalData,
      setHasIntervalError,
      setShopError,
      setHasAPIError,
      setError
    )
  }, [widget.settings.interval, selectedShop, access_token, tokenRefreshed])
  useEffect(() => {
    if (access_token === undefined) return
    if (selectedShop === null) {
      setShopError(true)
      return
    }

    //Fetching URL inspect data
    fetch('https://searchconsole.googleapis.com/v1/urlInspection/index:inspect', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${access_token}`,
        'User-Agent':
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_2) AppleWebKit/537.17 (KHTML, like Gecko) Chrome/24.0.1309.0 Safari/537.17',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        inspectionUrl: `https://${selectedShop}/`,
        siteUrl: `https://${selectedShop}/`,
        languageCode: 'en-US',
      }),
    })
      .then((response) => {
        if (response.ok) return response.json()
        throw Error(`${response.status}`)
      })
      .then((data) => {
        setUrlInspectData(data.inspectionResult)
      })
      .catch((error) => {
        setError({ text: t('shop.widget.googleSearchConsole.error.text'), link: '../extensions/google_search_console' })

        switch (error.message) {
          case '403':
            setShopError(true)
            break
          case '400':
            setHasIntervalError(true)
            break
          default:
            setHasAPIError(true)
            break
        }
      })
  }, [selectedShop, access_token, tokenRefreshed])
  return (
    <Widget
      title={t(`shop.widget.googleSearchConsole.title.Overview`)}
      subtitle={widget.settings?.googleSearchShop}
      widget={widget}
      loading={!hasIntervalError && !shopError && firstIntervallData === undefined && secondIntervalData === undefined}
      loadingSkeleton={<GSCSkeleton />}
      isError={hasAPIError}
      error={error}
      footerContent={lastCrawlTime !== undefined && `${t('dashboard.widgets.state.last_pull')} ${timestamp}`}
      customOptions={
        <IntervalSelection
          intervals={intervals}
          key="interval-selection"
          settings={widget && widget.settings}
          onIntervalChange={onIntervalChange}
        />
      }
      {...props}
    >
      {([size]) => (
        <View style={{ width: size.width, height: size.height }}>
          {shopError ? (
            <ShopSelection
              access_token={access_token}
              setSelectedShop={updateShop}
              setShopError={setShopError}
              setError={setError}
              selectedShop={selectedShop}
            />
          ) : null}
          {!hasIntervalError && !shopError && firstIntervallData !== undefined && secondIntervalData !== undefined && (
            <Status
              firstIntervallData={firstIntervallData}
              secondIntervalData={secondIntervalData}
              urlInspectData={urlInspectData}
            />
          )}
          {hasIntervalError && <InvalidInterval />}
        </View>
      )}
    </Widget>
  )
}

export default Overview
