import React from 'react'
import { FlatList, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import useStyles from './styles'
import shopWidgets from '../../../../../../widgets'
import { TFunction } from 'i18next'
import md5 from 'md5'
import { SemVer } from 'semver'
import BoldText from '../../../../../../components/CustomText/BoldText'
import { useTheme } from '../../../../../../utils/types/theme'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { Addons } from '../../../../../../types/plans'
import { mappedVersion } from '../../../../../../utils/versioning'
import { Image } from 'expo-image'

export const typeDefinitions = (t: TFunction, version: number) => [
  {
    group: t('widget.type.new'),
    widgets: [`shop.sale_forecast_v${version}`, `shop.visitor_forecast_v${version}`],
  },
  {
    group: t('widget.type.live'),
    widgets: [`shop.status_v${version}`, `shop.live_tracker_v${version}`],
  },
  //Only show demo group for testing reasons, should not be visible in production -> comment
  // {
  //   group: t('Demo'),
  //   widgets: [
  //     `misc.demo_mollie_v${version}`,
  //     `misc.demo_amazon_v${version}`,
  //     `shop.demo_forecast_v${version}`,
  //     `shop.demo_gsc_v${version}`,
  //     `shop.demo_pagespeed_v${version}`,
  //   ],
  // },
  {
    group: t('widget.type.orders'),
    widgets: [
      `shop.orders_v${version}`,
      `shop.runners_v${version}`,
      `misc.mollie_payment_v${version}`,
      `misc.wawi_orders_v${version}`,
    ],
  },
  {
    group: t('widget.type.analytics'),
    widgets: [
      `shop.sales_v${version}`,
      `shop.conversion_v${version}`,
      `shop.order_value_v${version}`,
      `shop.gender_v${version}`,
      `shop.pagespeed_performance_v${version}`,
      `shop.pagespeed_webvitals_v${version}`,
      `shop.pagespeed_histperformance_v${version}`,
      `shop.pagespeed_performance_mobile_v${version}`,
      `shop.pagespeed_webvitals_mobile_v${version}`,
      `shop.pagespeed_histperformance_mobile_v${version}`,
      `shop.sale_forecast_v${version}`,
      `shop.visitor_forecast_v${version}`,
      `shop.gsc_top5_search_v${version}`,
      `shop.gsc_top5_pages_v${version}`,
      `shop.gsc_devices_v${version}`,
      `shop.gsc_overview_v${version}`,
      `misc.amazon_sales_v${version}`,
      `misc.amazon_buyer_type_v${version}`,
      `misc.relevanz_dashboard_v${version}`,
      `misc.relevanz_history_v${version}`,
    ],
  },
  {
    group: t('widget.type.misc'),
    widgets: [`misc.advertisement_v${version}`],
  },
  {
    group: t('Basic'),
    widgets: [
      `shop.basic_sales_v${version}`,
      `shop.basic_conversion_v${version}`,
      `shop.basic_order_value_v${version}`,
      `shop.basic_orders_v${version}`,
      `shop.basic_live_tracker_v${version}`,
      `shop.basic_pagespeed_performance_v${version}`,
      `shop.basic_pagespeed_performance_mobile_v${version}`,
      `shop.basic_pagespeed_histperformance_v${version}`,
      `shop.basic_pagespeed_histperformance_mobile_v${version}`,
    ],
  },
]

const WidgetType = ({
  selectedType,
  selectedShopSystem,
  selectedPluginVersion,
  selectType,
  setTitle,
  setTypeIcon,
  auths,
}: {
  selectedType: string | null | undefined
  selectedShopSystem: string | null | undefined
  selectedPluginVersion: string | null | undefined
  selectType: React.Dispatch<React.SetStateAction<string | undefined | null>>
  setTitle: (type: string | null) => void
  setTypeIcon: (type: string | null) => void
  auths?: Addons | undefined
}) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const version = new SemVer(selectedPluginVersion || '1.0.0')
  const types = typeDefinitions(t, mappedVersion(version))
  const theme = useTheme()

  return (
    <View style={styles.wrapper}>
      <>
        {types.map((type) => {
          return (
            type.widgets.filter((key) => {
              console.log(`Widget ${key}`)
              const { shopSystem, minVersion, authorizations } = get(shopWidgets, key)
              const allowedSystems: Array<string> = shopSystem
              const permissions: Array<string> = authorizations
              const hasRequiredPluginVersion = new SemVer(selectedPluginVersion || '1.0.0').compare(minVersion) >= 0
              const hasPermisson =
                !permissions ||
                permissions?.length === 0 ||
                Object.keys(auths || {}).filter((k) => {
                  return permissions.includes(k) && auths && auths[k] === true
                }).length >= 1
              console.log(`${allowedSystems} &&
                              ${selectedShopSystem} &&
                              ${hasRequiredPluginVersion} &&
                              ${hasPermisson} &&
                             ${selectedShopSystem && allowedSystems.includes(selectedShopSystem)}`)
              return (
                allowedSystems &&
                selectedShopSystem &&
                hasRequiredPluginVersion &&
                hasPermisson &&
                allowedSystems.includes(selectedShopSystem)
              )
            }).length > 0 && (
              <View style={{ marginVertical: 10 }}>
                <BoldText style={{ fontSize: 20, marginVertical: 5, marginBottom: 10 }}>{type.group}</BoldText>
                <FlatList
                  horizontal
                  style={styles.list}
                  data={type.widgets}
                  keyExtractor={(widget) => md5(widget)}
                  initialNumToRender={50}
                  // onEndReached={onEndReached}
                  initialScrollIndex={0}
                  renderItem={({ item }) => {
                    const { icon, title, shopSystem, minVersion, authorizations } = get(shopWidgets, item)
                    const allowedSystems: Array<string> = shopSystem
                    const permissions: Array<string> = authorizations
                    const hasPermisson =
                      !permissions ||
                      permissions.length === 0 ||
                      Object.keys(auths || {}).filter((k) => {
                        return permissions.includes(k) && auths && auths[k] === true
                      }).length >= 1
                    const hasRequiredPluginVersion =
                      new SemVer(selectedPluginVersion || '1.0.0').compare(minVersion) >= 0
                    if (
                      (allowedSystems && selectedShopSystem && !allowedSystems.includes(selectedShopSystem)) ||
                      !hasRequiredPluginVersion ||
                      !hasPermisson
                    )
                      return null
                    return (
                      <TouchableOpacity
                        style={styles.widget}
                        onPress={() => {
                          if (selectedType === item) {
                            selectType(null)
                            setTitle(null)
                            setTypeIcon(null)
                          } else {
                            selectType(item)
                            setTitle(title)
                            setTypeIcon(icon[theme])
                          }
                        }}
                      >
                        <Image source={icon[theme]} style={styles.typeIcon} contentFit={'contain'} />
                        <ColoredText style={{ width: '100%', textAlign: 'center' }}>{title}</ColoredText>
                      </TouchableOpacity>
                    )
                  }}
                />
              </View>
            )
          )
        })}
      </>
    </View>
  )
}

export default WidgetType
