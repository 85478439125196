import { IconDefinition, IconName } from '@fortawesome/pro-solid-svg-icons'

const definition = {
  prefix: 'fad',
  iconName: 'dashbar' as IconName,
  icon: [
    100,
    110,
    [],
    'ff02',
    [
      'M16.6666667,0 L83.3333333,0 C92.5380792,-1.76207951e-15 100,7.77610698 100,17.3684211 L100,92.6315789 C100,102.223893 92.5380792,110 83.3333333,110 L16.6666667,110 C7.46192084,110 -5.97817111e-15,102.223893 -7.10542736e-15,92.6315789 L-5.26327952e-16,17.3684211 C-1.6535842e-15,7.77610698 7.46192084,1.76207951e-15 16.6666667,0 Z',
      'M16.2037037,82.0175439 L83.7962963,82.0175439 C90.1884809,82.0175439 95.3703704,87.4176181 95.3703704,94.0789474 C95.3703704,100.740277 90.1884809,106.140351 83.7962963,106.140351 L16.2037037,106.140351 C9.8115191,106.140351 4.62962963,100.740277 4.62962963,94.0789474 C4.62962963,87.4176181 9.8115191,82.0175439 16.2037037,82.0175439 Z',
    ],
  ],
} as IconDefinition

export default definition
