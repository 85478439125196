import { Platform, StyleSheet } from 'react-native'
import { useSecondaryBackground, useTextColor } from '../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    scrollView: {
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: 'transparent',
    },
    container: {
      flex: 1,
      width: '100%',
      alignItems: 'stretch',
      justifyContent: Platform.select({ native: 'flex-end' }),
    },
    button: {
      marginVertical: 2,
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      height: 50,
      borderRadius: 16,
      backgroundColor: useSecondaryBackground(),
    },
    textWrapper: {
      width: '75%',
      flex: 1,
      paddingLeft: 20,
      justifyContent: 'space-between',
    },
    buttonText: {
      paddingTop: 4,
      fontSize: 16,
      lineHeight: 16,
      flex: 2,
      textAlignVertical: 'center',
    },
    subtitle: {
      paddingBottom: 2,
      fontSize: 13,
      lineHeight: 13,
      color: useTextColor(),
      flex: 1,
    },
  })
}

export default useStyles
