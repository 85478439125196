import * as yup from 'yup'

const MIN_PASSWORD_LENGTH = 8

const ChangePasswordValidator = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword1: yup.string().min(MIN_PASSWORD_LENGTH).required(),
  newPassword2: yup
    .string()
    .oneOf([yup.ref('newPassword1')])
    .required(),
})

export default ChangePasswordValidator
