import React, { useContext } from 'react'
import { View } from 'react-native'
import { getErrorInfosByMessage } from './helper'
import ColoredText from '../../../../../../../components/CustomText/ColoredText'
import { useInvertedTextColor } from '../../../../../../../hooks/useColors'
import { FontAwesomeIcon } from '../../../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import { SessionContext } from '../../../../../../../context/SessionContext'

const CheckoutError = ({ message }: { message: string | null }) => {
  const { user } = useContext(SessionContext)
  const { headline, text, notes } = getErrorInfosByMessage(message, user?.firstName)
  const invertedTextColor = useInvertedTextColor()
  return (
    <View>
      <ColoredText style={{ color: invertedTextColor, fontSize: 16, marginBottom: 8 }}>{`${headline}`}</ColoredText>
      {text !== null && (
        <ColoredText style={{ color: invertedTextColor, fontSize: 16, marginBottom: 5 }}>{`${text}`}</ColoredText>
      )}
      {notes !== null &&
        notes.map((note) => {
          return (
            <View
              key={note}
              style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignContent: 'flex-start',
                alignItems: 'flex-start',
                marginVertical: 3,
              }}
            >
              <FontAwesomeIcon size="xs" color={invertedTextColor} icon={'circle-small'} />
              <ColoredText style={{ color: invertedTextColor, marginLeft: 7 }}>{`${note}`}</ColoredText>
            </View>
          )
        })}
    </View>
  )
}

export default CheckoutError
