import { Platform, useWindowDimensions } from 'react-native'

/**
 * @returns device type used
 */
const useDeviceType = (): 'web' | 'mobile' | 'tablet' => {
  const windowWidth = useWindowDimensions().width

  if (Platform.OS === 'web') return 'web'
  if (Platform.OS === 'ios' || Platform.OS === 'android') if (windowWidth < 700) return 'mobile'
  return 'tablet'
}

export default useDeviceType
