export type AuthRequestStatus = 'sent' | 'successfull' | 'error' | 'notSent' | 'rejected'
import { useTranslation } from '../../../../../../../hooks/useTranslation'
import React from 'react'
import WawiErrorText from '../WawiErrorText'
import ColoredText from '../../../../../../../components/CustomText/ColoredText'
import { ApiError } from '../../../../../../../../functions/src/widgets/wawi/fetchRegStatus'

type ConMsgPropsT = {
  authRequestStatus: AuthRequestStatus
  wawiFetchStatusMsg: string | null
  wawiAuthError: ApiError | null
  //native or web-style
  styles: any
}
/**
 * used to get the right message for the user depending on the status of the first request
 * @param authRequestStatus the status of the authentication request
 * @param wawiFetchStatusMsg a message about the status of the confirmation request
 * @param wawiAuthError a possible error from the first request
 * @param styles either web or native styles
 * @returns a React Node that contains the message
 */
export const WawiConnectionMsg = ({
  authRequestStatus,
  wawiFetchStatusMsg,
  wawiAuthError,
  styles,
}: ConMsgPropsT): React.JSX.Element => {
  const { t } = useTranslation()
  switch (authRequestStatus) {
    case 'sent':
      return (
        <>
          <ColoredText style={styles.successMessage}>{t('settings.web.connections.wawi.requestSent')}</ColoredText>
          <ColoredText style={{ marginLeft: 15, marginBottom: 20 }}>
            {t('settings.web.connections.wawi.pleaseConfirm')}
          </ColoredText>
          {wawiFetchStatusMsg !== null ? (
            <ColoredText style={styles.errorMessage}>{wawiFetchStatusMsg}</ColoredText>
          ) : null}
        </>
      )
    case 'successfull':
      return (
        <ColoredText style={styles.successMessage}>
          {t('settings.web.connections.wawi.connectionSuccessfull')}
        </ColoredText>
      )
    case 'notSent':
      return (
        <>
          {wawiFetchStatusMsg !== null ? (
            <ColoredText style={styles.errorMessage}>{wawiFetchStatusMsg}</ColoredText>
          ) : null}
        </>
      )
    case 'rejected':
      return (
        <>
          {wawiFetchStatusMsg !== null ? (
            <ColoredText style={styles.errorMessage}>{wawiFetchStatusMsg}</ColoredText>
          ) : null}
        </>
      )
    case 'error':
      return WawiErrorText(wawiAuthError, styles)
    default:
      return <ColoredText style={styles.errorMessage}>{t('settings.web.connections.wawi.error')}</ColoredText>
  }
}
export default WawiConnectionMsg
