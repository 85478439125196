import { Divider, Menu, Pressable, View, Text } from 'native-base'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import icons from '../../../constants/icons'
import Avatar from '../../../components/Avatar'
import { SessionContext, SessionContextValueType } from '../../../context/SessionContext'
import useStyles from './styles'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import ColoredText from '../../../components/CustomText/ColoredText'
import { useNavigate } from '../../../components/Router/Router'
import { FontAwesomeIcon } from '../../../components/FontAwesome/ColoredFontAwesomeIcon'

const ProfileMenu = () => {
  const { user, signOut } = useContext<SessionContextValueType>(SessionContext)
  const styles = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Menu
      closeOnSelect={false}
      style={styles.menu}
      trigger={(triggerProps) => {
        return (
          <Pressable {...triggerProps}>
            <View style={[styles.wrapper]}>
              <Avatar style={styles.avatar} photoURL={user?.profileImage || ''} />
              <ColoredText style={styles.profileText}>{`${user?.firstName || 'Account'} ${
                user?.lastName || ''
              }`}</ColoredText>
            </View>
          </Pressable>
        )
      }}
      placement="bottom right"
    >
      <Menu.Item onPress={() => navigate(`/settings/accountOverview`)}>
        <View style={styles.itemWrapper}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'user']} />
          <Text style={styles.itemText}>
            <>{t('nav.account_overview')}</>
          </Text>
        </View>
      </Menu.Item>
      <Divider />
      <Menu.Item onPress={() => navigate(`/settings/dashboard`)}>
        <View style={styles.itemWrapper}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'chart-pie' as IconName]} />
          <Text style={styles.itemText}>
            <>{t('nav.edit_dashboards')}</>
          </Text>
        </View>
      </Menu.Item>
      <Menu.Item onPress={() => navigate(`/settings/subscription`)}>
        <View style={styles.itemWrapper}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'dashbar' as IconName]} />
          <Text style={styles.itemText}>
            <>{t('nav.subscription')}</>
          </Text>
        </View>
      </Menu.Item>
      {/* <Menu.Item onPress={() => history.push(`/shop`)}>
        <View style={styles.itemWrapper}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'store' as IconName]} />
          <Text style={styles.itemText}>{t('nav.shop')}</Text>
        </View>
      </Menu.Item> */}
      <Menu.Item onPress={() => navigate(`/settings`)}>
        <View style={styles.itemWrapper}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'cogs']} />
          <Text style={styles.itemText}>
            <>{t('nav.settings')}</>
          </Text>
        </View>
      </Menu.Item>

      <Divider borderColor="gray.300" />

      <Menu.Item onPress={signOut}>
        <View style={styles.itemWrapper}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'sign-out']} />
          <Text style={styles.itemText}>
            <>{t('layout.logout')}</>
          </Text>
        </View>
      </Menu.Item>
    </Menu>
  )
}

export default ProfileMenu
