import React from 'react'
import { Image, TouchableOpacity, View } from 'react-native'
import { IconName } from '@fortawesome/pro-solid-svg-icons'
import useStyles from './styles'
import FavoriteButton from '../../../../layout/Header/FavoriteButton'
import icons from '../../../../constants/icons'
import { useNavigate } from '../../../../components/Router/Router'
import { useTranslation } from '../../../../hooks/useTranslation'
import { FontAwesomeIcon } from '../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../../../../components/CustomText/ColoredText'
import SemiBoldText from '../../../../components/CustomText/SemiBoldText'
import { IShop } from '../../../../types/dashboard'

interface ShopItem extends IShop {
  path: string
  fontAwesomeIcon?: IconName
}

type Props = {
  shops: Array<ShopItem>
}

const ShopMenuItems = ({ shops }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <>
      {shops.map(({ fontAwesomeIcon, icon, domain, path }) => (
        <View key={path} style={styles.wrapper}>
          <View style={styles.shopHeaderWrapper}>
            {fontAwesomeIcon ? (
              <View style={styles.iconBackground}>
                <FontAwesomeIcon style={styles.icon} icon={[icons.faIconStyle, fontAwesomeIcon]} />
              </View>
            ) : (
              <View style={styles.iconBackground}>
                <Image
                  style={styles.icon}
                  source={{
                    uri: icon,
                  }}
                  accessibilityLabel={domain}
                />
              </View>
            )}
            <SemiBoldText numberOfLines={1} style={[styles.name]}>
              {domain}
            </SemiBoldText>
            <View style={styles.iconWrapper}></View>
          </View>
          <View style={styles.shopItemsWrapper}>
            {[
              {
                path: `/shop/${domain}/live-tracker`,
                title: t('nav.live-tracker'),
              },
              {
                path: `/shop/${domain}/analytics`,
                title: t('nav.analytics'),
              },
              {
                path: `/shop/${domain}/orders`,
                title: t('nav.orders'),
              },
            ].map(({ path, title }) => (
              <TouchableOpacity onPress={() => navigate(path)} key={path}>
                <View style={styles.shopSubHeaderWrapper}>
                  <ColoredText style={styles.shopItems}>{title}</ColoredText>
                  <View>
                    <FavoriteButton url={path} title={title} subtitle={`${domain}`} />
                  </View>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      ))}
    </>
  )
}

export default ShopMenuItems
