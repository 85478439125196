import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Themes } from '../../../types/dashbar/theme'
import { BaseActionTypes, SET_LANGUAGE, SET_THEME, SetThemeAction } from './types'
import { AppDispatch } from '../../../store/store'
import { Languages } from '../../../types/language'

// export const useSetTheme = () => {
//   const dispatch = useDispatch()
//   return useCallback(
//     (theme: Themes) => {
//       dispatch(theme)
//     },
//     [dispatch]
//   )
// }
export const useSetTheme = () => {
  const useAppDispatch: () => AppDispatch = useDispatch
  const dispatch = useAppDispatch()
  return useCallback(
    (theme: Themes) => {
      dispatch(setTheme(theme))
    },
    [dispatch]
  )
}

export const setTheme = (theme: Themes): SetThemeAction => {
  return {
    type: SET_THEME,
    payload: theme,
  }
}

export const setLanguage = (lang: Languages): BaseActionTypes => {
  return {
    type: SET_LANGUAGE,
    payload: lang,
  }
}
