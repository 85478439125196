import React, { ReactNode } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Controller, FieldError, useForm } from 'react-hook-form'
import Button from '../../Button'
import TextInputWithIcon from '../../TextInputWithIcon'
import useStyles from '../login-styles'
import { yupResolver } from '@hookform/resolvers/yup'
import EmailValidator from '../../../validators/EmailValidator'
import ColoredText from '../../CustomText/ColoredText'

interface IProps {
  onSubmit: (params: { email: string; password: string }) => void
  loading: boolean
  submitButtonText?: ReactNode
  style?: StyleProp<ViewStyle>
}

const EmailForm = ({ onSubmit, loading, submitButtonText, style }: IProps) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<{
    email: string
  }>({
    resolver: yupResolver(EmailValidator),
  })
  const { t } = useTranslation()

  const styles = useStyles()
  return (
    <View style={style}>
      <Controller
        control={control}
        name={'email'}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInputWithIcon
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            icon={['fad', 'envelope']}
            style={styles.textInput}
            placeholder={t('placeholder.baseLogin.email')}
            editable={!loading}
          />
        )}
      />
      <View style={styles.errorWrapper}>
        {errors.email ? <ColoredText>{(errors.email as FieldError).message}</ColoredText> : null}
      </View>
      <Button style={styles.button} textStyle={styles.buttonText} onPress={handleSubmit(onSubmit)} disabled={loading}>
        {submitButtonText || t('button.baseSignup.signup')}
      </Button>
    </View>
  )
}

export default EmailForm
