import React from 'react'
import ColoredText from '../../../../../../../components/CustomText/ColoredText'
import { TouchableOpacity, View } from 'react-native'
import useStyles from './styles'
import { useTranslation } from '../../../../../../../hooks/useTranslation'
import Paypal from '../../../../../../../assets/imgs/subscription/checkout/Paypal'
import Creditcard from '../../../../../../../assets/imgs/subscription/checkout/Creditcard'
import Directdebit from '../../../../../../../assets/imgs/subscription/checkout/Directdebit'
import { useInvertedTextColor } from '../../../../../../../hooks/useColors'

const PaymentProviderDashbar = ({
  type,
  onPress,
}: {
  type: 'paypal' | 'creditcard' | 'directdebit'
  onPress: () => void
}) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const invertedTextColor = useInvertedTextColor()
  return (
    <TouchableOpacity onPress={onPress} style={styles.paymentMethod}>
      {type === 'paypal' && <Paypal />}
      {type === 'creditcard' && (
        <View style={styles.paymentMethodTitleWrapper}>
          <Creditcard color={invertedTextColor} />
          <ColoredText style={styles.paymentMethodTitle}>{`${t(
            'checkout.methods.dashbar.title.creditcard'
          )}`}</ColoredText>
        </View>
      )}
      {type === 'directdebit' && (
        <View style={styles.paymentMethodTitleWrapper}>
          <Directdebit color={invertedTextColor} />
          <ColoredText style={styles.paymentMethodTitle}>{`${t(
            'checkout.methods.dashbar.title.directdebit'
          )}`}</ColoredText>
        </View>
      )}
      {/* <View style={styles.paymentMethodTitleWrapper}>
        <ColoredText style={styles.paymentMethodTitle}>{`${t('checkout.methods.dashbar.title')}`}</ColoredText>
      </View> */}
    </TouchableOpacity>
  )
}

export default PaymentProviderDashbar
