import { useSelector } from 'react-redux'
import { ReduxFirestoreQuerySetting, useFirestoreConnect } from 'react-redux-firebase'
import { useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { IDashboard } from '../../types/dashboard'

type DashboardMap = Record<string, IDashboard>

const useDashboards = (): DashboardMap | null => {
  const { user, currentUser, company } = useContext(SessionContext)

  //console.debug('(useDashboards)', `company/${user?.company}/dashboard`)

  let query = [] as Array<string> | Array<ReduxFirestoreQuerySetting>

  // IMPORTANT: Check if company exists before connecting. If not done firestore can't connect on first load
  if (user?.company && user?.id && company?.authorizations['dashboard.dashboards']) {
    query = [
      {
        collection: 'company',
        doc: user.company,
        subcollections: [
          {
            collection: 'dashboard',
            where: ['creator', '==', currentUser?.uid],
            limit:
              (company?.authorizations['dashboard.dashboards'] &&
                (company.authorizations['dashboard.dashboards'] as number)) ??
              1, //Only allow 1 dashboard by default (free bundle)
          },
        ], //Only Query Dashboards of current User
        storeAs: 'company_dashboards',
      },
    ]
  }

  console.debug(
    `Allow ${(company?.authorizations && (company.authorizations['dashboard.dashboards'] as number)) ?? 1} dashboards`
  )
  useFirestoreConnect(() => query)

  const dashboards = useSelector(
    (state: { firestore: { data: { company_dashboards?: DashboardMap } } }) => state.firestore.data.company_dashboards
  )
  console.debug('(useDashboards)', Object.keys(dashboards ?? {}).length)

  return dashboards
    ? Object.entries(dashboards)
        .filter(([, value]) => {
          return !!value // Filters out empty values created when deleting dashboards
        })
        .sort(([keyA, valueA], [keyB, valueB]) => {
          if (user?.mainDashboard) {
            //If user has mainDashboard set mainDashboard as first item
            //console.debug(`Main Dashboard ${user?.mainDashboard}`)
            if (user.mainDashboard === keyA) {
              return -1
            } else if (user.mainDashboard === keyB) {
              return 0
            }
          }
          return valueA.title.toLowerCase().localeCompare(valueB.title.toLowerCase())
        })
        .reduce((dbs, [key, value]) => ({ ...dbs, [key]: value }), {}) // Create object from array
    : ({} as DashboardMap)
}

export default useDashboards
