import React from 'react'
import { VictoryArea, VictoryAxis, VictoryChart, VictoryLine } from 'victory'
import LoadingScreen from '../../../../../../components/LoadingScreen'
import colors from '../../../../../../constants/colors'
import { useSecondaryBackground, useTextColor } from '../../../../../../hooks/useColors'
import { Themes } from '../../../../../../types/dashbar/theme'
import { useTheme } from '../../../../../../utils/types/theme'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import numbro from 'numbro'
export const stringifyYValue = (value: number, tickunit) => {
  const formattedValue = numbro(value).format({
    average: true,
    mantissa: 0,
    spaceSeparated: true,
    postfix: 'Tsd.',
  })
  return formattedValue.replace('k', '') + ' ' + tickunit
}
export const getTickValue = (index, displayedSalesList, nextInterval, todayStr) => {
  if (index === 4) return todayStr
  if (index === 5) return ''
  if (index === 6) return nextInterval
  return displayedSalesList[index]?.date
}

const SalesForecastLinegraph = ({
  displayedSalesList,
  displayedForecastList,
  displayedForecastLowerList,
  displayedForecastUpperList,
  maxValue,
  minValue,
  size,
  loading,
  tickunit = '',
  nextInterval,
}: {
  displayedSalesList: Array<{ y: number; ds: number; date: string }>
  displayedForecastList: Array<{ y: number; ds: number }>
  displayedForecastLowerList: Array<{ y: number; ds: number }>
  displayedForecastUpperList: Array<{ y: number; ds: number }>
  minValue: number
  maxValue: number
  loading: boolean
  tickunit: string
  size: any

  nextInterval: string
}) => {
  const theme = useTheme()
  const lineColor = theme === Themes.Dark ? colors.color2 : colors.color2
  const underLineColor = theme === Themes.Dark ? colors.color2 : colors.color2
  const backgroundColor = useSecondaryBackground()
  const axesColor = useTextColor()
  const strokeDasharray = '1'
  const strokewidth = 4
  const textColor = useTextColor()
  const { t } = useTranslation()
  const leftPadding = maxValue > 10000 ? 70 : 50
  const chartTheme = {
    axis: {
      style: {
        tickLabels: {
          fill: textColor,
          fontFamily: 'Comfortaa_300Light',
        },
      },
    },
  }
  if (loading) return <LoadingScreen />
  return (
    <VictoryChart
      height={size?.height}
      width={size?.width}
      maxDomain={{ y: maxValue }}
      minDomain={{ y: minValue }}
      padding={{ top: 25, left: leftPadding, bottom: 40, right: 20 }}
      theme={chartTheme}
    >
      <VictoryLine
        x={'ds'}
        y={'y'}
        interpolation={'natural'}
        style={{
          data: { stroke: colors.color3, strokeWidth: strokewidth },
          parent: { border: '1px solid #ccc', borderRadius: 16 },
        }}
        data={displayedSalesList}
      />

      <VictoryArea
        animate={{
          duration: 2000,
          onLoad: { duration: 2000 },
          easing: 'circle',
        }}
        interpolation={'natural'}
        x={'ds'}
        y={'y'}
        data={displayedForecastUpperList}
        style={{
          data: {
            fill: underLineColor,
            fillOpacity: theme === Themes.Dark ? 0.5 : 0.2,
            stroke: lineColor,
            strokeWidth: 1,
          },
          labels: {
            fill: textColor, //CHANGE COLOR OF Y-AXIS LABELS
            fillOpacity: 0.5,
            fontSize: 10,
            padding: 5,
            fontFamily: 'Comfortaa_300Light',
          },
        }}
      />
      <VictoryArea
        animate={{
          duration: 2000,
          onLoad: { duration: 2000 },
          easing: 'circle',
        }}
        interpolation={'linear'}
        x={'ds'}
        y={'y'}
        data={displayedForecastLowerList}
        style={{
          data: {
            fill: backgroundColor,
            fillOpacity: 1,
            stroke: lineColor,
            strokeWidth: 1,
          },
          labels: {
            fill: textColor, //CHANGE COLOR OF Y-AXIS LABELS
            fillOpacity: 0.3,
            fontSize: 10,
            padding: 5,
            fontFamily: 'Comfortaa_300Light',
          },
        }}
      />
      <VictoryLine
        x={'ds'}
        y={'y'}
        animate={{
          duration: 2000,
          onLoad: { duration: 2000 },
          easing: 'circle',
        }}
        interpolation={'natural'}
        style={{
          data: { stroke: colors.color2, strokeWidth: strokewidth },
          parent: { border: '1px solid #ccc', borderRadius: 16 },
        }}
        data={displayedForecastList}
      />
      {/* <VictoryScatter
        style={{ data: { fill: colors.color3 } }}
        x={'ds'}
        y={'y'}
        size={2}
        data={[
          {
            y: displayedSalesList[displayedForecastList.length - 1].y,
            ds: 4,
          },
        ]}
      /> */}
      {/* AXIS  */}
      <VictoryAxis
        fixLabelOverlap
        dependentAxis
        tickFormat={(y) => stringifyYValue(y, tickunit)}
        style={{
          axis: {
            stroke: textColor, //CHANGE COLOR OF Y-AXIS
          },

          tickLabels: {
            fill: textColor, //CHANGE COLOR OF Y-AXIS LABELS

            fontSize: 10,
            padding: 5,
            fontFamily: 'Comfortaa_300Light',
          },
          grid: {
            stroke: 'transparent', //CHANGE COLOR OF Y-AXIS GRID LINES
            strokeDasharray: strokeDasharray,
          },
        }}
      />
      <VictoryAxis
        tickFormat={(x) => getTickValue(x, displayedSalesList, nextInterval, `${t('shop.widgets.interval.today')} `)}
        tickValues={[0, 1, 2, 3, 4, 5, 6]}
        style={{
          axis: {
            stroke: axesColor, //CHANGE COLOR OF Y-AXIS
          },
          tickLabels: {
            fill: textColor, //CHANGE COLOR OF Y-AXIS LABELS
            fontSize: 10,
            padding: 5,
            marginBottom: 10,
            fontFamily: 'Comfortaa_300Light',
          },
          grid: {
            stroke: 'transparent',
            fill: textColor, //CHANGE COLOR OF Y-AXIS GRID LINES
          },
        }}
      />
    </VictoryChart>
  )
}
export default SalesForecastLinegraph
