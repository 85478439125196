import React, { ReactNode } from 'react'
import { Text, TextProps, StyleSheet } from 'react-native'
import useStyles from './styles'

/** @description
 * Migrated from @webstollen/shared-react-components
 */

const ColoredText = ({ style, children, ...props }: TextProps & { children?: ReactNode }) => {
  const styles = useStyles()
  return (
    <Text
      {...{ ...props, style: StyleSheet.flatten([styles.text, style]) }}
      allowFontScaling={props.allowFontScaling === true}
    >
      <>{children}</>
    </Text>
  )
}

export default ColoredText
