export interface Orders {
  totalItems: number
  pageNumber: number
  pageSize: number
  items: Array<Order>
  totalPages: number
  hasPreviousPage: boolean
  hasNextPage: boolean
  nextPageNumber: number
  previousPageNumber: number
}

export interface Order {
  id: number
  number: string
  externalNumber: string
  companyId: number
  departureCountry: DepartureCountry
  customerId: number
  billingAddress: BillingAddress
  shipmentAddress: ShipmentAddress
  salesOrderDate: string
  salesOrderPaymentDetails: SalesOrderPaymentDetails
  salesOrderShippingDetail: SalesOrderShippingDetail
  isExternalInvoice: boolean
  comment?: string
  customerComment?: string
  isCancelled: boolean
  languageIso: string
  cancellationDetails: Record<string, any>
  colorCodeId?: number
}

export interface DepartureCountry {
  countryIso: string
  currencyIso: string
  currencyFactor: number
}

export interface BillingAddress {
  firstName: string
  lastName: string
  street: string
  postalCode: string
  city: string
  countryIso: string
  emailAddress: string
  company?: string
  vatID?: string
  phoneNumber?: string
}

export interface ShipmentAddress {
  firstName: string
  lastName: string
  street: string
  postalCode: string
  city: string
  countryIso: string
  emailAddress?: string
  vatID?: string
  phoneNumber?: string
}

export interface SalesOrderPaymentDetails {
  paymentStatus: number
  totalGrossAmount: number
  currencyIso: string
  currencyFactor: number
  stillToPay: number
  paymentTarget: number
  cashDiscount: number
  cashDiscountDays: number
  paymentMethodId?: number
}

export interface SalesOrderShippingDetail {
  shippingMethodId: number
  deliveryCompleteStatus: number
  shippingPriority: number
  extraWeight: number
}

/**
 * changes properies of type Orders to lowercase
 *
 * @param oldOrders an Orders object with uppercase properties
 * @returns an object of type Orders
 */
export const toOrders = (oldOrders: Record<string, any>): Orders => {
  return {
    totalItems: oldOrders.TotalItems,
    pageNumber: oldOrders.PageNumber,
    pageSize: oldOrders.PageSize,
    items: toItems(oldOrders.Items),
    totalPages: oldOrders.TotalPages,
    hasPreviousPage: oldOrders.HasPreviousPage,
    hasNextPage: oldOrders.HasNextPage,
    nextPageNumber: oldOrders.NextPageNumber,
    previousPageNumber: oldOrders.PreviousPageNumber,
  }
}

/**
 * changes properies of an Array of Order types to lowercase
 *
 * @param oldItems an Array of Order objects with uppercase properties
 * @returns an object of type Orders
 */
export const toItems = (oldItems: Array<Record<string, any>>): Array<Order> => {
  const newItems: Array<Order> = []
  oldItems.forEach((oldItem) => {
    newItems.push(toOrder(oldItem))
  })
  return newItems
}

/**
 * changes properies of an Order object to lowercase
 *
 * @param oldItem Order object with uppercase properties
 * @returns Order object (lowercase)
 */
export const toOrder = (oldItem: Record<string, any>): Order => {
  return {
    id: oldItem.Id,
    number: oldItem.Number,
    externalNumber: oldItem.ExternalNumber,
    companyId: oldItem.CompanyId,
    departureCountry: toDepartureCountry(oldItem.DepartureCountry),
    customerId: oldItem.CustomerId,
    billingAddress: toBillingAddress(oldItem.BillingAddress),
    shipmentAddress: toShippingAddress(oldItem.Shipmentaddress),
    salesOrderDate: oldItem.SalesOrderDate,
    salesOrderPaymentDetails: toSalesOrderPaymentDetail(oldItem.SalesOrderPaymentDetails),
    salesOrderShippingDetail: toSalesOrderShippingDetail(oldItem.SalesOrderShippingDetail),
    isExternalInvoice: oldItem.IsExternalInvoice,
    comment: oldItem?.Comment,
    customerComment: oldItem?.CustomerComment,
    isCancelled: oldItem.IsCancelled,
    languageIso: oldItem.LanguageIso,
    cancellationDetails: oldItem.CancellationDetails,
    colorCodeId: oldItem?.ColorcodeId,
  }
}

/**
 * changes properies of a SalesOrderShippingDetail object to lowercase
 *
 * @param oldDetail SalesOrderShippingDetail object with uppercase properties
 * @returns a SalesOrderShippingDetail (lowercase)
 */
const toSalesOrderShippingDetail = (oldDetail: Record<string, any>): SalesOrderShippingDetail => {
  return {
    shippingMethodId: oldDetail.ShippingMethodId,
    deliveryCompleteStatus: oldDetail.DeliveryCompleteStatus,
    shippingPriority: oldDetail.ShippingPriority,
    extraWeight: oldDetail.ExtraWeight,
  }
}

/**
 * changes properies of aSalesOrderPaymentDetail object to lowercase
 *
 * @param oldDetail SalesOrderPaymentDetail object with uppercase properties
 * @returns a SalesOrderPaymentDetail (lowercase)
 */
export const toSalesOrderPaymentDetail = (oldDetail: Record<string, any>): SalesOrderPaymentDetails => {
  return {
    paymentStatus: oldDetail.PaymentStatus,
    totalGrossAmount: oldDetail.TotalGrossAmount,
    currencyIso: oldDetail.CurrencyIso,
    currencyFactor: oldDetail.CurrencyFactor,
    stillToPay: oldDetail.StillToPay,
    paymentTarget: oldDetail.PaymentTarget,
    cashDiscount: oldDetail.CashDiscount,
    cashDiscountDays: oldDetail.cashDiscountDays,
    paymentMethodId: oldDetail?.paymentMethodId,
  }
}

/**
 * changes properies of ShippingAddress object to lowercase
 *
 * @param oldAddress ShippingAddress object with uppercase properties
 * @returns a ShippingAddress (lowercase)
 */
export const toShippingAddress = (oldAddress: Record<string, any>) => {
  return {
    firstName: oldAddress.FirstName,
    lastName: oldAddress.LastName,
    street: oldAddress.Street,
    postalCode: oldAddress.PostalCode,
    city: oldAddress.City,
    countryIso: oldAddress.CountryIso,
    emailAddress: oldAddress?.EmailAddress,
    vatID: oldAddress?.VatID,
    phoneNumber: oldAddress?.PhoneNumber,
  }
}

/**
 * changes properies of DepartureCountry object to lowercase
 *
 * @param oldCountry DepartureCountry object with uppercase properties
 * @returns a DepartureCountry (lowercase)
 */
export const toDepartureCountry = (oldCountry: Record<string, any>): DepartureCountry => {
  return {
    countryIso: oldCountry.CountryISO,
    currencyIso: oldCountry.CurrencyIso,
    currencyFactor: oldCountry.CurrencyFactor,
  }
}

/**
 * changes properies of BillingAddress object to lowercase
 *
 * @param oldAddress BillingAddress object with uppercase properties
 * @returns a BillingAddress (lowercase)
 */
export const toBillingAddress = (oldAddress: Record<string, any>): BillingAddress => {
  return {
    firstName: oldAddress.FirstName,
    lastName: oldAddress.LastName,
    street: oldAddress.Street,
    postalCode: oldAddress.PostalCode,
    city: oldAddress.City,
    countryIso: oldAddress.CountryIso,
    emailAddress: oldAddress.EmailAddress,
    company: oldAddress?.Company,
    vatID: oldAddress?.VatID,
    phoneNumber: oldAddress?.PhoneNumber,
  }
}
