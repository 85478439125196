import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IWidgetProps } from '../../../../../types/props/Widget'
import { View } from 'react-native'
import { SessionContext } from '../../../../../context/SessionContext'
import AreaChart from '../../../../../components/VictoryCharts/Area'
import useCollection from '../../../../../hooks/useCollection'
import { isLoaded } from 'react-redux-firebase'
import Widget from '../../../../components/WidgetNoAPI'
import { useNavigate } from '../../../../../components/Router/Router'
import HistorySkeleton from './Skeletons/HistorySkeleton'

const HistoryPerformance = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { company } = useContext(SessionContext)

  //get all Pagespeed Data
  //mobile: pagespeedData.desktop_data
  //desktop: pagespeedData.desktop_data
  const historyData = useCollection<any>(
    `company/${company?.id}/shop/${widget.settings.shop}/extension_pagespeed`,
    undefined,
    ['created_at', 'asc'],
    10
  )

  const histPerformance: Array<number> = []
  if (historyData !== null) {
    Object.entries(historyData || {})?.forEach((element: any) => {
      histPerformance.push(element[1].desktop_data.performance * 100)
    })
  }
  return (
    <Widget
      title={t('shop.widget.pagespeed.history.title')}
      titleIcon={'desktop'}
      widget={widget}
      loading={!isLoaded(historyData)}
      loadingSkeleton={<HistorySkeleton />}
      onClickWidget={() => {
        //alert('TODO: implement') /*"TODO: implement"*/
        navigate(`/analytics/pagespeed/${widget.settings.shop}`) // TODO: add correct link
      }}
      {...props}
    >
      {([size]) => {
        // if (!isLoaded(historyData)) return <LoadingScreen />
        return (
          <View style={{ height: size.height, flexDirection: 'column' }}>
            <AreaChart data={histPerformance} size={size} />
          </View>
        )
      }}
    </Widget>
  )
}

export default HistoryPerformance
