import { createContext, useContext } from 'react'
import { IIntervals } from './Intervals'
import { Themes } from './dashbar/theme'
import { IWidget } from './dashboard'

export interface IWidgetDefinition {
  component: any // React.ReactNode is currently not working
  title?: string
  minVersion: string
  settings?: {
    autoRefresh?: boolean
    refreshInterval?: number
    rowsPerPage?: number
    interval?: IIntervals
  }
  shopSystem?: Array<string>
  icon?: Record<Themes, string>
  authorizations?: Array<string>
}

// TODO: Move File to => context/

type ModifyWidgetContextT = {
  editWidget: (widget: IWidget) => void
  deleteWidget: (widget: IWidget) => void
  updateWidget: (widget: IWidget) => void
  createWidget: (widget: IWidget) => void
}

export const ModifyWidgetContext = createContext<ModifyWidgetContextT>({
  editWidget: () => null,
  deleteWidget: () => null,
  updateWidget: () => null,
  createWidget: () => null,
})

export const useModifyWidgetContext = () => useContext<ModifyWidgetContextT>(ModifyWidgetContext)

type UpdateWidgetContextT = (widget: IWidget) => void

export const UpdateWidgetContext = createContext<UpdateWidgetContextT>(() => null)

export const useUpdateWidgetContext = () => useContext<UpdateWidgetContextT>(UpdateWidgetContext)
