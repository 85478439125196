import React from 'react'
import { View } from 'react-native'
import ColoredText from '../CustomText/ColoredText'
import useStyles from './styles'

const ComponentWrapper = ({ children, title }: { children: React.ReactNode; title: string }) => {
  const styles = useStyles()
  return (
    <View style={styles.wrapper}>
      <ColoredText style={styles.title}>{title}</ColoredText>
      <View>
        <>{children}</>
      </View>
    </View>
  )
}

export default ComponentWrapper
