import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import useStyles from './styles'
import { useTranslation } from '../../../hooks/useTranslation'
import SemiBoldText from '../../../components/CustomText/SemiBoldText'
import ColoredText from '../../../components/CustomText/ColoredText'
import { ISettings } from '../../../types/dashboard'

interface IProps {
  settings: ISettings
  onIntervalChange: (value: string) => void
  title?
  intervals: Array<{
    label: string
    value: string
    active: boolean
  }>
}

const IntervalSelection = ({ intervals, title = '', ...props }: IProps) => {
  const { settings, onIntervalChange } = props
  const styles = useStyles()
  const { t } = useTranslation()
  const LINE_LENGTH = 4
  const intervalOptions = [...new Array(Math.ceil(intervals.length / LINE_LENGTH))].map((_, index) =>
    intervals.slice(index * LINE_LENGTH, index * LINE_LENGTH + LINE_LENGTH)
  )
  const { interval } = settings || {}

  return (
    <View style={{ width: '100%', overflow: 'hidden' }}>
      <SemiBoldText style={styles.header}>{title !== '' ? title : t('shop.widgets.interval.title')}</SemiBoldText>
      <View style={styles.tagContainer}>
        {intervalOptions.map((line, index) => (
          <View key={index} style={styles.lineContainer}>
            {line.map(
              ({ label, value, active }) =>
                active && (
                  <TouchableOpacity
                    key={value}
                    onPress={interval !== value ? () => onIntervalChange(value) : () => undefined}
                    style={[styles.tag, interval === value && styles.tagSelected]}
                  >
                    <ColoredText style={[styles.tagLabel, interval === value && styles.tagLabelSelected]}>
                      {label}
                    </ColoredText>
                  </TouchableOpacity>
                )
            )}
          </View>
        ))}
      </View>
    </View>
  )
}

export default IntervalSelection
