import { useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import useCollection from '../useCollection'

/** Returns current booking if exists otherwise returns empty object
 * @deprecated
 * @returns
 */
const useCurrentBundleBooking = (): Record<string, any> => {
  const { company } = useContext(SessionContext)
  const bookings = useCollection<any>(`company/${company?.id}/booking`)
  const currentBundle: Array<Record<string, any>> | undefined = Object.entries(bookings || {}).find(([k]) => {
    if (!bookings || bookings === null) return false
    if (
      bookings[k]?.startDate?.toDate() < new Date() &&
      (bookings[k]?.endDate?.toDate() > new Date() || !bookings[k]?.endDate) &&
      ['bundle', 'trial'].includes(bookings[k]?.key)
    ) {
      return true
    }
    return false
  })
  return currentBundle ? { ...currentBundle[1], id: currentBundle[0] } : {}
}

export default useCurrentBundleBooking
