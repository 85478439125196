import { Platform, StyleSheet } from 'react-native'
import { useSecondaryBackground } from '../../../../../../hooks/useColors'

const useStyles = () => {
  //const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      //alignItems: 'center',
      backgroundColor: 'transparent',
    },
    container: {
      marginBottom: 5,
    },
    button: {
      width: Platform.OS === 'web' ? 440 : '95%',
      marginHorizontal: 10,
      marginVertical: 5,
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: 50,
      backgroundColor: useSecondaryBackground(),
      borderColor: 'white',
      borderWidth: 1,
      borderRadius: 16,
    },
    disabledButton: {
      opacity: 0.5,
      borderWidth: 0,
    },
    typeIcon: {
      width: 200,
      height: 200,
      marginBottom: 10,
    },
    buttonText: {},
    widgetView: {
      width: Platform.OS === 'web' ? 440 : '95%',
      height: 280,
      //backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.darkBgTransparent7,
      backgroundColor: useSecondaryBackground(),
      borderRadius: 15,
      marginBottom: 10,
      //flex: 1,
      alignItems: 'center',
    },
  })
}

export default useStyles
