import React, { useContext } from 'react'
import { View } from 'react-native'
import { IWidgetProps } from '../../../../../types/props/Widget'
import Widget from '../../../../components/WidgetNoAPI'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import { UpsellingContext } from '../../../../../context/UpsellingContext'
import { useTranslation } from '../../../../../hooks/useTranslation'
import SvgComponent from '../../../../../assets/imgs/extensions/Forecast'

const ForecastDemoWidget = ({ widget, ...props }: IWidgetProps) => {
  const { open } = useContext(UpsellingContext)
  const { t } = useTranslation()

  return (
    <Widget
      title={'Prognosen'}
      widget={widget}
      loading={false}
      onClickWidget={() => {
        open()
      }}
      {...props}
    >
      {([size]) => {
        return (
          <View
            style={{
              height: size.height,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SvgComponent />
            <ColoredText style={{ fontSize: 18, marginTop: 20, textAlign: 'center' }}>
              {t('shop.widget.salesForecasting.demo.connect')}
            </ColoredText>
          </View>
        )
      }}
    </Widget>
  )
}

export default ForecastDemoWidget
