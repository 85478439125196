import * as yup from 'yup'

const BillingAddressValidator = yup.object().shape({
  address1: yup.string().required(),
  address2: yup.string().ensure(),
  city: yup.string().required(),
  lastName: yup.string().required(),
  firstName: yup.string().required(),
  zipCode: yup
    .string()
    .matches(/[0-9]+/)
    .min(4)
    .max(5)
    .required(),
  country: yup.string().min(2).max(2).required(),
  email: yup.string().email().required(),
})

export default BillingAddressValidator
