import * as React from 'react'
import Svg, { Defs, LinearGradient, Stop, G, Rect, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */
const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} {...props}>
    <Defs>
      <LinearGradient id="a" x1="3.822%" x2="98.671%" y1="90.401%" y2="11.121%">
        <Stop offset="0%" stopColor="#73157C" />
        <Stop offset="100%" stopColor="#2985D0" />
      </LinearGradient>
    </Defs>
    <G fill="none" fillRule="evenodd">
      <Rect width={59} height={59} x={0.5} y={0.5} fill="#FFF" stroke="url(#a)" rx={8} />
      <G fillRule="nonzero">
        <Path
          fill="#F90"
          d="M43.208 42.388C26.62 50.845 16.325 43.769 9.735 39.47c-.408-.27-1.101.064-.5.804C11.431 43.126 18.626 50 28.018 50c9.398 0 14.989-5.494 15.688-6.452.695-.95.204-1.474-.498-1.16Zm4.66-2.756c-.446-.622-2.71-.738-4.134-.55-1.426.182-3.568 1.116-3.382 1.677.096.21.291.115 1.271.021.983-.105 3.736-.477 4.31.326.576.809-.879 4.662-1.144 5.283-.257.622.098.782.58.368.475-.413 1.336-1.485 1.913-3.001.574-1.525.924-3.651.585-4.124Z"
        />
        <Path
          fill="#F90"
          d="M43.208 42.388C26.62 50.845 16.325 43.769 9.735 39.47c-.408-.27-1.101.064-.5.804C11.431 43.126 18.626 50 28.018 50c9.398 0 14.989-5.494 15.688-6.452.695-.95.204-1.474-.498-1.16Zm4.66-2.756c-.446-.622-2.71-.738-4.134-.55-1.426.182-3.568 1.116-3.382 1.677.096.21.291.115 1.271.021.983-.105 3.736-.477 4.31.326.576.809-.879 4.662-1.144 5.283-.257.622.098.782.58.368.475-.413 1.336-1.485 1.913-3.001.574-1.525.924-3.651.585-4.124Z"
        />
        <Path
          fill="#000"
          d="M38.337 40.542c-.373.32-.914.344-1.335.13-1.874-1.495-2.208-2.19-3.24-3.617-3.098 3.037-5.29 3.945-9.31 3.945C19.702 41 16 38.185 16 32.546c0-4.402 2.486-7.4 6.021-8.866 3.066-1.297 7.348-1.526 10.62-1.884v-.702c0-1.29.104-2.816-.683-3.93-.69-.999-2.01-1.411-3.17-1.411-2.152 0-4.074 1.06-4.543 3.258-.095.488-.468.969-.977.992l-5.48-.565c-.461-.1-.97-.458-.843-1.137C18.208 11.923 24.205 10 29.575 10c2.749 0 6.339.702 8.508 2.701 2.748 2.464 2.486 5.753 2.486 9.331v8.454c0 2.54 1.096 3.655 2.129 5.028.365.488.445 1.076-.016 1.442-1.152.923-3.201 2.64-4.33 3.602l-.015-.016"
        />
        <Path
          fill="#FFF"
          d="M31 28.126c0 2.025.053 3.714-1.007 5.512-.855 1.463-2.21 2.362-3.724 2.362C24.203 36 23 34.48 23 32.235 23 27.805 27.11 27 31 27v1.126Z"
        />
      </G>
    </G>
  </Svg>
)
export default SvgComponent
