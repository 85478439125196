import { StyleSheet } from 'react-native'
import colors from '../../../../constants/colors'
import { useHighlightColor, useSecondaryBackground, useTextColor } from '../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      backgroundColor: 'transparent',
      width: '100%',
    },
    container: {
      margin: 0,
      width: '100%',
    },

    widget: {
      borderRadius: 16,
      marginTop: 5,
      paddingVertical: 10,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 50,
      backgroundColor: useSecondaryBackground(),
    },
    selectedWidget: {
      backgroundColor: useHighlightColor(),
    },
    widgetText: {
      width: '50%',
      paddingLeft: 10,
    },
    selectedWidgetText: {
      color: colors.darkText1,
    },
    widgetButton: {
      justifyContent: 'center',
      flexDirection: 'row',
      paddingHorizontal: 10,
    },

    widgetIcon: {
      height: 25,
      width: 25,

      borderRadius: 12.5,
      overflow: 'hidden',
    },

    manageDashboardButton: {
      borderRadius: 16,
      marginTop: 5,
      paddingVertical: 10,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      height: 50,
      backgroundColor: useSecondaryBackground(),
    },
    fallbackWrapper: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    fallbackButtonDisabled: {
      opacity: 0.5,
    },
    fallbackButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginHorizontal: 3,
      padding: 5,
      width: 60,
      borderRadius: 10,
      backgroundColor: useTextColor(),
    },
  })
}

export default useStyles
