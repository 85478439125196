import numbro from 'numbro'
import React from 'react'
import { Platform, View } from 'react-native'
import Svg from 'react-native-svg'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLine } from 'victory'
import colors from '../../../constants/colors'
import { useTextColor } from '../../../hooks/useColors'
import ColoredText from '../../CustomText/ColoredText'

interface MixedChartProps {
  list: Array<{ line1: number; line2: number; bar1: number }>
  title: { titleLine1: string; titleLine2: string; titleBar1: string }
  size: {
    width: number
    height: number
  }
  showAxis?: boolean
}

const MixedRelevanzChart = ({ size, list, title }: MixedChartProps) => {
  const screenWidth = size.width
  const screenHeight = size.height
  const lineColorVisitor = colors.highlightLight
  const lineColorSales = colors.highlightDark
  const color = useTextColor()

  const barList = list.map(({ bar1 }) => bar1)

  const maxBarList = numbro(Math.max(...barList)).format({
    average: true,
    totalLength: 3,
  })

  // Normalizes Line Charts
  const line1List = list.map(({ line1 }) => line1)
  const maxLine1List = numbro(Math.max(...line1List)).format({
    average: true,
    totalLength: 3,
  })
  const normalizedLine1List = list.map(({ line1 }) => {
    if (Math.max(...line1List) === 0) {
      return 0
    }
    return line1 / Math.max(...line1List)
  })

  const line2List = list.map(({ line2 }) => line2)
  const maxLine2List = numbro(Math.max(...line2List)).format({
    average: true,
    totalLength: 3,
  })
  const normalizedLine2List = list.map(({ line2 }) => {
    if (Math.max(...line2List) === 0) {
      return 0
    }
    return line2 / Math.max(...line2List)
  })
  // const lineChartsData = [
  //   {
  //     data: normalizedLine1List,
  //     svg: { strokeWidth: 2, stroke: colors.color1, fill: 'none' },
  //   },
  //   {
  //     data: normalizedLine2List,
  //     svg: { strokeWidth: 2, stroke: colors.color3, fill: 'none' },
  //   },
  // ]

  return (
    <>
      <Svg style={{ width: '100%', height: '70%' }} viewBox={`0,0,${screenWidth},${screenHeight}`}>
        <VictoryChart
          domainPadding={{ y: 20, x: 0 }}
          standalone={false}
          padding={{ top: 0, bottom: 10, right: 0, left: 0 }}
          width={screenWidth}
          height={screenHeight}
          // style={{ background: { fill: 'white' } }}
        >
          <VictoryBar
            data={barList}
            alignment={'middle'}
            barRatio={1}
            // style={{ data: { fill: colors.color2, fillOpacity: theme === Themes.Dark ? 0.5 : 0.2 } }}
            style={{ data: { fill: colors.color2, fillOpacity: 1 } }}
          />
          <VictoryAxis
            standalone={false}
            style={{
              axis: { stroke: 'transparent' },
              ticks: { stroke: 'transparent' },
              tickLabels: { fill: 'transparent' },
            }}
          />
        </VictoryChart>
        <VictoryChart
          standalone={false}
          padding={{ top: 0, bottom: 10, right: 0, left: 0 }}
          width={screenWidth}
          height={screenHeight}
          // style={{ background: { fill: 'white' } }}
        >
          <VictoryLine
            domainPadding={{ y: 5, x: 0 }}
            singleQuadrantDomainPadding={false}
            width={screenWidth}
            height={screenHeight}
            standalone={false}
            data={normalizedLine1List}
            interpolation="natural"
            style={{
              data: {
                stroke: lineColorSales,
                strokeWidth: 3,
              },
            }}
          />
          <VictoryLine
            domainPadding={{ y: 5, x: 0 }}
            singleQuadrantDomainPadding={false}
            width={screenWidth}
            height={screenHeight}
            standalone={false}
            data={normalizedLine2List}
            interpolation="natural"
            style={{
              data: {
                stroke: lineColorVisitor,
                strokeWidth: 3,
              },
            }}
          />
          <VictoryAxis
            standalone={false}
            style={{
              axis: { stroke: 'transparent' },
              ticks: { stroke: 'transparent' },
              tickLabels: { fill: 'transparent' },
            }}
          />
        </VictoryChart>
      </Svg>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View
            style={{
              backgroundColor: colors.color2,
              height: 10,
              width: 10,
              borderRadius: 2,
              alignSelf: 'center',
              marginRight: Platform.select({ default: undefined, web: 4 }),
            }}
          />
          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginHorizontal: '1.5%',
            }}
          >
            <ColoredText
              style={{
                fontSize: 12,
              }}
            >
              <>{title.titleBar1}</>
            </ColoredText>
            <ColoredText
              style={{
                fontSize: 12,
                color: color,
              }}
            >
              max {maxBarList}
            </ColoredText>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View
            style={{
              backgroundColor: colors.color3,
              height: 10,
              width: 10,
              borderRadius: 2,
              alignSelf: 'center',
              marginRight: Platform.select({ default: undefined, web: 4 }),
            }}
          />
          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginHorizontal: '1.5%',
            }}
          >
            <ColoredText
              style={{
                fontSize: 12,
              }}
            >
              <>{title.titleLine1}</>
            </ColoredText>
            <ColoredText
              style={{
                fontSize: 12,
                color: color,
              }}
            >
              max {maxLine1List}
            </ColoredText>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View
            style={{
              backgroundColor: colors.color1,
              height: 10,
              width: 10,
              borderRadius: 2,
              alignSelf: 'center',
              marginRight: Platform.select({ default: undefined, web: 4 }),
            }}
          />
          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginHorizontal: '1.5%',
            }}
          >
            <ColoredText
              style={{
                fontSize: 12,
              }}
            >
              <>{title.titleLine2}</>
            </ColoredText>
            <ColoredText
              style={{
                fontSize: 12,
                color: color,
              }}
            >
              max {maxLine2List}
            </ColoredText>
          </View>
        </View>
      </View>
    </>
  )
}

export default MixedRelevanzChart
