import { StyleSheet, Platform } from 'react-native'
import colors from '../../constants/colors'
import { useSecondaryBackground } from '../../hooks/useColors'
import { Themes } from '../../types/dashbar/theme'
import { useTheme } from '../../utils/types/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    widget: {
      borderRadius: 10,
      //backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.darkBgTransparent8,
      backgroundColor: theme === Themes.Dark ? '#1e2239' : '#FFF',
      height: 250,
      overflow: 'hidden',
      padding: 10,
      // width: '100%',
    },
    widget_error: {
      backgroundColor: theme === Themes.Dark ? '#751e01' : '#ffd6c4',
    },
    widget_config: {
      backgroundColor: theme === Themes.Dark ? '#182c54' : '#9cb8f0',
    },
    header: {
      height: Platform.select({ web: 34, default: 30 }),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      padding: 5,
    },
    icon: {
      width: 24,
      height: 24,
      ...(Platform.OS === 'web' && { fontSize: 10 }),
      borderRadius: 12,
      backgroundColor: colors.lightBg1,
      position: 'absolute',
    },
    iconWrapper: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontWeight: '500',
      fontSize: 14,
      lineHeight: 14,
      paddingTop: Platform.select({ web: 5, default: 2 }),
      paddingLeft: 30,
      //flexGrow: 2,
    },
    subtitle: {
      fontSize: 10,
      lineHeight: 10,
      paddingTop: Platform.select({ web: 2, default: 2 }),
      paddingLeft: 30,
      marginBottom: 5,
    },
    optionWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      padding: 5,
    },
    optionsButton: {
      padding: 15,
      // backgroundColor: '#EEE',
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    options: {
      padding: 5,
      display: 'flex',
      justifyContent: 'flex-end',
      borderRadius: 10,
    },
    optionsText: {
      textAlign: 'right',
    },
    optionIntervallText: {
      fontSize: 10,
      padding: 2,
      verticalAlign: 'middle',
      height: 13,
      opacity: 0.5,
    },
    drawerHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 5,
    },
    content: {
      overflow: 'hidden',
      flex: 1,

      marginHorizontal: 15,
    },
    footer: {
      // paddingVertical: 10,
      width: '100%',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    footerText: {
      fontSize: 11,
    },

    hairline: {
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderBottomColor: useSecondaryBackground(),
    },
  })
}

export default useStyles
