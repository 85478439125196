import { SemVer } from 'semver'

export const getVersionString = (version: SemVer): string => {
  return `${version.major}.${version.minor}.x`
}

/** This function takes a plugin version and maps it to belonging
 * version in dash.bar
 *
 * @param version Plugin Version that should be mapped to dash.bar version
 * @returns {number} dash.bar version for given plugin version
 */
export const mappedVersion = (version: SemVer): number => {
  switch (getVersionString(version)) {
    case `2.6.x`:
    case `2.5.x`:
      return 3
    case `2.4.x`:
    case `2.3.x`:
    case `2.2.x`:
    case `2.1.x`:
    case `2.0.x`:
      return 2
    default:
      return 1
  }
}
