import { Skeleton } from 'native-base'
import React from 'react'
import { useSecondaryBackground } from '../../../../../../hooks/useColors'

const MandateSkeleton = () => {
  const loadingColor = useSecondaryBackground()
  return (
    <Skeleton
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        marginTop: 20,
        width: '60%',
        minWidth: 350,
        height: 250,
        borderRadius: 20,
      }}
      isLoaded={false}
      startColor={loadingColor}
    />
  )
}

export default MandateSkeleton
