import React, { PropsWithChildren, useState } from 'react'

export type SessionContextValueType = {
  dashboardNavdialogVisible: boolean
  toggleSecondaryDashboardNavDialog: () => void
  showSecondaryDashboardNavDialog: () => void
  hideSecondaryDashboardNavDialog: () => void
}

export const ManageDashboardDialogContext = React.createContext<SessionContextValueType>({
  dashboardNavdialogVisible: false,
  toggleSecondaryDashboardNavDialog: () => {},
  showSecondaryDashboardNavDialog: () => {},
  hideSecondaryDashboardNavDialog: () => {},
})

const ManageDashboardProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [showDialogState, setShowDialog] = useState<boolean>(false)
  const toggleSecondaryDashboardNavDialog = () => {
    setShowDialog(!showSecondaryDashboardNavDialog)
  }
  const showSecondaryDashboardNavDialog = () => {
    setShowDialog(true)
  }
  const hideSecondaryDashboardNavDialog = () => {
    setShowDialog(false)
  }

  return (
    <ManageDashboardDialogContext.Provider
      value={{
        dashboardNavdialogVisible: showDialogState,
        toggleSecondaryDashboardNavDialog: toggleSecondaryDashboardNavDialog,
        showSecondaryDashboardNavDialog: showSecondaryDashboardNavDialog,
        hideSecondaryDashboardNavDialog: hideSecondaryDashboardNavDialog,
      }}
    >
      {children}
    </ManageDashboardDialogContext.Provider>
  )
}

export default ManageDashboardProvider
