import { IconName } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import { Platform, Switch, View } from 'react-native'
import ColoredText from '../../../../components/CustomText/ColoredText'
import { FontAwesomeIcon } from '../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import icons from '../../../../constants/icons'
import { useTextColor } from '../../../../hooks/useColors'
import useStyles from './styles.native'
import { useTheme } from '../../../../utils/types/theme'
import { Themes } from '../../../../types/dashbar/theme'

const MenuButtonSwitch = ({
  enabled,
  text,
  icon,
  setEnabled,
}: {
  enabled: boolean
  text?: string
  icon?: IconName
  setEnabled: (value: React.SetStateAction<boolean>) => void
}) => {
  const styles = useStyles()
  const textColor = useTextColor()
  const theme = useTheme()
  const thumbColor = theme === Themes.Dark ? (Platform.OS === 'android' ? '#dedede' : '#292929') : '#000'
  const trackColor = theme === Themes.Dark ? '#b8b8b8' : '#e3e3e3'
  const trackColorActive = theme === Themes.Dark ? '#8a8a8a' : '#757876'
  return (
    <View style={styles.wrapper}>
      <View style={styles.textWrapper}>
        {icon && (
          /*@ts-expect-error [ToDo]: Describe Error */
          <FontAwesomeIcon size={20} style={styles.icon} color={textColor} icon={[icons.faIconStyle, icon]} />
        )}
        <ColoredText style={styles.text}>{text}</ColoredText>
      </View>
      <Switch
        style={styles.switch}
        value={enabled}
        trackColor={{ false: trackColor, true: trackColorActive }}
        thumbColor={enabled ? thumbColor : thumbColor}
        ios_backgroundColor={trackColor}
        onValueChange={() => {
          setEnabled(!enabled)
        }}
      />
    </View>
  )
}

export default MenuButtonSwitch
