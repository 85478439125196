import React from 'react'
import { TouchableOpacity, Platform, Dimensions } from 'react-native'
import * as WebBrowser from '../../../../components/Browser'
import PageWrapper from '../../../../components/PageWrapper'
import TutorialNew from '../../../../assets/imgs/backgrounds/TutorialNew'

const NewUserTutorial = () => {
  return (
    <PageWrapper fullScreen isScrollable>
      <TouchableOpacity
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          alignContent: 'center',
        }}
        onPress={() => {
          WebBrowser.openUrl(`https://ws-url.de/stepbystep`, '_blank').then(() => {})
        }}
      >
        {Platform.OS === 'web' && Dimensions.get('window').width >= 600 ? (
          <TutorialNew />
        ) : // <TutorialDarkWeb scale={2} viewBox={'-50 -225 800 900'} />
        Platform.OS === 'web' ? (
          // <TutorialDarkMobile scale={2} viewBox={'120 -310 50 900'} />
          <TutorialNew />
        ) : (
          // <TutorialDarkMobile scale={2} width={'100%'} viewBox={'10 -85 250 650'} />
          <TutorialNew />
        )}
      </TouchableOpacity>
    </PageWrapper>
  )
}

export default NewUserTutorial
