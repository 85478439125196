import * as React from 'react'
import Svg, { SvgProps, G, Path } from 'react-native-svg'

const Cartasi = (props: SvgProps) => (
  <Svg fill="none" height={24} width={32} {...props}>
    <G clipRule="evenodd" fillRule="evenodd">
      <Path d="M4 0h24a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#ff6e28" />
      <Path
        d="M15.397 6.088c.213-.06.435-.088.656-.088h.015c.287.002.556.05.788.135.187.068.35.16.48.267a1.048 1.048 0 0 1 .234.28.67.67 0 0 1-.04.724 1.035 1.035 0 0 1-.194.21c-.06.051-.13.098-.204.14a2.21 2.21 0 0 1-1.071.258c-.887 0-1.606-.452-1.606-1.01 0-.294.201-.56.522-.744l.094-.05c.1-.048.21-.09.326-.122zm-5.176 6.032c0-2.248 3.925-3.012 5.92-3.012 1.128 0 3.519.243 4.967 1.507a3.597 3.597 0 0 1 .62.72c.087.137.166.28.234.433.06.134.044.232-.012.284a.155.155 0 0 1-.03.023.191.191 0 0 1-.083.023.343.343 0 0 1-.28-.138 1.825 1.825 0 0 0-.232-.238c-.74-.67-2.328-1.168-3.783-1.233-1.585-.07-3.012.372-3.012 1.663v1.667c0 .08-.002.155-.007.225-.033.463-.177.695-.446.786a1.25 1.25 0 0 1-.46.046c-.493-.025-1.749-.267-2.597-1a.417.417 0 0 1-.005-.005c-.463-.416-.794-.984-.794-1.75zm7.463-.47c.132-.147.327-.203.59-.203l.11.003c.774.04 3.432.615 3.432 2.788 0 2.248-3.926 3.012-5.92 3.012-1.418 0-4.826-.383-5.858-2.692-.157-.352.21-.45.44-.16 1.06 1.327 5.496 2.13 6.693.658l.016-.019a1.382 1.382 0 0 0 .31-.895v-1.666c0-.4.06-.663.187-.827z"
        fill="#fff"
      />
    </G>
  </Svg>
)

export default Cartasi
