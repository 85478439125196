import { StyleSheet } from 'react-native'
import useIsMobileWebRender from '../../../../../../hooks/useIsMobileWebRender'
import colors from '../../../../../../constants/colors'
import sizes from '../../../../../../constants/sizes'
import {
  useHighlightColor,
  useInvertedTextColor,
  useSecondaryBackground,
  useTextColor,
} from '../../../../../../hooks/useColors'
import { Themes } from '../../../../../../types/dashbar/theme'
import { useTheme } from '../../../../../../utils/types/theme'

const useStyles = () => {
  const theme = useTheme()
  const textColor = useTextColor()
  const invertedTextColor = useInvertedTextColor()
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      backgroundColor: useSecondaryBackground(),
      borderRadius: 15,
      marginVertical: 15,
    },
    titleWrapper: {
      height: 170,
      maxHeight: 170,
      minHeight: 170,
      width: '100%',
      backgroundColor: 'orange',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 30,
    },
    backgroundFree: {
      backgroundColor: colors.bundleFree,
    },
    backgroundBusiness: {
      backgroundColor: colors.bundleBusiness,
    },
    backgroundPro: {
      backgroundColor: colors.bundlePro,
    },
    contentWrapper: {
      width: '100%',
    },
    bundleOptionsWrapper: {
      height: 70,
      minHeight: 70,
      maxHeight: 70,
      width: '100%',
      paddingHorizontal: 30,
      paddingVertical: 10,
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'space-around',
    },
    amountWrapper: {
      width: '100%',
      paddingHorizontal: 30,
      paddingVertical: 10,
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      alignContent: 'space-around',
    },
    intervalCard: {
      marginTop: 10,
      borderRadius: 15,
      borderWidth: 1,
      borderColor: colors.color2,
      padding: 15,
      width: '40%',
      minWidth: 300,
    },
    itemWrapper: {
      width: '50%',
      maxWidth: '50%',
      minWidth: '50%',
      flex: 1,
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'flex-start',
    },
    itemText: {
      marginHorizontal: 15,
    },
    title: {
      fontSize: 30,
      fontWeight: '500',
      marginVertical: 15,
      color: 'white',
    },
    subTitle: {
      fontSize: 20,
      fontWeight: '500',
      marginVertical: 5,
      color: 'white',
    },
    divider: {
      width: '100%',
      backgroundColor: theme === Themes.Dark ? colors.lightBg1 : colors.darkBg1,
      height: 1,
      maxHeight: 1,
    },
    intervalWrapper: {
      width: '100%',
      height: '100%',
      minHeight: '100%',
      flex: 1,
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    amountInterval: {
      width: '30%',
    },
    amountValue: {
      width: '40%',
      fontWeight: '600',
    },
    selected: {
      borderWidth: 2,
    },
    buttonWrapper: {
      width: '80%',
      maxWidth: '80%',
      minWidth: '80%',
      flex: 1,
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: useIsMobileWebRender() ? 'center' : 'flex-end',
    },
    button: {
      backgroundColor: useHighlightColor(),
      width: useIsMobileWebRender() ? '90%' : '30%',
      minWidth: 300,

      height: sizes.buttonHeight,
      borderRadius: 10,
      color: 'white',
    },
    //Mobile Settings
    mobileCard: {
      width: '100%',
      height: 120,
      minHeight: 120,
      maxHeight: 120,
      borderRadius: 15,
      marginVertical: 10,
      padding: 10,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      backgroundColor: useSecondaryBackground(),
    },
    bundleWrapper: {
      height: 100,
      minHeight: 100,
      maxHeight: 100,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      borderRadius: 15,
      padding: 5,
      overflow: 'hidden',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'center',
    },
    bundleTextWrapper: {
      width: '50%',
      minWidth: '50%',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 10,
      paddingHorizontal: 20,
    },
    iconWrapper: {
      alignSelf: 'flex-end',
      alignItems: 'flex-end',
      flex: 1,
      height: '100%',
      maxHeight: '100%',
      minHeight: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },
    paymentMethodWrapper: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'flex-start',
    },
    legalWrapper: {
      width: '90%',
      maxWidth: '90%',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginVertical: 10,
      padding: 10,
    },
    legalText: {
      fontSize: 12,
      fontWeight: '300',
    },
    //Error Modal
    errorModalWrapper: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      zIndex: 30,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.6)',
    },
    errorModal: {
      width: '25%',
      minWidth: '25%',
      maxWidth: '25%',
      minHeight: 200,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
      backgroundColor: textColor,
      borderRadius: 25,
    },
    errorModalTitleWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignContent: 'center',
      alignItems: 'center',
    },
    errorModalTitle: {
      color: invertedTextColor,
      fontSize: 22,
    },
    errorModalText: {
      color: invertedTextColor,
      fontSize: 16,
    },
    errorModalTextWrapper: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      width: '100%',
      padding: 10,
      paddingHorizontal: 20,
    },
    errorModalActionWrapper: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'flex-end',
      marginTop: 10,
      paddingVertical: 10,
      bottom: 0,
    },
    errorModalButton: {
      backgroundColor: invertedTextColor,
      width: '30%',
      minWidth: '30%',
      minHeight: 40,
      padding: 5,
      borderRadius: 15,
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    errorModalButtonText: {
      color: textColor,
      fontSize: 20,
    },
  })
}

export default useStyles
