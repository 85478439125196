/**
 * Use this instead if String.replaceAll(), because it causes an error on Android
 */
import { useMemo } from 'react'
import { useLocation } from '../components/Router/Router'

export const replaceAll = (str: string, find: string, replace: string) => str.replace(new RegExp(find, 'g'), replace)

export const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}
