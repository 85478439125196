import { StyleSheet } from 'react-native'
import colors from '../../constants/colors'
import { usePrimaryBackground } from '../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    card: {
      width: '100%',
      borderRadius: 15,
      minHeight: 80,
      marginVertical: 10,
      overflow: 'hidden',
      backgroundColor: 'rgba(153,28,28,1)',
    },
    icon: {
      height: 140,
      width: 140,
    },
    errorWrapper: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',
    },
    textWrapper: {
      width: '80%',
      flex: 1,
      flexDirection: 'column',
    },
    title: {
      width: '100%',
      color: 'white',
      //fontSize: 15,
    },
    message: {
      width: '100%',
      color: 'white',
      //fontSize: 12,
    },
    button: {
      color: 'white',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 15,
      width: 150,
      height: 50,
      margin: 5,
      borderRadius: 100,
      borderColor: usePrimaryBackground(),
      borderStyle: 'solid',
      backgroundColor: colors.darkBgTransparent2,
    },
  })
}

export default useStyles
