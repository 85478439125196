import { StyleSheet } from 'react-native'
import sizes from '../../../../../../constants/sizes'
import { useSecondaryBackground } from '../../../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      backgroundColor: useSecondaryBackground(),
      borderRadius: 15,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'flex-start',
    },
    title: {
      paddingVertical: 20,
      fontSize: 18,
    },
    widgetWrapper: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'flex-end',
      alignItems: 'flex-end',
      paddingHorizontal: 30,
      paddingVertical: 10,
    },
    widgetSettingsWrapper: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'flex-end',
      alignItems: 'flex-end',
    },
    widgetItemWrapper: {
      width: '50%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
    },
    button: {
      backgroundColor: '#000',
      width: '55%',
      alignSelf: 'center',
      borderRadius: 10,
      marginVertical: 15,
      marginTop: 30,
      color: 'white',
      height: sizes.buttonHeight,
    },
    numberInputWrapper: {
      marginTop: 10,
    },
  })
}

export default useStyles
