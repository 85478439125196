import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { TextInput, View } from 'react-native'
import Button from '../../../../../../components/Button'
import BoldText from '../../../../../../components/CustomText/BoldText'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import colors from '../../../../../../constants/colors'
import { SessionContext } from '../../../../../../context/SessionContext'
import { useTextColor } from '../../../../../../hooks/useColors'
import useDocument from '../../../../../../hooks/useDocument'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import useStyles from './styles'
import { Image } from 'expo-image'

type PropsT = {
  selectConfig: (config: Record<string, any> | null) => void
  config: Record<string, any>
  icon?: any
}

const WidgetConfig = ({ config, selectConfig, icon }: PropsT) => {
  const { company } = useContext(SessionContext)
  const { t } = useTranslation()
  const styles = useStyles()
  const currentConfig = useDocument<Record<string, any>>(`company/${company?.id}/extension_config`, config?.extension)
  const extensionSettings = useDocument<Record<string, any>>('extensions_settings', config?.extension)
  const color = useTextColor()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({})

  const onSubmit = (data: Record<string, any>) => {
    selectConfig(data)
  }

  useEffect(() => {
    if (currentConfig) {
      Object.entries(currentConfig).map(([k, v]) => {
        setValue(k, v)
      })
    }
  }, [currentConfig])
  return (
    <>
      <View
        style={{
          width: '100%',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          flex: 1,
          padding: 10,
        }}
      >
        <View style={styles.widgetView}>
          <BoldText style={{ marginTop: 10, fontSize: 18 }}>
            <>{t('dashboard.widgets.create.config')}</>
          </BoldText>
          <Image source={icon} style={{ width: 100, height: 100, marginVertical: 10 }} contentFit={'cover'} />
          <ColoredText style={{ width: '80%' }}>{t('dashboard.widgets.create.config_text')}</ColoredText>
          {extensionSettings &&
            Object.entries(extensionSettings)
              .filter(([k]) => {
                return k !== 'id' && k !== 'path' && k
              })
              .map(([k, v]) => {
                return (
                  <View key={k} style={{ width: '100%', padding: 10 }}>
                    <Controller
                      control={control}
                      name={`${v.key}`}
                      rules={{ required: v.required }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <View style={{ marginVertical: 10 }}>
                          <ColoredText>{v.name}</ColoredText>
                          <TextInput
                            key={k}
                            placeholder={v.name}
                            style={{
                              borderStyle: 'solid',
                              width: '100%',
                              borderWidth: 1,
                              paddingHorizontal: 12,
                              paddingVertical: 9,
                              fontSize: 15,
                              marginVertical: 10,
                              color: color,
                              borderColor: color,
                              borderRadius: 15,
                            }}
                            onBlur={onBlur}
                            onChangeText={onChange}
                            value={value}
                          />
                        </View>
                      )}
                    />
                    {errors[v.key] && <ColoredText style={{ color: colors.error }}>{`Field required`}</ColoredText>}
                  </View>
                )
              })}
        </View>
        <Button style={styles.button} onPress={handleSubmit(onSubmit)}>
          <>{t('dashboard.widgets.create.next')}</>
        </Button>
      </View>
    </>
  )
}

export default WidgetConfig
