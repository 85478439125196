import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { SelectorStateType } from '../types/firestore'

const useDocument = <T>(collection: string, id?: string) => {
  useFirestoreConnect({
    collection: collection,
    doc: id,
  })

  return useSelector((state: SelectorStateType<T>) => {
    if (!state.firestore.data[collection]) {
      return undefined
    }

    if (!id || !state.firestore.data[collection][id]) {
      return null
    }

    return {
      ...state.firestore.data[collection][id],
      id: id,
    }
  })
}

export default useDocument
