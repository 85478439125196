import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const Visa = (props: SvgProps) => (
  <Svg fill="none" height={24} width={32} {...props}>
    <Path d="M28 0H4a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z" fill="#27337a" />
    <Path
      d="M16.31 10.918c-.011.836.72 1.302 1.272 1.58.566.284.757.467.755.721-.005.39-.452.561-.871.568-.73.012-1.156-.204-1.494-.367l-.263 1.272c.34.161.967.302 1.617.308 1.528 0 2.527-.778 2.533-1.985.006-1.532-2.053-1.616-2.039-2.301.005-.208.197-.43.618-.485.208-.029.782-.05 1.434.259l.255-1.23c-.35-.132-.8-.258-1.36-.258-1.438 0-2.45.789-2.458 1.918zm6.274-1.812a.663.663 0 0 0-.619.426l-2.182 5.378h1.526l.304-.867h1.865l.177.867H25l-1.174-5.804zm.213 1.568.44 2.18h-1.205zm-8.339-1.568-1.203 5.804h1.455l1.202-5.804zm-2.151 0-1.514 3.95-.613-3.359c-.072-.375-.355-.591-.67-.591H7.035L7 9.274c.508.114 1.085.298 1.435.494.214.12.275.225.345.51l1.16 4.632h1.538l2.356-5.804z"
      fill="#fff"
    />
  </Svg>
)

export default Visa
