import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import Logo from '../Logo'
import useStyles from '../Login/styles'
import icons from '../../constants/icons'
import Button from '../Button'
import { useFirebase } from 'react-redux-firebase'
import { Toast } from 'native-base'
import { useTranslation } from '../../hooks/useTranslation'
import LoadingScreen from '../LoadingScreen'
import { Navigate } from '../Router/Router'
import { FontAwesomeIcon } from '../FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../CustomText/ColoredText'
import { getTextColor } from '../../hooks/useColors'
import { Themes } from '../../types/dashbar/theme'

export const RecoverEmail = ({ oobCode }: { oobCode: string }) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const firebase = useFirebase()
  const [recover, setRecover] = useState<boolean>(true)
  const [recovered, setRecovered] = useState<boolean>(false)

  useEffect(() => {
    setRecover(true)
    let restoredEmail = ''
    firebase
      .auth()
      .checkActionCode(oobCode)
      .then((info) => {
        restoredEmail = info.data.email ?? `n/a`
        return firebase.auth().applyActionCode(oobCode)
      })
      .then(() => {
        setRecovered(true)
        firebase
          .auth()
          .sendPasswordResetEmail(restoredEmail)
          .catch(() => {
            Toast.show({ title: t('text.toast.pw_reset_mail') })
          })
      })
  }, [oobCode])

  if (recover) {
    return <LoadingScreen />
  }
  if (!recovered) {
    Toast.show({ title: t('text.toast.error') })
    return <Navigate to={'/login'} />
  }
  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <Logo />

        <View style={[styles.hairLine]} />
        <View style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ margin: 5 }}>
            <FontAwesomeIcon icon={[icons.faIconStyle, 'check-circle']} size={'lg'} />
          </View>
          <View style={[styles.container, {}]}>
            <ColoredText style={{ textAlign: 'center', fontSize: 25 }}>
              <>{t('text.emailRecovered.title')}</>
            </ColoredText>
            <ColoredText style={{ textAlign: 'center' }}>
              <>{t('text.emailRecovered.text')}</>
            </ColoredText>
          </View>
        </View>
        <View style={[styles.hairLine]} />
        <View style={[styles.container]}>
          <Button to={'/'} style={styles.homeButton}>
            <FontAwesomeIcon color={getTextColor(Themes.Dark)} icon={[icons.faIconStyle, 'home']} />
          </Button>
        </View>
      </View>
    </View>
  )
}
