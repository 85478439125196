import { useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { IDProp } from '../../types/firestore'
import useCollection from '../useCollection'
import useCollectionGroup from '../useCollectionGroup'
import { NotType } from '../../types/notification'

const useNotifications = () => {
  const { user } = useContext(SessionContext)
  const notificationList: Array<[string, NotType, string, 'user' | 'company' | 'shop']> = []
  const shopNotifications: Record<string, NotType & IDProp> =
    useCollectionGroup<NotType>(`notification`, ['company', '==', user?.company], ['createdAt', 'desc']) || {}

  const userNotifications: Record<string, NotType & IDProp> =
    useCollection<NotType>(`user/${user?.id}/notification`) || {}

  Object.entries(shopNotifications || {}).map((notification) => {
    notificationList.push([notification[1].id, notification[1], notification[1].shop || '', 'shop'])
  })
  Object.entries(userNotifications || {}).map((notification) => {
    notificationList.push([notification[0], notification[1], '', 'user'])
  })
  notificationList.sort((a, b) => {
    if (!b[1].receivedAt?.seconds || !a[1].receivedAt?.seconds) return 0
    return b[1].receivedAt.seconds - a[1].receivedAt.seconds
  })
  return notificationList
}

export default useNotifications
