import { functions } from '../../../../../../firebase'
import { AuthenticationResponse } from '../../../../../../../functions/src/widgets/wawi/registerApp'
import { RegistrationStatus, ApiError } from '../../../../../../../functions/src/widgets/wawi/fetchRegStatus'

export type AuthRequestStatus = 'sent' | 'successfull' | 'error' | 'notSent' | 'rejected'

type RegRequestReturnT = {
  authError: ApiError | null
  authResponse: { success: AuthenticationResponse; xChallengeCode: string } | undefined
  requestStatus: AuthRequestStatus
}
type FetchRegStatusPropsT = {
  wawiApiUrl: string
  wawiAuthResp: {
    success: AuthenticationResponse
    xChallengeCode: string
  } | null
}

type FetchStatusReturnT = {
  authRequestStatus: AuthRequestStatus
  authError: ApiError | undefined
  fetchStatusMsg: string
  wawiConnected: boolean
  response: { data: { success: RegistrationStatus } }
}

/**
 * sends request to the /authentication endpoint of an Wawi API-Server
 * to start the registration Process
 *
 * @param wawiApiUrl The URL of the users JTL-Wai API Server
 * @returns a status code and either the api response and the used xChallengeCode or in case of error the errror
 */
export const sendWawiRegRequest = async (wawiApiUrl: string): Promise<RegRequestReturnT> => {
  let wawiAuthError: ApiError | null = null
  let authRequestStatus: AuthRequestStatus = 'notSent'
  let wawiAuthResponse: { success: AuthenticationResponse; xChallengeCode: string } | undefined
  const sendRegistration = functions().httpsCallable('widgets-wawi-registerApp')
  await sendRegistration({ apiUrl: wawiApiUrl.trim() }).then((response) => {
    if (response.data.error !== undefined) {
      wawiAuthError = response.data.error
      authRequestStatus = 'error'
    } else {
      const XChallengeCode: string = response.data.XChallengeCode
      wawiAuthResponse = { success: response.data.success, xChallengeCode: XChallengeCode }
      authRequestStatus = 'sent'
    }
  })
  return { authError: wawiAuthError, authResponse: wawiAuthResponse, requestStatus: authRequestStatus }
}

/**
 * sendsRequest to the endpoint /authentication/{RegistrationRequestId} to get the status oft the current request
 *
 * @param wawiApiUrl The URL of the users JTL-Wai API Server
 * @param wawiAuthResp The response to the first registration request plus the X-Challenge Code
 * @param t the translation function
 * @returns a status code and a corresponding messagefor the user and
 * either the api response or in case of error the error
 */
export const fetchWawiRegStatus = async ({
  wawiApiUrl,
  wawiAuthResp,
}: FetchRegStatusPropsT): Promise<FetchStatusReturnT> => {
  let authRequestStatus: AuthRequestStatus
  let authError: ApiError | undefined
  let fetchStatusMsg: string = ''
  let wawiConnected: boolean = false
  const fetchStatus = functions().httpsCallable('widgets-wawi-fetchStatus')
  const response = await fetchStatus({
    apiUrl: wawiApiUrl.trim(),
    registrationRequestId: wawiAuthResp?.success.registrationRequestId,
    xChallengeCode: wawiAuthResp?.xChallengeCode,
  })
  if (response.data.error !== undefined) {
    authRequestStatus = 'error'
    authError = response.data
  } else {
    switch (response.data.success.requestStatusInfo.status) {
      // Pending
      case 0:
        authRequestStatus = 'sent'
        fetchStatusMsg = 'settings.web.connections.wawi.notYetAccepted'
        break
      // Rejected
      case 1:
        authRequestStatus = 'notSent'
        fetchStatusMsg = 'settings.web.connections.wawi.requestRejected'
        break
      //success
      case 2:
        wawiConnected = true
        authRequestStatus = 'notSent'
        break
      default:
        authRequestStatus = 'sent'
    }
  }
  return { authRequestStatus, authError, fetchStatusMsg, wawiConnected, response }
}
