import React from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import StatusView from './View'
import { KeyFiguresT } from '../../../../../types/widgetDataTypes/V3/types'
import Widget from '../../../../components/Widget'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { useNavigate } from '../../../../../components/Router/Router'
import StatusSkeleton from './StatusSkeleton'

const Status = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Widget<KeyFiguresT>
      title={t('shop.widget.status.title')}
      widget={widget}
      loadingSkeleton={<StatusSkeleton />}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, interval = 'D30' }) => ({
          body: {
            module: 'Analytics',
            endpoint: ['sales', 'visitors_count', 'orders_count', 'conversion'],
            params: {
              options: {
                details: false,
                interval,
                refresh: autoRefresh ? refreshInterval : undefined,
              },
            },
          },
          // ToDo widgets ?
          widget: 'sales, visitors_count, orders_count, conversion',
        }),
      ]}
      showMissingData
      hasInterval
      onClickWidget={() => {
        navigate(`/analytics/live-tracker/${widget.settings.shop}`)
      }}
      {...props}
    >
      {([, data, , shop, size]) => {
        // console.log(data)
        return data && <StatusView status={data} keyFigures={data} shop={shop} size={size} />
      }}
    </Widget>
  )
}

export default Status
