const URL_TERMES_OF_USE = 'https://dash.bar/agb/'
const URL_TERMES_OF_USE_APPLE = 'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'
const URL_PRIVACY_POLICY = 'https://dash.bar/datenschutzerklaerung/'
const URL_REFRESH_MADATE_HELPDESK = 'https://helpdesk.webstollen.de/a/solutions/articles/44002335360'
const URL_JTL_WAWI_API_HELPDESK =
  'https://helpdesk.webstollen.de/support/solutions/articles/44002523616-verbinden-von-dash-bar-und-der-jtl-wawi-api'

export default {
  URL_TERMES_OF_USE,
  URL_TERMES_OF_USE_APPLE,
  URL_PRIVACY_POLICY,
  URL_REFRESH_MADATE_HELPDESK,
  URL_JTL_WAWI_API_HELPDESK,
}
