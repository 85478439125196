import { StyleSheet } from 'react-native'
import { useFontFamily } from '../../../hooks/useFontFamily'

const useStyles = () => {
  const { light } = useFontFamily()

  return StyleSheet.create({
    text: {
      fontFamily: light,
    },
  })
}

export default useStyles
