import { IconName } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import { FontAwesomeIcon } from '../../../components/FontAwesome/ColoredFontAwesomeIcon'
import colors from '../../../constants/colors'

const DefaultDashbarIcon = () => (
  <FontAwesomeIcon viewBox={'17,17,65,65'} icon={['fad', 'dashbar' as IconName]} color={colors.color3} />
)

export default DefaultDashbarIcon
