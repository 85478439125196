import React, { ReactNode } from 'react'
import { Navigate, useLocation } from '../../Router/Router'
import useAuth from '../../../hooks/useAuth'
import LoadingScreen from '../../LoadingScreen'
import { useTranslation } from '../../../hooks/useTranslation'

const RequireAuth = ({ children }: { children: ((props: any) => ReactNode) | ReactNode }): JSX.Element => {
  const { t } = useTranslation()

  const currentLocation = useLocation()

  const auth = useAuth()

  if (!auth || !auth.isLoaded) {
    return <LoadingScreen text={`${t('loading.auth')}`} />
  }

  if (!auth.isAuthed) {
    return <Navigate to={'/login'} state={{ referrer: currentLocation }} />
  }

  return <>{children}</>
}

export default RequireAuth

// function useTranslation(): { t: any } {
//   throw new Error('Function not implemented.')
// }
