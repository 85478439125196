import React, { ReactNode, useCallback, useState } from 'react'
import WidgetView, { PropsT as BaseProps } from '../../../components/Widget'
import { Modify } from '../../../types'
import useRemoveWidget from '../../../hooks/widget/useRemoveWidget'
import useDashboard from '../../../hooks/dashboard/useDashboard'
import Actions from '../../components/Actions'
import { isLoaded } from 'react-redux-firebase'
import { LayoutChangeEvent } from 'react-native'
import LoadingScreen from '../../../components/LoadingScreen'
import ErrorComponent from '../ErrorComponent'
import { IWidget, ShopMap } from '../../../types/dashboard'
import ConfigComponent from '../ConfigComponent'

interface Size {
  height: number
  width: number
}

type ChildrenParams = [Size]

interface WidgetProps {
  widget: IWidget
  dashboardId: string
  children: (args: ChildrenParams) => React.ReactNode
  shops: ShopMap
  loading?: boolean
  hasInterval?: boolean
  isBasic?: boolean
  showMissingData?: boolean
  loadingSkeleton?: ReactNode | null
  customOptions?: ReactNode | null
  isError?: boolean
  error?: { text: string; link: string | null } | null
  needsConfig?: boolean
}

const Widget = ({
  widget,
  dashboardId,
  children,
  shops,
  loading,
  loadingSkeleton,
  customOptions,
  isBasic = false,
  isError,
  error,
  needsConfig,
  ...props
}: Modify<BaseProps, WidgetProps>) => {
  const removeWidget = useRemoveWidget(dashboardId)
  const dashboard = useDashboard(dashboardId)
  const widgets = dashboard?.widgets
  const [size, setSize] = useState({ height: 0, width: 0 })

  const {
    settings: { shop },
  } = widget
  const widgetShop = shops[shop]

  const onWidgetLayout = useCallback((evt: LayoutChangeEvent) => {
    const { height, width } = evt.nativeEvent.layout
    setSize({ height, width })
  }, [])

  if (!isLoaded(widgets)) {
    return <LoadingScreen />
  }

  if (needsConfig) {
    return <ConfigComponent shop={widgetShop} title={props.title} image={props.image} />
  }

  if (isError && error && error !== null) {
    return <ErrorComponent error={error} shop={widgetShop} title={props.title} image={props.image} />
  }

  return (
    <WidgetView
      options={[
        customOptions && customOptions !== null && customOptions,
        <Actions
          key={'actions'}
          widget={widget}
          widgets={widgets}
          onRemoveWidget={() => {
            //Basic Widgets cannot be removed
            if (isBasic === true) {
              open()
            } else {
              removeWidget(widget, widgets)
            }
          }}
        />,
      ]}
      showOptions={true}
      key={widget.id}
      image={{
        source: widgetShop?.icon,
        alt: widgetShop?.domain,
      }}
      onWidgetLayout={onWidgetLayout}
      {...props}
    >
      {loading !== undefined && loading === true && loadingSkeleton && loadingSkeleton !== null
        ? loadingSkeleton
        : children([size])}
    </WidgetView>
  )
}

export default Widget
