import * as React from 'react'
import Svg, { SvgProps, Mask, Path, G } from 'react-native-svg'

const Directdebit = (props: SvgProps) => (
  <Svg fill="none" height={24} width={32} {...props}>
    <Mask id="a" height={7} width={6} x={6} y={9}>
      <Path d="M0 0v5.347h4.455V0z" fill="#fff" transform="translate(6.71 9.745)" />
    </Mask>
    <Mask id="b" height={7} width={6} x={16} y={9}>
      <Path d="M4.474 0H0v5.376h4.474z" fill="#fff" transform="translate(16.772 9.68)" />
    </Mask>
    <Mask id="c" height={7} width={7} x={20} y={9}>
      <Path d="M0 5.376h5.339V0H0z" fill="#fff" transform="translate(20.681 9.68)" />
    </Mask>
    <Path
      d="M4.129.5h23.742c2 0 3.629 1.64 3.629 3.674v15.652c0 2.034-1.63 3.674-3.629 3.674H4.129c-2 0-3.629-1.64-3.629-3.674V4.174C.5 2.14 2.13.5 4.129.5z"
      fill="#fff"
      stroke="#e6e6e6"
    />
    <G mask="url(#a)">
      <Path
        clipRule="evenodd"
        d="M11.005 11.432H9.682c0-.221-.026-.37-.076-.445-.08-.112-.296-.167-.652-.167-.345 0-.574.032-.686.096-.111.065-.167.205-.167.421 0 .196.05.325.148.387a.61.61 0 0 0 .282.077l.267.019c.572.039.929.066 1.071.081.452.047.78.169.983.367a.98.98 0 0 1 .286.607c.018.15.027.318.027.506 0 .433-.04.75-.122.95-.147.369-.478.602-.993.7-.215.04-.545.062-.989.062-.74 0-1.257-.045-1.548-.135-.358-.111-.594-.335-.708-.672-.064-.187-.095-.5-.095-.937h1.322v.111c0 .234.066.384.198.45.091.047.201.071.33.074h.487c.248 0 .407-.013.475-.039a.39.39 0 0 0 .24-.235.914.914 0 0 0 .03-.259c0-.237-.085-.38-.255-.432-.063-.02-.358-.05-.884-.085-.423-.03-.717-.06-.881-.09-.433-.081-.722-.251-.866-.508-.127-.219-.19-.55-.19-.992 0-.337.034-.608.103-.811a.886.886 0 0 1 .33-.463c.224-.162.509-.258.856-.286.29-.026.61-.039.962-.039.556 0 .951.033 1.187.097.576.158.863.598.863 1.323 0 .06-.004.148-.012.267z"
        fill="#0054a5"
        fillRule="evenodd"
      />
    </G>
    <G mask="url(#b)">
      <Path
        clipRule="evenodd"
        d="M18.193 12.42h.887c.28-.01.452-.032.513-.066.084-.047.14-.141.169-.282.018-.094.027-.234.027-.42 0-.227-.018-.395-.054-.505-.051-.155-.174-.249-.368-.283a2.827 2.827 0 0 0-.275-.008h-.899zm-1.421 2.636V9.68h2.648c.364 0 .641.03.831.09.436.14.728.424.878.854.077.225.116.565.116 1.018 0 .546-.043.938-.128 1.176-.17.472-.52.744-1.047.814-.062.01-.327.02-.793.028l-.236.008h-.848v1.388z"
        fill="#0054a5"
        fillRule="evenodd"
      />
    </G>
    <G mask="url(#c)">
      <Path
        clipRule="evenodd"
        d="m24.026 13.08-.666-2.33-.647 2.33zm.282 1.047h-1.9l-.256.929h-1.471l1.587-5.376h2.145l1.607 5.376h-1.44z"
        fill="#0054a5"
        fillRule="evenodd"
      />
    </G>
    <Path
      clipRule="evenodd"
      d="M14.868 10.36c.586 0 1.11.276 1.454.706l.306-.669a2.662 2.662 0 0 0-1.85-.745c-1.121 0-2.08.689-2.473 1.663h-.597l-.353.77h.769a2.67 2.67 0 0 0 .003.477h-.386l-.353.77h.936a2.665 2.665 0 0 0 2.453 1.617c.556 0 1.072-.169 1.499-.458v-.944c-.344.398-.847.65-1.408.65a1.865 1.865 0 0 1-1.567-.864h1.959l.353-.77h-2.6a1.983 1.983 0 0 1-.012-.478h2.83l.353-.77h-2.938c.325-.57.93-.954 1.622-.954z"
      fill="#fec10e"
      fillRule="evenodd"
    />
  </Svg>
)

export default Directdebit
