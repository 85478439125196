import React, { useContext } from 'react'
import { IBundle } from '../../../../types/booking'
import { BundleT } from '../../../../types/plans'
import { UpsellingContext } from '../../../../context/UpsellingContext'
import { View } from 'react-native'
import sizes from '../../../../constants/sizes'
import ColoredText from '../../../CustomText/ColoredText'
import Pro from '../../../../assets/imgs/subscription/Pro'
import Business from '../../../../assets/imgs/subscription/Business'
import UpsellingCardRow from '../UpsellingCardRow'
import { dashboardsForBundle, notificationsForBundle, shopsForBundle, userForBundle } from '../../../../utils/booking'
import Button from '../../../Button'
import { useNavigate } from '../../../Router/Router'
import { getCardBackground } from '../../helper'
import { useSecondaryBackground, useTextColor } from '../../../../hooks/useColors'
import { useTranslation } from '../../../../hooks/useTranslation'

const Card = ({ bundle }: { bundle: BundleT }) => {
  const [yearlyPaymentInterval, setYearlyPaymentInterval] = React.useState(true)
  const bundlePrice = yearlyPaymentInterval ? bundle?.price?.year.value + ' €' : bundle?.price?.month.value + ' €'
  const paymentInterval = yearlyPaymentInterval ? 'yearly ' : 'monthly '
  const oldPrice = bundle.type === IBundle.business.toUpperCase() ? '108,00 €' : '348,00€'
  const { close } = useContext(UpsellingContext)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const secondaryBackground = useSecondaryBackground()
  const textColor = useTextColor()

  return (
    <>
      <View
        style={{
          borderRadius: 16,
          height: 400,
          width: 300,
          overflow: 'hidden',
          backgroundColor: secondaryBackground,
          marginTop: 10,
        }}
      >
        {/* Header  */}
        <View
          style={{
            height: '25%',
            backgroundColor: getCardBackground(bundle.type),
            padding: sizes.paddingHorizontal,
          }}
        >
          <ColoredText style={{ color: 'white', fontSize: 30 }}>{bundle.name}</ColoredText>
          {bundle.name === 'Pro' && (
            <Pro
              style={{ position: 'absolute', bottom: 10, right: 10 }}
              width={110}
              height={60}
              viewBox="0 0 190 100"
            />
          )}
          {bundle.name === 'Business' && (
            <Business
              style={{ position: 'absolute', bottom: 10, right: 10 }}
              width={110}
              height={60}
              viewBox="0 0 190 100"
            />
          )}
        </View>

        <View style={{ padding: sizes.paddingHorizontal, width: '100%', height: '50%' }}>
          <UpsellingCardRow item={userForBundle(bundle.type.toLowerCase())} />
          <UpsellingCardRow item={shopsForBundle(bundle.type.toLowerCase())} />
          <UpsellingCardRow item={dashboardsForBundle(bundle.type.toLowerCase())} />
          <UpsellingCardRow item={notificationsForBundle(bundle.type.toLowerCase())} />
        </View>
        <View
          style={{
            width: '100%',
            height: '25%',

            paddingHorizontal: sizes.paddingHorizontal,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <ColoredText style={{ position: 'absolute', left: 0 }}>{paymentInterval}: </ColoredText>
            {yearlyPaymentInterval && (
              <ColoredText
                style={{
                  fontWeight: 'bold',
                  textDecorationLine: 'line-through',
                  textDecorationColor: textColor,
                  marginRight: 10,
                }}
              >
                {oldPrice}
              </ColoredText>
            )}
            <ColoredText
              style={{
                fontSize: 25,
              }}
            >
              {bundlePrice}
            </ColoredText>
          </View>
          <Button
            primary={true}
            onPress={() => {
              close()
              const paymentPath =
                `settings/subscription/checkout/${bundle.type}` + (yearlyPaymentInterval ? `/year` : `/month`)
              navigate(paymentPath)
            }}
          >
            {t('shop.upselling.body.pay_now')}
          </Button>
          <Button
            style={{ marginTop: 0 }}
            textStyle={{ textDecorationLine: 'underline', textDecorationColor: textColor }}
            onPress={() => setYearlyPaymentInterval(!yearlyPaymentInterval)}
          >
            {t('shop.upselling.body.change_interval', {
              interval:
                yearlyPaymentInterval === true ? t('shop.upselling.body.monthly') : t('shop.upselling.body.yearly'),
            })}
          </Button>
        </View>
      </View>
    </>
  )
}

export default Card
