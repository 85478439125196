import React from 'react'
import { useTranslation } from 'react-i18next'
import { IWidgetProps } from '../../../../../types/props/Widget'
import { GenderT } from '../../../../../types/widgetDataTypes/types'
import colors from '../../../../../constants/colors'
import { View } from 'react-native'
import { VictoryPie } from 'victory'
import Widget from '../../../../components/Widget'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import GenderSkeleton from './GenderSkeleton'

const Gender = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  return (
    <Widget<GenderT>
      title={t('shop.widget.gender.title')}
      widget={widget}
      loadingSkeleton={<GenderSkeleton />}
      getApiConfig={[
        // TODO: replace with gender query when available in dashbar plugin
        ({ autoRefresh, refreshInterval, interval = 'D30' }) => ({
          body: {
            request: 'Analytics',
            widget: {
              gender: {
                info: ['gender'],
                options: {
                  active: true,
                  details: false,
                  interval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                },
              },
            },
          },
          widget: 'gender',
        }),
      ]}
      showMissingData
      hasInterval
      onClickWidget={() => {
        //alert('TODO: implement') /*"TODO: implement"*/
        // history.push(`/shop/${widget.settings.shop}/live-tracker`) // TODO: add correct link
      }}
      {...props}
    >
      {([, data, , , size]) => {
        const pieData = [
          {
            name: t('shop.widget.gender.male'),
            color: colors.color1,
            value: (data && +data?.male) || 0, // TODO: Update pieDate with date from shop
            svg: {
              fill: colors.color1,
              // onPress: (event) => {
              //   event.preventDefault()
              //   // Code...
              // },
            },
            key: 2,
          },
          {
            value: (data && +data?.female) || 0, // TODO: Update pieDate with date from shop
            name: t('shop.widget.gender.female'),
            color: colors.color3,
            svg: {
              fill: colors.color3,
              // onPress: (event) => {
              //   event.preventDefault()
              //   // Code...
              // },
            },
            key: 1,
          },
        ]

        return (
          <View style={{ height: size.height, width: size.width, flexDirection: 'column' }}>
            <VictoryPie
              width={size.width + 50}
              height={size.height}
              radius={size.height * 0.45}
              innerRadius={size.height * 0.35}
              labels={[...pieData.map((data) => data.value.toLocaleString())]}
              colorScale={pieData.map((data) => data.color)}
              data={pieData.map((data) => data.value)}
            />
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexBasis: 16 }}>
              {pieData.map((obj, index) => (
                <View key={index} style={{ flexDirection: 'row' }}>
                  <View
                    style={{ backgroundColor: obj.color, height: 12, width: 12, borderRadius: 2, marginRight: 5 }}
                  />
                  <ColoredText key={index} style={{ fontSize: 12 }}>
                    {`${obj.name} `}
                  </ColoredText>
                </View>
              ))}
            </View>
          </View>
        )
      }}
    </Widget>
  )
}

export default Gender
