import React, { useContext, useEffect, useState } from 'react'
import { functions } from '../../../../../firebase'
import { SessionContext } from '../../../../../context/SessionContext'
import { View } from 'react-native'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import MandateChange from './MandateChange'
import useStyles from './styles'
import MandateSkeleton from './MandateSkeleton'
import MandateCard from './MandateCard'

const PaymentMethod = () => {
  const { company } = useContext(SessionContext)
  const styles = useStyles()
  const [mandateData, setMandateData] = useState<Record<string, any> | null | undefined>(undefined)
  const getMandate = functions().httpsCallable('calls-abocloud-getMandate')

  useEffect(() => {
    console.log(`Loading payment`)
    getMandate({ companyId: company?.id })
      .then((data) => {
        setMandateData(data.data)
        // Alert.alert(`Rsult`, JSON.stringify(data))
      })
      .catch(() => {
        setMandateData(null)
      })
  }, [company])
  return (
    <View style={styles.mainWrapper}>
      <View style={styles.mandateWrapper}>
        {mandateData === undefined ? (
          <MandateSkeleton />
        ) : (
          <>{mandateData !== null ? <MandateCard data={mandateData} /> : <ColoredText>No mandate</ColoredText>}</>
        )}
      </View>
      <View style={styles.mandateChangeWrapper}>
        <MandateChange />
      </View>
    </View>
  )
}

export default PaymentMethod
