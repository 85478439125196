import { StyleSheet } from 'react-native'
import { useTheme } from '../../../../utils/types/theme'
import { Themes } from '../../../../types/dashbar/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    dropdown: {
      borderRadius: 15,
      padding: 5,
      minHeight: 40,
      height: 40,
      borderColor: theme === Themes.Dark ? '#EEE' : '#111',
    },
  })
}

export default useStyles
