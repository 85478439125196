import React, { ReactNode } from 'react'
import { TextProps } from 'react-native'
import useStyles from './styles'
import ColoredText from '../ColoredText'

/** @description
 * Migrated from @webstollen/shared-react-components
 */

const BoldText = ({ style, children, ...props }: TextProps & { children?: ReactNode }) => {
  const styles = useStyles()
  return (
    <ColoredText {...{ ...props, style: [styles.text, style] }} allowFontScaling={props.allowFontScaling === true}>
      {children}
    </ColoredText>
  )
}

export default BoldText
