import React, { ReactElement } from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import { Helmet } from 'react-helmet'
import useStyles from './styles'
import NotificationBellComponent from './NotificationButton/bellcomponent'
import icons from '../../constants/icons'
import ProfileMenu from './ProfileMenu/ProfileMenu'
import ThemeButton from './ThemeButton/ThemeButton'
import BundleComponent from './BundleComponent/BundleComponent'
import { useNavigate } from '../../components/Router/Router'
import { FontAwesomeIcon } from '../../components/FontAwesome/ColoredFontAwesomeIcon'
import BoldText from '../../components/CustomText/BoldText'

const HeaderWrapper = ({ title, additionalControls }: { title: string; additionalControls?: ReactElement }) => {
  const styles = useStyles()
  const navigate = useNavigate()

  return Platform.OS === 'web' ? (
    //Show FavoriteIcon in Header
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <View style={styles.additionalControls}>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          {additionalControls && additionalControls}
        </View>
      </View>
      <BundleComponent />
      <ThemeButton />
      <NotificationBellComponent />
      <ProfileMenu />
    </>
  ) : (
    <>
      {Platform.OS === 'ios' ? (
        // Should be changed with Upgrade to ReactRouter v6
        <TouchableOpacity style={styles.backButton} onPress={() => navigate(-1)}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'arrow-left']} />
        </TouchableOpacity>
      ) : (
        <View style={styles.backButton} />
      )}
      <View style={styles.titleWrapper}>
        <BoldText allowFontScaling={false} numberOfLines={1} style={styles.title}>
          {title}
        </BoldText>
      </View>
      <View style={styles.additionalControls}>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <>
            {additionalControls ? additionalControls : null}
            <NotificationBellComponent />
          </>
        </View>
      </View>
    </>
  )
}

export default HeaderWrapper
