import React, { useContext } from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import { SalesT } from '../../../../../types/widgetDataTypes/types'
import BarChart from '../../../../../components/VictoryCharts/Bar'
import Widget from '../../../../components/Widget'
import { useTranslation } from '../../../../../hooks/useTranslation'
import SalesSkeleton from './SalesSkeleton'
import { ISettings } from '../../../../../types/dashboard'
import { UpsellingContext } from '../../../../../context/UpsellingContext'

const BasicSales = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const { open } = useContext(UpsellingContext)

  return (
    <Widget<SalesT>
      title={'Basic ' + t('shop.widget.sales.title')}
      widget={widget}
      hasInterval
      isBasic
      loadingSkeleton={<SalesSkeleton />}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, interval = 'D30' }: ISettings) => ({
          body: {
            request: 'Analytics',
            widget: {
              sales: {
                options: {
                  active: true,
                  details: true,
                  interval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                },
              },
            },
          },
          widget: 'sales',
        }),
      ]}
      onClickWidget={() => open()}
      {...props}
    >
      {([, data, , , size]) => {
        return (
          <BarChart
            data={[data.current, ...data.history.map((val) => val)].reverse()}
            size={size}
            displayValues={false}
          />
        )
      }}
    </Widget>
  )
}

export default BasicSales
