import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal, ScrollView, View } from 'react-native'
import ColoredText from '../CustomText/ColoredText'
import useStyles from './styles'

const ModalPopUp = forwardRef(
  (
    {
      title,
      children,
      modalStyle,
    }: {
      title?: string
      children: React.ReactNode
      modalStyle?: any
    },
    ref
  ) => {
    const styles = useStyles()
    const [modalVisible, setModalVisible] = useState(false)
    useImperativeHandle(ref, () => ({
      setVisible(visible: boolean) {
        setModalVisible(visible)
      },
    }))
    return (
      <Modal
        animationType="fade"
        visible={modalVisible}
        transparent={true}
        onRequestClose={() => {
          setModalVisible(!modalVisible)
        }}
      >
        <View style={styles.modalWrapper}>
          <View style={styles.centeredView}>
            <ScrollView
              style={[styles.modal, modalStyle]}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
            >
              <>
                {title && (
                  <ColoredText style={styles.title} numberOfLines={1}>
                    {title}
                  </ColoredText>
                )}
                {children}
              </>
            </ScrollView>
          </View>
        </View>
      </Modal>
    )
  }
)
ModalPopUp.displayName = 'ModalPopUp'

export default ModalPopUp
