import React, { useState, useEffect, ReactNode } from 'react'
import { View } from 'react-native'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import useStyles from './styles'

type RowProps = {
  avatars?: Array<ReactNode>
  icon?: ReactNode
  width: string
  value: ReactNode
  label: ReactNode
  index: number
}

const LiveTrackerRow = ({ avatars = [], width, value, label, icon, index }: RowProps) => {
  const styles = useStyles()

  const [rowWidth, setRowWidth] = useState<number>()
  const [valueWidth, setValueWidth] = useState<number>()
  const [segmentWidth, setSegmentWidth] = useState<number>()

  useEffect(() => {
    if (rowWidth != null && valueWidth != null) {
      setSegmentWidth((rowWidth - valueWidth) / 2 - 10)
    }
  }, [rowWidth, valueWidth])

  return (
    <View
      style={[styles.row, { width, marginBottom: index === 3 ? 0 : 10 }]}
      onLayout={(evt) => {
        setRowWidth(evt.nativeEvent.layout.width)
      }}
    >
      <View
        style={[
          styles.avatars,
          {
            minWidth: avatars.length * 15 + 10,
            width: segmentWidth,
            height: '100%',
            alignItems: 'center',
          },
        ]}
      >
        {icon ? icon : avatars}
      </View>

      <View
        onLayout={(evt) => {
          setValueWidth(evt.nativeEvent.layout.width)
        }}
        style={[styles.valueWrapper]}
      >
        <ColoredText>{value}</ColoredText>
      </View>
      <View style={[styles.labelWrapper, { width: segmentWidth }]}>
        <ColoredText style={[styles.label]}>{label}</ColoredText>
      </View>
    </View>
  )
}

export default LiveTrackerRow
