import { Skeleton } from 'native-base'
import React from 'react'
import { View } from 'react-native'
import { usePrimaryBackground } from '../../../../../hooks/useColors'

const OrderSkeleton = () => {
  const loadingColor = usePrimaryBackground()
  return (
    <View
      style={{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
        padding: 5,
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <Skeleton style={{ borderRadius: 10, height: '24%', width: '100%' }} isLoaded={false} startColor={loadingColor} />
      <Skeleton
        style={{ borderRadius: 10, height: '24%', width: '100%', marginTop: 2 }}
        isLoaded={false}
        startColor={loadingColor}
      />
      <Skeleton
        style={{ borderRadius: 10, height: '24%', width: '100%', marginTop: 2 }}
        isLoaded={false}
        startColor={loadingColor}
      />
      <Skeleton
        style={{ borderRadius: 10, height: '24%', width: '100%', marginTop: 2 }}
        isLoaded={false}
        startColor={loadingColor}
      />
    </View>
  )
}

export default OrderSkeleton
