import * as React from 'react'
import Svg, { SvgProps, Defs, Path, G, Mask, Use } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */

const Pro = (props: SvgProps) => (
  <Svg width={202} height={104} {...props}>
    <Defs>
      <Path id="a" d="M0 103.967h201.85V0H0z" />
      <Path id="c" d="M0 103.967h201.85V0H0z" />
      <Path id="e" d="M0 103.967h201.85V0H0z" />
      <Path id="g" d="M0 103.967h201.85V0H0z" />
      <Path id="i" d="M0 103.967h201.85V0H0z" />
      <Path id="k" d="M0 103.967h201.85V0H0z" />
      <Path id="m" d="M0 103.967h201.85V0H0z" />
      <Path id="o" d="M0 103.967h201.85V0H0z" />
      <Path id="q" d="M0 103.967h201.85V0H0z" />
      <Path id="s" d="M0 103.967h201.85V0H0z" />
      <Path id="u" d="M0 103.967h201.85V0H0z" />
      <Path id="w" d="M0 103.967h201.85V0H0z" />
      <Path id="y" d="M0 103.967h201.85V0H0z" />
      <Path id="A" d="M0 103.967h201.85V0H0z" />
      <Path id="C" d="M0 103.967h201.85V0H0z" />
      <Path id="E" d="M0 103.967h201.85V0H0z" />
      <Path id="G" d="M0 103.967h201.85V0H0z" />
      <Path id="I" d="M0 103.967h201.85V0H0z" />
      <Path id="K" d="M0 103.967h201.85V0H0z" />
      <Path id="M" d="M0 103.967h201.85V0H0z" />
      <Path id="O" d="M0 103.967h201.85V0H0z" />
      <Path id="Q" d="M0 103.967h201.85V0H0z" />
      <Path id="S" d="M0 103.967h201.85V0H0z" />
      <Path id="U" d="M0 103.967h201.85V0H0z" />
      <Path id="W" d="M0 103.967h201.85V0H0z" />
      <Path id="Y" d="M0 103.967h201.85V0H0z" />
      <Path id="aa" d="M0 103.967h201.85V0H0z" />
      <Path id="ac" d="M0 103.967h201.85V0H0z" />
      <Path id="ae" d="M0 103.967h201.85V0H0z" />
      <Path id="ag" d="M0 103.967h201.85V0H0z" />
      <Path id="ai" d="M0 103.967h201.85V0H0z" />
      <Path id="ak" d="M0 103.967h201.85V0H0z" />
      <Path id="am" d="M0 103.967h201.85V0H0z" />
      <Path id="ao" d="M0 103.967h201.85V0H0z" />
      <Path id="aq" d="M0 103.967h201.85V0H0z" />
      <Path id="as" d="M0 103.967h201.85V0H0z" />
      <Path id="au" d="M0 103.967h201.85V0H0z" />
      <Path id="aw" d="M0 103.967h201.85V0H0z" />
      <Path id="ay" d="M0 103.967h201.85V0H0z" />
      <Path id="aA" d="M0 103.967h201.85V0H0z" />
      <Path id="aC" d="M0 103.967h201.85V0H0z" />
      <Path id="aE" d="M0 103.967h201.85V0H0z" />
      <Path id="aG" d="M0 103.967h201.85V0H0z" />
      <Path id="aI" d="M0 103.967h201.85V0H0z" />
      <Path id="aK" d="M0 103.967h201.85V0H0z" />
      <Path id="aM" d="M0 103.967h201.85V0H0z" />
      <Path id="aO" d="M0 103.967h201.85V0H0z" />
      <Path id="aQ" d="M0 103.967h201.85V0H0z" />
      <Path id="aS" d="M0 103.967h201.85V0H0z" />
      <Path id="aU" d="M0 103.967h201.85V0H0z" />
      <Path id="aW" d="M0 103.967h201.85V0H0z" />
      <Path id="aY" d="M0 103.967h201.85V0H0z" />
      <Path id="ba" d="M0 103.967h201.85V0H0z" />
      <Path id="bc" d="M0 103.967h201.85V0H0z" />
      <Path id="be" d="M0 103.967h201.85V0H0z" />
      <Path id="bg" d="M0 103.967h201.85V0H0z" />
      <Path id="bi" d="M0 103.967h201.85V0H0z" />
      <Path id="bk" d="M0 103.967h201.85V0H0z" />
      <Path id="bm" d="M0 103.967h201.85V0H0z" />
      <Path id="bo" d="M0 103.967h201.85V0H0z" />
      <Path id="bq" d="M0 103.967h201.85V0H0z" />
      <Path id="bs" d="M0 103.967h201.85V0H0z" />
      <Path id="bu" d="M0 103.967h201.85V0H0z" />
      <Path id="bw" d="M0 103.967h201.85V0H0z" />
      <Path id="by" d="M0 103.967h201.85V0H0z" />
      <Path id="bA" d="M0 103.967h201.85V0H0z" />
      <Path id="bC" d="M0 103.967h201.85V0H0z" />
      <Path id="bE" d="M0 103.967h201.85V0H0z" />
      <Path id="bG" d="M0 103.967h201.85V0H0z" />
      <Path id="bI" d="M0 103.967h201.85V0H0z" />
      <Path id="bK" d="M0 103.967h201.85V0H0z" />
      <Path id="bM" d="M0 103.967h201.85V0H0z" />
      <Path id="bO" d="M0 103.967h201.85V0H0z" />
      <Path id="bQ" d="M0 103.967h201.85V0H0z" />
      <Path id="bS" d="M0 103.967h201.85V0H0z" />
      <Path id="bU" d="M0 103.967h201.85V0H0z" />
      <Path id="bW" d="M0 103.967h201.85V0H0z" />
      <Path id="bY" d="M0 103.967h201.85V0H0z" />
      <Path id="ca" d="M0 103.967h201.85V0H0z" />
      <Path id="cc" d="M0 103.967h201.85V0H0z" />
      <Path id="ce" d="M0 103.967h201.85V0H0z" />
      <Path id="cg" d="M0 103.967h201.85V0H0z" />
      <Path id="ci" d="M0 103.967h201.85V0H0z" />
      <Path id="ck" d="M0 103.967h201.85V0H0z" />
      <Path id="cm" d="M0 103.967h201.85V0H0z" />
      <Path id="co" d="M0 103.967h201.85V0H0z" />
      <Path id="cq" d="M0 103.967h201.85V0H0z" />
      <Path id="cs" d="M0 103.967h201.85V0H0z" />
      <Path id="cu" d="M0 103.967h201.85V0H0z" />
      <Path id="cw" d="M0 103.967h201.85V0H0z" />
      <Path id="cy" d="M0 103.967h201.85V0H0z" />
      <Path id="cA" d="M0 103.967h201.85V0H0z" />
      <Path id="cC" d="M0 103.967h201.85V0H0z" />
      <Path id="cE" d="M0 103.967h201.85V0H0z" />
      <Path id="cG" d="M0 103.967h201.85V0H0z" />
      <Path id="cI" d="M0 103.967h201.85V0H0z" />
      <Path id="cK" d="M0 103.967h201.85V0H0z" />
      <Path id="cM" d="M0 103.967h201.85V0H0z" />
      <Path id="cO" d="M0 103.967h201.85V0H0z" />
      <Path id="cQ" d="M0 103.967h201.85V0H0z" />
      <Path id="cS" d="M0 103.967h201.85V0H0z" />
      <Path id="cU" d="M0 103.967h201.85V0H0z" />
      <Path id="cW" d="M0 103.967h201.85V0H0z" />
      <Path id="cY" d="M0 103.967h201.85V0H0z" />
      <Path id="da" d="M0 103.967h201.85V0H0z" />
      <Path id="dc" d="M0 103.967h201.85V0H0z" />
      <Path id="de" d="M0 103.967h201.85V0H0z" />
      <Path id="dg" d="M0 103.967h201.85V0H0z" />
      <Path id="di" d="M0 103.967h201.85V0H0z" />
      <Path id="dk" d="M0 103.967h201.85V0H0z" />
      <Path id="dm" d="M0 103.967h201.85V0H0z" />
      <Path id="do" d="M0 103.967h201.85V0H0z" />
      <Path id="dq" d="M0 103.967h201.85V0H0z" />
      <Path id="ds" d="M0 103.967h201.85V0H0z" />
      <Path id="du" d="M0 103.967h201.85V0H0z" />
      <Path id="dw" d="M0 103.967h201.85V0H0z" />
      <Path id="dy" d="M0 103.967h201.85V0H0z" />
      <Path id="dA" d="M0 103.967h201.85V0H0z" />
      <Path id="dC" d="M0 103.967h201.85V0H0z" />
      <Path id="dE" d="M0 103.967h201.85V0H0z" />
      <Path id="dG" d="M0 103.967h201.85V0H0z" />
      <Path id="dI" d="M0 103.967h201.85V0H0z" />
      <Path id="dK" d="M0 103.967h201.85V0H0z" />
    </Defs>
    <G fill="none" fillRule="evenodd">
      <Path
        d="M107.5 37.405H67.558a.307.307 0 0 1-.307-.306V10.113c0-.169.138-.306.307-.306h39.94c.168 0 .306.137.306.306v26.986a.307.307 0 0 1-.306.306"
        fill="#D5D5D5"
        fillRule="nonzero"
      />
      <G>
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <Path fill="#272030" fillRule="nonzero" mask="url(#b)" d="M67.69 35.82h39.678V10.19H67.69z" />
      </G>
      <G>
        <Mask id="d" fill="#fff">
          <Use xlinkHref="#c" />
        </Mask>
        <Path
          d="M96.661 45.672H78.396v-.288c0-.37.3-.67.671-.67h16.924c.37 0 .67.3.67.67v.288Z"
          fill="#D5D5D5"
          fillRule="nonzero"
          mask="url(#d)"
        />
      </G>
      <G>
        <Mask id="f" fill="#fff">
          <Use xlinkHref="#e" />
        </Mask>
        <Path
          d="M76.728 12.75H70.65a.415.415 0 0 1-.415-.415c0-.228.187-.414.415-.414h6.077c.227 0 .414.186.414.414a.416.416 0 0 1-.414.414"
          fill="#F5882D"
          fillRule="nonzero"
          mask="url(#f)"
        />
      </G>
      <G>
        <Mask id="h" fill="#fff">
          <Use xlinkHref="#g" />
        </Mask>
        <Path
          d="M76.728 14.188H70.65a.415.415 0 0 1-.415-.414c0-.228.187-.414.415-.414h6.077c.227 0 .414.186.414.414a.415.415 0 0 1-.414.414"
          fill="#FBF1CA"
          fillRule="nonzero"
          mask="url(#h)"
        />
      </G>
      <G>
        <Mask id="j" fill="#fff">
          <Use xlinkHref="#i" />
        </Mask>
        <Path
          d="M73.827 15.627h-3.176a.415.415 0 0 1 0-.828h3.176c.228 0 .415.186.415.414a.415.415 0 0 1-.415.414"
          fill="#1D4363"
          fillRule="nonzero"
          mask="url(#j)"
        />
      </G>
      <G>
        <Mask id="l" fill="#fff">
          <Use xlinkHref="#k" />
        </Mask>
        <Path
          d="M81.59 15.627h-6.22a.415.415 0 0 1 0-.828h6.22c.228 0 .415.186.415.414a.415.415 0 0 1-.414.414"
          fill="#BA3026"
          fillRule="nonzero"
          mask="url(#l)"
        />
      </G>
      <G>
        <Mask id="n" fill="#fff">
          <Use xlinkHref="#m" />
        </Mask>
        <Path
          d="M84.469 14.188h-6.077a.415.415 0 0 1-.414-.414c0-.228.186-.414.414-.414h6.077c.228 0 .414.186.414.414a.415.415 0 0 1-.414.414"
          fill="#1D4363"
          fillRule="nonzero"
          mask="url(#n)"
        />
      </G>
      <G>
        <Mask id="p" fill="#fff">
          <Use xlinkHref="#o" />
        </Mask>
        <Path
          d="M79.544 30.51h-6.077a.415.415 0 0 1-.415-.414c0-.227.187-.414.415-.414h6.077c.227 0 .414.187.414.414a.416.416 0 0 1-.414.415"
          fill="#FBF1CA"
          fillRule="nonzero"
          mask="url(#p)"
        />
      </G>
      <G>
        <Mask id="r" fill="#fff">
          <Use xlinkHref="#q" />
        </Mask>
        <Path
          d="M79.544 31.95h-6.077a.415.415 0 0 1-.415-.415c0-.227.187-.414.415-.414h6.077c.227 0 .414.187.414.414a.415.415 0 0 1-.414.415"
          fill="#1D4363"
          fillRule="nonzero"
          mask="url(#r)"
        />
      </G>
      <G>
        <Mask id="t" fill="#fff">
          <Use xlinkHref="#s" />
        </Mask>
        <Path
          d="M76.643 33.389h-3.176a.415.415 0 0 1 0-.829h3.176c.228 0 .415.187.415.414a.415.415 0 0 1-.415.415"
          fill="#1D4363"
          fillRule="nonzero"
          mask="url(#t)"
        />
      </G>
      <G>
        <Mask id="v" fill="#fff">
          <Use xlinkHref="#u" />
        </Mask>
        <Path
          d="M84.406 33.389h-6.22a.415.415 0 0 1-.414-.415c0-.227.186-.414.414-.414h6.22c.228 0 .415.187.415.414a.415.415 0 0 1-.415.415"
          fill="#FBF1CA"
          fillRule="nonzero"
          mask="url(#v)"
        />
      </G>
      <G>
        <Mask id="x" fill="#fff">
          <Use xlinkHref="#w" />
        </Mask>
        <Path
          d="M87.285 31.95h-6.077a.415.415 0 0 1-.414-.415c0-.227.186-.414.414-.414h6.077c.228 0 .414.187.414.414a.415.415 0 0 1-.414.415"
          fill="#F5882D"
          fillRule="nonzero"
          mask="url(#x)"
        />
      </G>
      <G>
        <Mask id="z" fill="#fff">
          <Use xlinkHref="#y" />
        </Mask>
        <Path
          d="M79.323 22.09h-6.077a.415.415 0 0 1-.415-.413c0-.228.187-.415.415-.415h6.077a.415.415 0 0 1 0 .829"
          fill="#FBF1CA"
          fillRule="nonzero"
          mask="url(#z)"
        />
      </G>
      <G>
        <Mask id="B" fill="#fff">
          <Use xlinkHref="#A" />
        </Mask>
        <Path
          d="M79.323 23.53h-6.077a.415.415 0 0 1-.415-.414c0-.228.187-.415.415-.415h6.077a.415.415 0 0 1 0 .829"
          fill="#F5882D"
          fillRule="nonzero"
          mask="url(#B)"
        />
      </G>
      <G>
        <Mask id="D" fill="#fff">
          <Use xlinkHref="#C" />
        </Mask>
        <Path
          d="M87.064 23.53h-6.077a.415.415 0 0 1-.414-.414c0-.228.186-.415.414-.415h6.077a.415.415 0 0 1 0 .829"
          fill="#FBF1CA"
          fillRule="nonzero"
          mask="url(#D)"
        />
      </G>
      <G>
        <Mask id="F" fill="#fff">
          <Use xlinkHref="#E" />
        </Mask>
        <Path
          d="M79.323 17.606h-6.077a.416.416 0 0 1-.415-.414c0-.228.187-.414.415-.414h6.077c.227 0 .414.186.414.414a.416.416 0 0 1-.414.414"
          fill="#FBF1CA"
          fillRule="nonzero"
          mask="url(#F)"
        />
      </G>
      <G>
        <Mask id="H" fill="#fff">
          <Use xlinkHref="#G" />
        </Mask>
        <Path
          d="M87.74 20.498h-9.703a.415.415 0 0 1-.414-.414c0-.228.186-.415.414-.415h9.703a.415.415 0 0 1 0 .829"
          fill="#1D4363"
          fillRule="nonzero"
          mask="url(#H)"
        />
      </G>
      <G>
        <Mask id="J" fill="#fff">
          <Use xlinkHref="#I" />
        </Mask>
        <Path
          d="M76.422 19.045h-3.176a.415.415 0 0 1-.415-.414c0-.228.187-.414.415-.414h3.176c.228 0 .414.186.414.414a.415.415 0 0 1-.414.414"
          fill="#1D4363"
          fillRule="nonzero"
          mask="url(#J)"
        />
      </G>
      <G>
        <Mask id="L" fill="#fff">
          <Use xlinkHref="#K" />
        </Mask>
        <Path
          d="M76.422 20.498h-3.176a.415.415 0 0 1-.415-.414c0-.228.187-.415.415-.415h3.176a.415.415 0 0 1 0 .829"
          fill="#BA3026"
          fillRule="nonzero"
          mask="url(#L)"
        />
      </G>
      <G>
        <Mask id="N" fill="#fff">
          <Use xlinkHref="#M" />
        </Mask>
        <Path
          d="M76.599 27.258h-6.077a.416.416 0 0 1-.415-.414c0-.228.187-.414.415-.414h6.077c.228 0 .414.186.414.414a.415.415 0 0 1-.414.414"
          fill="#F5882D"
          fillRule="nonzero"
          mask="url(#N)"
        />
      </G>
      <G>
        <Mask id="P" fill="#fff">
          <Use xlinkHref="#O" />
        </Mask>
        <Path
          d="M79.415 28.697h-6.077a.415.415 0 0 1-.415-.414c0-.228.187-.414.415-.414h6.077c.228 0 .414.186.414.414a.415.415 0 0 1-.414.414"
          fill="#F5882D"
          fillRule="nonzero"
          mask="url(#P)"
        />
      </G>
      <G>
        <Mask id="R" fill="#fff">
          <Use xlinkHref="#Q" />
        </Mask>
        <Path
          d="M87.156 28.697h-6.077a.416.416 0 0 1-.414-.414c0-.228.186-.414.414-.414h6.077c.228 0 .414.186.414.414a.416.416 0 0 1-.414.414"
          fill="#BA3026"
          fillRule="nonzero"
          mask="url(#R)"
        />
      </G>
      <G>
        <Mask id="T" fill="#fff">
          <Use xlinkHref="#S" />
        </Mask>
        <Path
          d="M85.016 25.665h-9.703a.415.415 0 0 1-.414-.414c0-.228.186-.414.414-.414h9.703c.228 0 .414.186.414.414a.415.415 0 0 1-.414.414"
          fill="#BA3026"
          fillRule="nonzero"
          mask="url(#T)"
        />
      </G>
      <G>
        <Mask id="V" fill="#fff">
          <Use xlinkHref="#U" />
        </Mask>
        <Path
          d="M73.698 25.665h-3.176a.415.415 0 0 1 0-.828h3.176c.228 0 .415.186.415.414a.415.415 0 0 1-.415.414"
          fill="#FBF1CA"
          fillRule="nonzero"
          mask="url(#V)"
        />
      </G>
      <G>
        <Mask id="X" fill="#fff">
          <Use xlinkHref="#W" />
        </Mask>
        <Path
          d="M89.086 19.045h-3.177a.415.415 0 0 1-.414-.414c0-.228.186-.414.414-.414h3.177c.228 0 .414.186.414.414a.415.415 0 0 1-.414.414"
          fill="#FBF1CA"
          fillRule="nonzero"
          mask="url(#X)"
        />
      </G>
      <G>
        <Mask id="Z" fill="#fff">
          <Use xlinkHref="#Y" />
        </Mask>
        <Path
          d="M84.186 19.045h-6.22a.415.415 0 0 1-.415-.414c0-.228.186-.414.414-.414h6.22c.228 0 .415.186.415.414a.415.415 0 0 1-.414.414"
          fill="#F5882D"
          fillRule="nonzero"
          mask="url(#Z)"
        />
      </G>
      <G>
        <Mask id="ab" fill="#fff">
          <Use xlinkHref="#aa" />
        </Mask>
        <Path
          d="M83.611 17.606h-2.624a.416.416 0 0 1-.414-.414c0-.228.186-.414.414-.414h2.624c.228 0 .415.186.415.414a.415.415 0 0 1-.415.414"
          fill="#F5882D"
          fillRule="nonzero"
          mask="url(#ab)"
        />
      </G>
      <G>
        <Mask id="ad" fill="#fff">
          <Use xlinkHref="#ac" />
        </Mask>
        <Path
          d="M161.404 45.58h-15.272a.54.54 0 0 1-.539-.539.54.54 0 0 1 .539-.538h15.272a.54.54 0 0 1 .54.538.54.54 0 0 1-.54.54"
          fill="#D6D6D5"
          fillRule="nonzero"
          mask="url(#ad)"
        />
      </G>
      <G>
        <Mask id="af" fill="#fff">
          <Use xlinkHref="#ae" />
        </Mask>
        <Path
          d="m145.744 43.671-3.344-17.8a.702.702 0 0 0-.69-.572h-11.55a.702.702 0 0 0-.69.83l3.3 17.8a.702.702 0 0 0 .69.574h11.594c.44 0 .771-.4.69-.832"
          fill="#D6D6D5"
          fillRule="nonzero"
          mask="url(#af)"
        />
      </G>
      <G>
        <Mask id="ah" fill="#fff">
          <Use xlinkHref="#ag" />
        </Mask>
        <Path
          d="m145.437 43.671-3.344-17.8a.702.702 0 0 0-.69-.572h-11.55a.702.702 0 0 0-.69.83l3.3 17.8a.702.702 0 0 0 .69.574h11.594c.44 0 .771-.4.69-.832"
          fill="#E8E7E7"
          fillRule="nonzero"
          mask="url(#ah)"
        />
      </G>
      <G>
        <Mask id="aj" fill="#fff">
          <Use xlinkHref="#ai" />
        </Mask>
        <Path
          d="M145.694 45.58h-12.633a.493.493 0 0 1-.492-.491v-.095c0-.27.221-.491.492-.491h12.633c.27 0 .492.221.492.491v.095c0 .27-.221.491-.492.491"
          fill="#E8E7E7"
          fillRule="nonzero"
          mask="url(#aj)"
        />
      </G>
      <G>
        <Mask id="al" fill="#fff">
          <Use xlinkHref="#ak" />
        </Mask>
        <Path
          d="M148.366 45.277h-1.35a.251.251 0 0 1 0-.502h1.35c.138 0 .25.113.25.251s-.112.251-.25.251"
          fill="#B6B7B7"
          fillRule="nonzero"
          mask="url(#al)"
        />
      </G>
      <G>
        <Mask id="an" fill="#fff">
          <Use xlinkHref="#am" />
        </Mask>
        <Path
          d="M150.665 45.277h-1.35a.251.251 0 0 1 0-.502h1.35c.138 0 .251.113.251.251s-.113.251-.25.251"
          fill="#B6B7B7"
          fillRule="nonzero"
          mask="url(#an)"
        />
      </G>
      <G>
        <Mask id="ap" fill="#fff">
          <Use xlinkHref="#ao" />
        </Mask>
        <Path
          d="M141.001 79.16s-3.118 3.904-6.818 3.466c0 0-.38 2.185 2.097 2.623 2.477.437 5.974 0 5.974 0l3.293-4.662-4.546-1.427Z"
          fill="#E7D5C1"
          fillRule="nonzero"
          mask="url(#ap)"
        />
      </G>
      <G>
        <Mask id="ar" fill="#fff">
          <Use xlinkHref="#aq" />
        </Mask>
        <Path
          d="M134.182 82.627s-6.206-.263-7.46-.933c0 0-1.223-.117.38 1.69 1.602 1.806 7.071 5.011 12.394 4.166l-.476 2.477.834.407 2.494-5.318s-4.378 1.269-8.166-2.49"
          fill="#901A1D"
          fillRule="nonzero"
          mask="url(#ar)"
        />
      </G>
      <G>
        <Mask id="at" fill="#fff">
          <Use xlinkHref="#as" />
        </Mask>
        <Path
          d="M167.867 8.445s-2.076-4.438 3.26-5.485c5.335-1.046 7.928 2.792 8.726 5.784 0 0 6.433-1.695 7.08 6.232 0 0 12.617 4.736 1.148 15.804l-8.876-2.66-4.937-15.487-4.787-3.34-1.614-.848Z"
          fill="#A76730"
          fillRule="nonzero"
          mask="url(#at)"
        />
      </G>
      <G>
        <Mask id="av" fill="#fff">
          <Use xlinkHref="#au" />
        </Mask>
        <Path
          d="M168.019 5.66s-1.13 6.936 2.711 11.981c0 0 2.787-.903 3.54 0 .377.452 1.028 1.742 1.48 2.871 0 0-.87.368-.858.473.088.788.095.721.095.721l6.514-1.325s-3.088-5.676-3.465-9.442c-.377-3.765-1.506-7.003-4.896-6.514-3.389.49-5.121 1.235-5.121 1.235"
          fill="#E7D5C1"
          fillRule="nonzero"
          mask="url(#av)"
        />
      </G>
      <G>
        <Mask id="ax" fill="#fff">
          <Use xlinkHref="#aw" />
        </Mask>
        <Path
          d="M172.704 7.293s5.415 4.529 7.254 9.092l2.248-3.848-4.75-5.04-4.411-2.281-.341 2.077Z"
          fill="#A76730"
          fillRule="nonzero"
          mask="url(#ax)"
        />
      </G>
      <G>
        <Mask id="az" fill="#fff">
          <Use xlinkHref="#ay" />
        </Mask>
        <Path
          d="M168.019 5.66s.565 6.312 10.787 7.834l-.401-6.612-2.726-2.605-2.975-.601-3.098.521-1.587 1.463Z"
          fill="#A76730"
          fillRule="nonzero"
          mask="url(#az)"
        />
      </G>
      <G>
        <Mask id="aB" fill="#fff">
          <Use xlinkHref="#aA" />
        </Mask>
        <Path
          d="M192.376 76.212h-13.555v-1.859h13.537c.379-.01 7.62-.318 7.62-8.86 0-4.477-1.39-6.218-2.862-8.062-1.626-2.038-3.307-4.145-3.307-9.4h1.86c0 4.605 1.409 6.37 2.9 8.24 1.606 2.013 3.267 4.094 3.267 9.221 0 5.425-2.573 8.06-4.731 9.316-2.318 1.348-4.624 1.402-4.721 1.404h-.008Z"
          fill="#333"
          fillRule="nonzero"
          mask="url(#aB)"
        />
      </G>
      <G>
        <Mask id="aD" fill="#fff">
          <Use xlinkHref="#aC" />
        </Mask>
        <Path
          d="M166.091 84.655s4.5 9.05 2.571 10.707c-2.02 1.736-5.585.484-5.585.484s.915-3.497-1.291-10.223l4.305-.968Z"
          fill="#E7D5C1"
          fillRule="nonzero"
          mask="url(#aD)"
        />
      </G>
      <G>
        <Mask id="aF" fill="#fff">
          <Use xlinkHref="#aE" />
        </Mask>
        <Path
          d="m168.662 52.618-15.968 11.939s8.352 20.435 8.499 24.318c0 0 4.174-.294 6.372-1.978l-5.86-18.458 22.346-3.47-.807-9.202-4.835-3.735-9.747.586Z"
          fill="#913E1F"
          fillRule="nonzero"
          mask="url(#aF)"
        />
      </G>
      <G>
        <Mask id="aH" fill="#fff">
          <Use xlinkHref="#aG" />
        </Mask>
        <Path
          d="M157.27 42.75s-.787 1.267-3.914.862c0 0-2.755.347-3.633 1.21 0 0-.26-.01.364-.818.624-.807 2.57-3.59 6.145-2.864l1.545.572-.508 1.039Z"
          fill="#E7D5C1"
          fillRule="nonzero"
          mask="url(#aH)"
        />
      </G>
      <G>
        <Mask id="aJ" fill="#fff">
          <Use xlinkHref="#aI" />
        </Mask>
        <Path
          d="M156.376 41.193s.928.688.785 1.728c0 0 9.665 2.33 10.64 2.176.973-.153 6.437-6.352 6.437-6.352l-4.368-3.358s-1.526 6.059-2.937 6.335c-1.412.276-10.557-.529-10.557-.529"
          fill="#901A1D"
          fillRule="nonzero"
          mask="url(#aJ)"
        />
      </G>
      <G>
        <Mask id="aL" fill="#fff">
          <Use xlinkHref="#aK" />
        </Mask>
        <Path
          d="M175.75 20.512s-.34 2.005 5.315-.767l4.209 6.75s-1.347 15.532-.748 18.61c.598 3.077 2.094 9.11 2.094 9.11s-15.408 1.844-17.952-1.047l2.194-12.225-.299-11.756 1.746-5.334 3.44-3.34Z"
          fill="#194966"
          fillRule="nonzero"
          mask="url(#aL)"
        />
      </G>
      <G>
        <Mask id="aN" fill="#fff">
          <Use xlinkHref="#aM" />
        </Mask>
        <Path
          d="M175.743 20.512s-4.787 4.465-4.238 18.51c.548 14.046-3.192 14.744-3.192 14.744l-.748-.35s3.042-10.836 2.444-16.635c-.599-5.799-1.297-13.626 5.734-16.269"
          fill="#BA3026"
          fillRule="nonzero"
          mask="url(#aN)"
        />
      </G>
      <G>
        <Mask id="aP" fill="#fff">
          <Use xlinkHref="#aO" />
        </Mask>
        <Path
          d="M168.314 46.386s-1.719 1.259-5.648-.95c0 0-3.837-1.014-5.464-.339 0 0-.337-.153.921-.89 1.259-.736 5.327-3.384 9.677-.51l1.742 1.584-1.228 1.105Z"
          fill="#E7D5C1"
          fillRule="nonzero"
          mask="url(#aP)"
        />
      </G>
      <G>
        <Mask id="aR" fill="#fff">
          <Use xlinkHref="#aQ" />
        </Mask>
        <Path
          d="m186.614 54.215-34.255.57a.97.97 0 0 0-.87.57c-1.626 3.608-10.617 23.405-12.348 24.07l6.08 4.249 9.282-20.415s25.884 4.081 30.572 1.298c5.976-3.548 1.539-10.342 1.539-10.342"
          fill="#9F4923"
          fillRule="nonzero"
          mask="url(#aR)"
        />
      </G>
      <G>
        <Mask id="aT" fill="#fff">
          <Use xlinkHref="#aS" />
        </Mask>
        <Path
          d="M163.192 95.138s-2.805 5.443-5.12 6.842c0 0-.484.807 1.454.323 1.937-.485 9.655-5.435 9.655-5.435l1.36 2.674.662-.39-2.09-5.835s-.485 1.874-5.92 1.82"
          fill="#901A1D"
          fillRule="nonzero"
          mask="url(#aT)"
        />
      </G>
      <G>
        <Mask id="aV" fill="#fff">
          <Use xlinkHref="#aU" />
        </Mask>
        <Path
          d="M181.124 19.658s-6.446 2.499-6.446 16.695c0 14.197-1.22 21.862-1.22 21.862s9.393-5.284 14.744-2.207c0 0-2.78-7.142-1.967-13.471.813-6.33 2.947-16.08.095-19.088-2.85-3.008-5.206-3.79-5.206-3.79"
          fill="#BA3026"
          fillRule="nonzero"
          mask="url(#aV)"
        />
      </G>
      <G>
        <Mask id="aX" fill="#fff">
          <Use xlinkHref="#aW" />
        </Mask>
        <Path
          d="M185.121 22.777s7.847 1.875-1.776 27.186c0 0-3.405 1.184-15.347-3.355 0 0 .79-1.777-.198-2.911l10.462 1.48s3.899-7.056 1.974-18.157c0 0 1.925-4.144 4.885-4.243"
          fill="#901A1D"
          fillRule="nonzero"
          mask="url(#aX)"
        />
      </G>
      <G>
        <Mask id="aZ" fill="#fff">
          <Use xlinkHref="#aY" />
        </Mask>
        <Path
          d="M183.734 74.724c0 1.08-2.27 2.516-4.984 2.516s-4.842-1.436-4.842-2.516c0-1.081 2.128-1.398 4.842-1.398 2.713 0 4.984.317 4.984 1.398"
          fill="#333"
          fillRule="nonzero"
          mask="url(#aZ)"
        />
      </G>
      <G>
        <Mask id="bb" fill="#fff">
          <Use xlinkHref="#ba" />
        </Mask>
        <Path
          d="M189.567 73.065h-21.492a3.798 3.798 0 0 1-3.787-3.786 3.798 3.798 0 0 1 3.787-3.787h21.492a3.798 3.798 0 0 1 3.787 3.787 3.798 3.798 0 0 1-3.787 3.786"
          fill="#089ECA"
          fillRule="nonzero"
          mask="url(#bb)"
        />
      </G>
      <G>
        <Mask id="bd" fill="#fff">
          <Use xlinkHref="#bc" />
        </Mask>
        <Path
          d="M189.9 74.724h-21.592a.745.745 0 0 1-.743-.743v-.174c0-.408.334-.742.743-.742h21.591c.408 0 .743.334.743.742v.174a.745.745 0 0 1-.743.743"
          fill="#333"
          fillRule="nonzero"
          mask="url(#bd)"
        />
      </G>
      <G>
        <Mask id="bf" fill="#fff">
          <Use xlinkHref="#be" />
        </Mask>
        <Path
          d="M178.868 93.76v-.14c-.676 0-1.23-.554-1.23-1.23V76.78c0-.677.554-1.23 1.23-1.23.676 0 1.23.553 1.23 1.23v15.61c0 .676-.554 1.37-1.23 1.37"
          fill="#333"
          fillRule="nonzero"
          mask="url(#bf)"
        />
      </G>
      <G>
        <Mask id="bh" fill="#fff">
          <Use xlinkHref="#bg" />
        </Mask>
        <Path
          d="m188.353 99.316-9.624-5.556a1.159 1.159 0 0 1-.423-1.579 1.16 1.16 0 0 1 1.579-.423l9.624 5.556c.55.318.741 1.028.423 1.579a1.16 1.16 0 0 1-1.579.423"
          fill="#333"
          fillRule="nonzero"
          mask="url(#bh)"
        />
      </G>
      <G>
        <Mask id="bj" fill="#fff">
          <Use xlinkHref="#bi" />
        </Mask>
        <Path
          d="m169.356 99.316 9.624-5.556c.55-.318.74-1.028.423-1.579a1.16 1.16 0 0 0-1.58-.423l-9.623 5.556a1.16 1.16 0 0 0-.423 1.579 1.16 1.16 0 0 0 1.579.423"
          fill="#333"
          fillRule="nonzero"
          mask="url(#bj)"
        />
      </G>
      <G>
        <Mask id="bl" fill="#fff">
          <Use xlinkHref="#bk" />
        </Mask>
        <Path
          d="M193.802 99.222a3.3 3.3 0 1 1-6.6-.001 3.3 3.3 0 0 1 6.6 0"
          fill="#333"
          fillRule="nonzero"
          mask="url(#bl)"
        />
      </G>
      <G>
        <Mask id="bn" fill="#fff">
          <Use xlinkHref="#bm" />
        </Mask>
        <Path
          d="M192.368 99.222a1.866 1.866 0 1 1-3.732 0 1.866 1.866 0 0 1 3.732 0"
          fill="#606060"
          fillRule="nonzero"
          mask="url(#bn)"
        />
      </G>
      <G>
        <Mask id="bp" fill="#fff">
          <Use xlinkHref="#bo" />
        </Mask>
        <Path
          d="M171.292 99.222a3.3 3.3 0 1 1-6.6-.001 3.3 3.3 0 0 1 6.6 0"
          fill="#333"
          fillRule="nonzero"
          mask="url(#bp)"
        />
      </G>
      <G>
        <Mask id="br" fill="#fff">
          <Use xlinkHref="#bq" />
        </Mask>
        <Path
          d="M169.858 99.222a1.866 1.866 0 1 1-3.732 0 1.866 1.866 0 0 1 3.732 0"
          fill="#606060"
          fillRule="nonzero"
          mask="url(#br)"
        />
      </G>
      <G>
        <Mask id="bt" fill="#fff">
          <Use xlinkHref="#bs" />
        </Mask>
        <Path
          d="M201.812 34.377c-.9 2.692-3.089 10.729-.095 18.046a.71.71 0 0 1-.657.977h-8.141a.706.706 0 0 1-.607-.341c-.722-1.205-2.663-5.921.118-19.053a.711.711 0 0 1 .696-.565h8.013a.71.71 0 0 1 .673.936"
          fill="#0886A5"
          fillRule="nonzero"
          mask="url(#bt)"
        />
      </G>
      <G>
        <Mask id="bv" fill="#fff">
          <Use xlinkHref="#bu" />
        </Mask>
        <Path
          d="M200.11 34.53c-.92 2.81-2.953 10.587-.114 17.725a.833.833 0 0 1-.77 1.145h-7.89a.827.827 0 0 1-.718-.413c-.75-1.31-2.56-6.072.14-18.885a.833.833 0 0 1 .814-.66h7.747a.83.83 0 0 1 .79 1.088"
          fill="#089ECA"
          fillRule="nonzero"
          mask="url(#bv)"
        />
      </G>
      <G>
        <Mask id="bx" fill="#fff">
          <Use xlinkHref="#bw" />
        </Mask>
        <Path fill="#9C6A28" fillRule="nonzero" mask="url(#bx)" d="M34.641 47.848h130.225V45.58H34.64z" />
      </G>
      <G>
        <Mask id="bz" fill="#fff">
          <Use xlinkHref="#by" />
        </Mask>
        <Path fill="#7D551C" fillRule="nonzero" mask="url(#bz)" d="M34.641 47.848h130.225v-1.134H34.64z" />
      </G>
      <G>
        <Mask id="bB" fill="#fff">
          <Use xlinkHref="#bA" />
        </Mask>
        <Path fill="#885A24" fillRule="nonzero" mask="url(#bB)" d="M46.946 102.374h1.801V47.848h-1.801z" />
      </G>
      <G>
        <Mask id="bD" fill="#fff">
          <Use xlinkHref="#bC" />
        </Mask>
        <Path fill="#885A24" fillRule="nonzero" mask="url(#bD)" d="M149.43 102.374h1.8V47.848h-1.8z" />
      </G>
      <G>
        <Mask id="bF" fill="#fff">
          <Use xlinkHref="#bE" />
        </Mask>
        <Path
          d="M95.883 33.838s.195-4.982-1.177-5.827c-2.762-1.702-5.672.095-2.664 5.827h3.841Z"
          fill="#E7D5C1"
          fillRule="nonzero"
          mask="url(#bF)"
        />
      </G>
      <G>
        <Mask id="bH" fill="#fff">
          <Use xlinkHref="#bG" />
        </Mask>
        <Path
          d="m94.928 96.708 1.084 5.016a.468.468 0 0 1-.458.567H81.972c-.507 0-.653-.693-.19-.896 2.199-.966 7.569-2.62 8.722-4.51a.468.468 0 0 1 .358-.224l3.566-.32a.468.468 0 0 1 .5.367"
          fill="#171717"
          fillRule="nonzero"
          mask="url(#bH)"
        />
      </G>
      <G>
        <Mask id="bJ" fill="#fff">
          <Use xlinkHref="#bI" />
        </Mask>
        <Path
          d="m107.685 91.163 3.374 3.867a.468.468 0 0 1-.127.718l-11.89 6.564c-.443.245-.907-.291-.599-.693 1.458-1.908 5.36-5.95 5.455-8.163a.47.47 0 0 1 .205-.37l2.966-2.003a.469.469 0 0 1 .616.08"
          fill="#171717"
          fillRule="nonzero"
          mask="url(#bJ)"
        />
      </G>
      <G>
        <Mask id="bL" fill="#fff">
          <Use xlinkHref="#bK" />
        </Mask>
        <Path
          d="M131.533 59.188s.52 12.827-31.19 16.614l9.111 16.502-7.078 2.905-10.04-21.182-.616-4.382 39.813-10.457Z"
          fill="#0C364C"
          fillRule="nonzero"
          mask="url(#bL)"
        />
      </G>
      <G>
        <Mask id="bN" fill="#fff">
          <Use xlinkHref="#bM" />
        </Mask>
        <Path
          d="m112.66 57.494-22.183 6.408a5.286 5.286 0 0 0-3.785 5.687l3.18 27.439s3.942 1.263 6.036-.6l-2.094-24.413s36.174 4.562 37.833-13.15l-18.986-1.37Z"
          fill="#194966"
          fillRule="nonzero"
          mask="url(#bN)"
        />
      </G>
      <G>
        <Mask id="bP" fill="#fff">
          <Use xlinkHref="#bO" />
        </Mask>
        <Path
          d="M109.908 8.44s-1.876 8.235 1.998 11.333c0 0 1.189.204 2.674-.49l.833 3.915 6.32-.49-1.55-12.719-3.058-4.321h-4.648l-2.569 2.772Z"
          fill="#E7D5C1"
          fillRule="nonzero"
          mask="url(#bP)"
        />
      </G>
      <G>
        <Mask id="bR" fill="#fff">
          <Use xlinkHref="#bQ" />
        </Mask>
        <Path
          d="M109.786 10.315s-1.957-2.2 0-4.036c0 0-.449-4.28 6.646-2.935 0 0 3.507-.489 4.811 1.957 0 0 3.792 5.626 0 13.086 0 0-6.931 1.345-7.624-4.117 0 0 .081-2.97-1.876-3.462 0 0-1.101.445-1.957-.493"
          fill="#7B461D"
          fillRule="nonzero"
          mask="url(#bR)"
        />
      </G>
      <G>
        <Mask id="bT" fill="#fff">
          <Use xlinkHref="#bS" />
        </Mask>
        <Path
          d="M122.919 78.082c0 .98-2.06 2.283-4.523 2.283-2.462 0-4.393-1.303-4.393-2.283s1.931-1.268 4.393-1.268c2.462 0 4.523.287 4.523 1.268"
          fill="#333"
          fillRule="nonzero"
          mask="url(#bT)"
        />
      </G>
      <G>
        <Mask id="bV" fill="#fff">
          <Use xlinkHref="#bU" />
        </Mask>
        <Path
          d="M128.211 76.577h-19.5a3.446 3.446 0 0 1-3.436-3.435 3.446 3.446 0 0 1 3.436-3.436h19.5a3.446 3.446 0 0 1 3.436 3.436 3.446 3.446 0 0 1-3.436 3.435"
          fill="#089ECA"
          fillRule="nonzero"
          mask="url(#bV)"
        />
      </G>
      <G>
        <Mask id="bX" fill="#fff">
          <Use xlinkHref="#bW" />
        </Mask>
        <Path
          d="M128.512 78.082h-19.59a.675.675 0 0 1-.673-.674v-.157c0-.37.303-.674.673-.674h19.59c.37 0 .674.303.674.674v.157c0 .37-.303.674-.674.674"
          fill="#333"
          fillRule="nonzero"
          mask="url(#bX)"
        />
      </G>
      <G>
        <Mask id="bZ" fill="#fff">
          <Use xlinkHref="#bY" />
        </Mask>
        <Path
          d="M118.504 94.316a1.119 1.119 0 0 1-1.116-1.116V79.947c0-.614.502-1.116 1.116-1.116.613 0 1.115.502 1.115 1.116V93.2c0 .614-.502 1.116-1.115 1.116"
          fill="#333"
          fillRule="nonzero"
          mask="url(#bZ)"
        />
      </G>
      <G>
        <Mask id="cb" fill="#fff">
          <Use xlinkHref="#ca" />
        </Mask>
        <Path
          d="m127.11 99.383-8.733-5.04a1.052 1.052 0 0 1-.383-1.433c.288-.5.933-.672 1.433-.384l8.732 5.04c.5.289.672.933.383 1.433-.288.5-.933.672-1.432.384"
          fill="#333"
          fillRule="nonzero"
          mask="url(#cb)"
        />
      </G>
      <G>
        <Mask id="cd" fill="#fff">
          <Use xlinkHref="#cc" />
        </Mask>
        <Path
          d="m109.873 99.383 8.732-5.04c.5-.29.673-.934.384-1.433a1.052 1.052 0 0 0-1.433-.384l-8.732 5.04c-.5.289-.672.933-.384 1.433.289.5.934.672 1.433.384"
          fill="#333"
          fillRule="nonzero"
          mask="url(#cd)"
        />
      </G>
      <G>
        <Mask id="cf" fill="#fff">
          <Use xlinkHref="#ce" />
        </Mask>
        <Path
          d="M132.054 99.297a2.994 2.994 0 1 1-5.988 0 2.994 2.994 0 0 1 5.988 0"
          fill="#333"
          fillRule="nonzero"
          mask="url(#cf)"
        />
      </G>
      <G>
        <Mask id="ch" fill="#fff">
          <Use xlinkHref="#cg" />
        </Mask>
        <Path
          d="M130.82 99.365a1.693 1.693 0 1 1-3.386 0 1.693 1.693 0 0 1 3.386 0"
          fill="#606060"
          fillRule="nonzero"
          mask="url(#ch)"
        />
      </G>
      <G>
        <Mask id="cj" fill="#fff">
          <Use xlinkHref="#ci" />
        </Mask>
        <Path
          d="M111.719 99.386a2.994 2.994 0 1 1-5.988 0 2.994 2.994 0 0 1 5.988 0"
          fill="#333"
          fillRule="nonzero"
          mask="url(#cj)"
        />
      </G>
      <G>
        <Mask id="cl" fill="#fff">
          <Use xlinkHref="#ck" />
        </Mask>
        <Path
          d="M110.418 99.386a1.693 1.693 0 1 1-3.387 0 1.693 1.693 0 0 1 3.387 0"
          fill="#606060"
          fillRule="nonzero"
          mask="url(#cl)"
        />
      </G>
      <G>
        <Mask id="cn" fill="#fff">
          <Use xlinkHref="#cm" />
        </Mask>
        <Path
          d="m113.97 23.558.284-1.674s4.322-2.014 8.881-.936l.713 1.696s13.452.799 7.685 36.544c0 0-10.495 5.65-20.528-1.499 0 0-2.538-25.704-3-31.301l5.965-2.83Z"
          fill="#FBAF17"
          fillRule="nonzero"
          mask="url(#cn)"
        />
      </G>
      <G>
        <Mask id="cp" fill="#fff">
          <Use xlinkHref="#co" />
        </Mask>
        <Path
          d="M108.006 26.388s-11.159 16.193-8.894 21.33c0 0 1.274 2.44 4.295 1.046 3.022-1.394 12.012-14.969 4.599-22.376"
          fill="#FBAF17"
          fillRule="nonzero"
          mask="url(#cp)"
        />
      </G>
      <G>
        <Mask id="cr" fill="#fff">
          <Use xlinkHref="#cq" />
        </Mask>
        <Path
          d="M134.623 50.466h-15.515a2.571 2.571 0 0 1-2.563-2.563V32.392a2.571 2.571 0 0 1 2.563-2.564h15.515a2.571 2.571 0 0 1 2.563 2.564v15.51a2.57 2.57 0 0 1-2.563 2.564"
          fill="#089ECA"
          fillRule="nonzero"
          mask="url(#cr)"
        />
      </G>
      <G>
        <Mask id="ct" fill="#fff">
          <Use xlinkHref="#cs" />
        </Mask>
        <Path
          d="M135.466 50.466h-15.514a2.571 2.571 0 0 1-2.564-2.563V32.392a2.571 2.571 0 0 1 2.564-2.564h15.514a2.571 2.571 0 0 1 2.564 2.564v15.51a2.57 2.57 0 0 1-2.564 2.564"
          fill="#0886A5"
          fillRule="nonzero"
          mask="url(#ct)"
        />
      </G>
      <G>
        <Mask id="cv" fill="#fff">
          <Use xlinkHref="#cu" />
        </Mask>
        <Path
          d="M133.05 40.59a4.527 4.527 0 1 1-9.055 0 4.527 4.527 0 0 1 9.054 0"
          fill="#333"
          fillRule="nonzero"
          mask="url(#cv)"
        />
      </G>
      <G>
        <Mask id="cx" fill="#fff">
          <Use xlinkHref="#cw" />
        </Mask>
        <Path d="M118.461 78.082s10.06-5.255 10.06-37.491" stroke="#333" strokeWidth={17} mask="url(#cx)" />
      </G>
      <G>
        <Mask id="cz" fill="#fff">
          <Use xlinkHref="#cy" />
        </Mask>
        <Path
          d="m99.804 48.53-7.81-14.881s1.225-2.235 3.404-1.35l9.877 10.641-5.471 5.59Z"
          fill="#FBAF17"
          fillRule="nonzero"
          mask="url(#cz)"
        />
      </G>
      <G>
        <Mask id="cB" fill="#fff">
          <Use xlinkHref="#cA" />
        </Mask>
        <Path
          d="m21.691 23.773 3.242 7.777s4.422 4.017 8.101 6.959c1.957 1.563 3.703 2.823 4.465 3.013 2.195.548 14.662-4.388 15.958-5 1.297-.613 1.846-.036 4.139 0 2.294.036 5.885-4.273 6.085-5.37.199-1.098-6.683-.1-6.683-.1s1.396-2.493.598-3.136c-.798-.643-4.188 3.535-5.635 4.781-1.446 1.246-12.567 2.294-13.813 2.443-.215.025-.768-.386-1.539-1.085-3.7-3.36-12.386-13.365-12.386-13.365l-2.532 3.083Z"
          fill="#FFEFD7"
          fillRule="nonzero"
          mask="url(#cB)"
        />
      </G>
      <G>
        <Mask id="cD" fill="#fff">
          <Use xlinkHref="#cC" />
        </Mask>
        <Path
          d="m21.691 23.773 3.242 7.777s4.422 4.017 8.101 6.959c.997-1.874 2.451-3.425 3.575-4.454-3.7-3.36-12.386-13.365-12.386-13.365l-2.532 3.083Z"
          fill="#BA3026"
          fillRule="nonzero"
          mask="url(#cD)"
        />
      </G>
      <G>
        <Mask id="cF" fill="#fff">
          <Use xlinkHref="#cE" />
        </Mask>
        <Path
          d="M47.688 99.754s-.42 4.017.433 4.193c.853.176 14.415-.82 15.128-1.788.712-.967-.88-1.496-.88-1.496l-8.992-3.903-5.689 2.994Z"
          fill="#171717"
          fillRule="nonzero"
          mask="url(#cF)"
        />
      </G>
      <G>
        <Mask id="cH" fill="#fff">
          <Use xlinkHref="#cG" />
        </Mask>
        <Path
          d="M37.673 95.354s-2.053 2.34-1.594 2.831c.46.49 9.44 5.898 10.319 5.606.88-.292.113-1.333.113-1.333L42.587 96l-4.914-.646Z"
          fill="#171717"
          fillRule="nonzero"
          mask="url(#cH)"
        />
      </G>
      <G>
        <Mask id="cJ" fill="#fff">
          <Use xlinkHref="#cI" />
        </Mask>
        <Path
          d="M28.63 53.19s20.987 9.08 20.319 15.944c-.661 6.795-5.179 28.312-5.179 28.312s-4.146.967-6.55-2.33c0 0 2.803-20.73 2.341-22.102-.462-1.372-21.995-12.368-21.995-12.368l-.586-6.375 11.65-1.081Z"
          fill="#0F3C54"
          fillRule="nonzero"
          mask="url(#cJ)"
        />
      </G>
      <G>
        <Mask id="cL" fill="#fff">
          <Use xlinkHref="#cK" />
        </Mask>
        <Path
          d="M9.707 76.238h13.89v-1.904H9.725c-.389-.01-7.807-.326-7.807-9.08 0-4.586 1.424-6.37 2.93-8.26 1.667-2.087 3.39-4.246 3.39-9.632H6.334c0 4.72-1.444 6.529-2.973 8.445C1.715 57.869.014 60 .014 65.254c0 5.559 2.636 8.26 4.848 9.546 2.374 1.381 4.738 1.437 4.837 1.438h.008Z"
          fill="#333"
          fillRule="nonzero"
          mask="url(#cL)"
        />
      </G>
      <G>
        <Mask id="cN" fill="#fff">
          <Use xlinkHref="#cM" />
        </Mask>
        <Path
          d="M18.562 74.713c0 1.107 2.327 2.578 5.107 2.578 2.78 0 4.961-1.47 4.961-2.578 0-1.107-2.18-1.432-4.96-1.432s-5.108.325-5.108 1.432"
          fill="#333"
          fillRule="nonzero"
          mask="url(#cN)"
        />
      </G>
      <G>
        <Mask id="cP" fill="#fff">
          <Use xlinkHref="#cO" />
        </Mask>
        <Path
          d="M12.585 73.014h22.022a3.892 3.892 0 0 0 3.88-3.88 3.892 3.892 0 0 0-3.88-3.88H12.585a3.892 3.892 0 0 0-3.88 3.88 3.892 3.892 0 0 0 3.88 3.88"
          fill="#089ECA"
          fillRule="nonzero"
          mask="url(#cP)"
        />
      </G>
      <G>
        <Mask id="cR" fill="#fff">
          <Use xlinkHref="#cQ" />
        </Mask>
        <Path
          d="M12.245 74.713h22.123c.419 0 .761-.342.761-.76v-.179a.763.763 0 0 0-.76-.76H12.244a.763.763 0 0 0-.76.76v.179c0 .418.342.76.76.76"
          fill="#333"
          fillRule="nonzero"
          mask="url(#cR)"
        />
      </G>
      <G>
        <Mask id="cT" fill="#fff">
          <Use xlinkHref="#cS" />
        </Mask>
        <Path
          d="M23.548 93.046c.693 0 1.26-.567 1.26-1.26V76.82c0-.693-.567-1.26-1.26-1.26-.693 0-1.26.567-1.26 1.26v14.967c0 .693.567 1.26 1.26 1.26"
          fill="#333"
          fillRule="nonzero"
          mask="url(#cT)"
        />
      </G>
      <G>
        <Mask id="cV" fill="#fff">
          <Use xlinkHref="#cU" />
        </Mask>
        <Path
          d="m13.83 98.768 9.86-5.692c.565-.326.76-1.054.434-1.618a1.188 1.188 0 0 0-1.618-.433l-9.861 5.692a1.188 1.188 0 0 0-.434 1.618c.326.564 1.054.76 1.618.433"
          fill="#333"
          fillRule="nonzero"
          mask="url(#cV)"
        />
      </G>
      <G>
        <Mask id="cX" fill="#fff">
          <Use xlinkHref="#cW" />
        </Mask>
        <Path
          d="m33.294 98.768-9.86-5.692A1.188 1.188 0 0 1 23 91.458a1.188 1.188 0 0 1 1.618-.433l9.86 5.692c.565.326.76 1.054.435 1.618a1.188 1.188 0 0 1-1.619.433"
          fill="#333"
          fillRule="nonzero"
          mask="url(#cX)"
        />
      </G>
      <G>
        <Mask id="cZ" fill="#fff">
          <Use xlinkHref="#cY" />
        </Mask>
        <Path
          d="M8.246 98.672a3.381 3.381 0 1 0 6.762 0 3.381 3.381 0 0 0-6.762 0"
          fill="#333"
          fillRule="nonzero"
          mask="url(#cZ)"
        />
      </G>
      <G>
        <Mask id="db" fill="#fff">
          <Use xlinkHref="#da" />
        </Mask>
        <Path
          d="M9.715 98.672a1.912 1.912 0 1 0 3.824 0 1.912 1.912 0 0 0-3.824 0"
          fill="#606060"
          fillRule="nonzero"
          mask="url(#db)"
        />
      </G>
      <G>
        <Mask id="dd" fill="#fff">
          <Use xlinkHref="#dc" />
        </Mask>
        <Path
          d="M31.31 98.672a3.381 3.381 0 1 0 6.763 0 3.381 3.381 0 0 0-6.762 0"
          fill="#333"
          fillRule="nonzero"
          mask="url(#dd)"
        />
      </G>
      <G>
        <Mask id="df" fill="#fff">
          <Use xlinkHref="#de" />
        </Mask>
        <Path
          d="M32.78 98.672a1.912 1.912 0 1 0 3.824 0 1.912 1.912 0 0 0-3.824 0"
          fill="#606060"
          fillRule="nonzero"
          mask="url(#df)"
        />
      </G>
      <G>
        <Mask id="dh" fill="#fff">
          <Use xlinkHref="#dg" />
        </Mask>
        <Path
          d="M.038 33.373c.922 2.758 3.165 10.992.099 18.49a.727.727 0 0 0 .673 1.001H9.15a.724.724 0 0 0 .622-.35c.74-1.234 2.728-6.067-.122-19.522a.728.728 0 0 0-.712-.578H.728a.728.728 0 0 0-.69.959"
          fill="#0886A5"
          fillRule="nonzero"
          mask="url(#dh)"
        />
      </G>
      <G>
        <Mask id="dj" fill="#fff">
          <Use xlinkHref="#di" />
        </Mask>
        <Path
          d="M1.783 33.53c.943 2.878 3.025 10.847.116 18.16a.853.853 0 0 0 .79 1.174h8.084c.303 0 .585-.16.736-.423.768-1.343 2.623-6.222-.144-19.35a.853.853 0 0 0-.834-.677H2.593a.851.851 0 0 0-.81 1.115"
          fill="#089ECA"
          fillRule="nonzero"
          mask="url(#dj)"
        />
      </G>
      <G>
        <Mask id="dl" fill="#fff">
          <Use xlinkHref="#dk" />
        </Mask>
        <Path
          d="M9.15 52.864S4.904 65.977 23.308 67.19c0 0 16.143 1.71 16.364 4.025.22 2.315 7.15 28.363 7.15 28.363s1.663.537 4.222-.906c1.11-.626 2.9-1.984 2.9-1.984s-4.567-29.676-6.608-31.434c-2.04-1.758-22.828-11.46-22.828-11.46l-15.356-.93Z"
          fill="#194966"
          fillRule="nonzero"
          mask="url(#dl)"
        />
      </G>
      <G>
        <Mask id="dn" fill="#fff">
          <Use xlinkHref="#dm" />
        </Mask>
        <Path
          d="M27.14 5.39s5.368-3.523.894-4.527c-3.184-.715-3.354.727-4.696 0-1.342-.727-2.852-1.51-4.753 0-1.9 1.51-2.572.982-2.907 1.832-.336.851-.895 3.143 0 5.77 0 0-.71 3.39 1.302 4.396l5.687-3.557L27.14 5.39Z"
          fill="#171717"
          fillRule="nonzero"
          mask="url(#dn)"
        />
      </G>
      <G>
        <Mask id="dp" fill="#fff">
          <Use xlinkHref="#do" />
        </Mask>
        <Path
          d="M27.985 4.763s1.465 10.07-1.512 10.634c0 0-3.997-.069-4.152 1.007-.155 1.077-1.169 3.357-1.169 3.357s-3.547-.57-5.004-1.963l.313-2.401S17.626 2.293 27.985 4.763"
          fill="#FFEFD7"
          fillRule="nonzero"
          mask="url(#dp)"
        />
      </G>
      <G>
        <Mask id="dr" fill="#fff">
          <Use xlinkHref="#dq" />
        </Mask>
        <Path
          d="M24.382 3.739s-4.089 7.101-7.402 9.122l.752-7.982 2.977-2.027 3.673.887Z"
          fill="#171717"
          fillRule="nonzero"
          mask="url(#dr)"
        />
      </G>
      <G>
        <Mask id="dt" fill="#fff">
          <Use xlinkHref="#ds" />
        </Mask>
        <Path
          d="M19.54 8.725c.531.919 1.592 1.3 2.371.85.779-.45.98-1.559.449-2.478-.53-.919-1.592-1.3-2.37-.85-.78.45-.98 1.559-.45 2.478"
          fill="#FFEFD7"
          fillRule="nonzero"
          mask="url(#dt)"
        />
      </G>
      <G>
        <Mask id="dv" fill="#fff">
          <Use xlinkHref="#du" />
        </Mask>
        <Path
          d="M21.445 19.07s-2.778-.006-5.297-1.272c0 0-4.603 1.459-5.886 3.383-1.283 1.924-4.307 7.256-1.111 31.683 0 0 11.711 5.136 19.48.326 0 0-1.645-28.134-2.655-30.139-.647-1.283-1.846-3.045-4.53-3.98"
          fill="#BA3026"
          fillRule="nonzero"
          mask="url(#dv)"
        />
      </G>
      <G>
        <Mask id="dx" fill="#fff">
          <Use xlinkHref="#dw" />
        </Mask>
        <Path
          d="m10.302 40.657.072.28s.8 5.298 2.083 6.717c1.284 1.42 9.941 7.237 11.408 9.46 1.466 2.223 2.825 5.097 4.4 4.872 2.648-.378 5.203-2.223 3.926-4.54-1.278-2.318-4.684-1.23-6.15-2.65-1.467-1.418-8.421-9.318-8.894-11.21-.149-.594-.293-2.475-.42-4.776-.279-5.029-.48-12.062-.48-12.062s-.441-4.727-5.873-4.492c0 0-3.793 3.688-.072 18.4"
          fill="#FFEFD7"
          fillRule="nonzero"
          mask="url(#dx)"
        />
      </G>
      <G>
        <Mask id="dz" fill="#fff">
          <Use xlinkHref="#dy" />
        </Mask>
        <Path
          d="M10.187 40.577a16.838 16.838 0 0 1 6.772-1.947c-.293-5.3-.505-12.714-.505-12.714s-.466-4.983-6.192-4.736c0 0-3.998 3.889-.075 19.397"
          fill="#BA3026"
          fillRule="nonzero"
          mask="url(#dz)"
        />
      </G>
      <G>
        <Mask id="dB" fill="#fff">
          <Use xlinkHref="#dA" />
        </Mask>
        <Path
          d="M35.481 45.58h15.273a.54.54 0 0 0 .539-.539.54.54 0 0 0-.54-.538H35.482a.54.54 0 0 0-.539.538.54.54 0 0 0 .54.54"
          fill="#D6D6D5"
          fillRule="nonzero"
          mask="url(#dB)"
        />
      </G>
      <G>
        <Mask id="dD" fill="#fff">
          <Use xlinkHref="#dC" />
        </Mask>
        <Path
          d="m51.142 43.671 3.343-17.8a.702.702 0 0 1 .69-.572h11.55c.44 0 .771.398.691.83l-3.3 17.8a.702.702 0 0 1-.69.574H51.832c-.44 0-.771-.4-.69-.832"
          fill="#D6D6D5"
          fillRule="nonzero"
          mask="url(#dD)"
        />
      </G>
      <G>
        <Mask id="dF" fill="#fff">
          <Use xlinkHref="#dE" />
        </Mask>
        <Path
          d="m51.449 43.671 3.343-17.8a.702.702 0 0 1 .69-.572h11.55c.44 0 .77.398.69.83l-3.3 17.8a.702.702 0 0 1-.69.574H52.138c-.439 0-.77-.4-.69-.832"
          fill="#E8E7E7"
          fillRule="nonzero"
          mask="url(#dF)"
        />
      </G>
      <G>
        <Mask id="dH" fill="#fff">
          <Use xlinkHref="#dG" />
        </Mask>
        <Path
          d="M51.191 45.58h12.634c.27 0 .491-.221.491-.491v-.095a.493.493 0 0 0-.491-.491H51.19a.493.493 0 0 0-.491.491v.095c0 .27.22.491.491.491"
          fill="#E8E7E7"
          fillRule="nonzero"
          mask="url(#dH)"
        />
      </G>
      <G>
        <Mask id="dJ" fill="#fff">
          <Use xlinkHref="#dI" />
        </Mask>
        <Path
          d="M48.52 45.277h1.35c.138 0 .25-.113.25-.25a.251.251 0 0 0-.25-.251h-1.35a.252.252 0 0 0-.251.25c0 .138.113.251.25.251"
          fill="#B6B7B7"
          fillRule="nonzero"
          mask="url(#dJ)"
        />
      </G>
      <G>
        <Mask id="dL" fill="#fff">
          <Use xlinkHref="#dK" />
        </Mask>
        <Path
          d="M46.22 45.277h1.35c.139 0 .251-.113.251-.25a.251.251 0 0 0-.25-.251h-1.35a.251.251 0 0 0-.252.25c0 .138.113.251.251.251"
          fill="#B6B7B7"
          fillRule="nonzero"
          mask="url(#dL)"
        />
      </G>
    </G>
  </Svg>
)

export default Pro
