import React from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import ListView from '../../../../../components/ListView'
import ProductImage from '../../../../../components/ProductImage'
import useStyles from './styles'
import { RunnersT } from '../../../../../types/widgetDataTypes/types'
import { View } from 'react-native'
import icons from '../../../../../constants/icons'
import { isShopware } from '../../../../../utils/channelPlatforms'
import Widget from '../../../../components/Widget'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { Link, useNavigate } from '../../../../../components/Router/Router'
import { FontAwesomeIcon } from '../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import RunnersSkeleton from './RunnersSkeleton'

const Runners = ({ widget, shops, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const platformName = shops[widget?.settings?.shop]?.platform?.name || ''
  const styles = useStyles()

  return (
    <Widget<RunnersT>
      title={`${isShopware(platformName) ? 'Alltime ' : ''}${t('shop.widget.runners.title')}`}
      widget={widget}
      loadingSkeleton={<RunnersSkeleton />}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, interval = 'D30', rowsPerPage = 4 }, { page = 1 } = {}) => ({
          body: {
            request: 'Analytics',
            widget: {
              renner: {
                options: {
                  active: true,
                  details: false,
                  interval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                  pagination: { descending: true, page, rowsPerPage },
                },
              },
            },
          },
          widget: 'renner',
        }),
      ]}
      onClickWidget={() => {
        navigate(`/analytics/runner/${widget.settings.shop}`)
      }}
      shops={shops}
      {...props}
    >
      {([, data, , , size]) => {
        return (
          data &&
          data.data &&
          Array.isArray(data.data) && (
            <ListView
              testID={'runners-list'}
              size={size}
              styles={{
                mainTitle: styles.mainTitle,
                mainSubtitle: styles.mainSubtitle,
              }}
              data={data?.data?.map(({ id, nAmount, nAmountUnique, cName, cURL, cThumbnail }) => {
                return {
                  id,
                  icon: (
                    <ProductImage
                      style={styles.icon}
                      photoURL={cThumbnail?.cURLKlein || ''}
                      accessibilityLabel="thumbnail"
                    />
                  ),
                  mainTitle: cName,
                  mainSubtitle:
                    nAmountUnique !== 0
                      ? t('shop.widget.runners.sold', {
                          sold: nAmount,
                          count: nAmountUnique,
                        })
                      : '',
                  actionTitle: (
                    <View style={styles.linkWrapper}>
                      <Link to={cURL} style={styles.link}>
                        <FontAwesomeIcon icon={[icons.faIconStyle, 'external-link']} />
                      </Link>
                    </View>
                  ),
                }
              })}
            />
          )
        )
      }}
    </Widget>
  )
}

export default Runners
