import React from 'react'
import Button from '../../../components/Button'
import { AuthProvider as AuthProviderT, OAuthCredential as OAuthCredentialT } from '@firebase/auth-types'
import { Platform } from 'react-native'
import firebase from 'firebase/compat/app'
import useStyles from '../../../components/Form/login-styles'
import GoogleLoginSvg from '../../../assets/imgs/login/GoogleLogin'

interface IProps {
  handleProviderLogin: (provider: AuthProviderT) => void
  handleCredentialLogin: (credential: OAuthCredentialT) => void
}

const GoogleLogin = ({ handleProviderLogin }: IProps) => {
  const loginStyles = useStyles()
  const handleGoogleLogin = () => {
    handleProviderLogin(new firebase.auth.GoogleAuthProvider())
  }

  return (
    <Button
      onPress={() => handleGoogleLogin()}
      style={[
        loginStyles.buttonOutlined,
        {
          paddingVertical: 5,
          paddingTop: Platform.OS === 'web' ? 9 : 13,
        },
      ]}
      textStyle={loginStyles.buttonOutlinedText}
      disabled={false}
    >
      <GoogleLoginSvg />
    </Button>
  )
}

export default GoogleLogin
