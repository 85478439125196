import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import { openUrl } from '../../../components/Browser'
import ColoredText from '../../../components/CustomText/ColoredText'
import DefaultDashbarIcon from '../DefaultDashbarIcon.tsx'
import WidgetView from '../../../components/Widget'
import { useTextColor } from '../../../hooks/useColors'
import { IShop } from '../../../types/dashboard'
const defaultFavIcon = 'https://static.dash.bar/app/img/dblogo.png'

const ErrorComponent = ({
  error,
  shop,
  title,
  image,
  dbCode,
}: {
  error: {
    text: string
    link: string | null
  }
  shop: IShop
  title: string
  image?:
    | {
        source: string
        alt: string
      }
    | undefined
  dbCode?: string
}) => {
  // const errorInfo = getErrorByStatusCode(+(error?.status || 500))
  const textColor = useTextColor()
  return (
    <WidgetView
      title={title}
      isError
      image={
        image
          ? image
          : shop?.icon
            ? {
                source: shop?.icon ?? defaultFavIcon,
                alt: shop?.domain ?? '(domain missing)',
              }
            : undefined
      }
      icon={shop?.icon ? undefined : <DefaultDashbarIcon />}
    >
      <FontAwesomeIcon style={{ margin: 5, fontSize: 40 }} color={textColor} icon={['fas', 'cloud-xmark']} />
      <ColoredText style={{ padding: 2, fontSize: 16, fontWeight: 'bold' }}>{`Uups`}</ColoredText>
      <ColoredText
        style={{ padding: 5, paddingLeft: 2, fontSize: 14, fontWeight: '300' }}
      >{`${error.text}`}</ColoredText>
      {dbCode && (
        <ColoredText
          style={{ padding: 5, paddingLeft: 2, fontSize: 10, fontWeight: '300', marginTop: 2 }}
        >{`#${dbCode}`}</ColoredText>
      )}
      {error.link !== null && (
        <TouchableOpacity
          style={{ bottom: 0, position: 'absolute', width: '100%' }}
          onPress={() => {
            openUrl(`${error.link}`)
          }}
        >
          <ColoredText
            style={{
              padding: 5,
              paddingLeft: 2,
              fontSize: 14,
              fontWeight: '300',
              textAlign: 'center',
              textDecorationLine: 'underline',
            }}
          >{`weitere Infos`}</ColoredText>
        </TouchableOpacity>
      )}
    </WidgetView>
  )
}

export default ErrorComponent
