// import { v4 } from 'uuid'
import { useCallback, useContext } from 'react'
import { useFirestore } from 'react-redux-firebase'
import { SessionContext } from '../../context/SessionContext'
import firebase from 'firebase/compat/app'
import * as Crypto from 'expo-crypto'
import { IWidget } from '../../types/dashboard'

const useAddWidget = (dashboardId?: string | undefined) => {
  const firestore = useFirestore()
  const { user } = useContext(SessionContext)
  return useCallback(
    async (widgetToAdd: Partial<IWidget>, dashboardId2?: string) => {
      if (!dashboardId && !dashboardId2) {
        throw new Error('addWidget needs `dashboardId` with the hook or with the execution')
      }

      return firestore
        .collection(`company/${user?.company ?? '-'}/dashboard`)
        .doc(dashboardId || dashboardId2)
        .update({
          widgets: firebase.firestore.FieldValue.arrayUnion({
            id: Crypto.randomUUID(),
            ...widgetToAdd,
          }),
        })
    },
    [dashboardId, firestore, user?.company]
  )
}

export default useAddWidget
