import React, { ReactNode } from 'react'
import { Platform, StyleProp, View, ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Controller, FieldError, useForm } from 'react-hook-form'
import Button from '../../Button'
import useStyles from '../login-styles'
import { yupResolver } from '@hookform/resolvers/yup'
import SignupValidator from '../../../validators/SignupValidator'
import icons from '../../../constants/icons'
import ColoredText from '../../CustomText/ColoredText'
import InputWithIcon from '../../InputWithIcon'
import DashbarSignup from '../../../assets/imgs/login/DashbarSignup'
import Link from '../../Link'
import urls from '../../../constants/urls'

interface IProps {
  onSubmit: (params: { email: string; password: string }) => void
  loading: boolean
  submitButtonText?: ReactNode
  style?: StyleProp<ViewStyle>
}

const SignupForm = ({ onSubmit, loading, style }: IProps) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<{
    email: string
    password: string
    confirmPassword: string
  }>({
    resolver: yupResolver(SignupValidator),
  })
  const { t } = useTranslation()

  const styles = useStyles()
  return (
    <View style={style}>
      <Controller
        control={control}
        name={'email'}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputWithIcon
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            icon={[icons.faIconStyle, 'user']}
            style={styles.textInput}
            placeholder={`${t('placeholder.baseLogin.email')}`}
            editable={!loading}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
      />
      <View style={styles.errorWrapper}>
        {errors.email ? (
          <ColoredText style={{ color: 'black', padding: 5 }}>{(errors.email as FieldError).message}</ColoredText>
        ) : null}
      </View>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputWithIcon
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            icon={[icons.faIconStyle, 'lock']}
            secureTextEntry
            style={styles.textInput}
            placeholder={`${t('placeholder.baseLogin.password')}`}
            autoCorrect={false}
            editable={!loading}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
        name={'password'}
      />
      <View style={styles.errorWrapper}>
        {errors.password ? (
          <ColoredText style={{ color: 'black', padding: 5 }}>{(errors.password as FieldError).message}</ColoredText>
        ) : null}
      </View>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputWithIcon
            icon={[icons.faIconStyle, 'lock']}
            secureTextEntry
            style={styles.textInput}
            placeholder={`${t('placeholder.baseLogin.confirmPassword')}`}
            autoCorrect={false}
            editable={!loading}
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            onSubmitEditing={handleSubmit(onSubmit)}
          />
        )}
        name={'confirmPassword'}
      />
      <View style={styles.errorWrapper}>
        {errors.confirmPassword ? (
          <ColoredText style={{ color: 'black', padding: 5 }}>
            {(errors.confirmPassword as FieldError).message}
          </ColoredText>
        ) : null}
      </View>
      {/* <Button style={styles.button} textStyle={styles.buttonText} onPress={handleSubmit(onSubmit)} disabled={loading}>
        {submitButtonText || t('button.baseSignup.signup')}
      </Button> */}
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', marginVertical: 5 }}>
        <ColoredText style={{ color: 'black' }}>{`${t('subscription.booking_completion.condition_text_4', {
          signup: 'registrieren',
        })}`}</ColoredText>
        <Link url={Platform.OS === 'ios' ? urls.URL_TERMES_OF_USE_APPLE : urls.URL_TERMES_OF_USE}>
          <ColoredText style={{ color: 'black', textDecorationColor: 'black' }}>{`${t(
            'subscription.booking_completion.condition'
          )}`}</ColoredText>
        </Link>
        <ColoredText style={{ color: 'black' }}>{`${t(
          'subscription.booking_completion.condition_text_5'
        )}`}</ColoredText>
        <Link url={urls.URL_PRIVACY_POLICY}>
          <ColoredText style={{ color: 'black', textDecorationColor: 'black' }}>{`${t(
            'subscription.booking_completion.privacy_policy'
          )}`}</ColoredText>
        </Link>
      </View>
      <Button
        style={[styles.button, { paddingTop: Platform.OS === 'web' ? 7 : 11 }]}
        textStyle={styles.buttonText}
        onPress={handleSubmit(onSubmit)}
        disabled={loading}
      >
        {/* {t('button.baseLogin.login')} */}
        <DashbarSignup />
      </Button>
    </View>
  )
}

export default SignupForm
