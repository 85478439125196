import { StyleSheet } from 'react-native'
import { getTextColor, useTextColor } from '../../hooks/useColors'
import { Themes } from '../../types/dashbar/theme'

const useStyles = () => {
  const textColor = useTextColor()

  return StyleSheet.create({
    textInput: {
      height: 30,
      color: textColor,
      borderColor: textColor,
      borderBottomWidth: 1,
      padding: 5,
      margin: 5,
    },
    wrapper: {
      width: '100%',
      flexDirection: 'column',
      alignContent: 'center',
      alignItems: 'center',
    },
    button: {
      borderColor: textColor,
      borderWidth: 1,
      height: 40,
    },
    loginText: {
      color: getTextColor(Themes.Dark),
    },
    dividerWrapper: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      alignContent: 'center',
      marginVertical: 5,
    },
    dividerLine: {
      height: 1,
      backgroundColor: 'black',
      borderColor: 'black',
      width: '30%',
    },
  })
}

export default useStyles
