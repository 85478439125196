import React, { useContext } from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import LiveTrackerView from './View'
import { EventDataT } from '../../../../../types/widgetDataTypes/types'
import Widget from '../../../../components/Widget'
import { useTranslation } from '../../../../../hooks/useTranslation'
import LivetrackerSkeleton from '../../default/LiveTracker/LivetrackerSkeleton'
import { UpsellingContext } from '../../../../../context/UpsellingContext'

const BasicLiveTracker = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const { open } = useContext(UpsellingContext)

  return (
    <Widget<EventDataT>
      title={'Basic ' + t('shop.widget.live-tracker.title')}
      widget={widget}
      isBasic
      loadingSkeleton={<LivetrackerSkeleton />}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, rowsPerPage = 100 }, { page = 1 } = {}) => ({
          body: {
            request: 'EventTracker',
            widget: {
              visitors: {
                options: {
                  active: true,
                  details: false,
                  refresh: autoRefresh ? refreshInterval : undefined,
                  filter: 'none',
                  interval: null,
                  rowsPerPage: 100,
                  pagination: { descending: true, page, rowsPerPage },
                },
              },
            },
          },
          widget: 'visitors',
        }),
      ]}
      onClickWidget={() => open()}
      {...props}
    >
      {([, data, , shop]) => {
        return <LiveTrackerView {...data} currency={shop?.currency || '€'} />
      }}
    </Widget>
  )
}

export default BasicLiveTracker
