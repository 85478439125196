import React from 'react'
import { View } from 'react-native'
import ColoredText from '../../../../components/CustomText/ColoredText'
import useStyles from './styles.native'

const MenuTitle = ({ title }: { title?: string; subtitle?: string }) => {
  const styles = useStyles()
  return (
    <View style={styles.wrapper}>
      <ColoredText style={styles.title}>{title}</ColoredText>
    </View>
  )
}

export default MenuTitle
