const mollieDark = require('../../../assets/imgs/widgets/new_design/wg_mollie.png')
const mollieLight = require('../../../assets/imgs/widgets/new_design/wg_mollie.png')
const relevanzDark = require('../../../assets/imgs/widgets/new_design/wg_relevanz_dashboard.png')
const relevanzLight = require('../../../assets/imgs/widgets/new_design/wg_relevanz_dashboard.png')
const relevanzHistoryDark = require('../../../assets/imgs/widgets/new_design/wg_relevanz_historie.png')
const relevanzHistoryLight = require('../../../assets/imgs/widgets/new_design/wg_relevanz_historie.png')
const amazonSalesIconLight = require('../../../assets/imgs/widgets/new_design/wg_amz_umsatz.png')
const amazonSalesIconDark = require('../../../assets/imgs/widgets/new_design/wg_amz_umsatz.png')
const amazonBuyerTypeIconLight = require('../../../assets/imgs/widgets/new_design/wg_amz_gruppen.png')
const amazonBuyerTypeIconDark = require('../../../assets/imgs/widgets/new_design/wg_amz_gruppen.png')
const adLight = require('../../../assets/imgs/widgets/new_design/wg_werbung.png')
const adDark = require('../../../assets/imgs/widgets/new_design/wg_werbung.png')
const wawiLight = require('../../../assets/imgs/widgets/new_design/wg_wawi_orders.png')
const wawiDark = require('../../../assets/imgs/widgets/new_design/wg_wawi_orders.png')

import i18n from '../../../locales/i18n'
import Advertisement from './default/Advertisement'
import { Themes } from '../../../types/dashbar/theme'
import MolliePayment from './default/Mollie/Payments'
import RelevanzDashboard from './default/Relevanz/RelevanzDashboard'
import RelevanzHistory from './default/Relevanz/RelevanzHistory'
import AmazonSales from './default/Amazon/Sales'
import AmazonBuyerType from './default/Amazon/BuyerType'
import MollieDemoWidget from './demo/Mollie'
import AmazonDemoWidget from './demo/Amazon'
import WawiOrders from '../widgets_v3/default/Wawi/Orders'

export default {
  //
  //##########################
  //Demo Widgets
  //##########################
  //
  demo_mollie_v2: {
    component: MollieDemoWidget,
    title: 'Mollie',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: mollieLight,
      [Themes.Dark]: mollieDark,
    },
    shopSystem: ['mollie'],
    authorizations: ['widget.basic'],
    config: {
      extension: 'mollie',
    },
  },
  demo_amazon_v2: {
    component: AmazonDemoWidget,
    title: 'Amazon',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: amazonSalesIconLight,
      [Themes.Dark]: amazonSalesIconDark,
    },
    shopSystem: ['amazon'],
    authorizations: ['widget.basic'],
  },
  //
  //##########################
  //Default Widgets
  //##########################
  //
  advertisement_v2: {
    component: Advertisement,
    title: i18n.t('shop.widget.advertisement.title'),
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: adLight,
      [Themes.Dark]: adDark,
    },
    shopSystem: ['jtlshop4', 'jtlshop5', 'shopware6', 'shopify'],
    authorizations: [],
  },
  mollie_payment_v2: {
    component: MolliePayment,
    title: 'Mollie',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: mollieLight,
      [Themes.Dark]: mollieDark,
    },
    shopSystem: ['mollie'],
    authorizations: ['widget.business', 'widget.pro'],
    config: {
      extension: 'mollie',
    },
  },
  relevanz_dashboard_v2: {
    component: RelevanzDashboard,
    title: 'Relevanz Dashboard',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: relevanzLight,
      [Themes.Dark]: relevanzDark,
    },
    shopSystem: ['relevanz'],
    authorizations: ['widget.business', 'widget.pro'],
    config: {
      extension: 'relevanz',
    },
  },
  relevanz_history_v2: {
    component: RelevanzHistory,
    title: 'Relevanz Historie',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: relevanzHistoryLight,
      [Themes.Dark]: relevanzHistoryDark,
    },
    shopSystem: ['relevanz'],
    authorizations: ['widget.business', 'widget.pro'],
    config: {
      extension: 'relevanz',
    },
  },
  amazon_sales_v2: {
    component: AmazonSales,
    title: 'Amazon Umsatz',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: amazonSalesIconLight,
      [Themes.Dark]: amazonSalesIconDark,
    },
    shopSystem: ['amazon'],
    authorizations: ['widget.business', 'widget.pro'],
  },
  amazon_buyer_type_v2: {
    component: AmazonBuyerType,
    title: 'Amazon Kundengruppen Umsatz',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: amazonBuyerTypeIconLight,
      [Themes.Dark]: amazonBuyerTypeIconDark,
    },
    shopSystem: ['amazon'],
    authorizations: ['widget.business', 'widget.pro'],
  },
  wawi_orders_v2: {
    component: WawiOrders,
    title: 'Wawi Bestellungen',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: wawiLight,
      [Themes.Dark]: wawiDark,
    },
    shopSystem: [/*'jtlshop4', 'jtlshop5', 'shopware6', 'shopify', */ 'wawi'],
    authorizations: ['widget.business', 'widget.pro'],
  },
}
