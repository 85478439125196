import React from 'react'
import Widget from '../WidgetNoAPI'
import { FontAwesomeIcon } from '../../../components/FontAwesome/ColoredFontAwesomeIcon'
import icons from '../../../constants/icons'
import { IWidgetProps } from '../../../types/props/Widget'
import { useTranslation } from '../../../hooks/useTranslation'

const DeprecatedVersion = ({ widget, dashboardId, shops }: IWidgetProps) => {
  const { t } = useTranslation()
  return (
    <Widget
      title={'Deprecated'}
      widget={widget}
      dashboardId={dashboardId}
      shops={shops}
      loading={false}
      isError={true}
      error={{ text: `${t('widget.deprecated.error_text')}`, link: null }}
      icon={<FontAwesomeIcon style={{ marginBottom: 10 }} icon={[icons.faIconStyle, 'info-circle']} />}
    >
      {() => {
        return null
      }}
    </Widget>
  )
}

export default DeprecatedVersion
