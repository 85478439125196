import React, { useRef } from 'react'
import { Button } from 'native-base'
import { useTranslation } from 'react-i18next'
import { useAlertDialog } from '../../../../components/AlertDialog'
import { TouchableOpacity } from 'react-native'
import useStyles from './styles'
import icons from '../../../../constants/icons'
import { FontAwesomeIcon } from '../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import { useNavigate } from '../../../../components/Router/Router'
import { IWidget } from '../../../../types/dashboard'

const DeleteWidgetButtonWithModal = ({
  removeWidget,
  updateData,
  mayManageDashboard,
  widget,
  widgets,
}: {
  removeWidget: (widgetToRemove: IWidget, widgets: Array<IWidget>) => Promise<void>
  updateData: (widgets: Array<IWidget>) => void
  mayManageDashboard: boolean
  widget: IWidget
  widgets: Array<IWidget>
}) => {
  const styles = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const cancelRef = useRef(null)
  const { AlertDialog, open, close, alertDialogProps } = useAlertDialog({ leastDestructiveRef: cancelRef })

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          if (mayManageDashboard) {
            open()
          } else {
            navigate('/upselling')
          }
        }}
        style={styles.widgetButton}
      >
        <FontAwesomeIcon icon={[icons.faIconStyle, 'trash']} />
      </TouchableOpacity>
      <AlertDialog {...alertDialogProps}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>{t('dashboard.widgets.delete.title')}</AlertDialog.Header>
          <AlertDialog.Body>{t('dashboard.widgets.delete.body')}</AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button variant="unstyled" colorScheme="coolGray" onPress={close} ref={cancelRef}>
                {t('dashboard.widgets.delete.cancel')}
              </Button>
              <Button
                colorScheme="danger"
                onPress={() => {
                  removeWidget(widget, widgets).then(close)
                  updateData(widgets.filter((wgt) => wgt.id !== widget.id))
                }}
              >
                {t('dashboard.widgets.delete.confirm')}
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  )
}

export default DeleteWidgetButtonWithModal
