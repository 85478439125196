import { StyleSheet } from 'react-native'
import { useHighlightColor } from '../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      flex: 1,
      alignItems: 'baseline',
      marginRight: 8,
      marginLeft: 24,
    },
    text: {
      color: useHighlightColor(),
      fontWeight: '900',
    },
  })
}

export default useStyles
