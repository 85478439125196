import { Skeleton } from 'native-base'
import React from 'react'
import { View } from 'react-native'
import { usePrimaryBackground } from '../../../../../hooks/useColors'

const LivetrackerSkeleton = () => {
  const loadingColor = usePrimaryBackground()
  return (
    <View
      style={{
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
        padding: 5,
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <Skeleton style={{ borderRadius: 10, height: '13%', width: '95%' }} isLoaded={false} startColor={loadingColor} />
      <Skeleton
        style={{ borderRadius: 10, height: '13%', width: '85%', marginTop: 10 }}
        isLoaded={false}
        startColor={loadingColor}
      />
      <Skeleton
        style={{ borderRadius: 10, height: '13%', width: '75%', marginTop: 10 }}
        isLoaded={false}
        startColor={loadingColor}
      />
      <Skeleton
        style={{ borderRadius: 10, height: '13%', width: '65%', marginTop: 10 }}
        isLoaded={false}
        startColor={loadingColor}
      />
    </View>
  )
}

export default LivetrackerSkeleton
