import Subscription from './components/Subscription'
import PageWrapper from '../../../../components/PageWrapper'
import Profile from './components/Profile'
import Company from './components/Company'
import Bills from './components/Bills'
import React from 'react'
import { useParams } from 'react-router'
import ReleaseInfo from './components/ReleaseInfo'

const AccountOverview = () => {
  const { invitationCode } = useParams<{ invitationCode: string }>()
  return (
    <PageWrapper isScrollable={true}>
      <Profile />
      <Subscription />
      <Company invitationCode={invitationCode} />
      <Bills />
      <ReleaseInfo />
    </PageWrapper>
  )
}

export default AccountOverview
