import { StyleSheet } from 'react-native'
import { useSecondaryBackground } from '../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    chip: {
      margin: '1%',
    },
    button: {
      backgroundColor: useSecondaryBackground(),
      width: '100%',
      minHeight: 40,
      marginTop: 5,
      borderRadius: 15,
    },
    notificationCard: {
      marginTop: 5,
      backgroundColor: useSecondaryBackground(),
      borderRadius: 15,
    },
  })
}

export default useStyles
