import { useContext } from 'react'
import { SessionContext } from '../../../../../../context/SessionContext'
import ComponentWrapper from '../../../../../../components/ComponentWrapper'
import BundleCard from '../Bundle'
import React from 'react'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { IBundle } from '../../../../../../types/booking'

const AvailableBundles = () => {
  const { t } = useTranslation()
  const { company } = useContext(SessionContext)
  if (company && company.bundle) {
    return (
      <ComponentWrapper title={t('subscription.available_subscriptions')}>
        {company.bundle !== IBundle.free && <BundleCard bundle={IBundle.free} />}
        {company.bundle !== IBundle.business && <BundleCard bundle={IBundle.business} />}
        {company.bundle !== IBundle.pro && <BundleCard bundle={IBundle.pro} />}
      </ComponentWrapper>
    )
  }
  return null
}

export default AvailableBundles
