import React from 'react'
import { useTranslation } from 'react-i18next'
import { IWidgetProps } from '../../../../../types/props/Widget'
import AreaChart from '../../../../../components/VictoryCharts/Area'
import { OrderValueT } from '../../../../../types/widgetDataTypes/types'
import Widget from '../../../../components/Widget'
import { useNavigate } from '../../../../../components/Router/Router'
import OrdervalueSkeleton from './OrdervalueSkeleton'
import { ISettings } from '../../../../../types/dashboard'

const OrderValue = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Widget<OrderValueT>
      title={t('shop.widget.order-value.title')}
      widget={widget}
      hasInterval
      loadingSkeleton={<OrdervalueSkeleton />}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, interval = 'D30' }: ISettings) => ({
          body: {
            module: 'Analytics',
            endpoint: ['sales'],
            params: {
              options: {
                details: true,
                interval,
                refresh: autoRefresh ? refreshInterval : undefined,
              },
            },
          },
          widget: 'sales',
        }),
      ]}
      onClickWidget={() => {
        navigate(`/analytics/orders/${widget.settings.shop}`)
      }}
      {...props}
    >
      {([, data, , , size]) => {
        if (!data) return null
        if (data && data?.avg_history) {
          return <AreaChart data={[data.avg, ...data.avg_history.map((val) => val)].reverse()} size={size} />
        } else {
          return <AreaChart data={[data.avg]} size={size} />
        }
      }}
    </Widget>
  )
}

export default OrderValue
