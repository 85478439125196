import * as React from 'react'
import Svg, { SvgProps, LinearGradient, Stop, Mask, Path, G } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const Creditcard = (props: SvgProps) => (
  <Svg fill="none" height={24} width={32} {...props}>
    <LinearGradient
      id="c"
      gradientTransform="scale(45.2548 33.9411) rotate(-45 .25 .604)"
      gradientUnits="userSpaceOnUse"
      x2={1}
    >
      <Stop offset={0} stopColor="#060b0e" />
      <Stop offset={1} stopColor="#254764" />
    </LinearGradient>
    <LinearGradient id="a">
      <Stop offset={0} stopColor="#fff" stopOpacity={0} />
      <Stop offset={1} stopColor="#fff" />
    </LinearGradient>
    <LinearGradient
      id="d"
      gradientTransform="rotate(-110.052 12.697 4.253) scale(9.27096)"
      gradientUnits="userSpaceOnUse"
      x2={1}
    />
    <LinearGradient
      id="e"
      gradientTransform="rotate(-103.962 18.194 13.83) scale(15.1227)"
      gradientUnits="userSpaceOnUse"
      x2={1}
    />
    <LinearGradient
      id="f"
      gradientTransform="rotate(76.037 -.735 33.987) scale(24.2879)"
      gradientUnits="userSpaceOnUse"
      x2={1}
    />
    <LinearGradient id="h" gradientTransform="matrix(0 5 -7 0 7 0)" gradientUnits="userSpaceOnUse" x2={1}>
      <Stop offset={0} stopColor="#fad961" />
      <Stop offset={1} stopColor="#f7955d" />
    </LinearGradient>
    <Mask id="b" height={24} width={32} x={0} y={0}>
      <Path d="M28 0H4a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z" fill="#fff" />
    </Mask>
    <G mask="url(#b)">
      <Path d="M28 0H4a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z" fill="url(#c)" />
      <Path
        d="M31.5 63C48.897 63 63 48.897 63 31.5S48.897 0 31.5 0 0 14.103 0 31.5 14.103 63 31.5 63z"
        fill="url(#d)"
        opacity={0.1}
        transform="translate(6 6)"
      />
      <Path
        d="M16.5 33C25.613 33 33 25.613 33 16.5S25.613 0 16.5 0 0 7.387 0 16.5 7.387 33 16.5 33z"
        fill="url(#e)"
        opacity={0.1}
        transform="translate(-10 12)"
      />
      <Path
        d="M26.5 53C41.136 53 53 41.136 53 26.5S41.136 0 26.5 0 0 11.864 0 26.5 11.864 53 26.5 53z"
        fill="url(#f)"
        opacity={0.1}
        transform="translate(16 -31)"
      />
      <G>
        <Path d="M10 7H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1z" fill="#000" />
      </G>
      <Path
        d="M6 0H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z"
        fill="url(#h)"
        transform="translate(4 7)"
      />
      <Path d="M6 8H5v3h1zM8 8H7v3h1zM10 8H9v3h1z" fill="#7a3802" opacity={0.3} />
      <G fill="#fff" fillOpacity={0.8}>
        <Path d="M9 15H4v1h5zM7 17H4v1h3zM11 17H8v1h3zM15 17h-3v1h3zM15 15h-5v1h5zM21 15h-5v1h5zM27 15h-5v1h5z" />
      </G>
    </G>
  </Svg>
)

export default Creditcard
