import { IconProp } from '@fortawesome/fontawesome-svg-core'
import icons from '../constants/icons'

// ToDO folgenden Staten übernehmen: pending, paid, other, sent, closed
export type OrderStatusType = 'paid' | 'shipped' | 'open' | 'pending' | 'canceled'

const getOrderStatusIcon = (status: OrderStatusType): IconProp => {
  switch (status) {
    case 'paid':
      return [icons.faIconStyle, 'money-bill-wave']
    case 'shipped':
      return [icons.faIconStyle, 'truck-moving']
    case 'open':
      return [icons.faIconStyle, 'shopping-cart']
    case 'pending':
      return [icons.faIconStyle, 'hand-holding-box']
    case 'canceled':
      return [icons.faIconStyle, 'exclamation-circle']
    default:
      return [icons.faIconStyle, 'exclamation-circle']
  }
}

export default getOrderStatusIcon
