import React from 'react'
import { View } from 'react-native'
import useStyles from './styles'
import md5 from 'md5'
import numbro from 'numbro'
import Avatar from '../../../../../components/Avatar'
import LiveTrackerRow from './LiveTrackerRow'
import { useTranslation } from 'react-i18next'
import { EventDataT, UserT } from '../../../../../types/widgetDataTypes/V3/types'
import { faSackDollar, faUsers, faUsersClass } from '@fortawesome/pro-duotone-svg-icons'
import icons from '../../../../../constants/icons'
import { FontAwesomeIcon } from '../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import htmlDecode from '../../../../../utils/htmlDecode'

type PropsT = EventDataT & {
  currency: string
}

const LiveTrackerView = ({
  visitors_count,
  logged_in_count,
  visitor_arr,
  baskets,
  basket_value,
  orders_today,
  orders_value,
  currency,
}: PropsT) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const getUserAvatar = ({ created_at, city }: UserT, index: number) => (
    <>
      <Avatar
        key={created_at + city + index}
        style={[
          styles.avatar,
          {
            position: 'absolute',
            left: 15 * index,
          },
        ]}
        // ToDo Avatar Service nötig
        photoURL={`https://avatar.ws-svc.de/?seed=${md5((created_at + city).toLowerCase())}`}
        accessibilityLabel={(created_at + city).toLowerCase()}
      />
    </>
  )

  //const visitorAvatars = data.slice(0, Math.min(visitors, 8)).map(getUserAvatar)
  // from 27-roadmap access mannagement:
  const visitorAvatars =
    visitor_arr !== null
      ? visitor_arr
          .slice(0, Math.min(logged_in_count, 20))
          .reduce((list, datum) => {
            if (list.findIndex(({ mail }) => mail === datum.mail) === -1) {
              return [...list, datum]
            }
            return list
          }, [])
          .slice(0, Math.min(visitors_count, 8))
          .map(getUserAvatar)
      : null

  const customerAvatars =
    visitor_arr !== null
      ? visitor_arr
          .filter(({ customer_id }) => customer_id != null)
          // from 27-roadmap access mannagement
          .slice(0, Math.min(logged_in_count, 20))
          .reduce((list, datum) => {
            if (list.findIndex(({ mail }) => mail === datum.mail) === -1) {
              return [...list, datum]
            }
            return list
          }, [])
          //
          .slice(0, Math.min(logged_in_count, 7))
          .map(getUserAvatar)
      : null

  const basketIcon = <FontAwesomeIcon style={styles.basketIcon} icon={[icons.faIconStyle, 'shopping-cart']} />

  const rows = [
    {
      key: 'visitors',
      width: '100%',
      value: visitors_count,
      label: t('shop.widget.live-tracker.visitors'),
      avatars: visitorAvatars?.length
        ? visitorAvatars
        : [<FontAwesomeIcon key={'visitors_icon'} icon={faUsersClass} />],
    },
    {
      key: 'customers',
      width: '90%',
      value: logged_in_count,
      label: t('shop.widget.live-tracker.customers'),
      avatars: customerAvatars?.length ? customerAvatars : [<FontAwesomeIcon key={'customers_icon'} icon={faUsers} />],
    },
    {
      key: 'baskets',
      width: '80%',
      value: baskets !== null ? baskets : orders_today,
      label:
        baskets !== null
          ? t('shop.widget.live-tracker.baskets', { count: baskets })
          : t('shop.widget.live-tracker.ordersToday', { count: orders_today }),
      icon: basketIcon,
    },
    {
      key: 'basketValue',
      width: '70%',
      value: numbro(basket_value !== null ? basket_value : orders_value).formatCurrency({
        ...numbro.languageData().currencyFormat,
        currencySymbol: htmlDecode(currency),
        spaceSeparated: true,
        mantissa: 0,
      }),
      label:
        basket_value !== null ? t('shop.widget.live-tracker.basketValue') : t('shop.widget.live-tracker.orderValue'),
      icon: <FontAwesomeIcon style={styles.basketIcon} icon={faSackDollar} />,
    },
  ]

  return (
    <View style={styles.content}>
      {rows.map(({ key, ...props }, index) => (
        <LiveTrackerRow key={key} {...props} index={index} />
      ))}
    </View>
  )
}

export default LiveTrackerView
