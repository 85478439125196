import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import ColoredText from '../../../components/CustomText/ColoredText'
import DefaultDashbarIcon from '../DefaultDashbarIcon.tsx'
import WidgetView from '../../../components/Widget'
import { useTextColor } from '../../../hooks/useColors'
import { IShop } from '../../../types/dashboard'
import Button from '../../../components/Button/index.tsx'
import { useNavigate } from '../../../components/Router/Router/index.tsx'
const defaultFavIcon = 'https://static.dash.bar/app/img/dblogo.png'

const ConfigComponent = ({
  shop,
  title,
  image,
}: {
  shop: IShop
  title: string
  image?:
    | {
        source: string
        alt: string
      }
    | undefined
}) => {
  // const errorInfo = getErrorByStatusCode(+(error?.status || 500))
  const textColor = useTextColor()
  const navigate = useNavigate()
  return (
    <WidgetView
      title={title}
      needsConfig
      image={
        image
          ? image
          : shop?.icon
            ? {
                source: shop?.icon ?? defaultFavIcon,
                alt: shop?.domain ?? '(domain missing)',
              }
            : undefined
      }
      icon={shop?.icon ? undefined : <DefaultDashbarIcon />}
    >
      <FontAwesomeIcon style={{ margin: 5, fontSize: 40 }} color={textColor} icon={['fas', 'gear']} />
      <ColoredText style={{ padding: 2, fontSize: 16, fontWeight: 'bold' }}>{`Einrichtung`}</ColoredText>
      <ColoredText
        style={{ padding: 5, paddingLeft: 2, fontSize: 14, fontWeight: '300' }}
      >{`Dieses Widget benötigt eine erweiterte Einrichtung`}</ColoredText>

      <Button
        style={{ backgroundColor: '#FFF', padding: 5, borderRadius: 10 }}
        onPress={() => navigate('/settings?t=2')}
      >
        <ColoredText
          style={{
            padding: 5,
            paddingLeft: 2,
            fontSize: 14,
            fontWeight: '300',
            textAlign: 'center',
          }}
        >{`Einrichten`}</ColoredText>
      </Button>
    </WidgetView>
  )
}

export default ConfigComponent
