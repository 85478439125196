import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Navigate, useLocation } from '../../../../components/Router/Router'
import LoadingScreen from '../../../../components/LoadingScreen'
import { functions } from '../../../../firebase'

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const OAuthHandle = () => {
  const [path, setPath] = useState<string | null>(null)
  const query = useQuery()

  //Params general
  const state = query.get(`state`)

  let code: string | null = null
  let meta: Record<string, any> | null = null

  //Params for GoogleSearchConsole
  code = query.get(`code`)
  meta = null

  //Params for Amazon
  if (code === null) {
    code = query.get(`spapi_oauth_code`)
    const partnerId = query.get(`selling_partner_id`)
    meta = { partnerId: partnerId }
  }

  //Params for GoogleSearchConsole

  const handleResponse = functions().httpsCallable('widgets-helpers-oauth-handleResponse')

  useEffect(() => {
    handleResponse({ state: state, code: code, meta: meta })
      .then((result: Record<string, any>) => {
        // console.log(`Should redirect to ${result.data.path}`)
        setPath(result.data.path)
      })
      .catch(() => {
        // console.log(`Error ${err}`)
        // console.log(`Should redirect to app.dash.bar`)
        setPath('/')
      })
  }, [])

  if (path && path !== null) {
    return <Navigate to={path} />
  }

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* <ColoredText>{`Handle Request`}</ColoredText>
      <ColoredText>{query.get(`state`)}</ColoredText>
      <ColoredText>{query.toString()}</ColoredText> */}
      <LoadingScreen text="Handle OAuth Request" />
    </View>
  )
}

export default OAuthHandle
