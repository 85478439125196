import { Platform, StyleSheet } from 'react-native'
import { useSecondaryBackground } from '../../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: Platform.select({ default: 'flex-start', web: undefined }),
      backgroundColor: 'transparent',
      marginTop: 10,
    },
    container: {
      flex: 1,
      width: '100%',
      alignItems: 'stretch',
      justifyContent: Platform.select({ default: 'flex-start', web: undefined }),
      marginBottom: 5,
    },
    text: {
      marginHorizontal: 5,
      width: '100%',
      alignContent: 'center',
      textAlign: 'center',
    },
    textProduct: {
      marginHorizontal: 5,
      width: '100%',
      fontWeight: '400',
      fontSize: 17,
      alignContent: 'center',
      textAlign: 'center',
      marginBottom: 3,
    },
    textInfo: {
      marginHorizontal: 5,
      width: '100%',
      fontWeight: '100',
      fontSize: 12,
      fontStyle: 'italic',
      alignContent: 'center',
      textAlign: 'center',
    },
    textAmount: {
      borderRadius: 15,
      borderStyle: 'solid',
      borderWidth: 1,
      paddingVertical: 2,
      paddingHorizontal: 6,
      borderColor: 'green',
    },
    card: {
      width: '100%',
      borderRadius: 15,
      backgroundColor: useSecondaryBackground(),
      padding: 10,
      marginVertical: 5,
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'flex-start',
    },
    textWrapper: {
      flex: 1,
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
    },
    icon: {
      height: 40,
      width: 40,
      marginLeft: 10,
      marginRight: 30,
    },
  })
}

export default useStyles
