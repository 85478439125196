import * as React from 'react'
import Svg, { SvgProps, G, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */

const NoFavorite = (props: SvgProps) => (
  <Svg width={20} height={20} {...props}>
    <G stroke="#F5A623" fill="none" fillRule="evenodd">
      <Path d="M16.667.5H3.333c-.785 0-1.498.3-2.013.788a2.57 2.57 0 0 0-.82 1.87v13.684c0 .731.315 1.392.82 1.87a2.919 2.919 0 0 0 2.013.788h13.334c.785 0 1.498-.3 2.013-.788a2.57 2.57 0 0 0 .82-1.87V3.158a2.57 2.57 0 0 0-.82-1.87A2.919 2.919 0 0 0 16.667.5Z" />
      <Path d="M3.24 14.912h13.52c1.278 0 2.314.982 2.314 2.193s-1.036 2.193-2.315 2.193H3.241c-1.279 0-2.315-.982-2.315-2.193 0-1.21 1.036-2.193 2.315-2.193Z" />
    </G>
  </Svg>
)

export default NoFavorite
