import { StyleSheet, Platform } from 'react-native'
import colors from '../../../../../constants/colors'
import { useTextColor } from '../../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,

      justifyContent: 'space-between',
    },
    keyFigures: {
      flexDirection: 'row',
    },
    item: {
      flex: 1,
      flexDirection: 'row',
      padding: 5,
    },

    titleWrapper: {
      flexDirection: 'column',
    },
    itemTitle: {
      fontSize: 12,
      color: useTextColor(),
      marginBottom: Platform.select({ default: undefined, web: 4 }),
    },
    itemCurrentValue: {
      fontSize: 15,
    },
    percentage: {
      flex: 1,
      textAlign: 'right',
      alignSelf: 'flex-end',
      fontSize: 12,
    },
    negative: {
      color: colors.error,
    },
    positive: {
      color: colors.success,
    },
    status: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    statusItem: {
      flexDirection: 'row',
      width: '50%',
      padding: 5,
    },

    itemText: {
      fontSize: 10,
      paddingLeft: 10,
      lineHeight: Platform.select({ default: 15, ios: 18 }),
    },
  })
}

export default useStyles
