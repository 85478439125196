import { Skeleton } from 'native-base'
import React from 'react'
import { View } from 'react-native'
import { usePrimaryBackground } from '../../../../../hooks/useColors'

const SalesSkeleton = () => {
  const loadingColor = usePrimaryBackground()
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '100%',
        padding: 5,
        alignContent: 'flex-end',
        alignItems: 'flex-end',
      }}
    >
      <Skeleton style={{ borderRadius: 10, height: '60%', width: '15%' }} isLoaded={false} startColor={loadingColor} />
      <Skeleton style={{ borderRadius: 10, height: '80%', width: '15%' }} isLoaded={false} startColor={loadingColor} />
      <Skeleton style={{ borderRadius: 10, height: '70%', width: '15%' }} isLoaded={false} startColor={loadingColor} />
      <Skeleton style={{ borderRadius: 10, height: '100%', width: '15%' }} isLoaded={false} startColor={loadingColor} />
    </View>
  )
}

export default SalesSkeleton
