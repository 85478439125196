import { Platform, StyleSheet } from 'react-native'
import colors from '../../../../constants/colors'
import { useTextColor } from '../../../../hooks/useColors'
import { Themes } from '../../../../types/dashbar/theme'
import { useTheme } from '../../../../utils/types/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      flex: 1,
      width: '100%',
      flexDirection: 'column',
      alignContent: 'flex-start',
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent1 : colors.lightBgTransparent1,
      marginHorizontal: 8,
      marginTop: 4,
      marginBottom: 4,
      borderRadius: 16,
      padding: 8,
    },

    shopHeaderWrapper: {
      flex: 1,
      flexDirection: 'row',
      marginBottom: 2,
    },

    iconWrapper: {
      alignContent: 'flex-end',
    },

    iconBackground: {
      backgroundColor: colors.lightBg1,
      borderRadius: 16,
    },

    name: {
      paddingLeft: 8,
      color: useTextColor(),
      fontSize: 16,
      alignSelf: Platform.select({ web: 'center', default: undefined }),
    },

    icon: {
      height: 32,
      width: 32,
    },

    shopItemsWrapper: {
      marginBottom: 8,
      marginLeft: 16,
      marginRight: 5,
    },

    shopSubHeaderWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginVertical: 4,
      padding: 12,
      borderRadius: 16,
      backgroundColor: theme === Themes.Dark ? colors.lightBgTransparent2 : colors.darkBgTransparent7,
    },

    shopItems: {
      fontSize: 14,
    },
  })
}

export default useStyles
