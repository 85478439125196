import { Platform, StyleSheet } from 'react-native'
import { useHighlightColor } from '../../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    iconWrapper: {
      borderRadius: 12.5,
      backgroundColor: useHighlightColor(),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    orderValue: {
      color: useHighlightColor(),
    },

    actionSubtitle: {
      marginTop: Platform.select({ default: undefined, web: 2 }),
    },
  })
}

export default useStyles
