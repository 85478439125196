import React from 'react'
import { VictoryPie } from 'victory'
import colors from '../../../../../../../constants/colors'

const DeviceGraph = ({
  size,
  displayedData,
}: {
  size: { width: number; height: number }
  displayedData: Array<{ title: string; y: number; color: string }>
}) => {
  return (
    <VictoryPie
      width={size.width + 50}
      height={size.height}
      radius={size.height * 0.3}
      innerRadius={size.height * 0.44}
      colorScale={[colors.color1, colors.color2, colors.color3]}
      labels={displayedData.map(() => '')}
      data={displayedData}
    />
  )
}
export default DeviceGraph
