/**
 * Firebase/PubSub/Scheduler/Call Functions
 */
export const FUNCTIONS_REGION = 'europe-west3'

/**
 * Hosting mapped Functions
 */
export const HOSTING_FUNCTIONS_REGION = 'us-central1'

/**
 * OAuth Request gets outdated, after x minutes.
 **/
export const OAUTH_STATE_LIFETIME = 3

export const SCHEDULER_CHECK_BOOKINGS_SCHEDULE = '0 */12 * * *'
export const SCHEDULER_BACKUP_SCHEDULE = '0 2 * * *'
export const SCHEDULER_REMINDER_SHEDULE = '0 8 * * *'
export const SCHEDULER_CHECK_INVALID_BOOKINGS_SCHEDULE = '*/30 * * * *'
export const SCHEDULER_TWITTER_SYNC_SCHEDULE = 'every 60 minutes'

/**
 * Time in days after that company account should be blocked if there is no payment for repeated booking
 */
export const BOOKING_TOLERANCE_REPEATED_IN_DAYS = 8

/**
 * Time in days after that company account should be blocked if there is no payment for first booking
 */
export const BOOKING_TOLERANCE_FIRST_IN_DAYS = 14

/**
 * Time in days after that company account should be blocked if there is no payment for first booking
 */
export const BOOKING_TOLERANCE_EXTENSION = 4

/**
 * Time in days after that company account should be blocked if there is no payment for first booking
 */
export const BOOKING_DELETE_PENDING_AFTER = 6

/**
 * Time in days after that reminder mail should be send to notify about missing payment
 */
export const BOOKING_PAYMENT_REMINDER_AFTER_DAYS = 5

/**
 * Time in days after that reminder mail should be send to notify about missing payment
 */
export const BOOKING_TRIAL_REMINDER_BEFORE_DAYS = 3

/**
 * Vat amount for 'de'
 */
export const VAT_AMOUNT_IN_PERCENT_DEFAULT = 19

/**
 * Vat amount for EU
 */
export const VAT_AMOUNT_IN_PERCENT_EU = 0

/**
 * Vat amount for non EU
 */
export const VAT_AMOUNT_IN_PERCENT_NOT_EU = 0

/**
 * Part id for dash.bar pro
 */
export const SEVDESK_PART_ID_DASHBAR_PRO = 4651062

/**
 * Part id for dash.bar business
 */
export const SEVDESK_PART_ID_DASHBAR_BUSINESS = 4651059

/**
 * Contact person from webstollen
 */
export const SEVDESK_CONTACT_PERSON_ID = 312392

/**
 * Payment Method abocloud
 */
export const SEVDESK_PAYMENT_METHOD_ID_ABOCLOUD = 55502

/**
 * CostCentre dashbar
 */
export const SEVDESK_COST_CENTRE_ID_DASHBAR = 22578

/**
 * CostCentre dashbar
 */
export const SEVDESK_TAX_SET_ID_EU = 23088

/**
 * CostCentre dashbar
 */
export const SEVDESK_TAX_SET_ID_NOT_EU = 23129

/**
 * CostCentre dashbar
 */
export const SEVDESK_TAX_SET_ID_DEFAULT = 40966

/**
 * Trial length in days
 */
export const TRIAL_IN_DAYS = 14

export const BASIC_DASHBOARD_CONFIG: Array<{ type: string; shop: string | null }> = [
  { type: 'shop.basic_conversion', shop: null },
  { type: 'shop.basic_live_tracker', shop: null },
  { type: 'shop.basic_orders', shop: null },
  { type: 'shop.basic_sales', shop: null },
  { type: 'shop.basic_order_value', shop: null },
  { type: 'misc.advertisement', shop: null },
  { type: 'shop.basic_pagespeed_performance', shop: null },
  { type: 'shop.basic_pagespeed_performance_mobile', shop: null },
  { type: 'shop.basic_pagespeed_histperformance', shop: null },
  { type: 'shop.basic_pagespeed_histperformance_mobile', shop: null },
  { type: 'shop.demo_forecast', shop: null },
  { type: 'shop.demo_gsc', shop: null },
  { type: 'shop.demo_pagespeed', shop: null },
  { type: 'misc.demo_mollie', shop: `mollie` },
  { type: 'misc.demo_amazon', shop: `amazon` },
  { type: 'misc.advertisement', shop: null },
]

export const DEFAULT_DASHBOARD_CONFIG: Array<{ type: string; shop: string | null }> = [
  { type: 'shop.conversion', shop: null },
  { type: 'shop.live_tracker', shop: null },
  { type: 'shop.orders', shop: null },
  { type: 'shop.runners', shop: null },
  { type: 'shop.sales', shop: null },
  { type: 'misc.advertisement', shop: null },
  { type: 'shop.order_value', shop: null },
  { type: 'shop.status', shop: null },
  { type: 'shop.gender', shop: null },
  { type: 'shop.pagespeed_performance', shop: null },
  { type: 'shop.pagespeed_performance_mobile', shop: null },
  { type: 'shop.pagespeed_histperformance', shop: null },
  { type: 'shop.pagespeed_histperformance_mobile', shop: null },
  { type: 'shop.pagespeed_webvitals', shop: null },
  { type: 'shop.pagespeed_webvitals_mobile', shop: null },
  { type: 'shop.sale_forecast', shop: null },
  { type: 'shop.visitor_forecast', shop: null },
  { type: 'shop.gsc_top5_search', shop: null },
  { type: 'shop.gsc_devices', shop: null },
  { type: 'shop.gsc_overview', shop: null },
  { type: 'misc.mollie_payment', shop: `mollie` },
  { type: 'misc.relevanz_dashboard', shop: `relevanz` },
  { type: 'misc.relevanz_history', shop: `relevanz` },
  { type: 'misc.amazon_sales', shop: `amazon` },
  { type: 'misc.amazon_buyer_type', shop: `amazon` },
  { type: 'misc.advertisement', shop: null },
]
