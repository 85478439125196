import { useEffect, useState } from 'react'
import { formatURL } from './helper'
import Top5Rows from './Top5Rows'
import React from 'react'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { useTranslation } from '../../../../../../hooks/useTranslation'

const ShopSelection = ({
  setSelectedShop,
  setShopError,
  setError,
  access_token,
  selectedShop,
}: {
  setSelectedShop: (newShop: string) => void
  setShopError: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<
    React.SetStateAction<{
      text: string
      link: string | null
      history?: string | null | undefined
    }>
  >
  access_token: string | undefined
  selectedShop: string | null
}) => {
  const [shops, setShops] = useState<Array<{ siteUrl: string; permissionLevel: string }>>([])
  const [tokenRefreshed, setTokenRefreshed] = useState(false)
  // const handleRefreshGoogleToken = functions().httpsCallable('widgets-googleSearchConsole-handleRefreshGoogleToken')
  const preurl = 'https://search.google.com/search-console'
  const postURL = selectedShop !== null ? `?resource_id=https%3A%2F%2F${selectedShop}%2F` : ''
  const { t } = useTranslation()

  useEffect(() => {
    //Fetching the top 5 search terms
    fetch('https://www.googleapis.com/webmasters/v3/sites', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${access_token}`,
        'User-Agent':
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_2) AppleWebKit/537.17 (KHTML, like Gecko) Chrome/24.0.1309.0 Safari/537.17',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          const resp = response.json()
          return resp
        }
      })
      .then((data) => {
        if (data.siteEntry === undefined) {
          setError({ text: t(`shop.widget.googleSearchConsole.error.noShop`), link: preurl + postURL })
          setShopError(true)
        } else {
          setShops(data.siteEntry)
        }
      })
      .catch((error) => {
        setTokenRefreshed(true)
        // handleRefreshGoogleToken({ companyId: company?.id })
        console.error(error.message)
        setError({ text: t(`shop.widget.googleSearchConsole.error.noShop`), link: preurl + postURL })
        setShopError(true)
      })
  }, [tokenRefreshed])
  return (
    <>
      <Top5Rows description key={'selectShop'} title={'Select Shop'} value={null} />
      {shops
        ?.sort((a, b) => (formatURL(a.siteUrl) > formatURL(b.siteUrl) ? 1 : -1))
        .map((s) => {
          const shop = formatURL(s.siteUrl)
          return (
            <Top5Rows
              onPress={() => {
                setSelectedShop(shop)
                setShopError(false)
              }}
              key={s.siteUrl}
              title={s.siteUrl}
              value={null}
            />
          )
        })}
      {shops?.length === 0 && <ColoredText style={{ alignSelf: 'center' }}>no Shops found</ColoredText>}
    </>
  )
}

export default ShopSelection
