import {
  Amount,
  AmountDifference,
  BookingState,
  BundlePrice,
  CouponAmount,
  IBundle,
  Interval,
  MwstAmount,
} from '../types/booking'
import {
  optionsBusiness,
  optionsEnterprise,
  optionsFree,
  optionsPro,
  VAT_AMOUNT_IN_PERCENT_DEFAULT,
  VAT_AMOUNT_IN_PERCENT_EU,
  VAT_AMOUNT_IN_PERCENT_NOT_EU,
} from '../constants/bundle'
import i18n from '../locales/i18n'
import moment from 'moment'
import Dinero from 'dinero.js'
import { ExtensionPrice } from '../types/extensions'

export const translateTransactionState = (state: number): string => {
  switch (state) {
    case -1:
      return i18n.t('states.transaction.failed')
    case 0:
    case 1:
    case 2:
      return i18n.t('states.transaction.open')
    case 3:
    case 4:
      return i18n.t('states.transaction.paid')
    default:
      return i18n.t('states.transaction.failed')
  }
}

export const currencyToSymbol = (currency: string): string => {
  switch (currency) {
    case 'EUR':
    default:
      return '€'
  }
}

export const userForBundle = (bundle: string): string => {
  switch (bundle) {
    case 'enterprise':
      return i18n.t('subscription.bundle_items.user', { count: optionsEnterprise.user })
    case 'pro':
      return i18n.t('subscription.bundle_items.user', { count: optionsPro.user })
    case 'business':
      return i18n.t('subscription.bundle_items.user', { count: optionsBusiness.user })
    case 'free':
    default:
      return i18n.t('subscription.bundle_items.user', { count: optionsFree.user })
  }
}

export const shopsForBundle = (bundle: string): string => {
  switch (bundle) {
    case 'enterprise':
      return i18n.t('subscription.bundle_items.shops', { count: optionsEnterprise.shops })
    case 'pro':
      return i18n.t('subscription.bundle_items.shops', { count: optionsPro.shops })
    case 'business':
      return i18n.t('subscription.bundle_items.shops', { count: optionsBusiness.shops })
    case 'free':
    default:
      return i18n.t('subscription.bundle_items.shops', { count: optionsFree.shops })
  }
}

export const dashboardsForBundle = (bundle: string): string => {
  switch (bundle) {
    case 'enterprise':
      return i18n.t('subscription.bundle_items.dashboards', { count: optionsEnterprise.dashboards })
    case 'pro':
      return i18n.t('subscription.bundle_items.dashboards', { count: optionsPro.dashboards })
    case 'business':
      return i18n.t('subscription.bundle_items.dashboards', { count: optionsBusiness.dashboards })
    case 'free':
    default:
      return i18n.t('subscription.bundle_items.dashboards', { count: optionsFree.dashboards })
  }
}

export const notificationsForBundle = (bundle: string): string => {
  switch (bundle) {
    case 'enterprise':
      return i18n.t('subscription.bundle_items.notifications', { count: optionsEnterprise.notifications })
    case 'pro':
      return i18n.t('subscription.bundle_items.notifications', { count: optionsPro.notifications })
    case 'business':
      return i18n.t('subscription.bundle_items.notifications', { count: optionsBusiness.notifications })
    case 'free':
    default:
      return i18n.t('subscription.bundle_items.notifications', { count: optionsFree.notifications })
  }
}

/** Returns interval as readable text
 *
 * @param interval
 * @returns
 */
export const getTextForInterval = (interval: 'month' | 'year', isTrial = false): string => {
  if (isTrial) return i18n.t('subscription.trial')
  switch (interval) {
    case 'year':
      return i18n.t('subscription.interval.text_year')
    case 'month':
    default:
      return i18n.t('subscription.interval.text_month')
  }
}

export const getStatusTextForBookingProvider = (
  isActive: boolean,
  bookingProvider: string | null,
  isFree = false,
  endDate?: Date
): string => {
  if (isFree) return ``
  if (bookingProvider === 'apple') {
    if (isActive) {
      return i18n.t('subscription.status_messages.apple.active')
    } else {
      return i18n.t('subscription.status_messages.apple.canceled')
    }
  } else {
    if (isActive) {
      return i18n.t('subscription.status_messages.abocloud.active', { date: moment(endDate).format('DD.MM.YYYY') })
    } else {
      return i18n.t('subscription.status_messages.abocloud.canceled', { date: moment(endDate).format('DD.MM.YYYY') })
    }
  }
}

export const getCurrentBundleStateAsString = (
  currentBundle?: Record<string, any>,
  nextBundle?: Record<string, any>
): string => {
  if (nextBundle && nextBundle.bundle) {
    if (currentBundle && currentBundle.status) {
      switch (currentBundle?.status) {
        case BookingState.Pending:
          return i18n.t('subscription.status_messages.pending')
        case BookingState.Active:
          return i18n.t('subscription.status_messages.active', {
            date: moment(currentBundle?.endDate?.toDate()).format('DD.MM.YYYY'),
          })
        case BookingState.Cancled:
          return i18n.t('subscription.status_messages.change', {
            date: moment(currentBundle?.endDate?.toDate()).format('DD.MM.YYYY'),
            bundle: `${nextBundle.bundle.toLowerCase()} (${getTextForInterval(nextBundle?.interval || 'month')})`,
          })
      }
    }
  } else {
    if (currentBundle && currentBundle.status) {
      switch (currentBundle?.status) {
        case BookingState.Pending:
          return i18n.t('subscription.status_messages.pending')
        case BookingState.Active:
          return i18n.t('subscription.status_messages.active', {
            date: moment(currentBundle?.endDate?.toDate()).format('DD.MM.YYYY'),
          })
        case BookingState.Cancled:
          return i18n.t('subscription.status_messages.canceled', {
            date: moment(currentBundle?.endDate?.toDate()).format('DD.MM.YYYY'),
          })
      }
    }
  }
  return ''
}

export const getCancelText = (currentBundle: Record<string, any>): string => {
  if (currentBundle && currentBundle.status) {
    switch (currentBundle.status) {
      case BookingState.Active:
        return i18n.t('subscription.delete_messages.active', {
          date: moment(currentBundle?.endDate?.toDate()).format('DD.MM.YYYY'),
        })
      case BookingState.Pending:
        return i18n.t('subscription.delete_messages.pending')
      case BookingState.Cancled:
      case BookingState.Deleted:
      default:
        return i18n.t('subscription.delete_messages.canceled')
    }
  }
  return ''
}

export const calculateMwSt = (amount: Amount, countryCode = 'de'): MwstAmount => {
  const vatPercentage = getVatPercentage(countryCode)
  const amountDinero = Dinero({
    amount: parseInt(amount.value.replace('.', '')),
  })
  const mwst = amountDinero.percentage(vatPercentage)
  const totalAmount = amountDinero.add(mwst)
  return {
    amount: { currency: 'EUR', value: toFormatSafe(amountDinero) },
    mwst: { currency: 'EUR', value: toFormatSafe(mwst) },
    totalAmount: { currency: 'EUR', value: toFormatSafe(totalAmount) },
  }
}

/**
 * Calculates endDate of period by given date and interval
 * @param interval
 * @param date
 * @returns endDate of period
 */
export const calculateDateForInterval = (interval: 'month' | 'year', date: Date): Date => {
  let returnDate: Date
  switch (interval) {
    case 'month':
      returnDate = new Date(date.setMonth(date.getMonth() + 1))
      break
    case 'year':
      returnDate = new Date(date.setFullYear(date.getFullYear() + 1))
      break
    default:
      returnDate = new Date()
  }
  return returnDate
}

//ToDO make sure that everywhere is rounded off to avoid a higher amount for the customer
/**
 * Calculates the difference between two amounts that is left to pay for a given interval
 * @param amountOld
 * @param amountNew
 * @param currentInterval
 * @param currentDate
 * @param endDate
 * @returns Amount left to pay
 */
export const calculateAmountLeftToPay = (
  amountOld: Amount,
  amountNew: Amount,
  currentInterval: 'month' | 'year',
  currentDate: Date,
  endDate: Date
): AmountDifference => {
  const totalDays = daysInInterval(currentInterval)
  const currentDateMoment = moment(currentDate)
  const endDateMoment = moment(endDate)
  const daysLeft = Math.abs(currentDateMoment.diff(endDateMoment, 'days'))
  const amountOldDinero = Dinero({
    amount: parseInt(amountOld.value.replace('.', '')),
  })
  const amountNewDinero = Dinero({
    amount: parseInt(amountNew.value.replace('.', '')),
  })
  const amountOldByDay = amountOldDinero.divide(totalDays, 'HALF_DOWN')
  const amountOldLeft = amountOldByDay.multiply(daysLeft, 'HALF_DOWN')
  const amountLeftTotal = amountNewDinero.subtract(amountOldLeft)

  return {
    amountLeftTotal: { currency: 'EUR', value: amountLeftTotal.toFormat('0,0.00') },
    amountByDay: { currency: 'EUR', value: amountOldByDay.toFormat('0,0.00') },
    amountLeft: { currency: 'EUR', value: amountOldLeft.toFormat('0,0.00') },
    daysLeft: daysLeft,
    daysTotal: totalDays,
  }
}

/**
 * Get number of days that are included in a given interval
 * @param interval
 * @returns number of days of interval
 */
export const daysInInterval = (interval: 'month' | 'year'): number => {
  switch (interval) {
    case 'month':
      return 30
    case 'year':
      return 365
  }
}

export const amountIsMore = (amount: Amount, currentAmount: Amount): boolean => {
  const amountDinero = Dinero({
    amount: parseInt(amount.value.replace('.', '')),
  })
  const currentAmountDinero = Dinero({
    amount: parseInt(currentAmount.value.replace('.', '')),
  })
  return amountDinero.greaterThan(currentAmountDinero)
}

export const limitCreditToRealAmount = (credit: Amount, amount: Amount): Amount => {
  const creditDinero = Dinero({
    amount: parseInt(credit.value.replace('.', '')),
  })
  const amountDinero = Dinero({
    amount: parseInt(amount.value.replace('.', '')),
  })
  if (creditDinero.greaterThanOrEqual(amountDinero)) {
    return { currency: 'EUR', value: amountDinero.toFormat('0,0.00') }
  }
  return { currency: 'EUR', value: creditDinero.toFormat('0,0.00') }
}

const toFormatSafe = (d: Dinero.Dinero) => {
  const [units, subunits] = d.toRoundedUnit(2).toString().split('.')
  const stringified = subunits ? [units, subunits.padEnd(2, '0')].join('.') : [units, '00'].join('.')
  return `${stringified}`
}

export const addCouponAmount = (amount: Amount, coupon: Record<string, any>): CouponAmount => {
  if (!coupon || coupon === null)
    return {
      amountOld: amount,
      amountLeft: amount,
      amountCoupon: { currency: 'EUR', value: '0.00' },
      amountCouponUsed: { currency: 'EUR', value: '0.00' },
      couponText: '',
    }

  const couponData = coupon
  switch (couponData.type) {
    case 'amount': {
      const amountDinero = Dinero({
        amount: parseInt(amount.value.replace('.', '')),
      })
      const couponAmountDinero = Dinero({
        amount: parseInt(couponData.amount.value.replace('.', '')),
      })
      const amountLeft = amountDinero.subtract(couponAmountDinero)
      if (
        amountLeft.lessThanOrEqual(
          Dinero({
            amount: 0,
          })
        )
      ) {
        return {
          amountOld: amount,
          amountLeft: { currency: 'EUR', value: '0.00' },
          amountCoupon: { currency: 'EUR', value: couponData.amount.value },
          amountCouponUsed: amount,
          couponText: couponData.name,
        }
      } else {
        return {
          amountOld: amount,
          amountLeft: { currency: 'EUR', value: toFormatSafe(amountLeft) },
          amountCoupon: { currency: 'EUR', value: couponData.amount.value },
          amountCouponUsed: { currency: 'EUR', value: couponData.amount.value },
          couponText: couponData.name,
        }
      }
    }
    case 'percentage': {
      const amountPercentageDinero = Dinero({
        amount: parseInt(amount.value.replace('.', '')),
      })
      const percentageDinero = amountPercentageDinero.percentage(couponData.percentage, 'HALF_DOWN')
      const amountLeftPercentage = amountPercentageDinero.subtract(percentageDinero)
      if (
        amountLeftPercentage.lessThanOrEqual(
          Dinero({
            amount: 0,
          })
        )
      ) {
        return {
          amountOld: amount,
          amountLeft: { currency: 'EUR', value: '0.00' },
          amountCoupon: { currency: 'EUR', value: toFormatSafe(percentageDinero) },
          amountCouponUsed: amount,
          couponText: couponData.name,
        }
      } else {
        return {
          amountOld: amount,
          amountLeft: { currency: 'EUR', value: toFormatSafe(amountLeftPercentage) },
          amountCoupon: { currency: 'EUR', value: toFormatSafe(percentageDinero) },
          amountCouponUsed: { currency: 'EUR', value: toFormatSafe(percentageDinero) },
          couponText: couponData.name,
        }
      }
    }
    default:
      return {
        amountOld: amount,
        amountLeft: amount,
        amountCoupon: { currency: 'EUR', value: couponData.amount.value },
        amountCouponUsed: { currency: 'EUR', value: '0.00' },
        couponText: couponData.name,
      }
  }
}

export const bundlePrice = (bundlePrice: BundlePrice, interval: Interval): Amount => {
  if (bundlePrice) {
    switch (interval) {
      case Interval.year:
        return bundlePrice.year
      case Interval.month:
      default:
        return bundlePrice.month
    }
  }
  return { currency: 'EUR', value: '0.00' }
}

export const getVatPercentage = (countryCode: string): number => {
  const eu = [
    'be',
    'bg',
    'cz',
    'dk',
    'ee',
    'ie',
    'el',
    'es',
    'fr',
    'hr',
    'it',
    'cy',
    'lv',
    'lt',
    'lu',
    'hu',
    'mt',
    'nl',
    'at',
    'pl',
    'pt',
    'ro',
    'si',
    'sk',
    'fi',
    'se',
  ]

  if (countryCode.toLowerCase() !== 'de') {
    if (eu.includes(countryCode.toLowerCase())) {
      return VAT_AMOUNT_IN_PERCENT_EU
    }
    return VAT_AMOUNT_IN_PERCENT_NOT_EU
  }
  return VAT_AMOUNT_IN_PERCENT_DEFAULT
}

export const getVatRate = (countryCode: string): string => {
  return `${Number(getVatPercentage(countryCode)).toFixed(2)}`
}

export const getExtensionAmount = (price: ExtensionPrice, bundle: IBundle, interval: Interval): Amount => {
  let bundlePrice: BundlePrice
  switch (bundle) {
    case 'enterprise':
    case 'pro':
      bundlePrice = price?.pro
      break
    case 'business':
      bundlePrice = price?.business
      break
    case 'free':
    default:
      bundlePrice = price?.free
      break
  }
  switch (interval) {
    case 'month':
      return bundlePrice?.month
    case 'year':
    default:
      return bundlePrice?.year
  }
}

export const getShortTextForInterval = (interval: Interval): string => {
  switch (interval) {
    case Interval.year:
      return i18n.t('subscription.interval.text_year_short')
    case Interval.month:
      return i18n.t('subscription.interval.text_month_short')
  }
}

export const calculateExtensionAmountLeft = (
  amount: Amount,
  interval: Interval,
  endDate: Date
): { amountLeft: Amount; credit: Amount } => {
  if (!endDate) {
    return {
      amountLeft: { currency: 'EUR', value: amount.value },
      credit: { currency: 'EUR', value: '0.00' },
    }
  }
  const currentDateMoment = moment(new Date())
  const endDateMoment = moment(endDate)
  const totalDays = daysInInterval(interval)
  const daysLeft = Math.abs(currentDateMoment.diff(endDateMoment, 'days'))
  const amountDinero = Dinero({
    amount: parseInt(amount.value.replace('.', '')),
  })
  const amountByDay = amountDinero.divide(totalDays, 'HALF_DOWN')
  const amountLeft = amountByDay.multiply(daysLeft, 'HALF_DOWN')
  const credit = amountDinero.subtract(amountLeft)
  if (credit.lessThanOrEqual(Dinero({ amount: 0 }))) {
    return {
      amountLeft: { currency: 'EUR', value: amount.value },
      credit: { currency: 'EUR', value: '0.00' },
    }
  }
  return {
    amountLeft: { currency: 'EUR', value: toFormatSafe(amountLeft) },
    credit: { currency: 'EUR', value: toFormatSafe(credit) },
  }
}
