import React, { ReactNode, ReactElement, useState } from 'react'
import { View, Image, LayoutChangeEvent, TouchableOpacity, GestureResponderEvent } from 'react-native'
import useStyles from './styles'
import { faEllipsisH, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import icons from '../../constants/icons'
import { useHighlightColor } from '../../hooks/useColors'
import SemiBoldText from '../CustomText/SemiBoldText'
import { FontAwesomeIcon } from '../FontAwesome/ColoredFontAwesomeIcon'
import LightText from '../CustomText/LightText'
import ColoredText from '../CustomText/ColoredText'
import { addEasing } from '../../utils/animations'

interface BaseProps {
  footerContent?: ReactNode | Array<JSX.Element>
  title: string
  children: ReactNode | Array<JSX.Element>
  options?: Array<ReactNode>
  showOptions?: boolean
  subtitle?: string
  titleIcon?: IconName
  isError?: boolean
  needsConfig?: boolean
  headerOptionText?: string
  onWidgetLayout?: (evt: LayoutChangeEvent) => void
  onClickWidget?: (evt: GestureResponderEvent) => void
}

interface IconProps extends BaseProps {
  icon?: ReactElement
}
interface ImageProps extends BaseProps {
  image?: { source: string; alt: string }
}

export type PropsT = IconProps & ImageProps

const Widget = ({
  title,
  subtitle,
  titleIcon,
  children,
  footerContent,
  headerOptionText,
  options,
  showOptions = false,
  isError = false,
  needsConfig = false,
  onWidgetLayout,
  onClickWidget,
  ...props
}: PropsT) => {
  const styles = useStyles()
  const highlightColor = useHighlightColor()

  const [openOptions, setOpenOptions] = useState<boolean>(false)

  const { icon } = props as IconProps
  const { image } = props as ImageProps

  return (
    <View
      style={[styles.widget, isError === true && styles.widget_error, needsConfig === true && styles.widget_config]}
    >
      {openOptions === true ? (
        <View style={styles.options}>
          <View style={styles.drawerHeader}>
            <SemiBoldText>{title}</SemiBoldText>
            <TouchableOpacity
              onPress={() => {
                addEasing(), setOpenOptions(false)
              }}
            >
              <FontAwesomeIcon color={highlightColor} icon={faTimesCircle} />
            </TouchableOpacity>
          </View>
          {options && options.filter((e) => !!e)}
        </View>
      ) : (
        <TouchableOpacity style={{ flex: 1 }} onPress={onClickWidget}>
          <View style={[styles.header]}>
            <View style={[styles.icon, styles.iconWrapper]}>
              {icon && React.cloneElement(icon, { style: styles.icon })}
              {image && <Image style={styles.icon} source={{ uri: image.source }} accessibilityLabel={image.alt} />}
            </View>
            <View style={{ justifyContent: 'flex-start', flexDirection: 'row', maxWidth: `85%`, overflow: 'hidden' }}>
              <View style={{ justifyContent: 'center', flexDirection: 'column' }}>
                <SemiBoldText style={[styles.title, subtitle !== undefined ? { fontSize: 12 } : null]}>
                  {title}
                </SemiBoldText>
                {subtitle && <ColoredText style={styles.subtitle}>{subtitle}</ColoredText>}
              </View>
              {titleIcon && <FontAwesomeIcon style={{ marginLeft: 10 }} icon={[icons.faIconStyle, titleIcon]} />}
            </View>
            <View style={styles.optionWrapper}>
              {headerOptionText && <ColoredText style={styles.optionIntervallText}>{headerOptionText}</ColoredText>}
              {showOptions && (
                <TouchableOpacity
                  style={styles.optionsButton}
                  onPress={(event) => {
                    event.preventDefault()
                    addEasing()
                    setOpenOptions(true)
                  }}
                >
                  <FontAwesomeIcon color={highlightColor} icon={faEllipsisH} />
                </TouchableOpacity>
              )}
            </View>
          </View>
          <View style={styles.content} onLayout={onWidgetLayout}>
            {children}
          </View>
          {footerContent ? (
            <View style={[styles.footer]}>
              <LightText style={[styles.footerText]}>{footerContent}</LightText>
            </View>
          ) : null}
        </TouchableOpacity>
      )}
    </View>
  )
}

export default Widget
