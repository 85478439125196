import { Platform, StyleSheet } from 'react-native'
import { useHighlightColor } from '../../../../../hooks/useColors'
import { Themes } from '../../../../../types/dashbar/theme'
import { useTheme } from '../../../../../utils/types/theme'
import colors from '../../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    iconWrapper: {
      borderRadius: 12.5,
      backgroundColor: theme === Themes.Dark ? colors.darkBg1 : colors.lightBg1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    colorPending: {
      backgroundColor: colors.color3,
    },
    orderValue: {
      color: useHighlightColor(),
    },
    actionSubtitle: {
      marginTop: Platform.select({ default: undefined, web: 2 }),
    },
    noData: {
      flex: 1,
      alignItems: 'center',
      textAlign: 'center',
    },
  })
}

export default useStyles
