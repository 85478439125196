import { StyleSheet } from 'react-native'
import { useFontFamily } from '../../../hooks/useFontFamily'

const useStyles = () => {
  const { semibold } = useFontFamily()

  return StyleSheet.create({
    text: {
      fontFamily: semibold,
    },
  })
}

export default useStyles
