import numbro from 'numbro'
import React from 'react'
import { Platform, View } from 'react-native'
import Svg from 'react-native-svg'
import { VictoryArea, VictoryAxis, VictoryChart, VictoryScatter } from 'victory'
import colors from '../../../../../../constants/colors'
import { useHighlightColor, useTextColor } from '../../../../../../hooks/useColors'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { Themes } from '../../../../../../types/dashbar/theme'
import { useTheme } from '../../../../../../utils/types/theme'
import ColoredText from '../../../../../../components/CustomText/ColoredText'

interface LineChartProps {
  data: Array<number>
  size: {
    width: number
    height: number
  }
  showAxis?: boolean
}

const AmazonAreaChart = ({ data, showAxis = false, size }: LineChartProps) => {
  const textColor = useTextColor()
  const lineColor = useHighlightColor()
  const theme = useTheme()
  const fillColor = theme === Themes.Dark ? colors.color4 : colors.color2
  const { t } = useTranslation()
  let i = 1
  const preparedData = data.map((v) => {
    return {
      x: i++,
      y: v,
    }
  })

  function scatterSize() {
    if (data.length < 10) return 4
    else if (data.length < 35) return 3
    else return 1
  }

  const screenWidth = size.width
  const screenHeight = size.height

  return (
    <View style={{ width: '100%', height: '100%' }}>
      <Svg style={{ width: '100%', height: '100%' }} viewBox={`0,0,${screenWidth},${screenHeight - 40}`}>
        <VictoryChart
          domainPadding={{ y: 20, x: 10 }}
          standalone={false}
          padding={{ top: 0, bottom: 10, right: 0, left: 0 }}
          width={screenWidth}
          height={screenHeight - 40}
        >
          <VictoryArea
            width={screenWidth}
            height={screenHeight}
            standalone={false}
            scale={'linear'}
            data={preparedData}
            interpolation="monotoneX"
            style={{
              data: {
                fill: fillColor,
                fillOpacity: theme === Themes.Dark ? 0.5 : 0.2,
                stroke: lineColor,
                strokeWidth: 3,
              },
            }}
          />
          <VictoryScatter
            width={screenWidth}
            height={screenHeight}
            standalone={false}
            style={{ data: { fill: lineColor } }}
            size={scatterSize()}
            data={preparedData}
          />

          <VictoryAxis
            standalone={false}
            width={screenWidth}
            height={screenHeight}
            padding={{ top: 0 }}
            style={{
              ...(!showAxis && { axis: { stroke: 'transparent' } }),
            }}
          />
        </VictoryChart>
      </Svg>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View
            style={{
              backgroundColor: lineColor,
              height: 10,
              width: 10,
              borderRadius: 2,
              alignSelf: 'center',
              marginRight: Platform.select({ default: undefined, web: 4 }),
            }}
          />
          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginHorizontal: '1.5%',
            }}
          >
            <ColoredText
              style={{
                fontSize: 12,
              }}
            >
              <>{t('shop.widget.amazon.amazonumsatz.sales')}</>
            </ColoredText>
            <ColoredText
              style={{
                fontSize: 12,
                color: textColor,
              }}
            >
              max{' '}
              {numbro(Math.max(...data)).formatCurrency({
                ...numbro.languageData().currencyFormat,
                currencySymbol: '€',
                spaceSeparated: true,
                average: true,
              })}
            </ColoredText>
          </View>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View
            style={{
              backgroundColor: lineColor,
              height: 10,
              width: 10,
              borderRadius: 2,
              alignSelf: 'center',
              marginRight: Platform.select({ default: undefined, web: 4 }),
            }}
          />
          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginHorizontal: '1.5%',
            }}
          >
            <ColoredText
              style={{
                fontSize: 12,
              }}
            >
              <>{t('shop.widget.amazon.amazonumsatz.sales')}</>
            </ColoredText>
            <ColoredText
              style={{
                fontSize: 12,
                color: textColor,
              }}
            >
              min{' '}
              {numbro(Math.min(...data)).formatCurrency({
                ...numbro.languageData().currencyFormat,
                currencySymbol: '€',
                spaceSeparated: true,
                average: true,
              })}
            </ColoredText>
          </View>
        </View>
      </View>
    </View>
  )
}

export default AmazonAreaChart
