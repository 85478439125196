import React, { useMemo } from 'react'
import { View } from 'react-native'
import useStyles from './styles'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import PlatformCard from './PlatformCard'
import { IShop } from '../../../../../../types/dashboard'
import { ImageSource } from 'expo-image'

type PropsT = {
  shops: {
    [key: string]: IShop
  }
  selectedShop?: string | null

  selectShop: (key: string | null) => void
  selectShopSystem: (key: string | null) => void
  selectPluginVersion: (key: string | null) => void
  selectShopIsExternal: (key: boolean) => void
  setIcon: (key: string | ImageSource | ImageSource[] | null) => void
  disabled?: boolean
}

const WidgetShop = ({
  selectedShop,
  selectShop,
  selectShopSystem,
  selectPluginVersion,
  selectShopIsExternal,
  setIcon,
  shops,
}: // disabled = false,
PropsT) => {
  const styles = useStyles()
  const wawiImg = require(`../../../../../../assets/imgs/widgets/wawi/logo.png`)
  // const { t } = useTranslation()

  const data = useMemo(
    () =>
      Object.entries<IShop>(shops).map(([key, entry]) => ({
        ...entry,
        key,
      })),
    [shops]
  )

  const selectPlatform = (
    key: string,
    icon: string | ImageSource | ImageSource[],
    platform: Record<string, any>,
    isExternal = false
  ) => {
    if (selectedShop === key) {
      selectShop(null)
      selectShopSystem(null)
      selectPluginVersion(null)
      selectShopIsExternal(false)
      setIcon(null)
    } else {
      selectShop(key)
      selectShopSystem(platform.name)
      selectShopIsExternal(isExternal)
      selectPluginVersion(platform.pluginVersion || '1.0.0')
      setIcon(icon)
    }
  }

  return (
    <View style={styles.mainWrapper}>
      <ColoredText style={styles.headerText}>{`meine Plattformen`}</ColoredText>
      <View style={styles.extensionCardWrapper}>
        {data.map(({ key, icon, platform }) => {
          return (
            <PlatformCard
              key={key}
              domain={key}
              image={icon}
              platform={platform}
              select={selectPlatform}
              isSelected={selectedShop == key}
            />
          )
        })}
      </View>
      <View style={styles.divider} />
      <ColoredText style={styles.headerText}>{`weitere Plattformen`}</ColoredText>
      <View style={styles.extensionCardWrapper}>
        <PlatformCard
          domain={`releva.nz`}
          image={`https://ws-cdn.de/dashbar/ext_icons/ext_relevanz.png`}
          platform={{ name: `relevanz`, pluginVersion: '2.0.0' }}
          select={selectPlatform}
          isExternal
          isSelected={selectedShop == `releva.nz`}
        />
        <PlatformCard
          domain={`Mollie`}
          image={`https://ws-cdn.de/dashbar/ext_icons/ext_mollie.png`}
          platform={{ name: `mollie`, pluginVersion: '2.0.0' }}
          select={selectPlatform}
          isExternal
          isSelected={selectedShop == `Mollie`}
        />
        <PlatformCard
          domain={`Amazon`}
          image={`https://ws-cdn.de/dashbar/ext_icons/ext_amazon.png`}
          platform={{ name: `amazon`, pluginVersion: '2.0.0' }}
          select={selectPlatform}
          isExternal
          isSelected={selectedShop == `Amazon`}
        />
        <PlatformCard
          domain={`Wawi`}
          image={wawiImg} //TODO urrently not working on Mobile
          platform={{ name: `wawi`, pluginVersion: '2.0.0' }}
          select={selectPlatform}
          isExternal
          isSelected={selectedShop == `Wawi`}
        />
      </View>
    </View>
  )
}

export default WidgetShop
