import { StyleSheet } from 'react-native'

export default () =>
  StyleSheet.create({
    container: {
      // backgroundColor: '#fafafa',
      backgroundColor: '#ebebeb',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    content: {
      marginHorizontal: 16,
      maxWidth: 400,
    },
    title: {
      fontSize: 48,
      fontWeight: '300',
      paddingBottom: 16,
      color: '#000000',
    },
    subtitle: {
      fontSize: 32,
      fontWeight: '800',
      color: '#000000',
    },
    error: {
      paddingVertical: 16,
      color: '#000000',
    },
    error_code_msg: {
      paddingVertical: 0,
      fontWeight: '300',
      color: '#000000',
    },
    error_code: {
      width: '100%',
      paddingVertical: 0,
      fontWeight: '800',
      color: '#000000',
    },
    button: {
      // backgroundColor: '#2196f3',
      backgroundColor: '#000000',
      borderRadius: 50,
      padding: 16,
    },
    buttonText: {
      color: '#fff',
      fontWeight: '600',
      textAlign: 'center',
    },
  })
