import React, { useContext, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import useStyles from './styles'
import ComponentWrapper from '../../../../../../components/ComponentWrapper'
import useCollection from '../../../../../../hooks/useCollection'
import { SessionContext } from '../../../../../../context/SessionContext'
import BillRow from './components/BillRow'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { useTranslation } from '../../../../../../hooks/useTranslation'

const Bills = () => {
  const { company } = useContext(SessionContext)
  const styles = useStyles()
  const bills = useCollection<Record<string, any>>(`company/${company?.id}/bills`, undefined, ['created', 'desc'])
  const { t } = useTranslation()
  const [limit, setLimit] = useState<number>(10)

  const increaseLimit = () => setLimit(limit + 10)

  return (
    <ComponentWrapper title={t('accountOverview.bills.your_bills')}>
      <View style={styles.wrapper}>
        <View style={styles.contentWrapper}>
          {bills &&
            Object.entries(bills)
              .filter((_, index) => {
                return index < limit
              })
              .map(([k, v]) => {
                return <BillRow invoice={v} key={k} />
              })}
          {bills && Object.keys(bills).length > limit && (
            <TouchableOpacity onPress={increaseLimit}>
              <ColoredText>
                <>{t('accountOverview.bills.load_more')}</>
              </ColoredText>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </ComponentWrapper>
  )
}

export default Bills
