import React from 'react'

const SelectList = ({
  title,
}: {
  title: string
  items: Array<{ id: string; name: string }>
  selectedId: string | null | undefined
  selectId: (value: React.SetStateAction<string | null>) => void
}) => {
  console.log(title)
  return <></>
}

export default SelectList
