import React, { useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'
import PageWrapper from '../../../../components/PageWrapper'
import ColoredText from '../../../../components/CustomText/ColoredText'
import WidgetSettings from '../Settings/components/WidgetSettings'
import DefaultSettings from '../Settings/components/DefaultSettings'
import ConnectionSettings from '../Settings/components/ConnectionSettings'
import { useSearchParams } from 'react-router-dom'

const SettingsPage = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [selectedTab, selectTab] = useState<number>(0)

  useEffect(() => {
    const tab = +(searchParams.get(`t`) ?? 0)
    if (tab === selectedTab || tab > 3) return
    selectTab(tab)
  }, [searchParams])

  return (
    <PageWrapper scrollViewStyle={{ justifyContent: 'flex-start', margin: -10 }} isScrollable={true}>
      <View style={styles.wrapper}>
        <View style={styles.menu}>
          <View style={styles.container}>
            <MenuButton
              text={`${t('settings.web.menu.general')}`}
              onPress={() => {
                selectTab(0)
              }}
              active={selectedTab === 0}
            />
            <MenuButton
              text={`${t('settings.web.menu.widgets')}`}
              onPress={() => {
                selectTab(1)
              }}
              active={selectedTab === 1}
            />
            <MenuButton
              text={`${t('settings.web.menu.connections')}`}
              onPress={() => {
                selectTab(2)
              }}
              active={selectedTab === 2}
            />
          </View>
        </View>
        <View style={styles.content}>
          <>
            {selectedTab === 0 && <DefaultSettings />}
            {selectedTab === 1 && <WidgetSettings />}
            {selectedTab === 2 && <ConnectionSettings />}
          </>
        </View>
      </View>
    </PageWrapper>
  )
}

const MenuButton = ({
  text,
  onPress,
  onLongPress,
  active = false,
}: {
  text?: string
  onPress?: () => void
  onLongPress?: () => void
  active?: boolean
}) => {
  const styles = useStyles()
  return (
    <TouchableOpacity
      style={[styles.menuButtonWrapper, active === true && styles.menuButtonActive]}
      onPress={onPress}
      onLongPress={onLongPress}
    >
      <View style={styles.menuButtonTextWrapper}>
        <ColoredText style={styles.menuButtonText}>{text}</ColoredText>
      </View>
    </TouchableOpacity>
  )
}

export default SettingsPage
