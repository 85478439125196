import { StyleSheet } from 'react-native'
import { useTheme } from '../../../utils/types/theme'
import { FONT_SIZE_SUBTITLE_WEB, FONT_SIZE_WEB, FONT_WEIGHT_SUBTITLE_WEB, FONT_WEIGHT_TITLE_WEB } from './constants'
import { Themes } from '../../../types/dashbar/theme'
import { usePrimaryBackground } from '../../../hooks/useColors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    settingText: {
      fontSize: FONT_SIZE_WEB,
    },
    settingSubTitle: {
      fontSize: FONT_SIZE_SUBTITLE_WEB,
      fontWeight: `${FONT_WEIGHT_SUBTITLE_WEB}`,
    },
    settingTitle: {
      fontSize: FONT_SIZE_WEB,
      fontWeight: `${FONT_WEIGHT_TITLE_WEB}`,
    },
    settingsWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'flex-end',
      alignItems: 'flex-end',
      padding: 10,
    },
    settingsItemWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      flexWrap: 'wrap',
    },
    settingsHelpItemWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      flexWrap: 'wrap',
      borderRadius: 15,
      padding: 20,
      backgroundColor: usePrimaryBackground(),
    },
    settingsTitleWrapper: {
      width: '55%',
      maxWidth: '55%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
    },
    settingsOptionWrapper: {
      width: '35%',
      maxWidth: '35%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
    },
    settingsTitleWrapperFullWidth: {
      width: '100%',
      maxWidth: '100%',
    },
    settingsOptionWrapperFullWidth: {
      width: '100%',
      maxWidth: '100%',
    },
    settingsOptionWrapperMobile: {
      flexDirection: 'column',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    settingsDivider: {
      width: '100%',
      backgroundColor: theme === Themes.Dark ? '#DDD' : '#222',
      marginVertical: 5,
      height: 0.2,
      minHeight: 0.2,
      maxHeight: 0.2,
    },
  })
}

export default useStyles
