import React from 'react'
import { Text, TextProps, StyleSheet } from 'react-native'
import useStyles from './styles'
import { BlurView } from 'expo-blur'

/** @description
 * Migrated from @webstollen/shared-react-components
 */

const BluredText = ({ style, text, ...props }: TextProps & { text?: string }) => {
  const styles = useStyles()
  const isUpperCase = (char: string) => {
    return char === char.toUpperCase()
  }

  let encryptedText = ''
  if (text) {
    for (let i = 0; i < text.length; i++) {
      switch (text[i]) {
        case ' ':
          encryptedText += ' '
          break
        case ',':
        case '.':
          encryptedText += '.'
          break
        default:
          if (isUpperCase(text[i])) {
            encryptedText += 'X'
          } else {
            encryptedText += 'x'
          }
      }
    }
  }
  return (
    <>
      <Text
        {...{ ...props, style: StyleSheet.flatten([styles.text, style]) }}
        allowFontScaling={props.allowFontScaling === true}
      >
        <>{`${encryptedText}`}</>
      </Text>
      <BlurView
        style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, padding: 10 }}
        intensity={15}
        tint="light"
      />
    </>
  )
}

export default BluredText
