import React, { lazy, Suspense, useCallback } from 'react'
import './fontawesome/icon-library'
import './locales/i18n'
import 'react-native-get-random-values'
import './__ignore_warnings__'
import { KeyboardAvoidingView, Platform, UIManager, View } from 'react-native'
import { Provider } from 'react-redux'
import {
  Comfortaa_300Light,
  Comfortaa_400Regular,
  Comfortaa_500Medium,
  Comfortaa_600SemiBold,
  Comfortaa_700Bold,
  useFonts,
} from '@expo-google-fonts/comfortaa'
import getApp from './firebase'
import { ReactReduxFirebaseProvider, ReduxFirestoreProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import colors from './constants/colors'
import { Provider as PaperProvider } from 'react-native-paper'
import * as Sentry from '@sentry/react-native'
import { mySentry } from './utils/sentry'
import SessionProvider from './context/SessionContext'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import ThemeLanguageWrapper from './common/ThemeLanguageWrapper'
import store from './store'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import PrepareUser from './module/base/page/PrepareUser'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import * as SplashScreen from 'expo-splash-screen'
import InAppPopup from './components/InAppPopup'
import { initFontFamily } from './hooks/useFontFamily'
import { initColorMap } from './hooks/useColors'
import { BackButton, Route, Router, Routes } from './components/Router/Router'
import LoadingScreen from './components/LoadingScreen'
import ErrorBoundary from './components/ErrorBoundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import UpsellingProvider from './context/UpsellingContext'
import ManageDashboardProvider from './module/settings/pages/ManageDashboards/ManageDashboardProvider'
import { NotificationsCounterProvider } from './hooks/NotificationsCounterProvider'
import allRoutes from './routes/module-routes'
import RequireAuth from './components/Routes/RequireAuth'

//import OnlineTrackerWrapper from './components/OnlineTrackerWrapper'

library.add(fas)

SplashScreen.preventAutoHideAsync()

const sentryConfig = {
  dsn: 'https://349609f9d8e2455b8be4c373912e026d@o1045631.ingest.sentry.io/6021106',
  debug: process.env.NODE_ENV !== 'production',
  environment: process.env.NODE_ENV,
  //whitelistUrls: [/dash\.bar/, /app\.dash\.bar/],
  //release: `bar.dash.app@${require('../package.json').version}`,
}

Sentry.init(sentryConfig)

if (process.env.NODE_ENV === 'production') {
  console.debug('Debug logging disabled!')
  console.debug = (..._: Array<any>): any => null
}

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')

  whyDidYouRender(React, {
    trackAllPureComponents: false,
    // trackExtraHooks: [[ReactRedux, 'useSelector']],
  })
}

const firebase = getApp()

const rrfConfig = {
  userProfile: 'user',
  useFirestoreForProfile: true,
  enableLogging: true,
  attachAuthIsReady: true,
  autoPopulateProfile: true,
  logErrors: true,
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

initFontFamily({
  light: 'Comfortaa_300Light',
  regular: 'Comfortaa_400Regular',
  medium: 'Comfortaa_500Medium',
  semibold: 'Comfortaa_600SemiBold',
  bold: 'Comfortaa_700Bold',
  extrabold: 'Comfortaa_700Bold',
  monospace: undefined,
})

initColorMap(colors)

const App = () => {
  const [fontsLoaded] = useFonts({
    Comfortaa_300Light,
    Comfortaa_400Regular,
    Comfortaa_500Medium,
    Comfortaa_600SemiBold,
    Comfortaa_700Bold,
  })
  // if (!fontsLoaded) {
  //   return (
  //     <AppLoading
  //       onError={(e) => mySentry().captureException(e)}
  //       onFinish={() => console.debug('AppLoading Finished!')}
  //       startAsync={async () => console.debug('AppLoading startAsync')}
  //     />
  //   )
  // }

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) await SplashScreen.hideAsync()
  }, [fontsLoaded])

  const PaymentCompleted = lazy(
    () => import('./module/settings/pages/Subscription/Components/PaymentCompleted/PaymentCompleted')
  )
  const Login = lazy(() => import('./module/base/page/Login/index'))

  const Signup = lazy(() => import('./module/base/page/Signup'))
  const ResetPassword = lazy(() => import('./module/base/page/ResetPassword'))

  const AccountManagement = lazy(() => import('./module/base/page/AccountManagement/AccountManagement'))
  const NoCompany = lazy(() => import('./module/base/page/NoCompany'))
  const AppLayout = lazy(() => import('./layout/AppLayout'))

  const queryClient = new QueryClient()

  if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true)
  }

  if (!fontsLoaded) return null
  return (
    <View style={{ height: '100%', width: '100%' }} onLayout={onLayoutRootView}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <ReduxFirestoreProvider {...rrfProps}>
              <SafeAreaProvider>
                <KeyboardAvoidingView
                  behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                  style={{ display: 'flex', flex: 1, height: '100%' }}
                  contentContainerStyle={{ display: 'flex', flex: 1, height: '100%' }}
                >
                  {/* <OnlineTrackerWrapper> */}
                  <GestureHandlerRootView style={{ flex: 1 }}>
                    <PaperProvider>
                      <BackButton />
                      <Suspense fallback={<LoadingScreen />}>
                        <ManageDashboardProvider>
                          <SessionProvider>
                            <NotificationsCounterProvider>
                              <ThemeLanguageWrapper>
                                <Router>
                                  <UpsellingProvider>
                                    <PrepareUser>
                                      <Routes>
                                        <Route path="/payment_completed" element={<PaymentCompleted />} />
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/signup/:invitationCode" element={<Signup />} />
                                        <Route path="/signup" element={<Signup />} />
                                        <Route path="/reset-password" element={<ResetPassword />} />
                                        <Route path="/account-management" element={<AccountManagement />} />
                                        <Route path="/no-company" element={<NoCompany />} />
                                        <Route path="/" element={<AppLayout />}>
                                          {allRoutes.map(({ route: { path, children }, name, requireAuth }) => {
                                            return (
                                              <Route
                                                key={name}
                                                path={path}
                                                element={requireAuth ? <RequireAuth>{children}</RequireAuth> : children}
                                              />
                                            )
                                          })}
                                        </Route>
                                      </Routes>
                                      <InAppPopup />
                                    </PrepareUser>
                                  </UpsellingProvider>
                                </Router>
                              </ThemeLanguageWrapper>
                            </NotificationsCounterProvider>
                          </SessionProvider>
                        </ManageDashboardProvider>
                      </Suspense>
                    </PaperProvider>
                  </GestureHandlerRootView>
                  {/* </OnlineTrackerWrapper> */}
                </KeyboardAvoidingView>
              </SafeAreaProvider>
            </ReduxFirestoreProvider>
          </ReactReduxFirebaseProvider>
        </QueryClientProvider>
      </Provider>
    </View>
  )
}

const ErrorBoundApp = () => (
  <ErrorBoundary
    onError={(error: Error, stackTrace: string) => {
      mySentry().captureException(error)
      console.debug('ErrorBoundary')
      console.debug(error)
      console.debug(stackTrace)
    }}
  >
    <App />
  </ErrorBoundary>
)

export default ErrorBoundApp
