import React, { useContext, useEffect, useState } from 'react'
import ColoredText from '../../../CustomText/ColoredText'
import { View } from 'react-native'
import { SessionContext } from '../../../../context/SessionContext'
import { BundleT } from '../../../../types/plans'
import { useTranslation } from '../../../../hooks/useTranslation'
import { isLoaded, useFirestore } from 'react-redux-firebase'
import sizes from '../../../../constants/sizes'
import Card from '../Card'

const UpsellingView = () => {
  const [proBundle, setProBundle] = useState<BundleT | null>(null)
  const [businessBundle, setBusinessBundle] = useState<BundleT | null>(null)

  const db = useFirestore()
  const { user, company } = useContext(SessionContext)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchBundles = async () => {
      const proBundleData = await db.collection('bundle').doc('pro').get()
      setProBundle(proBundleData.data() as BundleT)
      const businessBundleData = await db.collection('bundle').doc('business').get()
      setBusinessBundle(businessBundleData.data() as BundleT)
    }
    fetchBundles()
  }, [])

  if (!isLoaded(company)) return null

  return (
    <>
      <View
        style={{
          width: '100%',
          minWidth: '100%',
          padding: 20,
          borderRadius: 16,
          alignSelf: 'center',
          flexDirection: 'column',
        }}
      >
        <View style={{ alignItems: 'center' }}>
          <ColoredText style={{ fontSize: 18 }}>
            {`${t('shop.upselling.body.hello')} ${user?.firstName ?? ''},`}
          </ColoredText>
          <ColoredText style={{ marginVertical: sizes.paddingTop }}>
            {t('shop.upselling.body.messageToCustomer')}
          </ColoredText>
        </View>
        <View
          style={{ width: '100%', alignItems: 'center', justifyContent: 'space-evenly', gap: 30, flexDirection: 'row' }}
        >
          {businessBundle && <Card bundle={businessBundle} />}
          {proBundle && <Card bundle={proBundle} />}
        </View>
      </View>
    </>
  )
}

export default UpsellingView
