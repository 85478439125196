import { Dimensions, Platform, StyleSheet } from 'react-native'
import colors from '../../constants/colors'
import { usePrimaryBackground } from '../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    centeredView: {
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    modalWrapper: {
      height: '100%',
      justifyContent: 'center',
      width: '100%',
      alignSelf: 'stretch',
      zIndex: 30,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: colors.lightBgTransparent7,
    },
    title: {
      fontSize: 24,
      width: '100%',
      padding: 30,
      textAlign: 'center',
    },
    button: {
      backgroundColor: usePrimaryBackground(),
      padding: 5,
      ...(Platform.OS === 'ios' && { paddingTop: 10 }),
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 50,
      width: 30,
      heigth: 30,
      margin: 10,
    },
    modal: {
      width: Dimensions.get('window').width <= 1000 ? 300 : 900,
      maxWidth: Dimensions.get('window').width <= 1000 ? 300 : 900,
      height: Dimensions.get('window').width <= 1000 ? 450 : 650, //+(hasCoupon ? 50 : 0),
      maxHeight: Dimensions.get('window').width <= 1000 ? 450 : 650, // +(hasCoupon ? 50 : 0),
      borderRadius: Platform.OS === 'android' ? 20 : 15,
      overflow: 'hidden',
      backgroundColor: usePrimaryBackground(),
      margin: 20,
    },
  })
}

export default useStyles
