import { StyleSheet } from 'react-native'
import { useSecondaryBackground } from '../../../../../../hooks/useColors'
import { processFontFamily } from 'expo-font'

const useStyles = () => {
  return StyleSheet.create({
    cardWrapper: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: 15,
      marginTop: 20,
      width: '60%',
      minWidth: 350,
      height: 250,
      borderRadius: 30,
      backgroundColor: useSecondaryBackground(),
    },
    cardHeader: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
    },
    cardBody: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      marginTop: 80,
      paddingHorizontal: 10,
    },
    cardFooter: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      paddingHorizontal: 10,
    },
    cardType: {
      color: 'white',
      fontSize: 22,
      fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
    },
    cardHolder: {
      color: 'white',
      fontSize: 20,
      fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
    },
    cardNumber: {
      marginTop: 5,
      color: 'white',
      fontSize: 18,
      fontFamily: processFontFamily('Comfortaa_300Light') || undefined,
    },
    validUntil: {
      marginTop: 5,
      color: 'white',
      fontSize: 14,
      fontFamily: processFontFamily('Comfortaa_300Light') || undefined,
    },
  })
}

export default useStyles
