import React from 'react'
import { useTranslation } from 'react-i18next'
import { IWidgetProps } from '../../../../../types/props/Widget'
import { Image } from 'react-native'
import DefaultDashbarIcon from '../../../../components/DefaultDashbarIcon.tsx'
import Widget from '../../../../components/WidgetNoAPI'
import { Link } from '../../../../../components/Router/Router'
import AdvertismentSkeleton from './AdvertismentSkeleton'

const Advertisement = ({ widget, dashboardId, shops }: IWidgetProps) => {
  const { t } = useTranslation()

  return (
    <Widget
      dashboardId={dashboardId}
      shops={shops}
      loadingSkeleton={<AdvertismentSkeleton />}
      title={t('shop.widget.advertisement.title')}
      widget={widget}
      icon={<DefaultDashbarIcon />}
      image={undefined}
    >
      {([size]) => {
        return (
          <Link to={'https://ws-url.de/tm-dashbar'}>
            <Image
              {...{
                ...size,
                resizeMode: 'contain',
                source: { uri: `https://ws-cdn.de/marketing/aktionsbanner/db-image?${new Date().getHours()}` },
                style: {
                  ...size,
                },
              }}
            />
          </Link>
        )
      }}
    </Widget>
  )
}

export default Advertisement
