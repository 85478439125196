import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */
const SvgComponent = (props: SvgProps) => (
  <Svg width={21} height={17} {...props}>
    <Path
      fill={props.color || '#000'}
      fillRule="nonzero"
      d="M18 .375a2.251 2.251 0 0 1 2.25 2.25V3.75H0V2.625A2.25 2.25 0 0 1 2.25.375H18Zm2.25 13.5a2.252 2.252 0 0 1-2.25 2.25H2.25A2.251 2.251 0 0 1 0 13.875v-6.75h20.25v6.75Zm-16.313-2.25a.564.564 0 0 0-.562.563c0 .309.253.562.563.562h2.25c.309 0 .562-.253.562-.563a.564.564 0 0 0-.563-.562h-2.25Zm4.5 1.125h4.5c.31 0 .563-.253.563-.563a.564.564 0 0 0-.563-.562h-4.5a.564.564 0 0 0-.562.563c0 .309.253.562.563.562Z"
    />
  </Svg>
)
export default SvgComponent
