import * as React from 'react'
import Svg, { SvgProps, G, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */

const Favorite = (props: SvgProps) => (
  <Svg width={20} height={20} {...props}>
    <G fill="none" fillRule="evenodd">
      <Path
        d="M3.333 0h13.334C18.507 0 20 1.414 20 3.158v13.684C20 18.586 18.508 20 16.667 20H3.333C1.493 20 0 18.586 0 16.842V3.158C0 1.414 1.492 0 3.333 0Z"
        fill="#F5A623"
      />
      <Path
        d="M3.24 14.912h13.52c1.278 0 2.314.982 2.314 2.193s-1.036 2.193-2.315 2.193H3.241c-1.279 0-2.315-.982-2.315-2.193 0-1.21 1.036-2.193 2.315-2.193Z"
        fill="#FFF"
      />
    </G>
  </Svg>
)

export default Favorite
