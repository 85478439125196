export const calculateDatesOfInterval = (interval: string) => {
  let date2: Date = new Date()
  date2.setHours(23, 59, 59, 999)
  const intervalWidget = interval
  let date1: Date = new Date()
  const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3)

  if (intervalWidget === undefined) return

  const value = parseInt(intervalWidget.substring(1))
  switch (intervalWidget[0]) {
    case 'D':
      date1.setDate(new Date().getDate() - value + 1)
      date1.setHours(0, 0, 0, 0)
      break
    case 'Q':
      date1.setMonth(value - 1)
      date1.setDate(1)
      date1.setHours(0, 0, 0, 0)

      date2 = new Date()
      date2.setHours(0, 0, 0, 0)
      date2.setDate(1)
      date2.setMonth(value + 3 - 1)

      // wenn es das Quartal in dem Jahr noch nicht gibt, dann das aus dem Vorjahr
      if (currentQuarter < value) {
        date1.setFullYear(date1.getFullYear() - 1)
        date2.setFullYear(date2.getFullYear() - 1)
      }
      break
    case 'M':
      date1.setMonth(value - 1)
      date1.setDate(1)
      date1.setHours(0, 0, 0, 0)
      break
    case 'Y':
      date1 = new Date(value + '-01-01')
      date1.setHours(0) // sonst 1 Uhr
      date2 = new Date(value + 1 + '-01-01')
      date2.setHours(0)
      break
  }
  return { dateStart: date1, dateEnd: date2 }
}
