import { Skeleton } from 'native-base'
import React from 'react'
import { View } from 'react-native'
import { usePrimaryBackground } from '../../../../../hooks/useColors'

const RelevanzDashboardSkeleton = () => {
  const loadingColor = usePrimaryBackground()
  return (
    <View style={{ width: '100%', height: '100%' }}>
      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: '100%',
          width: '100%',
          alignContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            height: '28%',
            alignContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginVertical: 5,
          }}
        >
          <Skeleton
            style={{ borderRadius: 10, height: '100%', width: '43%' }}
            isLoaded={false}
            startColor={loadingColor}
          />
          <Skeleton
            style={{ borderRadius: 10, height: '100%', width: '43%' }}
            isLoaded={false}
            startColor={loadingColor}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            height: '28%',
            alignContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginVertical: 5,
          }}
        >
          <Skeleton
            style={{ borderRadius: 10, height: '100%', width: '43%' }}
            isLoaded={false}
            startColor={loadingColor}
          />
          <Skeleton
            style={{ borderRadius: 10, height: '100%', width: '43%' }}
            isLoaded={false}
            startColor={loadingColor}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            height: '28%',
            alignContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginVertical: 5,
          }}
        >
          <Skeleton
            style={{ borderRadius: 10, height: '100%', width: '43%' }}
            isLoaded={false}
            startColor={loadingColor}
          />
          <Skeleton
            style={{ borderRadius: 10, height: '100%', width: '43%' }}
            isLoaded={false}
            startColor={loadingColor}
          />
        </View>
      </View>
    </View>
  )
}

export default RelevanzDashboardSkeleton
