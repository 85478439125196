import React, { useCallback, useState } from 'react'
import { IAlertDialogProps } from 'native-base/lib/typescript/components/composites/AlertDialog'
import { AlertDialog } from 'native-base'

export interface PanelOptions {
  /** Defaults to false. Should the panel be open by default? */
  leastDestructiveRef: React.MutableRefObject<unknown>
  startOpen?: boolean
}
const defaults: PanelOptions = {
  startOpen: false,
  leastDestructiveRef: null,
}

export const useAlertDialog = (opts: PanelOptions) => {
  const { startOpen, leastDestructiveRef } = { ...defaults, ...opts }
  const [isOpen, setIsOpen] = useState(startOpen)

  const open = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const alertDialogProps: IAlertDialogProps = {
    isOpen,
    leastDestructiveRef,
    onClose: close,
  }

  return {
    isOpen,
    open,
    close,
    alertDialogProps,
    AlertDialog,
  }
}
