import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IWidgetProps } from '../../../../../types/props/Widget'
import ListView from '../../../../../components/ListView'
import moment from 'moment'
import useStyles from './styles'
import { Image, Platform, View } from 'react-native'
import icons from '../../../../../constants/icons'
import Widget from '../../../../components/WidgetNoAPI'
import { useTextColor } from '../../../../../hooks/useColors'
import { FontAwesomeIcon } from '../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import PaymentSkeleton from './PaymentSkeleton'
import { functions } from '../../../../../firebase'
import { useFirestore } from 'react-redux-firebase'
import { SessionContext } from '../../../../../context/SessionContext'

const MolliePayment = ({ widget, dashboardId, shops }: IWidgetProps) => {
  const styles = useStyles()
  const textColor = useTextColor()
  const { t } = useTranslation()
  const firestore = useFirestore()
  const { company } = useContext(SessionContext)
  const [payments, setPayments] = useState<Array<Record<string, any>> | null>(null)
  const [error, setError] = useState<{ text: string; link: string | null } | null>(null)
  const getPayments = functions().httpsCallable('widgets-mollie-payments')
  const [needsConfig, setNeedsConfig] = useState<boolean>(false)

  useEffect(() => {
    setError(null)
    getPayments({ apikey: widget.settings.apikey })
      .then((payments: Record<string, any>) => {
        setPayments(payments.data)
      })
      .catch((err) => {
        setPayments(null)
        setError({ text: `${t('widget_errors.mollie.default')}`, link: null })
        console.debug(err)
      })
  }, [])

  useEffect(() => {
    firestore
      .collection(`company/${company?.id}/extension_config`)
      .doc('mollie')
      .get()
      .then((doc) => {
        if (!doc.exists || !doc.data()) {
          setNeedsConfig(true)
          return
        }
        if (!doc.data()?.apikey) {
          setNeedsConfig(true)
          return
        }
        setNeedsConfig(false)
      })
  }, [firestore, company])

  const getMethodImage = (method: string): JSX.Element => {
    let methodImage: JSX.Element
    switch (method) {
      case 'creditcard':
        //methodImage = <Creditcard viewBox="-7 -10 45 45" />
        methodImage = (
          <Image
            source={
              Platform.OS === 'web'
                ? require('../../../../../assets/imgs/widgets/mollie/creditcard.png')
                : Image.resolveAssetSource(require('../../../../../assets/imgs/widgets/mollie/creditcard.png'))
            }
            style={{ width: 15, height: 10 }}
          />
        )
        break
      case 'paypal':
        //methodImage = <Creditcard viewBox="-7 -10 45 45" />
        methodImage = (
          <Image
            source={
              Platform.OS === 'web'
                ? require('../../../../../assets/imgs/widgets/mollie/paypal.png')
                : Image.resolveAssetSource(require('../../../../../assets/imgs/widgets/mollie/paypal.png'))
            }
            style={{ width: 15, height: 10 }}
          />
        )
        break
      default:
        //methodImage = <Directdebit viewBox="-7 -10 45 45" />
        methodImage = (
          <Image
            source={
              Platform.OS === 'web'
                ? require('../../../../../assets/imgs/widgets/mollie/directdebit.png')
                : Image.resolveAssetSource(require('../../../../../assets/imgs/widgets/mollie/directdebit.png'))
            }
            style={{ width: 15, height: 10 }}
          />
        )
    }
    return methodImage
  }

  return (
    <Widget
      title={'Mollie'}
      widget={widget}
      dashboardId={dashboardId}
      shops={shops}
      loadingSkeleton={<PaymentSkeleton />}
      loading={payments === null && error === null}
      isError={error !== null}
      needsConfig={needsConfig}
      error={error}
      image={{
        source:
          Platform.OS === 'web'
            ? require('../../../../../assets/imgs/widgets/mollie/mollie.png')
            : Image.resolveAssetSource(require('../../../../../assets/imgs/widgets/mollie/mollie.png')).uri,
        alt: '',
      }}
    >
      {([size]) => {
        // if (payments === null && error === null) return <LoadingScreen />
        if (error !== null) {
          return (
            <View style={styles.noData}>
              <FontAwesomeIcon
                style={{ marginBottom: 10 }}
                color={textColor}
                icon={[icons.faIconStyle, 'info-circle']}
              />
              <ColoredText>
                <>{t('shop.widget.mollie.payments_error')}</>
              </ColoredText>
            </View>
          )
        }
        return (
          <>
            <ListView
              size={size}
              styles={{
                thumbnailWrapper: styles.iconWrapper,
                actionTitleText: styles.orderValue,
                actionSubtitle: styles.actionSubtitle,
              }}
              data={
                payments !== null
                  ? payments.map(({ id, createdAt, description, amount, method, status }) => ({
                      id,
                      icon: getMethodImage(method),
                      mainTitle: description || t('shop.widget.orders.customer'),
                      mainSubtitle: id,
                      actionTitle: `${amount.currency} ${amount.value}`,
                      actionSubtitle: moment(createdAt).format('L LT'),
                      warpperStyle: status === 'paid' ? styles.colorPaid : styles.colorPending,
                    }))
                  : null
              }
            />
          </>
        )
      }}
    </Widget>
  )
}

export default MolliePayment
