import { OrderByOptions, ReduxFirestoreQuerySetting, useFirestoreConnect, WhereOptions } from 'react-redux-firebase'
import md5 from 'md5'
import { useSelector } from 'react-redux'
import { IDProp, SelectorStateType } from '../types/firestore'
import { replaceAll } from '../utils/string'

const useCollection = <T>(
  collection: string,
  where?: WhereOptions | Array<WhereOptions>,
  orderBy?: OrderByOptions | Array<OrderByOptions>,
  limit = 15
): undefined | Record<string, T & IDProp> | null => {
  // IMPORTANT: Check if collection exists before connecting. If not done firestore can't connect correctly on first load
  let query = [] as Array<string> | Array<ReduxFirestoreQuerySetting>
  let storeHash = ''

  if (collection) {
    storeHash = replaceAll(where ? 'collection' + md5(JSON.stringify([where])) : collection, '/', '_')
    query = [
      {
        collection: collection,
        orderBy: orderBy,
        limit: limit,
        where: where,
        storeAs: storeHash,
      },
    ]
  }
  useFirestoreConnect(() => query)

  return useSelector((state: SelectorStateType<T>) => {
    if (!where) {
      if (state.firestore.data[storeHash] === undefined) {
        return null
      }
      return (state.firestore.data[storeHash] as Record<string, T & IDProp>) || null
    }

    if (state.firestore.ordered[storeHash] === undefined) {
      return null
    }
    return (state.firestore.ordered[storeHash] as Record<string, T & IDProp>) || null
  })
}

export default useCollection
