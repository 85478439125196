import { IconDefinition, IconName } from '@fortawesome/pro-solid-svg-icons'

const definition = {
  prefix: 'fas',
  iconName: 'dashbar' as IconName,
  // inkscape scaled so we reverse the scale for the path
  icon: [
    100 / 0.26458333,
    110 / 0.26458333,
    [],
    'ff01',
    'M 56.693359 0 C 25.285485 0 0 25.285485 0 56.693359 L 0 359.05469 C 0 390.46256 25.285485 415.74805 56.693359 415.74805 L 321.25977 415.74805 C 352.66764 415.74805 377.95312 390.46256 377.95312 359.05469 L 377.95312 56.693359 C 377.95312 25.285485 352.66764 0 321.25977 0 L 56.693359 0 z M 66.142578 302.36133 L 311.81055 302.36133 C 337.98378 302.36133 359.05469 323.43224 359.05469 349.60547 C 359.05469 375.7787 337.98378 396.84961 311.81055 396.84961 L 66.142578 396.84961 C 39.96935 396.84961 18.898438 375.7787 18.898438 349.60547 C 18.898438 323.43224 39.96935 302.36133 66.142578 302.36133 z ',
  ],
} as IconDefinition

export default definition
