import React, { useContext, useEffect, useState } from 'react'
import * as useDefaultStyles from '../../../../utils/styles'
import { TextInput, TouchableOpacity, View, Linking } from 'react-native'
import MenuTitle from '../../../../components/MenuTitle'
import useStyles from './styles'
import { useFirestore } from 'react-redux-firebase'
import { SessionContext } from '../../../../../../context/SessionContext'
import Button from '../../../../../../components/Button'
import { Skeleton } from 'native-base'
import { useInvertedTextColor, usePrimaryBackground, useTextColor } from '../../../../../../hooks/useColors'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { FontAwesomeIcon } from '../../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import { functions } from '../../../../../../firebase'
import { openUrl } from '../../../../../../components/Browser'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { AuthenticationResponse } from '../../../../../../../functions/src/widgets/wawi/registerApp'
import { ApiError } from '../../../../../../../functions/src/widgets/wawi/fetchRegStatus'
import { sendWawiRegRequest, fetchWawiRegStatus, AuthRequestStatus } from './funcs'
import WawiConnectionMsg from './WawiConnectionMsg'
import urls from '../../../../../../constants/urls'
import { ActivityIndicator } from 'react-native'

const ConnectionSettings = () => {
  const defaultStyles = useDefaultStyles.default()
  const styles = useStyles()
  const { t } = useTranslation()
  const firestore = useFirestore()
  const loadingColor = usePrimaryBackground()
  const textColor = useTextColor()
  const invertedTextColor = useInvertedTextColor()
  const { company } = useContext(SessionContext)
  const generateState = functions().httpsCallable('widgets-helpers-oauth-generateState')

  const [loading, setLoading] = useState<boolean>(true)
  const [updating, setUpdating] = useState<boolean>(false)

  const [relevanzKey, setRelevanzKey] = useState<string | null>(null)
  const [mollieKey, setMollieKey] = useState<string | null>(null)
  const [pagespeedKey, setPagespeedKey] = useState<string | null>(null)
  const [sellermathKey, setSellermathKey] = useState<string | null>(null)
  const [amazonConnected, setAmazonConnected] = useState<boolean>(false)
  const [searchConsoleConnected, setSearchConsoleConnected] = useState<boolean>(false)
  const [wawiConnected, setWawiConnected] = useState<boolean>(false)

  const [relevanzKeyEdit, setRelevanzKeyEdit] = useState<string | null>(null)
  const [mollieKeyEdit, setMollieKeyEdit] = useState<string | null>(null)
  const [pagespeedKeyEdit, setPagespeedKeyEdit] = useState<string | null>(null)
  const [sellermathKeyEdit, setSellermathKeyEdit] = useState<string | null>(null)

  const [wawiApiUrl, setWawiApiUrl] = useState<string>('')
  const [authRequestStatus, setAuthRequestStatus] = useState<AuthRequestStatus>('notSent')
  const [wawiFetchStatusMsg, setWawiFetchStatusMsg] = useState<string | null>(null)
  const [wawiAuthError, setWawiAuthError] = useState<ApiError | null>(null)
  const [wawiAuthResp, setWawiAuthResp] = useState<{
    success: AuthenticationResponse
    xChallengeCode: string
  } | null>(null)

  const storeRelevanz = async () => {
    setUpdating(true)
    if (relevanzKeyEdit?.trim() === '') {
      await firestore.collection(`company/${company?.id}/extension_config`).doc(`relevanz`).delete()
      setUpdating(false)
      return
    }
    await firestore
      .collection(`company/${company?.id}/extension_config`)
      .doc(`relevanz`)
      .set({ apikey: relevanzKeyEdit }, { merge: true })
    setRelevanzKey(relevanzKeyEdit)
    setUpdating(false)
  }

  const storeMollie = async () => {
    setUpdating(true)
    if (mollieKeyEdit?.trim() === '') {
      await firestore.collection(`company/${company?.id}/extension_config`).doc(`mollie`).delete()
      setUpdating(false)
      return
    }
    await firestore
      .collection(`company/${company?.id}/extension_config`)
      .doc(`mollie`)
      .set({ apikey: mollieKeyEdit }, { merge: true })
    setMollieKey(mollieKeyEdit)
    setUpdating(false)
  }

  const storePagespeed = async () => {
    setUpdating(true)
    if (pagespeedKeyEdit?.trim() === '') {
      await firestore.collection(`company/${company?.id}/extension_config`).doc(`google_pagespeed`).delete()
      setUpdating(false)
      return
    }
    await firestore
      .collection(`company/${company?.id}/extension_config`)
      .doc(`google_pagespeed`)
      .set({ apikey: pagespeedKeyEdit }, { merge: true })
    setPagespeedKey(pagespeedKeyEdit)
    setUpdating(false)
  }

  const storeSellermath = async () => {
    setUpdating(true)
    if (sellermathKeyEdit?.trim() === '') {
      await firestore.collection(`company/${company?.id}/extension_config`).doc(`seller_math`).delete()
      setUpdating(false)
      return
    }
    await firestore
      .collection(`company/${company?.id}/extension_config`)
      .doc(`seller_math`)
      .set({ apikey: sellermathKeyEdit }, { merge: true })
    setSellermathKey(sellermathKeyEdit)
    setUpdating(false)
  }

  /**
   * creates or alters companys firestore document with credentials for Wawi-API
   * @param apiKey the apiKey to be stored
   */
  const storeWawi = async (apiKey: string): Promise<void> => {
    setUpdating(true)
    await firestore
      .collection(`company/${company?.id}/extension_config`)
      .doc(`jtl_wawi`)
      .set({ apikey: 'Wawi ' + apiKey, apiUrl: wawiApiUrl, appId: wawiAuthResp?.success.appId, appVersion: '2.0.16' })
    setUpdating(false)
  }

  /**
   * deletes companys firestore document with credentials for the Wawi-API
   *
   * @returns nothing but sets WawiConnected to false
   */
  const disconnectWawi = async (): Promise<void> => {
    setUpdating(true)
    setAuthRequestStatus('notSent')
    await firestore.collection(`company/${company?.id}/extension_config`).doc(`jtl_wawi`).delete()
    setWawiConnected(false)
    setUpdating(false)
  }

  const connectAmazon = async () => {
    setUpdating(true)
    const amazonConfigDoc = await firestore.collection(`extensions_settings`).doc(`amazon`).get()
    const amazonConfig = amazonConfigDoc.data()
    if (!amazonConfigDoc.exists || !amazonConfig || !amazonConfig.oauth) {
      setUpdating(false)
      return
    }
    const oauthSettings = amazonConfig.oauth
    const state = (await generateState({ exid: `amazon`, path: `/settings?t=2` })).data
    console.log(state)
    const url = `${oauthSettings.oauth_url}${
      oauthSettings.redirect_uri !== undefined && oauthSettings.redirect_uri !== null
        ? `&redirect_uri=${oauthSettings.redirect_uri}`
        : ''
    }${oauthSettings.scope !== undefined && oauthSettings.scope !== null ? `&scope=${oauthSettings.scope}` : ''}&state=${state}`
    openUrl(url)
    setUpdating(false)
  }

  const disconnectAmazon = async () => {
    setUpdating(true)
    await firestore.collection(`company/${company?.id}/extension_config`).doc(`amazon`).delete()
    setAmazonConnected(false)
    setUpdating(false)
  }

  const connectSearchConsole = async () => {
    setUpdating(true)
    const searchConsoleConfigDoc = await firestore.collection(`extensions_settings`).doc(`google_search_console`).get()
    const searchConsoleConfig = searchConsoleConfigDoc.data()
    if (!searchConsoleConfigDoc.exists || !searchConsoleConfig || !searchConsoleConfig.oauth) {
      setUpdating(false)
      return
    }
    const oauthSettings = searchConsoleConfig.oauth
    const state = (await generateState({ exid: `google_search_console`, path: `/settings?t=2` })).data
    console.log(state)
    const url = `${oauthSettings.oauth_url}${
      oauthSettings.redirect_uri !== undefined && oauthSettings.redirect_uri !== null
        ? `&redirect_uri=${oauthSettings.redirect_uri}`
        : ''
    }${oauthSettings.scope !== undefined && oauthSettings.scope !== null ? `&scope=${oauthSettings.scope}` : ''}&state=${state}`
    openUrl(url)
    setUpdating(false)
  }

  const disconnectSearchConsole = async () => {
    setUpdating(true)
    await firestore.collection(`company/${company?.id}/extension_config`).doc(`google_search_console`).delete()
    setSearchConsoleConnected(false)
    setUpdating(false)
  }

  /**
   * sends requests to the /authentication endpoint of an Wawi API-Server
   * to start the registration Process
   *
   * @returns nothing but sets authRequestStatus, WawiAuthResp, and authError
   */
  const registerWawi = async (): Promise<void> => {
    setUpdating(true)
    const resp = await sendWawiRegRequest(wawiApiUrl)
    setAuthRequestStatus(resp.requestStatus)
    if (resp.authResponse !== undefined) setWawiAuthResp(resp.authResponse)
    if (resp.authError !== undefined) setWawiAuthError(resp.authError)
    setWawiFetchStatusMsg(null)
    setUpdating(false)
  }

  /**
   * sends requests to the /authentication/{RegistrationRequestId} endpoint of an Wawi API-Server
   * to try and get the APi-Key and confirm the Registration
   *
   * @returns nothing but sets authRequestStatus, WawiConnected, authError and wawiFetchStatusMsg
   */
  const confirmRegistration = async (): Promise<void> => {
    setUpdating(true)
    const resp = await fetchWawiRegStatus({ wawiApiUrl, wawiAuthResp })
    setAuthRequestStatus(resp.authRequestStatus)
    if (resp.authError !== undefined) setWawiAuthError(resp.authError)
    if (resp.fetchStatusMsg !== undefined) setWawiFetchStatusMsg(t(resp.fetchStatusMsg))
    if (resp.wawiConnected === true) {
      setWawiConnected(true)
      if (resp.response.data.success.token?.apiKey !== undefined && resp.response.data.success.token.apiKey !== null) {
        await storeWawi(resp.response.data.success.token.apiKey)
      }
    }
    setUpdating(false)
  }

  useEffect(() => {
    setLoading(true)
    firestore
      .collection(`company/${company?.id}/extension_config`)
      .get()
      .then(async (docs) => {
        for (const doc of docs.docs) {
          const data = (await doc.ref.get()).data()
          const id = doc.id
          switch (id) {
            case 'amazon':
              if (data && data.oauth && data.oauth.token) {
                setAmazonConnected(true)
              }
              break
            case 'google_pagespeed':
              if (data && data.apikey) {
                setPagespeedKey(data.apikey)
                setPagespeedKeyEdit(data.apikey)
              }
              break
            case 'google_search_console':
              if (data && data.oauth && data.oauth.token) {
                setSearchConsoleConnected(true)
              }
              break
            case 'mollie':
              if (data && data.apikey) {
                setMollieKey(data.apikey)
                setMollieKeyEdit(data.apikey)
              }
              break
            case 'relevanz':
              if (data && data.apikey) {
                setRelevanzKey(data.apikey)
                setRelevanzKeyEdit(data.apikey)
              }
              break
            case 'seller_math':
              if (data && data.apikey) {
                setSellermathKey(data.apikey)
                setSellermathKeyEdit(data.apikey)
              }
              break
            case 'jtl_wawi':
              if (data && data.apikey) {
                setWawiConnected(true)
              }
              break
            default:
              break
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [company])

  return (
    <View style={defaultStyles.settingsWrapper}>
      <View style={defaultStyles.settingsHelpItemWrapper}>
        <ColoredText>{`${t('settings.web.connections.help.title')}`}</ColoredText>
        <TouchableOpacity
          onPress={() => {
            openUrl(`https://ws-url.de/dashbar-connections`)
          }}
        >
          <ColoredText
            style={{ textDecorationLine: 'underline' }}
          >{`${t('settings.web.connections.help.subtitle')}`}</ColoredText>
        </TouchableOpacity>
      </View>
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={[defaultStyles.settingsTitleWrapper, defaultStyles.settingsTitleWrapperFullWidth]}>
          <MenuTitle
            title={`${t('settings.web.connections.relevanz.title')}`}
            subtitle={`${t('settings.web.connections.relevanz.subtitle')}`}
          />
        </View>
        <View style={[defaultStyles.settingsOptionWrapper, defaultStyles.settingsOptionWrapperFullWidth]}>
          {loading === true ? (
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between', height: 43, width: '50%', padding: 5 }}
            >
              <Skeleton
                style={{ borderRadius: 5, height: 38, width: '100%' }}
                isLoaded={false}
                startColor={loadingColor}
              />
            </View>
          ) : (
            <>
              <TextInput
                style={styles.input}
                editable={!updating}
                value={relevanzKeyEdit || ''}
                onChangeText={setRelevanzKeyEdit}
                placeholderTextColor={'#333'}
                placeholder="API-Key"
              />
              {(relevanzKey ?? '').trim() !== (relevanzKeyEdit ?? '').trim() && (
                <Button
                  onPress={storeRelevanz}
                  disabled={updating}
                  textStyle={{ color: invertedTextColor }}
                  style={styles.button}
                >
                  <>{`${t('settings.web.connections.save')}`}</>
                </Button>
              )}
            </>
          )}
        </View>
      </View>
      <View style={defaultStyles.settingsDivider} />
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle
            title={`${t('settings.web.connections.pagespeed.title')}`}
            subtitle={`${t('settings.web.connections.pagespeed.subtitle')}`}
          />
        </View>
        <View style={[defaultStyles.settingsOptionWrapper, defaultStyles.settingsOptionWrapperFullWidth]}>
          {loading === true ? (
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between', height: 43, width: '50%', padding: 5 }}
            >
              <Skeleton
                style={{ borderRadius: 5, height: 38, width: '100%' }}
                isLoaded={false}
                startColor={loadingColor}
              />
            </View>
          ) : (
            <>
              <TextInput
                style={styles.input}
                editable={!updating}
                value={pagespeedKeyEdit || ''}
                onChangeText={setPagespeedKeyEdit}
                placeholderTextColor={'#333'}
                placeholder="API-Key"
              />
              {(pagespeedKey ?? '').trim() !== (pagespeedKeyEdit ?? '').trim() && (
                <Button
                  onPress={storePagespeed}
                  disabled={updating}
                  textStyle={{ color: invertedTextColor }}
                  style={styles.button}
                >
                  <>{`${t('settings.web.connections.save')}`}</>
                </Button>
              )}
            </>
          )}
        </View>
      </View>
      <View style={defaultStyles.settingsDivider} />
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle
            title={`${t('settings.web.connections.mollie.title')}`}
            subtitle={`${t('settings.web.connections.mollie.subtitle')}`}
          />
        </View>
        <View style={[defaultStyles.settingsOptionWrapper, defaultStyles.settingsOptionWrapperFullWidth]}>
          {loading === true ? (
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between', height: 43, width: '50%', padding: 5 }}
            >
              <Skeleton
                style={{ borderRadius: 5, height: 38, width: '100%' }}
                isLoaded={false}
                startColor={loadingColor}
              />
            </View>
          ) : (
            <>
              <TextInput
                style={styles.input}
                editable={!updating}
                value={mollieKeyEdit || ''}
                onChangeText={setMollieKeyEdit}
                placeholderTextColor={'#333'}
                placeholder="API-Key"
              />
              {(mollieKey ?? '').trim() !== (mollieKeyEdit ?? '').trim() && (
                <Button
                  onPress={storeMollie}
                  disabled={updating}
                  textStyle={{ color: invertedTextColor }}
                  style={styles.button}
                >
                  <>{`${t('settings.web.connections.save')}`}</>
                </Button>
              )}
            </>
          )}
        </View>
      </View>
      <View style={defaultStyles.settingsDivider} />
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle
            title={`${t('settings.web.connections.seller_math.title')}`}
            subtitle={`${t('settings.web.connections.seller_math.subtitle')}`}
          />
        </View>
        <View style={[defaultStyles.settingsOptionWrapper, defaultStyles.settingsOptionWrapperFullWidth]}>
          {loading === true ? (
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between', height: 43, width: '50%', padding: 5 }}
            >
              <Skeleton
                style={{ borderRadius: 5, height: 38, width: '100%' }}
                isLoaded={false}
                startColor={loadingColor}
              />
            </View>
          ) : (
            <>
              <TextInput
                style={styles.input}
                editable={!updating}
                value={sellermathKeyEdit || ''}
                onChangeText={setSellermathKeyEdit}
                placeholderTextColor={'#333'}
                placeholder="API-Key"
              />
              {(sellermathKey ?? '').trim() !== (sellermathKeyEdit ?? '').trim() && (
                <Button
                  onPress={storeSellermath}
                  disabled={updating}
                  textStyle={{ color: invertedTextColor }}
                  style={styles.button}
                >
                  <>{`${t('settings.web.connections.save')}`}</>
                </Button>
              )}
            </>
          )}
        </View>
      </View>
      <View style={defaultStyles.settingsDivider} />
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle
            title={`${t('settings.web.connections.amazon.title')}`}
            subtitle={`${t('settings.web.connections.amazon.subtitle')}`}
          />
        </View>
        <View style={[defaultStyles.settingsOptionWrapper, defaultStyles.settingsOptionWrapperFullWidth]}>
          {loading === true ? (
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between', height: 43, width: '50%', padding: 5 }}
            >
              <Skeleton
                style={{ borderRadius: 5, height: 38, width: '100%' }}
                isLoaded={false}
                startColor={loadingColor}
              />
            </View>
          ) : (
            <>
              {amazonConnected === true && (
                <View style={styles.connectTextWrapper}>
                  <FontAwesomeIcon icon={`check-circle`} color={textColor} />
                  <ColoredText
                    style={{ marginLeft: 10, fontWeight: 'bold' }}
                  >{`${t('settings.web.connections.connected')}`}</ColoredText>
                </View>
              )}
              {amazonConnected === true && (
                <Button
                  onPress={disconnectAmazon}
                  disabled={updating}
                  textStyle={{ color: textColor }}
                  style={styles.disconnectButton}
                >
                  <>{`${t('settings.web.connections.disconnect')}`}</>
                </Button>
              )}
              {amazonConnected === false && (
                <Button
                  onPress={connectAmazon}
                  disabled={updating}
                  textStyle={{ color: invertedTextColor }}
                  style={styles.connectButton}
                >
                  <>{`${t('settings.web.connections.connect')}`}</>
                </Button>
              )}
            </>
          )}
        </View>
      </View>
      <View style={defaultStyles.settingsDivider} />
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle
            title={`${t('settings.web.connections.search_console.title')}`}
            subtitle={`${t('settings.web.connections.search_console.subtitle')}`}
          />
        </View>
        <View style={[defaultStyles.settingsOptionWrapper, defaultStyles.settingsOptionWrapperFullWidth]}>
          {loading === true ? (
            <View
              style={{ flexDirection: 'row', justifyContent: 'space-between', height: 43, width: '50%', padding: 5 }}
            >
              <Skeleton
                style={{ borderRadius: 5, height: 38, width: '100%' }}
                isLoaded={false}
                startColor={loadingColor}
              />
            </View>
          ) : (
            <>
              {searchConsoleConnected === true && (
                <View style={styles.connectTextWrapper}>
                  <FontAwesomeIcon icon={`check-circle`} color={textColor} />
                  <ColoredText
                    style={{ marginLeft: 10, fontWeight: 'bold' }}
                  >{`${t('settings.web.connections.connected')}`}</ColoredText>
                </View>
              )}
              {searchConsoleConnected === true && (
                <Button
                  onPress={disconnectSearchConsole}
                  disabled={updating}
                  textStyle={{ color: textColor }}
                  style={styles.disconnectButton}
                >
                  <>{`${t('settings.web.connections.disconnect')}`}</>
                </Button>
              )}
              {searchConsoleConnected === false && (
                <Button
                  onPress={connectSearchConsole}
                  disabled={updating}
                  textStyle={{ color: invertedTextColor }}
                  style={styles.connectButton}
                >
                  <>{`${t('settings.web.connections.connect')}`}</>
                </Button>
              )}
            </>
          )}
        </View>
      </View>
      <View style={defaultStyles.settingsDivider} />
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle
            title={`${t('settings.web.connections.wawi.title')}`}
            subtitle={t('settings.web.connections.wawi.connectToWawi')}
          />
        </View>
        {wawiConnected === true && (
          <View style={styles.connectTextWrapper}>
            <FontAwesomeIcon icon={`check-circle`} color={textColor} />
            <ColoredText
              style={{ marginLeft: 10, fontWeight: 'bold' }}
            >{`${t('settings.web.connections.connected')}`}</ColoredText>
          </View>
        )}
        {wawiConnected === true && (
          <Button
            onPress={disconnectWawi}
            disabled={updating}
            textStyle={{ color: textColor }}
            style={styles.disconnectButton}
          >
            <>{`${t('settings.web.connections.disconnect')}`}</>
          </Button>
        )}
        {wawiConnected === false && (
          <View style={defaultStyles.settingsItemWrapper}>
            <View style={defaultStyles.settingsTitleWrapper}>
              <View
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  alignContent: 'flex-start',
                  padding: 15,
                }}
              >
                <ColoredText
                  style={styles.hyperlink}
                  onPress={async () => {
                    await Linking.openURL(urls.URL_JTL_WAWI_API_HELPDESK)
                  }}
                >
                  {t('settings.web.connections.wawi.helpdesk_guide')}
                </ColoredText>
              </View>
            </View>
            <View style={[defaultStyles.settingsOptionWrapper, defaultStyles.settingsOptionWrapperFullWidth]}>
              {loading === true ? (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    height: 43,
                    width: '50%',
                    padding: 5,
                  }}
                >
                  <Skeleton
                    style={{ borderRadius: 5, height: 38, width: '100%' }}
                    isLoaded={false}
                    startColor={loadingColor}
                  />
                </View>
              ) : (
                <>
                  <TextInput
                    style={styles.input}
                    editable={true} //change later
                    value={wawiApiUrl ? wawiApiUrl : ''}
                    onChangeText={setWawiApiUrl}
                    placeholderTextColor={'#333'}
                    placeholder="API-Url z.B. https://myapi-url.de"
                  ></TextInput>
                  {(wawiApiUrl ?? '').trim() !== '' && (
                    <Button
                      onPress={registerWawi}
                      disabled={updating}
                      textStyle={{ color: invertedTextColor }}
                      style={[styles.button, { width: '30%' }]}
                    >
                      <>{t('settings.web.connections.wawi.requestConnection')}</>
                    </Button>
                  )}
                  {updating === true ? <ActivityIndicator size="small" style={styles.spinner} /> : null}
                </>
              )}
            </View>
            <View style={styles.wawiConnectionMsg}>
              {WawiConnectionMsg({ authRequestStatus, wawiFetchStatusMsg, wawiAuthError, styles })}
            </View>

            {authRequestStatus === 'sent' || wawiAuthError?.errorCode === 'AppAlreadyRegistered' ? (
              <>
                <Button
                  disabled={updating}
                  onPress={confirmRegistration}
                  textStyle={{ color: invertedTextColor }}
                  style={[styles.button, { width: '50%', marginLeft: 15 }]}
                >
                  {t('settings.web.connections.wawi.confirmConnection')}
                </Button>
              </>
            ) : (
              <></>
            )}
          </View>
        )}
      </View>
    </View>
  )
}

export default ConnectionSettings
