import React from 'react'
import { View, Platform } from 'react-native'
import { AuthProvider as AuthProviderT, OAuthCredential as OAuthCredentialT } from '@firebase/auth-types'
import * as AppleAuthentication from 'expo-apple-authentication'
import useStyles from './styles'
import loginWithApple from '../../utils/auth/login-with-apple'
import GoogleLogin from '../../utils/auth/GoogleLogin'
import AppleLogin from '../../utils/auth/AppleLogin'
import ColoredText from '../CustomText/ColoredText'

interface IProps {
  handleProviderLogin: (provider: AuthProviderT) => void
  handleCredentialLogin: (credential: OAuthCredentialT) => void
  loading: boolean
}

const AuthProvider = ({ handleProviderLogin, handleCredentialLogin }: IProps) => {
  const styles = useStyles()

  const onClickLoginWithApple = () => loginWithApple({ handleCredentialLogin, handleProviderLogin })

  return (
    <View style={styles.wrapper}>
      <View style={styles.dividerWrapper}>
        <View style={styles.dividerLine} />
        <ColoredText style={{ color: 'black' }}>oder</ColoredText>
        <View style={styles.dividerLine} />
      </View>
      {Platform.OS === 'ios' && (
        <AppleAuthentication.AppleAuthenticationButton
          buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
          buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE_OUTLINE}
          cornerRadius={10}
          style={{ width: '100%', height: 47 }}
          onPress={onClickLoginWithApple}
        />
      )}
      {Platform.OS === 'web' && (
        <AppleLogin handleCredentialLogin={handleCredentialLogin} handleProviderLogin={handleProviderLogin} />
      )}
      <GoogleLogin handleCredentialLogin={handleCredentialLogin} handleProviderLogin={handleProviderLogin} />
    </View>
  )
}

export default AuthProvider
