import React from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import LiveTrackerView from './View'
import { EventDataT } from '../../../../../types/widgetDataTypes/V3/types'
import Widget from '../../../../components/Widget'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { useNavigate } from '../../../../../components/Router/Router'
import LivetrackerSkeleton from './LivetrackerSkeleton'

const LiveTracker = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Widget<EventDataT>
      title={t('shop.widget.live-tracker.title')}
      widget={widget}
      loadingSkeleton={<LivetrackerSkeleton />}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, rowsPerPage = 100 }, { page = 1 } = {}) => ({
          body: {
            module: 'EventTracker',
            endpoint: ['visitors'],
            params: {
              options: {
                details: false,
                refresh: autoRefresh ? refreshInterval : undefined,
                rowsPerPage: 100,
                pagination: { descending: true, page, rowsPerPage },
              },
            },
          },
          widget: 'visitors',
        }),
      ]}
      onClickWidget={() => {
        navigate(`/analytics/live-tracker/${widget.settings.shop}`)
      }}
      {...props}
    >
      {([, data, , shop]) => {
        // console.log(data)
        return <LiveTrackerView {...data} currency={shop?.currency || '€'} />
      }}
    </Widget>
  )
}

export default LiveTracker
