import { Toast } from 'native-base'
import React, { useState } from 'react'
import { View } from 'react-native'
import { useTranslation } from '../../../hooks/useTranslation'
import Button from '../../Button'
import ColoredText from '../../CustomText/ColoredText'
import LoadingScreen from '../../LoadingScreen'
import useStyles from '../styles'
import { functions } from '../../../firebase'
import { IUser } from '../../../types/user'

export const SetAdminDialogContent = ({
  user,
  modalRef,
  setSetAdmin,
  isAdmin,
}: {
  user: IUser
  modalRef: React.MutableRefObject<any>
  setSetAdmin: (arg0: boolean) => void
  isAdmin: boolean
}) => {
  const { t } = useTranslation()
  const [settingAdmin, setSettingAdmin] = useState<boolean>(false)
  const styles = useStyles()
  const admin = functions().httpsCallable('calls-company-setAdmin')
  return settingAdmin ? (
    <LoadingScreen />
  ) : (
    <>
      <ColoredText style={styles.modalContentText}>
        <>
          {isAdmin
            ? t('accountOverview.user.submit_demote_text', { user: `${user?.firstName} ${user?.lastName}` })
            : t('accountOverview.user.submit_promote_text', { user: `${user?.firstName} ${user?.lastName}` })}
        </>
      </ColoredText>
      <ColoredText style={styles.modalContentText}>
        <> {isAdmin ? t('accountOverview.user.demote_text') : t('accountOverview.user.promote_text')}</>
      </ColoredText>
      <View style={styles.buttonWrapper}>
        <Button
          style={styles.cancelButton}
          onPress={() => {
            if (modalRef && modalRef.current) modalRef.current.setVisible(false)
            setSetAdmin(false)
          }}
        >
          {t('accountOverview.user.cancel')}
        </Button>
        <Button
          onPress={() => {
            setSettingAdmin(true)
            admin({ uid: user.id, admin: !isAdmin })
              .then(() => {
                Toast.show({
                  title: isAdmin
                    ? t('accountOverview.user.successfully_demoted')
                    : t('accountOverview.user.successfully_promoted'),
                })
              })
              .catch((err) => {
                Toast.show({
                  title: isAdmin ? t('accountOverview.user.error_demoting') : t('accountOverview.user.error_promoting'),
                  description: err.message,
                })
              })
              .finally(() => {
                if (modalRef && modalRef.current) modalRef.current.setVisible(false)
                setSettingAdmin(false)
                setSetAdmin(false)
              })
          }}
          textStyle={{ color: 'white' }}
          style={isAdmin ? styles.deleteButton : styles.promoteButton}
        >
          {isAdmin ? t('accountOverview.user.demote_admin') : t('accountOverview.user.promote_admin')}
        </Button>
      </View>
    </>
  )
}
