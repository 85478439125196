//import { Platform } from 'react-native'

//const headbarHeight = Platform.OS === 'web' ? 80 : 42 change when header gets Transaparent
const headerHeight = 0
const footerHeight = 52
const paddingHorizontal = 10
const paddingTop = 5
const opacity = 0.9
const maxWidth = 1024

export default {
  headerHeight,
  footerHeight,
  paddingHorizontal,
  paddingTop,
  opacity,
  maxWidth,
}
