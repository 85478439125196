import { StyleSheet } from 'react-native'

const useStyles = () => {
  return StyleSheet.create({
    mainWrapper: {
      //   flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    mandateWrapper: {
      marginVertical: 20,
      //   flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    mandateChangeWrapper: {
      width: '100%',
      //   flex: 1,
      justifyContent: 'center',
      paddingHorizontal: 20,
      marginTop: 30,
    },
  })
}

export default useStyles
