import * as React from 'react'
import Svg, { SvgProps, G, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */
const SvgComponent = (props: SvgProps) => (
  <Svg width={99} height={25} {...props}>
    <G fill="none" fillRule="evenodd">
      <Path
        fill="#2890C3"
        d="m94.378 6.877-2.18 13.827a.442.442 0 0 0 .437.51h2.193a.737.737 0 0 0 .73-.622l2.15-13.578a.442.442 0 0 0-.438-.51h-2.454a.442.442 0 0 0-.438.373M87.834 16.338c-.246 1.448-1.4 2.421-2.872 2.421-.738 0-1.33-.237-1.709-.685-.377-.443-.518-1.076-.399-1.78.23-1.437 1.402-2.44 2.852-2.44.722 0 1.309.239 1.696.69.39.456.543 1.092.432 1.794m3.546-4.936h-2.545a.443.443 0 0 0-.438.373l-.111.708-.178-.256c-.551-.797-1.78-1.064-3.006-1.064-2.81 0-5.212 2.123-5.68 5.1-.242 1.486.103 2.906.948 3.896.776.91 1.884 1.29 3.204 1.29 2.267 0 3.523-1.45 3.523-1.45l-.114.704a.442.442 0 0 0 .438.51h2.291a.737.737 0 0 0 .73-.62l1.375-8.681a.442.442 0 0 0-.437-.51M76.111 11.463c-.29 1.9-1.747 1.9-3.156 1.9h-.802l.563-3.548a.442.442 0 0 1 .437-.372h.368c.959 0 1.865 0 2.332.544.28.325.364.809.258 1.476m-.613-4.96h-5.314a.738.738 0 0 0-.73.622l-2.148 13.578a.442.442 0 0 0 .438.51h2.726c.254 0 .47-.184.51-.434l.61-3.85a.737.737 0 0 1 .73-.622H74c3.5 0 5.52-1.687 6.048-5.033.238-1.463.01-2.612-.677-3.417-.756-.885-2.095-1.354-3.873-1.354"
      />
      <Path
        fill="#28346A"
        d="M67.032 11.402h-2.557a.737.737 0 0 0-.61.323l-3.529 5.178-1.494-4.976a.74.74 0 0 0-.708-.525H55.62a.442.442 0 0 0-.419.584l2.816 8.238-2.649 3.724a.441.441 0 0 0 .362.696h2.555a.74.74 0 0 0 .607-.316l8.504-12.233a.441.441 0 0 0-.364-.693M49.935 16.338c-.246 1.448-1.4 2.421-2.872 2.421-.738 0-1.329-.237-1.709-.685-.376-.443-.517-1.076-.398-1.78.229-1.437 1.401-2.44 2.851-2.44.723 0 1.31.239 1.696.69.39.456.544 1.092.432 1.794m3.546-4.936h-2.545a.443.443 0 0 0-.437.373l-.112.708-.177-.256c-.552-.797-1.78-1.064-3.007-1.064-2.81 0-5.212 2.123-5.679 5.1-.243 1.486.102 2.906.948 3.896.776.91 1.884 1.29 3.204 1.29 2.266 0 3.522-1.45 3.522-1.45l-.113.704a.442.442 0 0 0 .437.51h2.291a.737.737 0 0 0 .73-.62l1.376-8.681a.442.442 0 0 0-.438-.51M38.212 11.463c-.29 1.9-1.746 1.9-3.156 1.9h-.801l.562-3.548a.442.442 0 0 1 .437-.372h.368c.96 0 1.865 0 2.332.544.28.325.364.809.258 1.476m-.613-4.96h-5.313a.738.738 0 0 0-.73.622l-2.148 13.578a.442.442 0 0 0 .437.51h2.537a.738.738 0 0 0 .73-.62l.58-3.664a.737.737 0 0 1 .729-.622h1.68c3.5 0 5.521-1.687 6.049-5.033.237-1.463.01-2.612-.678-3.417-.756-.885-2.095-1.354-3.873-1.354M17.957 1.869C16.81.56 14.733 0 12.077 0H4.37c-.543 0-1.005.395-1.09.93L.07 21.27a.661.661 0 0 0 .654.764h4.758l1.195-7.575-.037.238a1.1 1.1 0 0 1 1.086-.93h2.261c4.443 0 7.92-1.804 8.937-7.02.03-.153.056-.304.079-.45-.129-.068-.129-.068 0 0 .302-1.928-.002-3.24-1.046-4.428"
      />
      <Path
        fill="#2890C3"
        d="M18.983 6.234c-.023.147-.049.295-.079.449-1.014 5.194-4.488 6.99-8.924 6.99H7.722c-.542 0-1 .393-1.084.926l-1.485 9.38a.577.577 0 0 0 .57.666H9.73a.963.963 0 0 0 .951-.81l.04-.204.754-4.768.049-.264a.963.963 0 0 1 .951-.81h.6c3.88 0 6.918-1.571 7.806-6.115.37-1.898.18-3.484-.801-4.597a3.824 3.824 0 0 0-1.097-.843"
      />
      <Path
        fill="#1F274F"
        d="M17.921 5.812a7.422 7.422 0 0 0-.479-.122 9.073 9.073 0 0 0-.508-.097 12.567 12.567 0 0 0-1.991-.144H8.91a.959.959 0 0 0-.952.811l-1.283 8.103-.037.236a1.096 1.096 0 0 1 1.084-.926h2.259c4.436 0 7.91-1.796 8.924-6.99.03-.154.055-.302.079-.45a5.49 5.49 0 0 0-1.063-.421"
      />
    </G>
  </Svg>
)
export default SvgComponent
