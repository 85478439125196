import React, { useContext } from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import { TouchableOpacity, View } from 'react-native'
import useStyles from '../../../../../components/Widget/styles'
import { useNavigate } from '../../../../../components/Router/Router'
import { UpsellingContext } from '../../../../../context/UpsellingContext'
import { SessionContext } from '../../../../../context/SessionContext'
import AddWidget from '../../../../../assets/imgs/widgets/add_widget/AddWidget'
import colors from '../../../../../constants/colors'

const AddNewWidget = ({ dashboardId }: IWidgetProps) => {
  const navigate = useNavigate()
  const widgetStyles = useStyles()
  const { company } = useContext(SessionContext)
  const { open } = useContext(UpsellingContext)
  const canManageDashboards = company?.authorizations['dashboard.edit'] === true
  return (
    <TouchableOpacity
      onPress={() => {
        if (!canManageDashboards) {
          open()
        } else {
          navigate(`/settings/dashboard/${dashboardId}/create-widget`)
        }
      }}
    >
      <View
        style={[
          { flexDirection: 'row', justifyContent: 'center', alignItems: 'stretch', alignContent: 'stretch' },
          widgetStyles.widget,
          {
            padding: 0,
            width: '100%',
            minWidth: '100%',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: colors.color3,
          },
        ]}
      >
        {/* <FontAwesomeIcon icon={[icons.faIconStyle, 'plus']} color={highlightColor} />
        <BoldText style={{ color: highlightColor, marginLeft: 7 }}>
          <>{t('dashboard.addWidget')}</>
        </BoldText> */}
        <AddWidget style={{ width: '100%' }} />
      </View>
    </TouchableOpacity>
  )
}

export default AddNewWidget
