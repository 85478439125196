import { StyleSheet } from 'react-native'
import { usePrimaryBackground } from '../../../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    shopSelectionWrapper: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    shopSelectionTitle: {
      marginVertical: 2,
      justifyContent: 'center',
    },
    shopSelectionRow: {
      width: '100%',
      flexDirection: 'row',
      backgroundColor: usePrimaryBackground(),
      marginVertical: 5,
      borderRadius: 10,
      padding: 5,
      justifyContent: 'center',
    },
  })
}

export default useStyles
