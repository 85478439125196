import 'firebase/firestore'
import { configureStore, combineReducers, createSlice } from '@reduxjs/toolkit'
import { withExtraArgument } from 'redux-thunk'
import { firebaseReducer, getFirebase } from 'react-redux-firebase'

import { firestoreReducer } from 'redux-firestore'
import { Languages } from '../types/language'
import { Themes } from '../types/dashbar/theme'

type LanguageState = {
  language: Languages
}

const initialLanguageState: LanguageState = { language: Languages.DE }

const languageSlice = createSlice({
  name: 'language',
  initialState: initialLanguageState,
  reducers: {
    changeLanguage(state, action) {
      state.language = action.payload
    },
  },
})

type ThemeState = {
  theme: Themes
}

const initialThemeState: ThemeState = { theme: Themes.Light }

const themeSlice = createSlice({
  name: 'theme',
  initialState: initialThemeState,
  reducers: {
    changeTheme(state, action) {
      state.theme = action.payload
    },
  },
})

// const setTheme = (state: ThemeState, action: SetThemeAction): BaseState => ({

// })

// const setLanguage = (state: BaseState, action: SetLanguageAction): BaseState => ({
//   ...state,
//   config: {
//     ...state.config,
//     language: action.payload,
//   },
// })

export const rootReducer = combineReducers({
  // base: baseReducer,
  language: languageSlice.reducer,
  theme: themeSlice.reducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  //firebaseUser: fbUserReducer,
  //firebaseUserNotifications: fbUserNotificationsReducer,
})

// const middlewares = [withExtraArgument(getFirebase) /*, ...mwBase*/]

// let composeEnhancers = compose

if (process.env.NODE_ENV !== 'production') {
  // const { composeWithDevTools } = require('redux-devtools-extension')
  // composeEnhancers = composeWithDevTools({
  //   trace: true,
  //   traceLimit: 25,
  // })
  // const { createLogger } = require('redux-logger')
  // const logger = createLogger({
  //   duration: true,
  //   diff: false,
  // })
  // middlewares.push(logger)
}
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(withExtraArgument(getFirebase)),
  devTools:
    process.env.NODE_ENV !== 'production'
      ? {
          trace: true,
          traceLimit: 25,
        }
      : false,
})

// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))
export type AppDispatch = typeof store.dispatch

export default store
