import React, { ReactNode } from 'react'
import { FlexStyle, ScrollView, View } from 'react-native'
import layoutSizes from './layoutSizes'
import useDeviceType from '../../hooks/useDeviceType'

const PageWrapper = ({
  children,
  isScrollable,
  scrollViewStyle,
  fullScreen = false,
  scrollEnabled = true,
}: {
  children: ReactNode
  isScrollable: boolean
  scrollViewStyle?: FlexStyle
  fullScreen?: boolean
  scrollEnabled?: boolean
}) => {
  let styling: FlexStyle
  const device = useDeviceType()
  if (device === 'web' && !fullScreen) {
    styling = {
      width: layoutSizes.maxWidth,
      maxWidth: '100%',
      alignSelf: 'center',
      paddingTop: layoutSizes.headerHeight + layoutSizes.paddingTop,
    }
  } else if (device === 'web') {
    styling = {
      width: '100%',
      maxWidth: '100%',
      alignSelf: 'center',
      paddingTop: layoutSizes.headerHeight + layoutSizes.paddingTop,
    }
  } else {
    styling = {
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      alignSelf: 'center',
      paddingHorizontal: layoutSizes.paddingHorizontal,
      paddingTop: layoutSizes.headerHeight,
    }
  }
  if (isScrollable) {
    return (
      <ScrollView
        scrollEnabled={scrollEnabled}
        style={[styling, { overflow: 'scroll' }]}
        contentContainerStyle={scrollViewStyle}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        <>{children}</>
      </ScrollView>
    )
  }
  return (
    <View style={[styling, scrollViewStyle]}>
      <>{children}</>
    </View>
  )
}

export default PageWrapper
