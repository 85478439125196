import { useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import React from 'react'
import Button from '../../../../../../components/Button'
import useStyles from './styles'
import GSCSkeleton from '../Skeleton'
import { t } from 'i18next'

const ShopSelection = ({
  access_token,
  selectShop,
}: {
  access_token: string | undefined
  selectShop: (shopId: string) => void
}) => {
  const [page, setPage] = useState<number>(0)
  const ROWS_PER_PAGE = 4
  const styles = useStyles()
  const [shops, setShops] = useState<Array<{ siteUrl: string; permissionLevel: string }> | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)
    fetch('https://www.googleapis.com/webmasters/v3/sites', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${access_token}`,
        'User-Agent':
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_2) AppleWebKit/537.17 (KHTML, like Gecko) Chrome/24.0.1309.0 Safari/537.17',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          const resp = response.json()
          return resp
        }
      })
      .then((data) => {
        if (data?.siteEntry) {
          setShops(data.siteEntry)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [access_token])

  const decreasePage = () => {
    if (page > 0) {
      setPage(page - 1)
    } else {
      setPage(0)
    }
  }

  const increasePage = () => {
    if (!shops) return
    if (shops.length > (page + 1) * ROWS_PER_PAGE) {
      setPage(page + 1)
    }
  }

  if (loading) {
    return <GSCSkeleton />
  }

  return (
    <View style={styles.shopSelectionWrapper}>
      {shops === null ? (
        <View style={styles.shopSelectionTitle}>
          <ColoredText>{`No Shop found`}</ColoredText>
        </View>
      ) : (
        <>
          <View style={styles.shopSelectionTitle}>
            <ColoredText>{`Wähle einen Shop aus`}</ColoredText>
          </View>
          {shops
            .filter((v, index) => {
              //Currently not supporting more then 4 Shops for Design Reasons
              return index >= page * ROWS_PER_PAGE && index < (page + 1) * ROWS_PER_PAGE
            })
            .map((v, index) => {
              return (
                <TouchableOpacity
                  key={`${v.siteUrl}_${index}`}
                  style={styles.shopSelectionRow}
                  onPress={() => {
                    selectShop(v.siteUrl)
                  }}
                >
                  <ColoredText numberOfLines={1}>{v.siteUrl ?? 'Unkown Shop-Url'}</ColoredText>
                </TouchableOpacity>
              )
            })}
          <View style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center', alignContent: 'center' }}>
            {page > 0 && (
              <Button style={{ marginHorizontal: 5 }} onPress={decreasePage}>
                {t('shop.widget.googleSearchConsole.changePage.Back')}
              </Button>
            )}
            {shops.length > (page + 1) * ROWS_PER_PAGE && (
              <Button style={{ marginHorizontal: 5 }} onPress={increasePage}>
                {t('shop.widget.googleSearchConsole.changePage.Next')}
              </Button>
            )}
          </View>
        </>
      )}
    </View>
  )
}

export default ShopSelection
