/** @description
 * Migrated from @webstollen/shared-react-components
 */

import { createContext, useContext } from 'react'
import { Themes } from '../../types/dashbar/theme'

export const isThemeDark = (theme: Themes) => theme === Themes.Dark
export const isThemeLight = (theme: Themes) => theme === Themes.Light

export const ThemeContext = createContext(Themes.Dark)

export const useTheme = () => useContext(ThemeContext)

export const useIsDarkTheme = () => isThemeDark(useTheme())
export const useIsLightTheme = () => isThemeLight(useTheme())
