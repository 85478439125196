import { StyleSheet } from 'react-native'
import colors from '../../constants/colors'

const useStyles = () => {
  return StyleSheet.create({
    textBold: {
      marginVertical: 5,
      color: colors.highlightDark,
      fontWeight: 'bold',
      marginHorizontal: 5,
    },
    text: {
      marginVertical: 5,
      color: colors.highlightDark,
      marginHorizontal: 5,
    },
  })
}

export default useStyles
