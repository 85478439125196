import { Dimensions, Platform } from 'react-native'
const MOBILE_WINDOW_SIZE = 810

/**
 * @returns Boolean wether windowsize is mobile Web or not
 */
const useIsMobileWebRender = (): boolean => {
  if (Platform.OS !== 'web') return false
  if (Dimensions.get('window').width < MOBILE_WINDOW_SIZE) return true
  return false
}

export default useIsMobileWebRender
