import { View } from 'react-native'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { FontAwesomeIcon } from '../../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import React from 'react'
import colors from '../../../../../../constants/colors'
import icons from '../../../../../../constants/icons'
import { round } from 'lodash'
import BoldText from '../../../../../../components/CustomText/BoldText'
import numbro from 'numbro'
import useStyles from '../../../../widgets_v1/Status/styles'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { GoogleSearchConsoleResponse } from './helper'

const Status = ({
  firstIntervallData,
  secondIntervalData,
  urlInspectData,
}: {
  firstIntervallData: GoogleSearchConsoleResponse | undefined
  secondIntervalData: GoogleSearchConsoleResponse | undefined
  urlInspectData: any
}) => {
  const { t } = useTranslation()
  const styles = useStyles()
  return (
    <View style={{ justifyContent: 'space-between', height: '100%' }}>
      <View style={[styles.keyFigures]}>
        {[
          {
            value: firstIntervallData?.clicks,
            previous: secondIntervalData?.clicks || 0,
            label: t('shop.widget.key-figures.body.clicks'),
            rawValue: firstIntervallData?.clicks || 0,
            average: false,
          },
          {
            value: firstIntervallData?.impressions,
            previous: secondIntervalData?.impressions || 0,
            label: t('shop.widget.key-figures.body.impressionen'),
            rawValue: firstIntervallData?.impressions || 0,
            average: true,
          },
        ].map(({ value, label, previous, rawValue, average }, index) => {
          const number = previous === 0 ? 0 : (rawValue - previous) / previous

          return (
            <View key={label} style={[styles.item, index === 0 ? { paddingRight: 25 } : { paddingLeft: 25 }]}>
              <View style={styles.titleWrapper}>
                <ColoredText style={styles.itemTitle}>{label}</ColoredText>
                <BoldText style={styles.itemCurrentValue}>
                  {numbro(value).format({ thousandSeparated: true, average: average }).replace(',', '.')}
                </BoldText>
              </View>
              <ColoredText style={[styles.percentage, number >= 0 ? styles.positive : styles.negative]}>
                {numbro(number).format({
                  output: 'percent',
                  mantissa: 0,
                  forceSign: true,
                })}
              </ColoredText>
            </View>
          )
        })}
      </View>
      <View style={[styles.keyFigures]}>
        {[
          {
            value: firstIntervallData?.ctr || 0,
            previous: secondIntervalData?.ctr || 0,
            label: t('shop.widget.key-figures.body.ctr'),
            rawValue: firstIntervallData?.ctr || 0,
          },
          {
            value: firstIntervallData?.position || 0,
            previous: secondIntervalData?.position || 0,
            label: t('shop.widget.key-figures.body.position'),
            rawValue: firstIntervallData?.position || 0,
          },
        ].map(({ value, label, previous, rawValue }, index) => {
          const number = previous === 0 ? 0 : (rawValue - previous) / previous

          return (
            <View key={label} style={[styles.item, index === 0 ? { paddingRight: 25 } : { paddingLeft: 25 }]}>
              <View style={styles.titleWrapper}>
                <ColoredText style={styles.itemTitle}>{label}</ColoredText>
                <BoldText style={styles.itemCurrentValue}>{round(value, 2).toLocaleString()}</BoldText>
              </View>
              <ColoredText style={[styles.percentage, number >= 0 ? styles.positive : styles.negative]}>
                {numbro(number).format({
                  output: 'percent',
                  mantissa: 0,
                  forceSign: true,
                })}
              </ColoredText>
            </View>
          )
        })}
      </View>
      <View style={[styles.status]}>
        {[
          {
            status: urlInspectData?.indexStatusResult?.coverageState === 'Submitted and indexed',
            label: t('shop.widget.googleSearchConsole.overview.coverageState'),
          },
          {
            status: urlInspectData?.indexStatusResult?.verdict === 'PASS',
            label: t('shop.widget.googleSearchConsole.overview.indexStatusResult'),
          },
          {
            status: urlInspectData?.mobileUsabilityResult?.verdict === 'PASS',
            label: t('shop.widget.googleSearchConsole.overview.mobileUsabilityResult'),
          },
          {
            status: urlInspectData?.indexStatusResult?.indexingState === 'INDEXING_ALLOWED',
            label: t('shop.widget.googleSearchConsole.overview.https'),
          },
        ].map(({ status, label }, index) => (
          <View key={label} style={[styles.statusItem, index % 2 === 0 ? { paddingRight: 20 } : { paddingLeft: 20 }]}>
            <FontAwesomeIcon
              color={urlInspectData === undefined ? colors.text : status ? colors.success : colors.error}
              icon={
                urlInspectData === undefined ? 'loader' : [icons.faIconStyle, status ? 'check-circle' : 'times-circle']
              }
              spin={urlInspectData === undefined}
            />
            <ColoredText style={styles.itemText}>{label}</ColoredText>
          </View>
        ))}
      </View>
    </View>
  )
}

export default Status
