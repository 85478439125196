import { Skeleton } from 'native-base'
import React from 'react'
import { View } from 'react-native'
import { usePrimaryBackground } from '../../../../../../hooks/useColors'

const HistorySkeleton = () => {
  const loadingColor = usePrimaryBackground()
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between', height: '100%', padding: 5 }}>
      <Skeleton style={{ borderRadius: 15, height: '100%' }} isLoaded={false} startColor={loadingColor} />
    </View>
  )
}

export default HistorySkeleton
