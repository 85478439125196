import { View } from 'native-base'
import React from 'react'
import Avatar from '../../../../../../../components/Avatar'
import ColoredText from '../../../../../../../components/CustomText/ColoredText'
import useStyles from './styles'
import { IShop } from '../../../../../../../types/dashboard'

const ShopView = ({ shop }: { shop: IShop }) => {
  const styles = useStyles()

  return (
    <View style={styles.shopViewWrapper}>
      <Avatar style={[styles.avatar]} photoURL={shop.icon} />
      <ColoredText style={styles.name}>{`${shop?.domain}`}</ColoredText>
    </View>
  )
}

export default ShopView
