import * as React from 'react'
import Svg, { SvgProps, Defs, Path, G, Mask, Use } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */

const Business = (props: SvgProps) => (
  <Svg width={206} height={123} {...props}>
    <Defs>
      <Path id="a" d="M0 122.86h172.266V0H0z" />
    </Defs>
    <G fill="none" fillRule="evenodd">
      <Path fill="#C87842" fillRule="nonzero" d="M119 117h3V36h-3z" />
      <Path fill="#010202" fillRule="nonzero" d="M121 117h1V36h-1z" />
      <Path fill="#FEFEFE" fillRule="nonzero" d="M80 60h82V0H80z" />
      <Path fill="#EAA562" fillRule="nonzero" d="M79 64h84v-2H79zM79 2h84V0H79z" />
      <G fill="#010202" fillRule="nonzero">
        <Path d="M78.052 2.193h84.941v-1.14H78.052zM78.052 62.99h84.941v-1.14H78.052z" />
      </G>
      <Path
        fill="#DA8E51"
        fillRule="nonzero"
        d="m119 111-17 9.016.434.984L119 114.704zM122 111l17 8.977-.534 1.023L122 114.704z"
      />
      <Path
        d="M125.173 113.867h-9.488a.935.935 0 0 1-.934-.936v-1.88c0-.517.418-.936.934-.936h9.488c.515 0 .933.419.933.936v1.88a.935.935 0 0 1-.933.936M123.083 117.17h-5.309a.523.523 0 0 1-.522-.523v-1.052c0-.29.233-.524.522-.524h5.309c.288 0 .523.234.523.524v1.052c0 .29-.235.524-.523.524"
        fill="#EAA562"
        fillRule="nonzero"
      />
      <G fillRule="nonzero">
        <Path fill="#1D4363" d="M89.683 30.598h.253V7.578h-.253z" />
        <Path
          fill="#1D4363"
          d="M89.683 30.598h28.364v-.254H89.683zM86.55 28.228h-.003l-.135.073-.02-.08.17-.091h.09v.78h-.102zM87.17 28.737c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.213 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.63.627-.355 0-.595-.218-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603"
        />
        <Path
          d="M87.783 28.737c0 .31.146.485.369.485.25 0 .37-.192.37-.495 0-.294-.114-.485-.368-.485-.214 0-.37.172-.37.495m.984-.017c0 .403-.23.627-.63.627-.355 0-.595-.218-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603"
          fill="#1D4363"
        />
        <Path
          d="M88.396 28.737c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.63.627-.355 0-.595-.218-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603M86.321 23.892v-.064l.083-.08c.198-.19.288-.291.289-.408 0-.08-.038-.153-.153-.153a.267.267 0 0 0-.166.066l-.033-.074a.34.34 0 0 1 .22-.079c.167 0 .237.115.237.226 0 .145-.104.261-.268.418l-.061.059v.002h.348v.087h-.496ZM87.17 23.72c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.213 0-.37.172-.37.495m.985-.017c0 .404-.23.627-.63.627-.355 0-.595-.217-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603"
          fill="#1D4363"
        />
        <Path
          d="M87.783 23.72c0 .31.146.485.369.485.25 0 .37-.192.37-.495 0-.293-.114-.485-.368-.485-.214 0-.37.172-.37.495m.984-.017c0 .404-.23.627-.63.627-.355 0-.595-.217-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603"
          fill="#1D4363"
        />
        <Path
          d="M88.396 23.72c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .404-.23.627-.63.627-.355 0-.595-.217-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603M86.347 18.86c.03.019.098.049.172.049.134 0 .177-.086.176-.151-.001-.11-.1-.156-.201-.156h-.059v-.08h.06c.076 0 .172-.038.172-.13 0-.063-.04-.118-.136-.118a.283.283 0 0 0-.155.051l-.029-.076c.041-.03.12-.06.204-.06.153 0 .223.09.223.186 0 .08-.049.149-.143.183v.003c.095.018.172.09.172.2 0 .124-.097.233-.283.233a.382.382 0 0 1-.201-.053l.028-.08ZM87.17 18.809c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.213 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.63.627-.355 0-.595-.218-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603"
          fill="#1D4363"
        />
        <Path
          d="M87.783 18.809c0 .31.146.485.369.485.25 0 .37-.192.37-.495 0-.294-.114-.485-.368-.485-.214 0-.37.172-.37.495m.984-.017c0 .403-.23.627-.63.627-.355 0-.595-.218-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603"
          fill="#1D4363"
        />
        <Path
          d="M88.396 18.809c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.63.627-.355 0-.595-.218-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603M87.067 13.98v-.41c0-.065.002-.13.008-.194h-.008a3.34 3.34 0 0 1-.14.182l-.41.419-.001.003h.55Zm0 .465v-.335h-.781V14l.75-.785h.245v.765h.236v.13h-.236v.335h-.214Z"
          fill="#1D4363"
        />
        <Path
          d="M87.17 13.822c0 .31.145.485.368.485.25 0 .37-.191.37-.495 0-.293-.113-.485-.368-.485-.213 0-.37.173-.37.495m.985-.017c0 .404-.23.627-.63.627-.355 0-.595-.217-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603"
          fill="#1D4363"
        />
        <Path
          d="M87.783 13.822c0 .31.146.485.369.485.25 0 .37-.191.37-.495 0-.293-.114-.485-.368-.485-.214 0-.37.173-.37.495m.984-.017c0 .404-.23.627-.63.627-.355 0-.595-.217-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603"
          fill="#1D4363"
        />
        <Path
          d="M88.396 13.822c0 .31.145.485.368.485.25 0 .37-.191.37-.495 0-.293-.113-.485-.368-.485-.214 0-.37.173-.37.495m.985-.017c0 .404-.23.627-.63.627-.355 0-.595-.217-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603M86.785 8.227h-.296l-.03.2a.41.41 0 0 1 .063-.005c.06 0 .12.013.168.043.06.034.11.101.11.2 0 .152-.12.265-.288.265a.38.38 0 0 1-.194-.048l.026-.08c.032.019.096.044.167.044.098 0 .184-.066.183-.17 0-.1-.068-.172-.222-.172a.74.74 0 0 0-.109.008l.05-.374h.372v.089ZM87.17 8.745c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.63.627-.355 0-.596-.218-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603"
          fill="#1D4363"
        />
        <Path
          d="M87.783 8.745c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.63.627-.355 0-.596-.218-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603"
          fill="#1D4363"
        />
        <Path
          d="M88.396 8.745c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.63.627-.355 0-.596-.218-.601-.61 0-.4.263-.62.63-.62.383 0 .601.224.601.603M94.552 32.022c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.631.627-.354 0-.595-.218-.6-.61 0-.4.262-.62.63-.62.383 0 .6.223.6.603"
          fill="#1D4363"
        />
        <Path
          d="M94.929 32.194v-.064l.082-.08c.198-.19.289-.291.29-.409 0-.079-.038-.152-.154-.152a.268.268 0 0 0-.165.066l-.034-.074a.342.342 0 0 1 .22-.08c.167 0 .238.116.238.227 0 .145-.104.26-.268.418l-.061.058v.003h.348v.087h-.496ZM91.825 32.022c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.114-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.631.627-.354 0-.595-.218-.6-.61 0-.4.262-.62.63-.62.382 0 .6.223.6.603"
          fill="#1D4363"
        />
        <Path
          fill="#1D4363"
          d="M92.43 31.513h-.002l-.135.073-.02-.08.17-.091h.09v.779h-.102zM97.244 32.022c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.114-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.631.627-.354 0-.595-.218-.6-.61 0-.4.262-.62.63-.62.383 0 .6.223.6.603"
        />
        <Path
          d="M97.646 32.074c.03.018.099.048.173.048.134 0 .176-.086.176-.151-.002-.11-.1-.156-.202-.156h-.058v-.08h.059c.076 0 .173-.039.173-.13 0-.063-.04-.118-.137-.118a.283.283 0 0 0-.155.051l-.028-.076c.04-.03.12-.06.203-.06.154 0 .224.09.224.186 0 .08-.05.149-.144.183v.003c.096.018.173.09.173.2 0 .124-.097.233-.284.233a.382.382 0 0 1-.201-.053l.028-.08ZM100.007 32.022c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.63.627-.355 0-.596-.218-.601-.61 0-.4.263-.62.63-.62.383 0 .601.223.601.603"
          fill="#1D4363"
        />
        <Path
          d="M101.13 32.18v-.411c0-.065.002-.128.008-.193l-.008-.001a3.355 3.355 0 0 1-.14.183l-.41.418-.001.004h.55Zm0 .465v-.336h-.781V32.2l.75-.785h.245v.765h.236v.13h-.236v.335h-.214ZM102.829 32.022c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.114-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.631.627-.354 0-.595-.218-.6-.61 0-.4.262-.62.63-.62.382 0 .6.223.6.603"
          fill="#1D4363"
        />
        <Path
          d="M103.67 31.503h-.296l-.03.2a.388.388 0 0 1 .23.038c.06.034.111.102.111.2 0 .153-.12.266-.289.266a.38.38 0 0 1-.194-.048l.027-.08c.032.019.095.043.166.043.099 0 .184-.065.184-.17 0-.1-.068-.171-.223-.171a.719.719 0 0 0-.108.008l.05-.374h.371v.088ZM105.609 32.022c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.114-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.631.627-.354 0-.595-.218-.6-.61 0-.4.262-.62.63-.62.382 0 .6.223.6.603"
          fill="#1D4363"
        />
        <Path
          d="M106.958 32.222c0-.17-.148-.273-.369-.273-.147 0-.284.06-.35.147a.115.115 0 0 0-.025.07c.003.196.139.343.394.343.209 0 .35-.116.35-.287Zm.07-.688a1.53 1.53 0 0 0-.184.01c-.381.04-.582.225-.622.422h.007a.652.652 0 0 1 .433-.139c.319 0 .542.154.542.386 0 .218-.224.42-.598.42-.382 0-.633-.199-.633-.507 0-.235.126-.42.304-.538.148-.097.347-.157.573-.176.072-.008.132-.01.176-.01l.001.132ZM108.365 32.022c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.114-.485-.369-.485-.213 0-.37.172-.37.495m.986-.017c0 .403-.23.627-.631.627-.354 0-.595-.218-.6-.61 0-.4.262-.62.63-.62.382 0 .6.223.6.603"
          fill="#1D4363"
        />
        <Path
          fill="#1D4363"
          d="M109.247 31.415v.07l-.339.709h-.108l.336-.69v-.002h-.38v-.087zM111.169 32.022c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.114-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.631.627-.354 0-.595-.218-.6-.61 0-.4.262-.62.63-.62.383 0 .6.223.6.603"
        />
        <Path
          d="M111.84 31.707c-.001.119.138.183.347.22.157-.034.278-.108.278-.217 0-.094-.087-.193-.31-.193-.204 0-.315.088-.315.19m.678.595c0-.144-.154-.214-.4-.259-.213.04-.33.132-.33.245-.007.12.132.226.365.226.223 0 .365-.09.365-.212m-.981.008c0-.15.138-.256.36-.32v-.005c-.204-.062-.292-.163-.292-.267 0-.188.245-.316.567-.316.352 0 .53.143.53.292 0 .101-.077.21-.3.278v.006c.226.058.366.164.366.307 0 .208-.27.347-.62.347-.381 0-.611-.148-.611-.322M113.939 32.022c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.114-.485-.369-.485-.213 0-.37.172-.37.495m.986-.017c0 .403-.23.627-.631.627-.354 0-.595-.218-.6-.61 0-.4.262-.62.63-.62.382 0 .6.223.6.603"
          fill="#1D4363"
        />
        <Path
          d="M114.557 31.815c0 .148.137.252.353.252.165 0 .295-.053.36-.124a.088.088 0 0 0 .022-.06c0-.204-.116-.358-.375-.358-.212 0-.36.121-.36.29Zm-.09.684c.054.003.115 0 .2-.006a.884.884 0 0 0 .384-.114.505.505 0 0 0 .24-.318h-.007a.685.685 0 0 1-.433.128c-.329 0-.54-.161-.54-.365 0-.224.251-.422.626-.422.374 0 .605.197.605.503a.64.64 0 0 1-.316.558 1.19 1.19 0 0 1-.532.159c-.088.008-.17.011-.226.01v-.133ZM116.654 31.513h-.003l-.135.073-.02-.08.17-.091h.09v.779h-.102zM117.275 32.022c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.294-.114-.485-.369-.485-.213 0-.37.172-.37.495m.985-.017c0 .403-.23.627-.63.627-.354 0-.595-.218-.601-.61 0-.4.263-.62.63-.62.383 0 .601.223.601.603M89.333 28.716h.603v-.268h-.603zM91.913 30.948h.267v-.604h-.267zM94.686 30.948h.267v-.604h-.267zM97.458 30.948h.267v-.604h-.267zM100.231 30.948h.267v-.604h-.267zM103.004 30.948h.267v-.604h-.267zM105.776 30.948h.267v-.604h-.267zM108.55 30.948h.266v-.604h-.267zM111.322 30.948h.267v-.604h-.267zM114.095 30.948h.267v-.604h-.267zM116.867 30.948h.267v-.604h-.267zM89.333 23.73h.603v-.268h-.603zM89.333 18.743h.603v-.268h-.603zM89.333 13.757h.603v-.268h-.603zM89.333 8.77h.603v-.267h-.603z"
          fill="#1D4363"
        />
        <Path fill="#225170" d="M91.122 30.344h1.849V20.177h-1.849z" />
        <Path fill="#FBF1CA" d="M93.895 30.344h1.848V15.43h-1.848z" />
        <Path fill="#FBAF17" d="M96.668 30.344h1.848V12.3h-1.848z" />
        <Path fill="#F5882D" d="M99.44 30.344h1.849v-8.936H99.44z" />
        <Path fill="#BA3026" d="M102.213 30.344h1.848v-12.79h-1.848z" />
        <Path fill="#225170" d="M104.986 30.344h1.848V16.186h-1.848z" />
        <Path fill="#FBF1CA" d="M107.759 30.344h1.848V13.867h-1.848z" />
        <Path fill="#FBAF17" d="M110.531 30.344h1.849V16.67h-1.849z" />
        <Path fill="#F5882D" d="M113.304 30.344h1.848V11.622h-1.848z" />
        <Path fill="#BA3026" d="M116.077 30.344h1.848V8.917h-1.848z" />
        <G fill="#010202">
          <Path d="M90.358 28.716h27.567v-.268H90.358zM90.358 23.73h27.567v-.268H90.358zM90.358 18.743h27.567v-.268H90.358zM90.358 13.757h27.567v-.268H90.358zM90.358 8.77h27.567v-.267H90.358z" />
        </G>
      </G>
      <Path fill="#BA3026" fillRule="nonzero" d="M154.098 30.282h-11.7l5.85-20.046z" />
      <Path fill="#FBAF17" fillRule="nonzero" d="M148.247 30.282h-11.7l5.85-15.816z" />
      <Path
        fill="#1D4363"
        fillRule="nonzero"
        d="M142.397 30.282h-11.7l5.85-11.527zM129.868 18.755a2.289 2.289 0 0 1-2.286 2.291 2.289 2.289 0 0 1-2.285-2.291 2.289 2.289 0 0 1 2.285-2.291 2.289 2.289 0 0 1 2.286 2.29"
      />
      <Path
        d="M135.493 14.466a2.289 2.289 0 0 1-2.286 2.291 2.289 2.289 0 0 1-2.285-2.291 2.289 2.289 0 0 1 2.285-2.291 2.289 2.289 0 0 1 2.286 2.291"
        fill="#FBAF17"
        fillRule="nonzero"
      />
      <Path
        d="M141.457 10.236a2.289 2.289 0 0 1-2.286 2.291 2.288 2.288 0 0 1-2.286-2.291 2.289 2.289 0 0 1 2.286-2.292 2.289 2.289 0 0 1 2.286 2.292"
        fill="#BA3026"
        fillRule="nonzero"
      />
      <Path stroke="#1C4463" strokeWidth={2} d="M127.582 18.755h7.911" />
      <Path stroke="#FAAE1A" strokeWidth={2} d="M133.207 14.466h8.259" />
      <Path stroke="#BA3226" strokeWidth={2} d="M139.171 10.236h8.259" />
      <Path
        d="M126.699 19.136v.18c0 .138.06.188.157.188s.158-.05.158-.188v-.427c0-.139-.061-.191-.158-.191s-.157.052-.157.191v.058h-.288l.055-1.125h.802v.277h-.528l-.025.463a.29.29 0 0 1 .265-.141c.224 0 .338.158.338.443v.432c0 .31-.155.488-.454.488-.298 0-.453-.177-.453-.488v-.16h.288ZM127.52 19.762v-.294h.292v.263l-.132.308h-.125l.08-.277zM128.926 17.822v.266l-.448 1.674h-.304l.445-1.663h-.605v-.277zM132.391 14.605v.35c0 .138.081.188.21.188.13 0 .21-.05.21-.189v-.349c0-.138-.08-.19-.21-.19-.129 0-.21.052-.21.19Zm-.405-.662c0-.327.199-.504.62-.504.397 0 .604.177.604.487v.05h-.384v-.069c0-.138-.08-.191-.21-.191-.144 0-.225.053-.225.238v.355c.07-.111.196-.172.376-.172.3 0 .45.158.45.443v.355c0 .31-.217.488-.616.488-.398 0-.615-.178-.615-.488v-.992Z"
        fill="#FEFEFE"
        fillRule="nonzero"
      />
      <Path
        fill="#FEFEFE"
        fillRule="nonzero"
        d="M133.1 15.4v-.293h.292v.263l-.132.308h-.125l.08-.277zM134.059 13.716c-.097 0-.158.053-.158.191v.208h-.288v-.189c0-.31.155-.487.454-.487.298 0 .453.177.453.487 0 .61-.605.837-.605 1.156 0 .014 0 .028.003.042h.575v.277h-.88v-.239c0-.57.603-.665.603-1.224 0-.175-.06-.222-.157-.222M138.549 11.01c.125 0 .218-.046.222-.218v-.18c0-.153-.083-.22-.222-.22-.14 0-.222.067-.222.22v.18c0 .172.096.219.222.219Zm-.222-1.1c0 .156.093.206.222.206.125 0 .222-.05.222-.205v-.108c0-.175-.097-.222-.222-.222-.126 0-.222.047-.222.222v.108Zm.222 1.378c-.398 0-.616-.177-.616-.488v-.152c0-.186.071-.33.243-.405-.164-.07-.243-.2-.243-.39v-.061c0-.31.218-.488.616-.488.397 0 .615.177.615.488v.06c0 .192-.082.322-.243.391.172.075.243.22.243.405v.152c0 .31-.218.488-.615.488Z"
      />
      <Path
        fill="#FEFEFE"
        fillRule="nonzero"
        d="M139.066 11.266v-.294h.293v.263l-.133.308h-.124l.08-.277zM140.375 10.121v-.349c0-.138-.08-.188-.21-.188-.129 0-.21.05-.21.188v.35c0 .138.081.19.21.19.13 0 .21-.052.21-.19Zm.406.663c0 .327-.2.504-.62.504-.398 0-.604-.177-.604-.488v-.05h.383v.07c0 .138.082.19.21.19.144 0 .225-.052.225-.237v-.355c-.07.11-.195.172-.376.172-.298 0-.45-.158-.45-.444v-.354c0-.31.218-.488.616-.488.399 0 .616.177.616.488v.992Z"
      />
      <G transform="translate(0 .14)">
        <Mask id="b" fill="#fff">
          <Use xlinkHref="#a" />
        </Mask>
        <Path fill="#1D4363" fillRule="nonzero" mask="url(#b)" d="M130.696 31.82h23.402v-.546h-23.402z" />
      </G>
      <G fillRule="nonzero">
        <Path
          d="m110.58 40.092-3.582 3.59A4.523 4.523 0 0 0 104 42.255V37.17a9.564 9.564 0 0 1 6.579 2.92"
          fill="#1D4363"
        />
        <Path
          d="M113.29 46.794c0 .22-.008.44-.022.656h-5.09a4.577 4.577 0 0 0-.78-3.274l3.607-3.616a9.6 9.6 0 0 1 2.285 6.234"
          fill="#F5882D"
        />
        <Path
          d="M113.204 48.083c-.614 4.606-4.477 8.181-9.203 8.334a8.962 8.962 0 0 1-.631 0 9.55 9.55 0 0 1-6.371-2.711 9.612 9.612 0 0 1-2.833-5.623l-.063-.633a9.586 9.586 0 0 1-.022-.656c0-5.211 4.13-9.455 9.289-9.623v5.084a4.546 4.546 0 0 0-4.178 5.195 4.533 4.533 0 0 0 1.389 2.665 4.505 4.505 0 0 0 3.42 1.22 4.544 4.544 0 0 0 4.039-3.252h5.164Z"
          fill="#BA3026"
        />
        <Path d="M94.166 48.083a9.532 9.532 0 0 1-.063-.633l.063.633Z" fill="#2880B9" />
        <Path d="M94.166 48.083a9.532 9.532 0 0 1-.063-.633l.063.633Z" fill="#2880B9" />
        <Path
          fill="#FEFEFE"
          d="M98.08 50.523v.311l-.525 1.96H97.2l.521-1.947h-.708v-.324zM98.316 52.793v-.344h.343v.309l-.156.36h-.145l.094-.325zM99.238 52.06v.211c0 .162.071.22.184.22.114 0 .185-.058.185-.22v-.5c0-.162-.071-.223-.185-.223-.113 0-.184.061-.184.223v.069H98.9l.065-1.317h.938v.324h-.618l-.029.542a.34.34 0 0 1 .31-.166c.263 0 .396.185.396.52v.505c0 .364-.182.571-.531.571-.35 0-.53-.207-.53-.57v-.189h.336ZM105.335 39.796c.186 0 .228-.09.265-.193h.163v1.54h-.242v-1.175h-.186v-.172ZM106.408 39.805c-.077 0-.125.042-.125.152v.165h-.228v-.15c0-.246.123-.387.36-.387s.36.141.36.387c0 .485-.481.665-.481.918 0 .011 0 .022.002.033h.457v.22h-.698v-.19c0-.452.478-.527.478-.972 0-.138-.048-.176-.125-.176M106.924 41.143v-.233h.233v.209l-.105.244h-.1l.064-.22zM107.55 40.646v.143c0 .11.048.15.125.15s.125-.04.125-.15v-.339c0-.11-.048-.152-.125-.152s-.125.042-.125.152v.046h-.228l.044-.893h.636v.22h-.42l-.019.367a.23.23 0 0 1 .21-.112c.179 0 .269.126.269.352v.344c0 .246-.123.387-.36.387s-.36-.141-.36-.387v-.128h.228ZM109.145 44.38c.186 0 .228-.09.265-.193h.163v1.54h-.242v-1.175h-.186v-.172ZM110.219 44.39c-.077 0-.126.04-.126.15v.166h-.228v-.15c0-.246.123-.387.36-.387s.36.14.36.387c0 .484-.48.665-.48.918 0 .01 0 .022.002.033h.456v.22h-.698v-.19c0-.453.479-.527.479-.972 0-.138-.049-.176-.125-.176M110.735 45.727v-.233h.232v.209l-.105.244h-.099l.064-.22zM111.36 45.23v.143c0 .11.049.15.125.15.077 0 .126-.04.126-.15v-.34c0-.11-.049-.15-.126-.15-.076 0-.125.04-.125.15v.047h-.228l.044-.893h.636v.22h-.419l-.02.367a.23.23 0 0 1 .211-.112c.178 0 .268.125.268.352v.343c0 .247-.123.388-.36.388s-.36-.141-.36-.388v-.127h.228Z"
        />
      </G>
      <G fillRule="nonzero">
        <Path fill="#1D4363" d="M132.5 55.153h.197V37.195h-.197z" />
        <Path
          fill="#1D4363"
          d="M132.5 55.153h22.126v-.198H132.5zM137.412 53.836h16.24s.76-16.64-2.988-16.64c-4.955 0-1.725 13.403-13.252 16.64"
        />
        <Path d="M137.412 53.836s2.445-13.794 5.218-13.794c3.425 0 3.086 11.532 11.022 13.794h-16.24Z" fill="#FBAF17" />
        <Path d="M133.964 53.836s-.445-9.856 2.98-9.856.792 9.6 11.364 9.856h-14.344Z" fill="#BA3026" />
        <Path
          fill="#1D4363"
          d="M130.055 53.305h-.002l-.105.057-.016-.062.133-.071h.07v.607h-.08zM130.594 53.838c0 .31.145.486.368.486.25 0 .37-.192.37-.495 0-.294-.114-.485-.368-.485-.214 0-.37.172-.37.494m.985-.016c0 .403-.23.627-.631.627-.355 0-.595-.217-.6-.61 0-.4.262-.62.63-.62.383 0 .6.224.6.603"
        />
        <Path
          d="M131.072 53.838c0 .31.145.486.368.486.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.214 0-.37.172-.37.494m.985-.016c0 .403-.23.627-.631.627-.354 0-.595-.217-.6-.61 0-.4.262-.62.63-.62.383 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M131.55 53.838c0 .31.145.486.368.486.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.214 0-.37.172-.37.494m.985-.016c0 .403-.23.627-.63.627-.355 0-.596-.217-.602-.61 0-.4.264-.62.632-.62.382 0 .6.224.6.603M129.877 49.922v-.05l.064-.062c.155-.148.225-.227.225-.318 0-.062-.029-.12-.119-.12a.21.21 0 0 0-.129.052l-.026-.057a.266.266 0 0 1 .172-.062c.13 0 .185.09.185.176 0 .113-.081.203-.21.326l-.047.045v.002h.271v.068h-.386ZM130.594 49.925c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.293-.114-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .404-.23.627-.631.627-.355 0-.595-.217-.6-.61 0-.4.262-.62.63-.62.383 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M131.072 49.925c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .404-.23.627-.631.627-.354 0-.595-.217-.6-.61 0-.4.262-.62.63-.62.383 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M131.55 49.925c0 .31.145.485.368.485.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.214 0-.37.172-.37.495m.985-.017c0 .404-.23.627-.63.627-.355 0-.596-.217-.602-.61 0-.4.264-.62.632-.62.382 0 .6.224.6.603M129.897 45.997a.276.276 0 0 0 .134.038c.105 0 .138-.067.137-.118 0-.085-.078-.121-.157-.121h-.045v-.062h.046c.06 0 .135-.03.135-.102 0-.05-.031-.092-.107-.092a.222.222 0 0 0-.12.04l-.023-.06a.287.287 0 0 1 .159-.046c.12 0 .174.07.174.145 0 .063-.038.116-.112.143v.002c.074.014.135.07.135.155 0 .097-.076.182-.221.182a.294.294 0 0 1-.157-.041l.022-.063ZM130.594 46.093c0 .31.145.486.368.486.25 0 .37-.192.37-.495 0-.294-.114-.485-.368-.485-.214 0-.37.172-.37.494m.985-.016c0 .403-.23.627-.631.627-.355 0-.595-.218-.6-.61 0-.4.262-.62.63-.62.383 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M131.072 46.093c0 .31.145.486.368.486.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.214 0-.37.172-.37.494m.985-.016c0 .403-.23.627-.631.627-.354 0-.595-.218-.6-.61 0-.4.262-.62.63-.62.383 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M131.55 46.093c0 .31.145.486.368.486.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.214 0-.37.172-.37.494m.985-.016c0 .403-.23.627-.63.627-.355 0-.596-.218-.602-.61 0-.4.264-.62.632-.62.382 0 .6.224.6.603M130.63 42.36v-.412c0-.065.003-.129.009-.193l-.01-.001a2.924 2.924 0 0 1-.14.183l-.41.418v.004h.551Zm0 .464v-.336h-.78v-.108l.749-.786h.246v.765h.236v.129h-.236v.336h-.215Z"
          fill="#1D4363"
        />
        <Path
          d="M130.594 42.203c0 .31.145.486.368.486.25 0 .37-.192.37-.495 0-.294-.114-.485-.368-.485-.214 0-.37.172-.37.494m.985-.016c0 .403-.23.627-.631.627-.355 0-.595-.218-.6-.61 0-.4.262-.62.63-.62.383 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M131.072 42.203c0 .31.145.486.368.486.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.214 0-.37.172-.37.494m.985-.016c0 .403-.23.627-.631.627-.354 0-.595-.218-.6-.61 0-.4.262-.62.63-.62.383 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M131.55 42.203c0 .31.145.486.368.486.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.214 0-.37.172-.37.494m.985-.016c0 .403-.23.627-.63.627-.355 0-.596-.218-.602-.61 0-.4.264-.62.632-.62.382 0 .6.224.6.603M130.238 37.702h-.23l-.023.156a.32.32 0 0 1 .049-.003c.047 0 .093.01.13.033.047.026.087.079.087.155 0 .119-.094.207-.225.207a.297.297 0 0 1-.152-.037l.021-.063a.268.268 0 0 0 .13.034c.076 0 .143-.05.143-.132 0-.078-.053-.134-.174-.134a.551.551 0 0 0-.084.007l.04-.292h.288v.069ZM130.594 38.242c0 .31.145.486.368.486.25 0 .37-.192.37-.495 0-.294-.114-.485-.368-.485-.214 0-.37.172-.37.494m.985-.016c0 .403-.23.627-.631.627-.355 0-.595-.217-.6-.61 0-.4.262-.62.63-.62.383 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M131.072 38.242c0 .31.145.486.368.486.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.214 0-.37.172-.37.494m.985-.016c0 .403-.23.627-.631.627-.354 0-.595-.217-.6-.61 0-.4.262-.62.63-.62.383 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M131.55 38.242c0 .31.145.486.368.486.25 0 .37-.192.37-.495 0-.294-.113-.485-.368-.485-.214 0-.37.172-.37.494m.985-.016c0 .403-.23.627-.63.627-.355 0-.596-.217-.602-.61 0-.4.264-.62.632-.62.382 0 .6.224.6.603M136.352 56.4c0 .311.145.486.368.486.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.214 0-.37.173-.37.495m.985-.017c0 .404-.23.627-.63.627-.355 0-.596-.217-.602-.61 0-.4.263-.62.631-.62.383 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M136.592 56.398v-.05l.064-.062c.154-.148.225-.227.225-.318 0-.062-.029-.12-.12-.12-.054 0-.1.03-.128.052l-.026-.057a.265.265 0 0 1 .171-.062c.13 0 .185.09.185.176 0 .113-.08.204-.208.326l-.048.046v.001h.271v.068h-.386ZM134.225 56.4c0 .311.145.486.368.486.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.213 0-.37.173-.37.495m.985-.017c0 .404-.23.627-.63.627-.355 0-.596-.217-.602-.61 0-.4.264-.62.632-.62.382 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          fill="#1D4363"
          d="M134.643 55.868h-.002l-.105.056-.016-.062.132-.07h.07v.606h-.08zM138.452 56.4c0 .311.145.486.368.486.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.214 0-.37.173-.37.495m.985-.017c0 .404-.23.627-.63.627-.355 0-.596-.217-.602-.61 0-.4.264-.62.632-.62.382 0 .6.224.6.603"
        />
        <Path
          d="M138.712 56.304a.277.277 0 0 0 .134.038c.105 0 .137-.067.137-.117-.001-.086-.078-.122-.157-.122h-.045v-.062h.045c.06 0 .135-.03.135-.102 0-.049-.03-.091-.106-.091a.222.222 0 0 0-.12.04l-.023-.06a.287.287 0 0 1 .159-.047c.12 0 .174.07.174.145 0 .063-.038.116-.112.143v.002c.074.014.135.07.135.156 0 .096-.076.182-.221.182a.296.296 0 0 1-.157-.042l.022-.063ZM140.608 56.4c0 .311.145.486.368.486.25 0 .37-.192.37-.495 0-.293-.114-.485-.368-.485-.214 0-.37.173-.37.495m.985-.017c0 .404-.23.627-.631.627-.354 0-.595-.217-.6-.61 0-.4.262-.62.63-.62.383 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M141.6 56.557v-.412c0-.064.003-.128.01-.193h-.01a3.333 3.333 0 0 1-.14.182l-.409.419-.001.004h.55Zm0 .465v-.337h-.78v-.108l.75-.785h.246v.765h.236v.128h-.236v.337h-.215ZM142.809 56.4c0 .311.145.486.368.486.25 0 .37-.192.37-.495 0-.293-.114-.485-.368-.485-.214 0-.37.173-.37.495m.985-.017c0 .404-.23.627-.631.627-.354 0-.595-.217-.601-.61 0-.4.263-.62.631-.62.382 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M143.41 55.86h-.23l-.024.156a.317.317 0 0 1 .05-.003c.046 0 .092.01.13.033.047.026.086.08.086.156 0 .118-.094.207-.225.207a.293.293 0 0 1-.151-.038l.02-.062a.268.268 0 0 0 .13.034c.077 0 .144-.051.143-.133 0-.078-.053-.134-.174-.134a.551.551 0 0 0-.084.007l.04-.292h.289v.07ZM144.978 56.4c0 .311.144.486.368.486.25 0 .37-.192.37-.495 0-.293-.114-.485-.369-.485-.214 0-.37.173-.37.495m.985-.017c0 .404-.23.627-.63.627-.354 0-.595-.217-.601-.61 0-.4.263-.62.631-.62.382 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M146.192 56.602c0-.171-.147-.273-.368-.273-.147 0-.284.06-.35.146a.113.113 0 0 0-.026.07c.003.197.14.343.395.343.208 0 .35-.116.35-.286Zm.07-.689c-.051 0-.113.002-.184.01-.38.04-.582.225-.622.423h.007a.648.648 0 0 1 .433-.14c.32 0 .543.154.543.386 0 .218-.225.42-.598.42-.383 0-.634-.199-.634-.507 0-.235.126-.42.304-.538.148-.097.347-.157.574-.176.071-.007.131-.01.175-.01l.002.132ZM147.128 56.4c0 .311.144.486.367.486.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.214 0-.37.173-.37.495m.985-.017c0 .404-.23.627-.63.627-.355 0-.595-.217-.601-.61 0-.4.263-.62.631-.62.382 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          fill="#1D4363"
          d="M147.76 55.791v.055l-.263.552h-.084l.262-.537v-.002h-.296v-.068zM149.315 56.4c0 .311.145.486.368.486.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.214 0-.37.173-.37.495m.985-.017c0 .404-.23.627-.63.627-.355 0-.596-.217-.602-.61 0-.4.263-.62.631-.62.383 0 .6.224.6.603"
        />
        <Path
          d="M149.851 56.086c-.001.12.138.184.347.22.157-.034.278-.107.278-.216 0-.094-.087-.193-.31-.193-.204 0-.315.087-.315.189m.678.595c0-.144-.154-.214-.401-.258-.212.039-.33.131-.33.244-.006.12.133.226.366.226.222 0 .365-.09.365-.212m-.981.009c0-.15.137-.257.36-.32l-.001-.006c-.204-.061-.291-.163-.291-.267 0-.187.245-.316.566-.316.353 0 .53.144.53.292 0 .102-.077.21-.3.278v.006c.227.058.367.164.367.308 0 .207-.271.346-.62.346-.38 0-.611-.148-.611-.321M151.476 56.4c0 .311.145.486.368.486.25 0 .37-.192.37-.495 0-.293-.114-.485-.368-.485-.214 0-.37.173-.37.495m.984-.017c0 .404-.23.627-.63.627-.354 0-.595-.217-.601-.61 0-.4.263-.62.631-.62.382 0 .6.224.6.603"
          fill="#1D4363"
        />
        <Path
          d="M151.957 56.194c0 .148.138.253.354.253.165 0 .295-.054.36-.126a.087.087 0 0 0 .022-.058c.001-.205-.116-.359-.375-.359-.212 0-.36.121-.36.29Zm-.089.684c.053.004.115 0 .199-.006a.88.88 0 0 0 .384-.114.503.503 0 0 0 .241-.317h-.007a.69.69 0 0 1-.433.128c-.329 0-.54-.162-.54-.366 0-.224.251-.422.626-.422.373 0 .605.197.605.503a.64.64 0 0 1-.316.558c-.14.09-.335.145-.532.159-.089.009-.17.011-.227.01v-.133ZM153.54 55.868h-.003l-.105.056-.016-.062.133-.07h.07v.606h-.08zM154.078 56.4c0 .311.145.486.368.486.25 0 .37-.192.37-.495 0-.293-.113-.485-.368-.485-.214 0-.37.173-.37.495m.985-.017c0 .404-.23.627-.631.627-.354 0-.595-.217-.6-.61 0-.4.262-.62.63-.62.383 0 .6.224.6.603M132.227 53.685h.47v-.209h-.47zM134.24 55.427h.208v-.472h-.209zM136.402 55.427h.208v-.472h-.208zM138.565 55.427h.208v-.472h-.208zM140.728 55.427h.208v-.472h-.208zM142.891 55.427h.209v-.472h-.209zM145.054 55.427h.208v-.472h-.208zM147.217 55.427h.208v-.472h-.208zM149.38 55.427h.209v-.472h-.209zM151.543 55.427h.209v-.472h-.209zM153.706 55.427h.208v-.472h-.208zM132.227 49.795h.47v-.208h-.47zM132.227 45.905h.47v-.208h-.47zM132.227 42.016h.47v-.21h-.47zM132.227 38.126h.47v-.209h-.47z"
          fill="#1D4363"
        />
      </G>
      <Path
        d="M63.006 117.313s4.037 1.152 6.409-.74c0 0 3.659 3.845 7.58 4.136 0 0 .677.728 0 .874-.678.145-8.374.436-9.826-.631 0 0-2.313.954-4.163-.194-1.014-.63 0-3.445 0-3.445M51.394 117.097s-3.933 1.123-6.243-.72c0 0-3.565 3.745-7.384 4.029 0 0-.66.709 0 .85.66.143 8.157.426 9.572-.614 0 0 2.253.93 4.055-.189.988-.613 0-3.356 0-3.356"
        fill="#1E1D1D"
        fillRule="nonzero"
      />
      <Path
        d="m46.919 63.428-2.183 53.229s4.374 2.051 6.678.471l1.433-22.337 4.552-20.3 7.677 19.935-2.183 22.948s5.43 1.148 7.032-.8l2.596-23.65a5.444 5.444 0 0 0-.115-1.838l-6.09-25.755s-13.908 2.352-19.397-1.903"
        fill="#1D4363"
        fillRule="nonzero"
      />
      <Path
        d="M58.376 25.99c.92-.022 1.694-.361 2.34-.883l-.249 6.392h-6.062l-.799-7.507c.978 1.025 2.524 2.05 4.77 1.998"
        fill="#F1DFCD"
        fillRule="nonzero"
      />
      <Path
        d="m60.913 20.072-.173 4.422c-.602.712-1.42 1.146-2.518 1.146-2.527 0-3.987-1.681-4.83-3.665l-.233-2.184 7.754.281Z"
        fill="#F3E5CD"
        fillRule="nonzero"
      />
      <Path
        d="M60.74 24.494c-.602.712-1.42 1.146-2.518 1.146-2.527 0-3.987-1.681-4.83-3.665-1.216-2.86-1.15-6.35-1.15-6.35l1.046-.995 1.945-1.85 6.47-.797s.562 2.849.582 5.943c.018 2.475-.31 5.108-1.545 6.568"
        fill="#F1DFCD"
        fillRule="nonzero"
      />
      <Path
        d="m60.74 24.494-.023.613c-.647.522-1.42.86-2.341.882-2.246.054-3.792-.972-4.77-1.997l-.215-2.017c.844 1.984 2.304 3.665 4.83 3.665 1.1 0 1.917-.434 2.52-1.146"
        fill="#E1CCBA"
        fillRule="nonzero"
      />
      <Path
        d="M60.74 24.494c-.602.712-1.42 1.146-2.518 1.146-2.527 0-3.987-1.681-4.83-3.665-1.216-2.86-1.15-6.35-1.15-6.35l1.046-.995 8.997 3.296c.018 2.475-.31 5.108-1.545 6.568"
        fill="#F1DFCD"
        fillRule="nonzero"
      />
      <Path
        d="M53.415 14.668s7.625 2.121 10.682-3.171c0 0-4.982-2.094-7.736-2.094-2.754 0-4.335 2.977-4.335 2.977s-3.785 2.298 1.436 10.269l-.047-7.98Z"
        fill="#3A3A3A"
        fillRule="nonzero"
      />
      <Path
        d="M53.71 17.39c.546.777.602 1.68.125 2.016-.476.337-1.305-.02-1.85-.796-.547-.777-.603-1.679-.126-2.015.476-.337 1.305.02 1.851.796"
        fill="#F1DFCD"
        fillRule="nonzero"
      />
      <Path
        d="m53.907 26.82-1.451 1.683-5.537 1.536V65.33s10.656 3.35 19.396 0V30.213l-4.596-1.361-1.002-1.606-3.453 3.316-3.357-3.741ZM78.927 48.303l-3.785 2.975s-.981.702-1.683 0c-.7-.703-7.144-9.941-7.144-9.941V30.213s1.001.621 3.652 4.992c2.65 4.369 5.43 8.764 5.43 8.764l1.343-.768c.27 1.44.87 3.348 2.187 5.102M46.919 30.04v10.33s-7.36 5.554-7.49 9.533c-.023.677.194 1.422.567 2.195-1.99.946-3.267 1.943-4.087 2.822l-.058-.076c-3.533-4.635-1.876-8.526-1.876-8.526 3.272-7.872 12.944-16.279 12.944-16.279"
        fill="#F5A623"
        fillRule="nonzero"
      />
      <Path
        d="M92.553 34.846s.548 3.335-1.198 5.085c0 0-1.325.614-2.626.665l-9.802 7.707c-1.318-1.754-1.917-3.662-2.187-5.102l9.058-5.186s1.325-5.545 5.123-4.166c0 0 5.06-3.437 5.533-2.964.47.473-3.9 3.961-3.9 3.961M49.505 66.82c-2.226.444-4.1-2.88-4.1-2.88s-5.955-4.412-9.496-9.02c.82-.879 2.097-1.876 4.087-2.822 1.826 3.77 7.416 8.169 7.416 8.169l2.835 1.356c2.836 1.355 1.485 4.752-.742 5.197"
        fill="#F1DFCD"
        fillRule="nonzero"
      />
      <Path
        fill="#E1E8F0"
        fillRule="nonzero"
        d="m56.998 30.266-2.4 2.324-2.142-4.087 1.45-1.682zM57.528 30.309l2.09 2.532 2.1-3.99-1.001-1.605z"
      />
      <Path
        d="M82.02 121.386c0 .715-10.594 1.295-23.662 1.295s-23.661-.58-23.661-1.295 10.593-1.295 23.661-1.295c13.068 0 23.662.58 23.662 1.295"
        fill="#010202"
        fillRule="nonzero"
      />
      <Path
        d="m176.67 111.66.281 4.369s2.057 1.206 3.668.292l.25-4.327-4.2-.334ZM191.865 111.192l1.412 5.429s2.405.804 3.87-.56l-.817-6.071s-2.949 1.33-4.465 1.202"
        fill="#FBF1CB"
        fillRule="nonzero"
      />
      <Path
        d="M176.003 54.739s12.014 3.273 18.67 0c0 0 1.724 22.448-3.075 33.738 0 0 3.933.468 4.732 21.513 0 0-2.732 1.737-4.465 1.202L185.4 86.206 184 66.898l-3.968 23.717s2.57 2.94 1.036 11.892c0 0-.6 8.351-.2 9.487 0 0-2.332 1.27-4.198-.334l-2.133-21.446s-2.933-19.107 1.466-35.475M192.524 113.726s2.897.976 4.239-.52c0 0 2.195 2.862 1.012 4.068-1.184 1.206-4.774 3.774-12.712 3.346 0 0-.757-.428 0-.973.757-.544 5.74-3.175 7.461-5.921M176.856 114.549s.856 1.452 3.804 1.067c0 0 .91 3.564-.235 4.498-1.145.934-12.459.409-12.459.409s-1.28-.39-.466-.915c.815-.525 9.356-5.059 9.356-5.059"
        fill="#09213F"
        fillRule="nonzero"
      />
      <Path
        d="M178.595 17.48s1.376.994 1.559 3.425c0 0-.005 1.785-.059 2.174 0 0 2.934 1.692 5.693 0 0 0-.398-5.571-1.412-7.599l-5.78 2Z"
        fill="#F1DFCD"
        fillRule="nonzero"
      />
      <Path
        d="M175.079 8.28s-.173 10.22 3.984 10.41c4.158.19 6.166-4.777 6.166-4.777l-2.63-7.43-7.52 1.797Z"
        fill="#F1DFCD"
        fillRule="nonzero"
      />
      <Path
        d="M171.892 6.663s1.04 3.508 5.107 2.133 5.344-.711 6.053 2.133l1.325 4.551s4.236-5.69-1.277-10.808c0 0-1.892-1.564-5.77.474-3.877 2.038-5.438 1.517-5.438 1.517"
        fill="#020303"
        fillRule="nonzero"
      />
      <Path
        d="M182.512 11.42s.312-2.93 1.431-2.4c1.119.531.59 2.641-.716 3.739l-.715-1.339Z"
        fill="#F1DFCD"
        fillRule="nonzero"
      />
      <Path
        d="M196.505 33.482c-1.15 5.983-3.475 10.955-2.522 15.196l1.232 5.928s-13.759 3.624-19.671-.506c0 0 1.738-8.427 1.065-11.348-.672-2.922-1.429-5.956-1.429-5.956s.757 7.332-6.726 17.22c-.08.107-.16.211-.24.314-7.31 9.446-10.436 3.59-10.436 3.59l3.139-2.134s-1.682-1.04-1.094-1.461c.59-.422 4.232-1.124 4.232-1.124s.56-.685 1.356-1.75c1.936-2.593 5.266-7.435 5.285-10.103.028-3.765-.168-15.394 3.195-17.923 0 0 3.119-.954 6.113-3.497 0 0 .947 5.537 5.404-.272 0 0 6.557 3.096 7.874 3.713 1.317.618 4.372 4.13 3.223 10.113"
        fill="#E8EBF0"
        fillRule="nonzero"
      />
      <Path
        d="M185.931 19.903s-3.694 8.248-2.095 17.562c1.599 9.315 3.976 15.726 2.42 23.308 0 0 8.6.78 11.15-1.43 0 0-3.976-11.697-3.414-13.3 0 0 3.37-12.152 2.874-16.809-.497-4.657-2.097-5.925-10.935-9.33"
        fill="#D2DAE8"
        fillRule="nonzero"
      />
      <Path
        d="M168.214 54.33c-7.31 9.446-10.437 3.59-10.437 3.59l3.14-2.134s-1.681-1.04-1.094-1.461c.59-.422 4.232-1.124 4.232-1.124s.56-.685 1.356-1.75c1.143.678 2.262 1.621 2.803 2.879"
        fill="#F1DFCD"
        fillRule="nonzero"
      />
      <Path
        d="m178.82 60.194-4.991-.876s4.506-10.809 1.535-21.777c0 0 .087 8.829-6.996 17.117 0 0-1.373-2.533-3.37-3.002 0 0 5.273-7.57 5.398-9.634.125-2.063-.499-14.917 3.463-18.827 0 0 .484-.177 1.287-.545a35.173 35.173 0 0 0 4.858-2.722s-3.492 4.58-1.028 16.278c2.465 11.697-.156 23.988-.156 23.988"
        fill="#D2DAE8"
        fillRule="nonzero"
      />
      <Path
        d="M192.717 43.833c-1.684 2.552-4.36 4.547-11.52 11.062l-.755.687c-7.731 7.067-11.001 3.494-11.298 2.386-.298-1.107 5.648-2.386 5.648-2.386s-1.528-1.956-1.213-2.467c.316-.512 3.13 1.227 3.13 1.227l1.847-2.078 8.596-9.666s1.614-4.428 3.268-10.899c1.654-6.472 4.547-7.195 4.547-7.195 5.18 5.534-.51 16.69-2.25 19.33"
        fill="#C4CDDC"
        fillRule="nonzero"
      />
      <Path
        d="m181.196 54.895-.754.687c-7.731 7.067-11.001 3.494-11.298 2.386-.298-1.107 5.648-2.386 5.648-2.386s-1.528-1.956-1.213-2.467c.316-.512 3.13 1.227 3.13 1.227l1.847-2.078c.768.626 1.81 1.562 2.64 2.63"
        fill="#F1DFCD"
        fillRule="nonzero"
      />
      <Path
        d="M168.368 54.658s-1.373-2.533-3.37-3.002c0 0 5.273-7.57 5.398-9.634.125-2.063-.499-14.917 3.463-18.827 0 0 .484-.177 1.288-.545-1.496 9.73.218 14.89.218 14.89s.086 8.83-6.997 17.118"
        fill="#C4CDDC"
        fillRule="nonzero"
      />
      <Path
        d="M205.853 120.538c0 1.36-10.594 2.462-23.662 2.462-13.067 0-23.661-1.102-23.661-2.462s10.594-2.463 23.661-2.463c13.068 0 23.662 1.102 23.662 2.463"
        fill="#010202"
        fillRule="nonzero"
      />
    </G>
  </Svg>
)

export default Business
