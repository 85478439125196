import React from 'react'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { View } from 'react-native'

const InvalidInterval = () => {
  const { t } = useTranslation()
  const text = t('shop.widget.googleSearchConsole.error.noValidInterval')
  return (
    <View style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <ColoredText>{text}</ColoredText>
    </View>
  )
}

export default InvalidInterval
