import { Toast } from 'native-base'
import React, { useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import { SessionContext } from '../../../../../../context/SessionContext'
import { useLanguage, useSetLanguage } from '../../../../../../common/helpers/language'
import { Languages } from '../../../../../../types/language'
import { useSetTheme } from '../../../../../base/store/actions'
import * as useDefaultStyles from '../../../../utils/styles'
import { isLoaded, useFirestore } from 'react-redux-firebase'
import useStoreTheme from '../../../../../../hooks/preferences/useStoreTheme'
import useStoreLanguage from '../../../../../../hooks/preferences/useStoreLanguage'
import LoadingScreen from '../../../../../../components/LoadingScreen'
import { Themes } from '../../../../../../types/dashbar/theme'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { useTheme } from '../../../../../../utils/types/theme'
import MenuButtonSwitch from '../../../../components/MenuButtonSwitch'
import useStoreNotificationSettings from '../../../../../../hooks/preferences/useStoreNotificationSettings'
import Dropdown from '../../../../components/Dropdown'
import MenuTitle from '../../../../components/MenuTitle'

const DefaultSettings = () => {
  const defaultStyles = useDefaultStyles.default()
  const setLanguage = useSetLanguage()
  const { t } = useTranslation()
  const setTheme = useSetTheme()
  const theme = useTheme()
  const firestore = useFirestore()
  const storeNotificationSettings = useStoreNotificationSettings()

  //Notifications
  const [ntfShopOrder, setNtfShopOrder] = useState<boolean>(false)
  const [ntfShopContact, setNtfShopContact] = useState<boolean>(false)
  const [ntfShopComment, setNtfShopComment] = useState<boolean>(false)
  const [ntfShopEvaluation, setNtfShopEvaluation] = useState<boolean>(false)
  const [ntfWatcher, setNtfWatcher] = useState<boolean>(false)
  const [ntfDashbarReport, setNtfDashbarReport] = useState<boolean>(false)
  const [ntfDashbarStream, setNtfDashbarStream] = useState<boolean>(false)
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false)
  const { user } = useContext(SessionContext)

  const [darkmode, setDarkmode] = useState<boolean>(theme === Themes.Dark)

  const storeTheme = useStoreTheme()
  const storeLanguage = useStoreLanguage()
  const currentLanguage = useLanguage()

  const languages = [
    {
      title: t('layout.language.deutsch'),
      language: Languages.DE,
    },
    {
      title: t('layout.language.english'),
      language: Languages.EN,
    },
  ]

  useEffect(() => {
    if (darkmode === true && theme !== Themes.Dark) {
      setTheme(Themes.Dark)
      storeTheme(Themes.Dark)
    }
    if (darkmode === false && theme !== Themes.Light) {
      setTheme(Themes.Light)
      storeTheme(Themes.Light)
    }
  }, [darkmode])

  useEffect(() => {
    firestore
      .doc(`user/${user?.id}`)
      .get()
      .then((doc) => {
        const notificationSettings = doc.data()?.preferences?.notifications || null
        if (notificationSettings && notificationSettings !== null) {
          setNtfShopOrder(notificationSettings[`shop:order`] === true)
          setNtfShopContact(notificationSettings[`shop:contact`] === true)
          setNtfShopComment(notificationSettings[`shop:comment`] === true)
          setNtfShopEvaluation(notificationSettings[`shop:evaluation`] === true)
          setNtfWatcher(notificationSettings[`watcher:online`] === true)
          setNtfDashbarReport(notificationSettings[`dashbar:report`] === true)
          setNtfDashbarStream(notificationSettings[`dashbar:stream`] === true)
        }
      })
      .finally(() => {
        setSettingsLoaded(true)
      })
  }, [])
  useEffect(() => {
    if (settingsLoaded) {
      storeNotificationSettings({
        [`shop:contact`]: ntfShopContact === true,
        [`shop:order`]: ntfShopOrder === true,
        [`shop:comment`]: ntfShopComment === true,
        [`shop:evaluation`]: ntfShopEvaluation === true,
        [`watcher:online`]: ntfWatcher === true,
        [`watcher:offline`]: ntfWatcher === true,
        [`watcher:maintenance:on`]: ntfWatcher === true,
        [`watcher:maintenance:off`]: ntfWatcher === true,
        [`dashbar:report`]: ntfDashbarReport === true,
        [`dashbar:stream`]: ntfDashbarStream === true,
      })
    }
  }, [ntfShopOrder, ntfShopContact, ntfShopEvaluation, ntfShopComment, ntfWatcher, ntfDashbarReport, ntfDashbarStream])

  if (!isLoaded(user)) return <LoadingScreen />
  return (
    <View style={defaultStyles.settingsWrapper}>
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle
            title={`${t('settings.web.general.language.title')}`}
            subtitle={`${t('settings.web.general.language.subtitle')}`}
          />
        </View>
        <View style={defaultStyles.settingsOptionWrapper}>
          <Dropdown
            items={languages.map((lang) => {
              return { name: lang.title, value: lang.language }
            })}
            value={currentLanguage}
            setValue={(lang: string) => {
              if (currentLanguage !== lang) {
                setLanguage(lang as Languages)
                storeLanguage(lang as Languages)
                Toast.show({
                  title: <>{t('notifications.newLanguage')}</>,
                })
              }
            }}
          />
        </View>
      </View>
      <View style={defaultStyles.settingsDivider} />
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle
            title={`${t('settings.web.general.theme.title')}`}
            subtitle={`${t('settings.web.general.theme.subtitle')}`}
          />
        </View>
        <View style={defaultStyles.settingsOptionWrapper}>
          <MenuButtonSwitch
            text={`${t('settings.mobile.options.personal.darkmode')}`}
            enabled={darkmode}
            setEnabled={setDarkmode}
            single
          />
        </View>
      </View>
      <View style={defaultStyles.settingsDivider} />
      <View style={defaultStyles.settingsItemWrapper}>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle
            title={`${t('settings.web.general.notifications.title')}`}
            subtitle={`${t('settings.web.general.notifications.subtitle')}`}
          />
        </View>
        <View style={defaultStyles.settingsOptionWrapper} />
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle subtitle={`${t('settings.mobile.options.personal.orders')}`} />
        </View>
        <View style={defaultStyles.settingsOptionWrapper}>
          <MenuButtonSwitch
            text={`${t('settings.mobile.options.personal.orders')}`}
            enabled={ntfShopOrder}
            setEnabled={setNtfShopOrder}
            single
          />
        </View>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle subtitle={`${t('settings.mobile.options.personal.contact_request')}`} />
        </View>
        <View style={defaultStyles.settingsOptionWrapper}>
          <MenuButtonSwitch
            text={`${t('settings.mobile.options.personal.contact_request')}`}
            enabled={ntfShopContact}
            setEnabled={setNtfShopContact}
            single
          />
        </View>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle subtitle={`${t('settings.mobile.options.personal.comment')}`} />
        </View>
        <View style={defaultStyles.settingsOptionWrapper}>
          <MenuButtonSwitch
            text={`${t('settings.mobile.options.personal.comment')}`}
            enabled={ntfShopComment}
            setEnabled={setNtfShopComment}
            single
          />
        </View>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle subtitle={`${t('settings.mobile.options.personal.rating')}`} />
        </View>
        <View style={defaultStyles.settingsOptionWrapper}>
          <MenuButtonSwitch
            text={`${t('settings.mobile.options.personal.rating')}`}
            enabled={ntfShopEvaluation}
            setEnabled={setNtfShopEvaluation}
            single
          />
        </View>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle subtitle={`${t('settings.mobile.options.personal.watcher')}`} />
        </View>
        <View style={defaultStyles.settingsOptionWrapper}>
          <MenuButtonSwitch
            text={`${t('settings.mobile.options.personal.watcher')}`}
            enabled={ntfWatcher}
            setEnabled={setNtfWatcher}
            single
          />
        </View>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle subtitle={`${t('settings.mobile.options.personal.report')}`} />
        </View>
        <View style={defaultStyles.settingsOptionWrapper}>
          <MenuButtonSwitch
            text={`${t('settings.mobile.options.personal.report')}`}
            enabled={ntfDashbarReport}
            setEnabled={setNtfDashbarReport}
            single
          />
        </View>
        <View style={defaultStyles.settingsTitleWrapper}>
          <MenuTitle subtitle={`${t('settings.mobile.options.personal.stream')}`} />
        </View>
        <View style={defaultStyles.settingsOptionWrapper}>
          <MenuButtonSwitch
            text={`${t('settings.mobile.options.personal.stream')}`}
            enabled={ntfDashbarStream}
            setEnabled={setNtfDashbarStream}
            single
          />
        </View>
      </View>
    </View>
  )
}

export default DefaultSettings
