import * as React from 'react'
import Svg, { SvgProps, Defs, LinearGradient, Stop, G, Rect, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */
const SvgComponent = (props: SvgProps) => (
  <Svg width={60} height={60} {...props}>
    <Defs>
      <LinearGradient id="a" x1="3.822%" x2="98.671%" y1="90.401%" y2="11.121%">
        <Stop offset="0%" stopColor="#73157C" />
        <Stop offset="100%" stopColor="#2985D0" />
      </LinearGradient>
    </Defs>
    <G fill="none" fillRule="evenodd">
      <Rect width={59} height={59} x={0.5} y={0.5} fill="#FFF" stroke="url(#a)" rx={8} />
      <G fill="#000" fillRule="nonzero">
        <Path d="M25.884 26.21a5.401 5.401 0 0 0-5.396 5.395A5.405 5.405 0 0 0 25.884 37a5.405 5.405 0 0 0 5.395-5.395 5.401 5.401 0 0 0-5.395-5.396Zm0 8.238a2.841 2.841 0 0 1-2.838-2.838 2.841 2.841 0 0 1 2.838-2.838 2.841 2.841 0 0 1 2.838 2.838 2.841 2.841 0 0 1-2.838 2.838ZM44.116 24.349a1.674 1.674 0 1 0 0-3.349 1.674 1.674 0 0 0 0 3.349ZM15.048 26.226a5.324 5.324 0 0 0-.42-.017 4.796 4.796 0 0 0-3.456 1.452 4.799 4.799 0 0 0-3.44-1.452C5.125 26.21 3 28.274 3 30.814v5.814h2.55v-5.742c0-1.055.89-2.026 1.938-2.131.074-.006.148-.011.216-.011 1.178 0 2.142.938 2.147 2.087v5.797h2.607v-5.753c0-1.05.884-2.021 1.938-2.126.074-.005.148-.011.216-.011 1.178 0 2.148.933 2.153 2.076v5.814h2.607v-5.742a4.647 4.647 0 0 0-1.241-3.153 4.694 4.694 0 0 0-3.083-1.507ZM35.372 21.372h-2.605V37h2.605zM40.21 21.372h-2.605V37h2.604zM45.419 26.581h-2.605V37h2.605zM57.326 31.372a5.18 5.18 0 0 0-1.486-3.638c-.963-.981-2.232-1.525-3.584-1.525h-.067a5.289 5.289 0 0 0-3.718 1.581 5.397 5.397 0 0 0-1.564 3.75 5.372 5.372 0 0 0 1.553 3.851A5.29 5.29 0 0 0 52.261 37h.006a5.379 5.379 0 0 0 4.608-2.646l.122-.207-2.115-1.048-.105.173a2.85 2.85 0 0 1-2.466 1.402 2.879 2.879 0 0 1-2.771-2.12h7.786v-1.182Zm-5.17-2.82c1.168 0 2.214.774 2.582 1.873h-5.16a2.736 2.736 0 0 1 2.577-1.873Z" />
      </G>
    </G>
  </Svg>
)
export default SvgComponent
