import React from 'react'

import i18n from '../../locales/i18n'
import Favorites from './pages/Favorites'

export default () => [
  {
    title: i18n.t('title.favorites'),
    name: 'favorites',
    route: {
      exact: true,
      path: '/favorites',
      children: <Favorites />,
    },
  },
]
