import React from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import { ActivityIndicator, View, ViewStyle } from 'react-native'
import useStyles from './styles'
import ColoredText from '../CustomText/ColoredText'

/** @description
 * Migrated from @webstollen/shared-react-components
 */

const LoadingScreen = ({ text, style }: { text?: string; style?: ViewStyle }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  return (
    <View style={[styles.wrapper, style]}>
      <ActivityIndicator style={styles.spinner} size="large" />
      <View style={styles.spacer} />
      <ColoredText style={styles.text}>{text || t('layout.loading')}</ColoredText>
    </View>
  )
}

export default LoadingScreen
