import React, { useContext } from 'react'
import moment from 'moment'
import numbro from 'numbro'
import htmlDecode from '../../../../../utils/htmlDecode'
import { IWidgetProps } from '../../../../../types/props/Widget'
import useStyles from './styles'
import getOrderStatusIcon from '../../../../../utils/get-order-status-icon'
import { OrdersT } from '../../../../../types/widgetDataTypes/types'
import { Platform } from 'react-native'
import Widget from '../../../../components/Widget'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { FontAwesomeIcon } from '../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import ListView from '../../../../../components/ListView'
import OrdersSkeleton from '../../default/Orders/OrdersSkeleton'
import { UpsellingContext } from '../../../../../context/UpsellingContext'

const BasicOrders = ({ widget, ...props }: IWidgetProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const { open } = useContext(UpsellingContext)

  return (
    <Widget<OrdersT>
      title={`Basic ${t('shop.widget.orders.title')}`}
      widget={widget}
      isBasic
      loadingSkeleton={<OrdersSkeleton />}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, rowsPerPage = 4, interval = 'D30' }, { page = 1, search = '' } = {}) => ({
          body: {
            request: 'Analytics',
            widget: {
              order: {
                options: {
                  active: true,
                  details: false,
                  refresh: autoRefresh ? refreshInterval : undefined,
                  interval,
                  pagination: { descending: true, page, rowsPerPage },
                },
                search,
              },
            },
          },
          widget: 'order',
        }),
      ]}
      onClickWidget={() => open()}
      {...props}
    >
      {([, { order }, , , size]) => {
        return (
          <ListView
            size={size}
            styles={{
              thumbnailWrapper: styles.iconWrapper,
              actionTitleText: styles.orderValue,
              actionSubtitle: styles.actionSubtitle,
            }}
            data={order?.map(({ id, buyer, status, orderNumber, total, currency, created }) => ({
              id,
              icon: (
                <FontAwesomeIcon
                  color={'white'}
                  icon={getOrderStatusIcon(status)}
                  size={Platform.select({ default: undefined, web: 'sm' })}
                />
              ),
              mainTitle: buyer || t('shop.widget.orders.customer'),
              mainSubtitle: orderNumber,
              actionTitle: numbro(total).formatCurrency({
                ...numbro.languageData().currencyFormat,
                currencySymbol: htmlDecode(currency),
                spaceSeparated: true,
              }),
              actionSubtitle: moment(created).format('L'), //Bei Basic Widget nur Datum
              onPress: () => open(),
            }))}
          />
        )
      }}
    </Widget>
  )
}

export default BasicOrders
