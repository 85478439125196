import React from 'react'
import { Platform, View } from 'react-native'
import styles from './styles'
import Logo from '../../assets/imgs/Logo'

const LogoWrapper = ({ color }: { color?: string }) => {
  return (
    <View style={styles.logo}>
      {/* <Logo logoURL={logoURL} /> */}
      {Platform.OS === 'web' ? (
        <Logo viewBox="65 -20 300 65" color={color} />
      ) : (
        <Logo viewBox="45 -20 300 65" color={color} />
      )}
    </View>
  )
}

export default LogoWrapper
