import { Dimensions, StyleSheet } from 'react-native'
import colors from '../../constants/colors'
import { useHighlightColor, useSecondaryBackground, useTextColor } from '../../hooks/useColors'
import { Themes } from '../../types/dashbar/theme'
import { useTheme } from '../../utils/types/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    alert: {
      borderColor: colors.error,
      borderBottomWidth: 2,
      margin: 5,
      padding: 5,
      alignItems: 'center',
      justifyContent: 'center',
    },
    alertText: {
      color: colors.error,
    },
    container: {
      backgroundColor: useSecondaryBackground(),
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      margin: 20,
      flexDirection: 'column',
    },
    layoutWrapper: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    formWrapper: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
    },
    spacer: {
      width: 100,
    },
    wrapper: {
      backgroundColor: 'white',
      borderColor: theme === Themes.Dark ? 'white' : 'black',
      borderWidth: theme === Themes.Dark ? 0 : 1,
      padding: 20,
      paddingHorizontal: Dimensions.get('screen').width < 600 ? 15 : 50,
      borderRadius: 10,
      maxWidth: Dimensions.get('screen').width < 600 ? 350 : 490,
      minWidth: Dimensions.get('screen').width < 600 ? 350 : 490,
      flexDirection: 'column',
    },
    center: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    hairLine: {
      marginVertical: 5,
      display: 'flex',
      borderBottomColor: useTextColor(),
      borderBottomWidth: StyleSheet.hairlineWidth,
    },
    homeButton: {
      marginTop: 10,
      paddingHorizontal: 10,
      backgroundColor: useHighlightColor(),
    },
  })
}

export default useStyles
