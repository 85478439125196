import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faAppStore,
  faApple,
  faGitlab,
  faGoogle,
  faChrome,
  faEdge,
  faSafari,
  faFirefoxBrowser,
  faOpera,
  faWindows,
  faUbuntu,
  faLinux,
  faSuse,
  faRedhat,
  faFedora,
  faCentos,
  faGooglePlay,
} from '@fortawesome/free-brands-svg-icons'

import { fad } from '@fortawesome/pro-duotone-svg-icons'

//import { far } from '@fortawesome/pro-regular-svg-icons'

import {
  faBell as fadBell,
  faCheckCircle as fadCheckCircle,
  faCircle as fadCircle,
  faCog as fadCog,
  faColumns as fadColufaColumns,
  faEllipsisH as fadEllipsisH,
  faMoon as fadMoon,
  faPlusCircle as fadPlusCircle,
  faSave as fadSave,
  faSignIn as fadSignIn,
  faSignOut as fadSignOut,
  faSlidersH as fadSlidersH,
  faSpinner as fadSpinner,
  faSpinnerThird as fadSpinnerThird,
  faStore as fadStore,
  faSun as fadSun,
  faTimesCircle as fadTimesCircle,
  faTrash as fadTrash,
  faUserCog as fadUserCog,
  faChartPie as fadChartPie,
  faNewspaper as fadNewspaper,
  faBoxes as fadBoxes,
} from '@fortawesome/pro-duotone-svg-icons'

import fasDashbarIcon from './dashbar-icon/fas'
import fadDashbarIcon from './dashbar-icon/fad'

library.add(
  fasDashbarIcon,
  fadDashbarIcon,
  /* Brands */
  faApple,
  faGitlab,
  faGoogle,
  faChrome,
  faEdge,
  faSafari,
  faFirefoxBrowser,
  faOpera,
  faWindows,
  faUbuntu,
  faLinux,
  faSuse,
  faRedhat,
  faFedora,
  faCentos,
  faAppStore,
  faGooglePlay,
  /* PRO duotone */
  fadBell,
  fadCheckCircle,
  fadCircle,
  fadCog,
  fadColufaColumns,
  fadEllipsisH,
  fadMoon,
  fadPlusCircle,
  fadSave,
  fadSignIn,
  fadSignOut,
  fadSlidersH,
  fadSpinner,
  fadSpinnerThird,
  fadStore,
  fadSun,
  fadTimesCircle,
  fadTrash,
  fadUserCog,
  fadChartPie,
  fadNewspaper,
  fadBoxes,
  /* PRO regular */
  //...Object.values(far),
  /* PRO solid */
  ...Object.values(fad)
)
