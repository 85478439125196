import { StyleSheet } from 'react-native'
import { usePrimaryBackground } from '../../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    shopSelectionWrapper: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    shopSelectionTitle: {
      marginVertical: 2,
      justifyContent: 'center',
    },
    shopSelectionRow: {
      width: '100%',
      flexDirection: 'row',
      backgroundColor: usePrimaryBackground(),
      marginVertical: 5,
      borderRadius: 10,
      padding: 5,
      justifyContent: 'center',
    },
    dashboardViewWrapper: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    dashboardViewRow: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginVertical: 3,
      width: '100%',
    },
    dashboardCard: {
      padding: 7,
      borderRadius: 10,
      width: '47%',
      backgroundColor: usePrimaryBackground(),
    },
    selectedShopCard: {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      padding: 7,
      width: '47%',
    },
    selectedShopText: {
      fontSize: 8,
    },
    dashboardCardTitle: {
      fontSize: 10,
    },
    dashboardCardValue: {
      fontSize: 18,
      fontWeight: 'bold',
      width: '100%',
      textAlign: 'center',
      padding: 7,
    },
  })
}

export default useStyles
