import i18n from '../../../locales/i18n'
import AddNewWidget from './AddNewWidget'
import { IWidgetDefinition } from '../../../types/Widget'
import { Themes } from '../../../types/dashbar/theme'
import DeprecatedVersion from '../../components/DeprecatedVersion'

const widgetDefinitions: Record<string, IWidgetDefinition> = {
  conversion_v1: {
    component: DeprecatedVersion,
    title: `${i18n.t('shop.widget.conversion.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    // authorization: 'widget.conversion', // TODO: enable / refactor if needed
  },
  live_tracker_v1: {
    component: DeprecatedVersion,
    title: `${i18n.t('shop.widget.live-tracker.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: true, refreshInterval: 10, rowsPerPage: 4 },
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    // authorization: 'widget.liveTracker', // TODO: enable / refactor if needed
  },
  orders_v1: {
    component: DeprecatedVersion,
    title: `${i18n.t('shop.widget.orders.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: true, refreshInterval: 10, rowsPerPage: 4 },
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    // authorization: 'widget.orders', // TODO: enable / refactor if needed
  },
  runners_v1: {
    component: DeprecatedVersion,
    title: `${i18n.t('shop.widget.runners.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: true, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    // authorization: 'widget.runners', // TODO: enable / refactor if needed
  },
  sales_v1: {
    component: DeprecatedVersion,
    title: `${i18n.t('shop.widget.sales.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    // authorization: 'widget.sales', // TODO: enable / refactor if needed
  },
  order_value_v1: {
    component: DeprecatedVersion,
    title: `${i18n.t('shop.widget.order-value.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10, interval: 'D30' },
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    // authorization: 'widget.orderValue', // TODO: enable / refactor if needed
  },
  status_v1: {
    component: DeprecatedVersion,
    title: `${i18n.t('shop.widget.status.title')}`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false, refreshInterval: 10 },
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    // authorization: 'widget.status', // TODO: enable / refactor if needed
  },
  add_new_widget_v1: {
    component: AddNewWidget,
    minVersion: '1.0.0',
  },
  gender_v1: {
    component: DeprecatedVersion,
    title: `${i18n.t('shop.widget.gender.title')}`,
    minVersion: '2.0.2',
    settings: { autoRefresh: false, refreshInterval: 10 },
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    // authorization: 'widget.gender', // TODO: enable / refactor if needed
  },
  pagespeed_performance_v1: {
    component: DeprecatedVersion,
    title: 'Pagespeed Performance Desktop',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    authorizations: ['widgets.pagespeed'],
  },
  pagespeed_performance_mobile_v1: {
    component: DeprecatedVersion,
    title: 'Pagespeed Performance Mobile',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    authorizations: ['widgets.pagespeed'],
  },
  pagespeed_histperformance_v1: {
    component: DeprecatedVersion,
    title: 'Pagespeed Performance History Desktop',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    authorizations: ['widgets.pagespeed'],
  },
  pagespeed_histperformance_mobile_v1: {
    component: DeprecatedVersion,
    title: 'Pagespeed Performance History Mobile',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    authorizations: ['widgets.pagespeed'],
  },
  pagespeed_webvitals_v1: {
    component: DeprecatedVersion,
    title: 'Pagespeed WebVitals Desktop',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    authorizations: ['widgets.pagespeed'],
  },
  pagespeed_webvitals_mobile_v1: {
    component: DeprecatedVersion,
    title: 'Pagespeed WebVitals Mobile',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    authorizations: ['widgets.pagespeed'],
  },
  sale_forecast_v1: {
    component: DeprecatedVersion,
    title: `Sales Forecast`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false },
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    // authorization: 'widget.gender', // TODO: enable / refactor if needed
  },
  visitor_forecast_v1: {
    component: DeprecatedVersion,
    title: `Visitors Forecast`,
    minVersion: '1.0.0',
    settings: { autoRefresh: false },
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    // authorization: 'widget.gender', // TODO: enable / refactor if needed
  },
  gsc_top5_search_v1: {
    component: DeprecatedVersion,
    title: 'Google Searchrequests',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    // TODO: Hier durch search engine authorization ersetzen
    authorizations: ['widgets.search'],
  },
  gsc_top5_pages_v1: {
    component: DeprecatedVersion,
    title: 'Google Pages',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    authorizations: ['widgets.search'],
  },
  gsc_devices_v1: {
    component: DeprecatedVersion,
    title: 'Google Devices',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    authorizations: ['widgets.search'],
  },
  gsc_overview_v1: {
    component: DeprecatedVersion,
    title: 'Google Overview',
    minVersion: '1.0.0',
    settings: {},
    icon: {
      [Themes.Light]: '',
      [Themes.Dark]: '',
    },
    shopSystem: [],
    authorizations: ['widgets.search'],
  },
}

export default widgetDefinitions
