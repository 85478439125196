import { Text, View } from 'native-base'
import React, { useContext } from 'react'
import useStyles from './styles'
import useCurrentBundleBooking from './../../../hooks/booking/useCurrentBundleBooking'
import { SessionContext } from '../../../context/SessionContext'

const BundleComponent = () => {
  const styles = useStyles()
  const { company } = useContext(SessionContext)
  const booking = useCurrentBundleBooking()
  return (
    <View style={styles.wrapper}>
      <Text allowFontScaling={false} style={styles.text}>
        {`${company?.bundle || 'free'} ${booking?.isTrial ? '(trial)' : ''}`}
      </Text>
    </View>
  )
}

export default BundleComponent
