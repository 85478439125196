import { Dimensions, Platform, StyleSheet } from 'react-native'
import colors from '../../../../../../constants/colors'
import { Themes } from '../../../../../../types/dashbar/theme'
import { useTheme } from '../../../../../../utils/types/theme'

const useStyles = () => {
  const theme = useTheme()
  const isSmallDevice = Dimensions.get('window').width < 400
  return StyleSheet.create({
    wrapper: {
      alignSelf: 'center',
      marginHorizontal: Platform.OS === 'web' ? 100 : isSmallDevice ? 5 : 10,
      width: Platform.OS === 'web' ? '70%' : '100%',
      marginBottom: 10,
      padding: 10,
      flexDirection: 'column',
      borderRadius: 16,
    },
    title: {
      padding: 5,
      fontSize: 18,
    },
    list: {
      // backgroundColor: 'green',
    },
    type: {
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent2 : colors.lightBgTransparent1,
      padding: 5,
      marginHorizontal: 2.5,
      borderWidth: 2,
      borderRadius: 10,
      borderColor: 'transparent',
    },
    typeIcon: {
      width: 200,
      height: 200,
      marginBottom: 10,
    },
    widget: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
      padding: 5,
      marginHorizontal: 3,
      marginVertical: 3,
      width: '20%',
      minWidth: 220,
      maxWidth: 220,
      minHeight: 250,
      overflow: 'hidden',
    },
  })
}

export default useStyles
