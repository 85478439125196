import moment from 'moment'
import { Timestamp } from '@firebase/firestore-types'

export const getTimestamp = (timestamp: Timestamp) => {
  if (timestamp === undefined) return
  const date = timestamp.toDate()
  const now = new Date()
  const passedMinutes = Math.floor((now.getTime() - date.getTime()) / 1000 / 60)
  if (passedMinutes < 60) return Math.floor(passedMinutes) + 'Min'
  if (passedMinutes < 1440) return Math.floor(passedMinutes / 60) + ' Std'
  return moment(timestamp.toDate()).fromNow()
}
