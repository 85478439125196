import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

const Mastercard = (props: SvgProps) => (
  <Svg width={32} height={24} fill="none" {...props}>
    <Path
      d="M4.129.5h23.742c2 0 3.629 1.64 3.629 3.674v15.652c0 2.034-1.63 3.674-3.629 3.674H4.129c-2 0-3.629-1.64-3.629-3.674V4.174C.5 2.14 2.13.5 4.129.5Z"
      fill="#fff"
      stroke="#E6E6E6"
    />
    <Path
      d="M12.903 17.217c2.85 0 5.162-2.336 5.162-5.217s-2.311-5.217-5.162-5.217c-2.85 0-5.161 2.336-5.161 5.217s2.31 5.217 5.161 5.217Z"
      fill="#E7001A"
    />
    <Path
      d="M19.097 17.217c2.85 0 5.161-2.336 5.161-5.217s-2.31-5.217-5.161-5.217c-2.85 0-5.162 2.336-5.162 5.217s2.311 5.217 5.162 5.217Z"
      fill="#F49B1C"
    />
    <Path
      opacity={0.3}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7.826A5.228 5.228 0 0 1 18.065 12c0 1.707-.811 3.222-2.065 4.174A5.228 5.228 0 0 1 13.935 12c0-1.707.811-3.222 2.065-4.174Z"
      fill="#E7001A"
    />
  </Svg>
)

export default Mastercard
