import { StyleSheet } from 'react-native'
import colors from '../../../../constants/colors'
import { useSecondaryBackground } from '../../../../hooks/useColors'
import { Themes } from '../../../../types/dashbar/theme'
import { useTheme } from '../../../../utils/types/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    containerInModal: {
      marginHorizontal: 10,
      marginBottom: 10,
      padding: 10,
      backgroundColor: 'transparent',
      borderRadius: 16,
    },
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'transparent',
      marginTop: 10,
    },
    container: {
      flex: 1,
      width: '100%',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      marginBottom: 5,
    },
    button: {
      borderRadius: 16,
      marginVertical: 2,
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 50,
      width: '100%',
      backgroundColor: useSecondaryBackground(),
    },
    buttonMain: {
      borderRadius: 16,
      //marginHorizontal: 10,
      marginBottom: 7,
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 50,
      backgroundColor: theme === Themes.Dark ? colors.darkBgTransparent2 : colors.lightBgTransparent2,
    },
    buttonText: {
      width: '75%',
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-start',
      alignItems: 'center',
    },
    buttonTextMain: {
      marginRight: 5,
      overflow: 'hidden',
      maxWidth: '75%',
    },
    textInput: {
      color: theme === Themes.Dark ? 'white' : 'black',
    },
  })
}

export default useStyles
