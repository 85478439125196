import { Platform } from 'react-native'
import * as SentryBrowser from '@sentry/browser'
import * as SentryNative from '@sentry/react-native'

export const mySentry = () => {
  if (Platform.OS === 'web') {
    return SentryBrowser
  }
  return SentryNative
}
