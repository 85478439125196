import React, { useContext, useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'
import WidgetTypes from '../CreateWidget/WidgetSettings/Type'
import WidgetShop from '../CreateWidget/WidgetSettings/Shop'
import useUpdateWidget from '../../../../hooks/widget/useUpdateWidget'
import { isLoaded } from 'react-redux-firebase'
import useDashboard from '../../../../hooks/dashboard/useDashboard'
import removeEmpty from '../../../../utils/remove-empty-keys'
import { isEqual } from 'lodash'
import { SessionContext } from '../../../../context/SessionContext'
import { useNavigate, useParams } from '../../../../components/Router/Router'
import LoadingScreen from '../../../../components/LoadingScreen'
import BoldText from '../../../../components/CustomText/BoldText'
import { IWidget } from '../../../../types/dashboard'

const EditWidget = () => {
  const styles = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [selectedType, selectType] = useState<string | null>()
  const [selectedShop, selectShop] = useState<string | null>()

  const { shops } = useContext(SessionContext)

  const { dashboardId } = useParams()
  const { widgetId } = useParams()

  const updateWidget = useUpdateWidget(dashboardId || '-')
  const dashboard = useDashboard(dashboardId || '-')

  useEffect(() => {
    if (isLoaded(dashboard)) {
      const widget = dashboard.widgets.find(({ id }) => id === widgetId)
      selectType(widget?.type)
      selectShop(widget?.settings.shop)
    }
  }, [dashboard, widgetId])

  if (!isLoaded(shops)) {
    return <LoadingScreen text={`${t('loading.shops')}`} />
  }

  if (!isLoaded(dashboard)) {
    return <LoadingScreen text={`${t('loading.dashboard')}`} />
  }

  const disabledButton = () => {
    if (['misc.advertisement_v1'].includes(selectedType || '')) {
      return false
    }

    return !selectedShop || !selectedType
  }

  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <WidgetTypes selectedType={selectedType} selectType={selectType} />
        <WidgetShop
          disabled={['misc.advertisement_v1'].includes(selectedType || '')}
          selectedShop={selectedShop}
          selectShop={selectShop}
          shops={shops}
        />

        <TouchableOpacity
          style={[styles.button, disabledButton() && styles.disabledButton]}
          disabled={disabledButton()}
          onPress={async () => {
            const widget = dashboard.widgets.find(({ id }) => id === widgetId)

            const newWidget = removeEmpty({
              ...widget,
              type: `${selectedType}`,
              settings: {
                ...widget?.settings,
                shop: selectedShop,
              },
            })

            if (!isEqual(widget, newWidget)) {
              await updateWidget(newWidget as IWidget)
            }

            navigate(`/settings/dashboard/${dashboardId}`)
          }}
        >
          <BoldText style={styles.buttonText}>Update Widget</BoldText>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default EditWidget
