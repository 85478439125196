import { StyleSheet } from 'react-native'
import colors from '../../constants/colors'

const useStyles = () => {
  return StyleSheet.create({
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 30,
      borderRadius: 5,

      marginVertical: 5,
      paddingVertical: 5,
    },
    primary: {
      backgroundColor: colors.highlightDark,
    },
    primaryText: {
      color: colors.darkText1,
    },
  })
}

export default useStyles
