import { StyleSheet } from 'react-native'
import { usePrimaryBackground } from '../../../hooks/useColors'
import { Themes } from '../../../types/dashbar/theme'
import { useTheme } from '../../../utils/types/theme'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    wrapper: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: theme === Themes.Dark ? 'rgba(255,255,255, 0.04)' : 'rgba(0,0,0, 0.04)',
      marginHorizontal: 18,
      padding: 8,
      marginVertical: 4,
      borderRadius: 15,
      width: 250,
      maxWidth: 230,
    },
    avatar: {
      backgroundColor: usePrimaryBackground(),
      width: 30,
      height: 30,
      borderRadius: 25,
    },
    profileText: {
      marginLeft: 12,
    },
    menu: {
      marginHorizontal: 20,
      width: 250,
    },
    itemWrapper: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    itemText: {
      marginLeft: 10,
    },
  })
}

export default useStyles
