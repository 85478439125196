import { useCallback, useContext } from 'react'
import { useFirestore } from 'react-redux-firebase'
import { SessionContext } from '../../context/SessionContext'

const useRemoveDashboard = () => {
  const firestore = useFirestore()
  const { user } = useContext(SessionContext)
  return useCallback(
    async (dashboardId: string) => {
      firestore
        .collection(`company/${user?.company ?? '-'}/dashboard`)
        .doc(dashboardId)
        .delete(),
        //Also delete favorite if it contains dashboardID
        firestore
          .collection(`user/${user?.id}/favorites`)
          .get()
          .then((docs) => {
            docs.forEach((doc) => {
              const url: string = doc?.data()?.url || ''
              if (url.includes(dashboardId)) {
                doc.ref.delete()
              }
            })
          })
    },
    [firestore, user?.company]
  )
}

export default useRemoveDashboard
