import React, { useEffect, useState } from 'react'
import { Dimensions, View, Image, TouchableOpacity, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { AuthProvider as AuthProviderT } from '@firebase/auth-types'
import Logo from '../Logo'
import colors from '../../constants/colors'
import useStyles from '../Login/styles'
import SignupForm from '../Form/SignupForm'
import AuthProvider from '../AuthProvider'
import { useLoginProvider } from '../../module/base/page/Login/helper'
import ColoredText from '../CustomText/ColoredText'
import { Link } from '../Router/Router'
import { processFontFamily } from 'expo-font'
import { openUrl } from '../Browser'
import AppStore from '../../assets/imgs/login/AppStore'
import PlayStore from '../../assets/imgs/login/PlayStore'

interface ISignupProps {
  alert?: {
    content?: string
    color?: 'error' | 'success'
  }
  loading: boolean
  handleSignup: (params: { email: string; password: string }) => void
  handleProviderSignup: (provider: AuthProviderT) => void
  providerSignupEnabled: boolean
}

const Signup = ({ alert, loading, handleSignup, handleProviderSignup, providerSignupEnabled = true }: ISignupProps) => {
  const { t } = useTranslation()
  const { handleCredentialLogin } = useLoginProvider()
  const styles = useStyles()

  const [screenWidth, setScreenWidth] = useState<number>(Dimensions.get('window').width)

  const showImage = screenWidth > 1200
  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setScreenWidth(window.width)
    })
    return () => subscription?.remove()
  })

  return (
    <View style={styles.container}>
      <View style={styles.layoutWrapper}>
        <View style={styles.formWrapper}>
          <View style={styles.wrapper}>
            <View style={styles.center}>
              <View style={{ maxHeight: 75, marginLeft: 80 }}>
                <Logo color="black" />
              </View>

              <ColoredText style={{ color: 'black' }}>{t('text.baseLogin.welcome')}</ColoredText>
            </View>
            {alert && (
              <View style={[styles.alert, { borderColor: colors[alert.color || 'error'] }]}>
                <ColoredText style={{ color: colors[alert.color || 'error'] }}>{alert.content}</ColoredText>
              </View>
            )}

            <View
              style={{
                paddingTop: 20,
              }}
            >
              <SignupForm loading={loading} onSubmit={handleSignup} />
              {providerSignupEnabled && (
                <AuthProvider
                  loading={loading}
                  handleProviderLogin={handleProviderSignup}
                  handleCredentialLogin={handleCredentialLogin}
                />
              )}

              {/* <View style={{ flexDirection: 'row', display: 'flex' }}>
              <ColoredText>{t('text.baseSignup.hasAccount')} </ColoredText>
              <Link to={'/login'}>{t('link.baseSignup.login')}</Link>
            </View> */}
            </View>
            <View
              style={{
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <ColoredText
                style={{
                  fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
                  fontSize: 12,
                  color: 'black',
                }}
              >{`${t('text.baseSignup.hasAccount')}`}</ColoredText>
              <Link to={'/login'} style={{ marginBottom: 5, textDecorationLine: 'none' }}>
                <ColoredText
                  style={{
                    fontFamily: processFontFamily('Comfortaa_700Bold') || undefined,
                    fontSize: 12,
                    color: colors.linkColor,
                  }}
                >
                  {t('link.baseSignup.login')}
                </ColoredText>
              </Link>
            </View>
          </View>
          {Platform.OS === 'web' && (
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignContent: 'center',
                alignItems: 'center',
                marginTop: 15,
              }}
            >
              <ColoredText style={{ marginRight: 10, fontSize: 14 }}>{`${t(
                'text.baseSignup.install_app'
              )}`}</ColoredText>
              <TouchableOpacity onPress={() => openUrl(`https://apps.apple.com/us/app/dash-bar/id1439795569`)}>
                <AppStore />
              </TouchableOpacity>
              <TouchableOpacity
                style={{ marginLeft: 10 }}
                onPress={() => openUrl(`https://play.google.com/store/apps/details?id=bar.dash.app&hl=de`)}
              >
                <PlayStore />
              </TouchableOpacity>
            </View>
          )}
        </View>
        {showImage === true && (
          <>
            <View style={styles.spacer} />
            <View>
              <Image
                {...{
                  height: 550,
                  width: 300,
                  resizeMode: 'contain',
                  source: { uri: 'https://ws-cdn.de/dashbar/landingpage.png' },
                  alt: 'Not found',
                  style: {
                    height: 550,
                    width: 300,
                  },
                }}
              />
            </View>
          </>
        )}
      </View>
    </View>
  )
}

export default Signup
