import { StyleSheet } from 'react-native'
import sizes from '../../constants/sizes'

const useStyles = () => {
  return StyleSheet.create({
    button: {
      borderColor: 'black',
      backgroundColor: 'black',
      borderWidth: 1,
      borderRadius: sizes.buttonBorderRadius,
      height: sizes.buttonHeight,
      width: '100%',
      color: 'white',
    },
    loginText: {
      color: 'white',
    },
  })
}

export default useStyles
