import React, { ReactNode, ComponentType } from 'react'
import FallbackComponent from './FallbackComponent'

/** @description
 * Migrated from @webstollen/shared-react-components
 */

type Props = {
  children: ReactNode
  FallbackComponent: ComponentType<{ error: Error; resetError: () => void }>
  onError?: (error: Error, stack: string) => void
}

type State = { error: Error | null; hasError: boolean }

class ErrorBoundary extends React.Component<Props, State> {
  state = { error: null as unknown as Error, hasError: false }

  static defaultProps = {
    FallbackComponent,
  }

  static getDerivedStateFromError(error: Error) {
    return { error, hasError: true }
  }

  componentDidCatch(error: Error, info: { componentStack: string }) {
    if (typeof this.props.onError === 'function') {
      this.props.onError(error, info.componentStack)
    }
  }

  resetError = () => {
    this.setState({ error: null, hasError: false })
  }

  render() {
    const { FallbackComponent } = this.props

    return this.state.hasError ? (
      <FallbackComponent error={this.state.error} resetError={this.resetError} />
    ) : (
      this.props.children
    )
  }
}

export default ErrorBoundary
