import React from 'react'

import { ImageProps } from 'react-native'
import LazyLoadingImage from '../LazyLoadingImage'

const ProductImage = ({ photoURL, ...props }: Partial<ImageProps> & { photoURL: any }) => {
  const defaultImage = require('../../assets/imgs/default_bild.png')

  return <LazyLoadingImage defaultImage={defaultImage} imageUrl={photoURL} {...props} />
}

export default ProductImage
