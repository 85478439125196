import { Dimensions, StyleSheet } from 'react-native'
import { useHighlightColor, usePrimaryBackground, useSecondaryBackground } from '../../../../../../hooks/useColors'

const useStyles = () => {
  const isSmallDevice = Dimensions.get('window').width < 500
  const highlight = useHighlightColor()
  return StyleSheet.create({
    wrapper: {
      marginHorizontal: 10,
      marginTop: 50,
      marginBottom: 10,
      padding: 10,
      backgroundColor: useSecondaryBackground(),
      borderRadius: 16,
    },
    wrapperDisabled: {
      backgroundColor: `${useSecondaryBackground()}80`,
    },
    title: {
      padding: 5,
      fontSize: 18,
    },
    list: {
      backgroundColor: 'transparent',
      borderRadius: 10,
    },

    shop: {
      backgroundColor: usePrimaryBackground(),
      padding: 5,
      marginHorizontal: 2.5,
      borderWidth: 2,
      borderRadius: 10,
      borderColor: 'transparent',
      alignItems: 'center',
      maxWidth: 160,
      width: 160,
    },

    selectedShop: {
      borderColor: useHighlightColor(),
    },

    shopIcon: {
      width: 90,
      height: 90,

      padding: 5,
    },
    shopIconWrapper: {
      width: 100,
      height: 100,

      backgroundColor: 'white',
      borderRadius: 50,

      overflow: 'hidden',
    },

    shopName: {
      marginTop: 5,
    },
    shopWrapper: {
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    mainWrapper: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    extensionCardWrapper: {
      width: isSmallDevice ? '100%' : '80%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
      paddingVertical: 20,
      paddingHorizontal: 5,
      flexWrap: 'wrap',
      gap: 15,
    },
    headerText: {
      width: isSmallDevice ? '100%' : '80%',
      marginTop: 15,
      paddingHorizontal: 5,
      paddingVertical: 5,
      textAlign: 'left',
      fontSize: 16,
    },
    divider: {
      height: 2,
      minHeight: 2,
      maxHeight: 2,
      width: '80%',
      marginVertical: 2,
      border: 1,
      borderStyle: 'solid',
      borderColor: highlight,
      backgroundColor: highlight,
    },
  })
}

export default useStyles
