// import { initializeApp, getApps } from 'firebase/app'
import Constants from 'expo-constants'
import { config, ReCaptchaV3SiteKey } from '../firebaseConfig'
import { FUNCTIONS_REGION } from '../../functions/src/constants'
import { Platform } from 'react-native'
import { EMULATORS_ACTIVATED } from './setting'
// import { connectAuthEmulator, getAuth } from 'firebase/auth'
// import { connectFirestoreEmulator, getFirestore, initializeFirestore } from 'firebase/firestore'
// import { getAnalytics } from 'firebase/analytics'
// import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/app-check'
import 'firebase/compat/storage'
import * as firebaseAuth from 'firebase/auth'
//Workaround https://github.com/firebase/firebase-js-sdk/issues/7584#issuecomment-1692195713
const reactNativePersistence = (firebaseAuth as any).getReactNativePersistence
import AsyncStorage from '@react-native-async-storage/async-storage'

const getApp = () => {
  if (!firebase.apps.length) {
    const app = firebase.initializeApp(config)
    if (Platform.OS === 'web') {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        if (EMULATORS_ACTIVATED) {
          firebase.functions(app).useEmulator('localhost', 5050)
          firebase.auth(app).useEmulator('http://localhost:9099')
          app.firestore().settings({
            host: 'localhost:8080',
            ssl: false,
            ignoreUndefinedProperties: true,
          })
          firebase.firestore().useEmulator('localhost', 8080)
        }
        //firebase.analytics(app)
      } else {
        const appCheck = firebase.appCheck()
        appCheck.activate(ReCaptchaV3SiteKey, true)
        appCheck.setTokenAutoRefreshEnabled(true)
        //Check if this works with mobile Apps
        import(`firebase/compat/analytics`).then(() => {
          firebase.analytics(app)
        })
        //firebase.performance(app)
      }
    } else if (__DEV__) {
      firebaseAuth.initializeAuth(app, {
        persistence: reactNativePersistence(AsyncStorage),
      })
      if (EMULATORS_ACTIVATED) {
        const lanIP = `${Constants.expoConfig?.extra?.expoGo?.debuggerHost?.split(':').shift() || 'localhost'}`
        console.debug(lanIP)
        firebase.functions(app).useEmulator(lanIP, 5050)
        firebase.auth(app).useEmulator(`http://${lanIP}:9099`)
        app.firestore().settings({
          host: `${lanIP}:8080`,
          ssl: false,
          ignoreUndefinedProperties: true,
        })
        firebase.firestore().useEmulator(lanIP, 8080)
      }
    } else {
      //Must not be used in web
      firebaseAuth.initializeAuth(app, {
        persistence: reactNativePersistence(AsyncStorage),
      })
      //firebase.analytics(app)
      //firebase.performance(app)
    }
  }
  return !firebase.apps.length ? firebase.initializeApp(config) : firebase.apps[0]
}

// const getApp = () => {
//   const apps = getApps()

//   if (!apps.length) {
//     const app = initializeApp(config)
//     const functions = getFunctions(app)
//     const auth = getAuth(app)
//     const firestore = getFirestore(app)
//     if (Platform.OS == 'web') {
//       if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//         if (EMULATORS_ACTIVATED) {
//           connectFunctionsEmulator(functions, 'localhost', 5100)
//           connectAuthEmulator(auth, 'http://localhost:9099')
//           initializeFirestore(app, { host: 'localhost:8080', ssl: false, ignoreUndefinedProperties: true })
//           connectFirestoreEmulator(firestore, 'localhost', 8080)
//         }
//         getAnalytics(app)
//       } else {
//         initializeAppCheck(app, { provider: reCaptchaV3Provider, isTokenAutoRefreshEnabled: true })
//         getAnalytics(app)
//         //firebase.performance(app)
//       }
//     } else if (__DEV__) {
//       if (EMULATORS_ACTIVATED) {
//         const lanIP = `${Constants.manifest2?.extra?.expoGo?.debuggerHost?.split(':').shift() || 'localhost'}`
//         console.debug(lanIP)
//         connectFunctionsEmulator(functions, lanIP, 5100)
//         connectAuthEmulator(auth, `http://${lanIP}:9099`)
//         initializeFirestore(app, { host: `${lanIP}:8080`, ssl: false, ignoreUndefinedProperties: true })
//         connectFirestoreEmulator(firestore, lanIP, 8080)
//       }
//     } else {
//       //firebase.analytics(app)
//       //firebase.performance(app)
//     }
//   }
//   return !apps.length ? initializeApp(config) : apps[0]
// }

// export const functions = (region = FUNCTIONS_REGION): Functions => {
//   if (Constants.manifest?.extra?.useEmulators || EMULATORS_ACTIVATED) {
//     const lanIP = Constants?.manifest?.extra?.lanIP
//     const functions = getFunctions(getApp(), region)
//     connectFunctionsEmulator(functions, lanIP, 5050)
//     return functions
//   }
//   return getFunctions(getApp(), region)
// }
export const functions = (region = FUNCTIONS_REGION): firebase.functions.Functions => {
  if (Constants.expoConfig?.extra?.useEmulators) {
    const lanIP = Constants?.expoConfig?.extra?.lanIP
    const functions = getApp().functions(region)
    functions.useEmulator(lanIP, 5050)
    return functions
  }
  return getApp().functions(region)
}

// const reCaptchaV3Provider = new ReCaptchaV3Provider(ReCaptchaV3SiteKey)

export default getApp
