import { functions } from '../../../../../../firebase'
import i18n from '../../../../../../locales/i18n'
const setWidgetRefreshInterval = functions().httpsCallable('calls-user-setWidgetRefreshInterval')

export const storeSettings = async (
  widget: Record<string, any>,
  bundle: string | undefined,
  setError: (err: React.SetStateAction<string | null>) => void
) => {
  const interval = +widget.interval
  let allowedInterval = 120
  //console.log(`Bundle: ${bundle}`)
  switch (bundle) {
    case 'pro':
      allowedInterval = 1
      break
    case 'business':
      allowedInterval = 10
      break
    default:
      allowedInterval = 120
  }
  if (interval < allowedInterval) {
    setError(i18n.t('settings.widget.min_interval_for_bundle', { interval: allowedInterval }))
    return
  }
  if (interval === 0) {
    setError(`0 ist nicht gültig`)
    return
  }

  setWidgetRefreshInterval({ widget: widget })
    .then(() => {
      setError(null)
    })
    .catch((err) => {
      setError(err)
    })
}
