import React from 'react'
import { View } from 'react-native'
import ColoredText from '../../../CustomText/ColoredText'
import { FontAwesomeIcon } from '../../../FontAwesome/ColoredFontAwesomeIcon'
import colors from '../../../../constants/colors'

const UpsellingCardRow = ({ item }: { item: string }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingBottom: 15,
      }}
    >
      <FontAwesomeIcon icon={'check'} color={colors.success} />
      <ColoredText style={{ marginLeft: 20 }}>{item}</ColoredText>
    </View>
  )
}

export default UpsellingCardRow
