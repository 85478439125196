import { Skeleton } from 'native-base'
import React from 'react'
import { View } from 'react-native'
import { usePrimaryBackground } from '../../../../../../hooks/useColors'

const PerformanceSkeleton = () => {
  const loadingColor = usePrimaryBackground()
  return (
    <View
      style={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        padding: 5,
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <Skeleton style={{ borderRadius: 100, height: '95%', width: '50%' }} isLoaded={false} startColor={loadingColor} />
    </View>
  )
}

export default PerformanceSkeleton
