import { StyleSheet } from 'react-native'
import { useTheme } from '../../../../../../utils/types/theme'
import { Themes } from '../../../../../../types/dashbar/theme'
import colors from '../../../../../../constants/colors'

const useStyles = () => {
  const theme = useTheme()
  return StyleSheet.create({
    input: {
      width: '50%',
      marginHorizontal: 15,
      padding: 5,
      borderRadius: 5,
      borderColor: theme === Themes.Dark ? '#EEE' : '#111',
      color: theme === Themes.Dark ? '#EEE' : '#111',
      borderWidth: 1,
      marginVertical: 7,
      borderStyle: 'solid',
      fontFamily: 'Comfortaa',
    },
    button: {
      backgroundColor: theme === Themes.Dark ? '#FFF' : '#000',
      width: '25%',
      alignSelf: 'center',
      borderRadius: 10,
      color: theme === Themes.Dark ? '#000' : '#FFF',
      height: 30,
    },
    connectButton: {
      backgroundColor: theme === Themes.Dark ? '#FFF' : '#000',
      width: '25%',
      alignSelf: 'center',
      borderRadius: 10,
      color: theme === Themes.Dark ? '#000' : '#FFF',
      height: 30,
      marginHorizontal: 15,
    },
    disconnectButton: {
      width: '25%',
      alignSelf: 'center',
      borderRadius: 10,
      color: theme === Themes.Dark ? '#FFF' : '#000',
      borderColor: theme === Themes.Dark ? '#FFF' : '#000',
      borderWidth: 1,
      borderStyle: 'solid',
      height: 30,
      marginHorizontal: 15,
    },
    connectTextWrapper: {
      width: '50%',
      marginHorizontal: 15,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
    },
    wawiConnectionMsg: {
      width: '85%',
      marginVertical: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
    },
    successMessage: {
      marginBottom: 10,
      alignSelf: 'flex-start',
      marginLeft: 15,
      color: colors.success,
    },
    errorMessage: {
      alignSelf: 'flex-start',
      marginLeft: 15,
      color: colors.error,
    },
    hyperlink: {
      textDecorationLine: 'underline',
      marginLeft: 15,
      marginBottom: 10,
    },
    spinner: {
      marginLeft: 5,
    },
  })
}

export default useStyles
