import { StyleSheet } from 'react-native'
import colors from '../../../../../../constants/colors'
import sizes from '../../../../../../constants/sizes'
import { useHighlightColor, useSecondaryBackground } from '../../../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      borderRadius: 15,
      marginTop: 10,
      padding: 10,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      backgroundColor: useSecondaryBackground(),
    },
    backgroundFree: {
      backgroundColor: colors.bundleFree,
    },
    backgroundBusiness: {
      backgroundColor: colors.bundleBusiness,
    },
    backgroundPro: {
      backgroundColor: colors.bundlePro,
    },
    //Mobile Settings
    mobileCard: {
      width: '100%',
      height: 120,
      minHeight: 120,
      maxHeight: 120,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    bundleWrapper: {
      height: 100,
      minHeight: 100,
      maxHeight: 100,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      borderRadius: 15,
      padding: 5,
      overflow: 'hidden',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'center',
    },
    bundleTextWrapper: {
      width: '50%',
      minWidth: '50%',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      padding: 10,
      paddingHorizontal: 20,
    },
    iconWrapper: {
      alignSelf: 'flex-end',
      alignItems: 'flex-end',
      flex: 1,
      height: '100%',
      maxHeight: '100%',
      minHeight: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },
    itemWrapper: {
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      flex: 1,
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'flex-start',
    },
    bundleOptionsWrapper: {
      width: '100%',
      paddingVertical: 10,
      paddingHorizontal: 10,
      flex: 1,
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'space-around',
    },
    itemText: {
      marginHorizontal: 15,
    },
    intervalCard: {
      borderRadius: 15,
      borderWidth: 1,
      borderColor: colors.color2,
      marginVertical: 10,
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
    },
    amountInterval: {
      width: '30%',
    },
    amountValue: {
      width: '40%',
      fontWeight: '600',
    },
    intervalWrapper: {
      width: '100%',
      height: '100%',
      minHeight: '100%',
      flex: 1,
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    selected: {
      borderWidth: 2,
    },
    touchWrapper: {
      borderRadius: 15,
      borderWidth: 1,
      borderColor: colors.color2,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      padding: 15,
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      marginVertical: 5,
    },
    buttonWrapper: {
      width: '100%',
      marginVertical: 20,
      maxWidth: '100%',
      minWidth: '100%',
      flex: 1,
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    deleteButton: {
      borderColor: colors.error,
      borderWidth: 2,
      padding: 15,
      width: '80%',
      height: sizes.buttonHeight,
      borderRadius: 15,
      color: colors.error,
      backgroundColor: colors.darkBgTransparent1,
    },
    deleteText: {
      width: '60%',
      maxWidth: '60%',
      marginHorizontal: 20,
      fontSize: 12,
      paddingVertical: 10,
      textAlign: 'center',
    },
    cancelButton: {
      borderRadius: 15,
      borderColor: useHighlightColor(),
      borderWidth: 1,
      height: sizes.buttonHeight,
      width: '100%',
    },
    deleteSubscriptionButton: {
      borderColor: colors.error,
      borderWidth: 2,
      width: '100%',
      height: sizes.buttonHeight,
      borderRadius: 15,
      color: 'white',
    },
    deleteModalWrapper: {
      width: '100%',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
}

export default useStyles
